import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row bg-gray-50 border border-solid border-gray-200 rounded-md text-gray-500" }

import { PropType } from 'vue';
import BaseSegment from './BaseSegment.vue';
import { ISegmentOption } from '@/lib';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSegmentList',
  props: {
  items: {
    type: Array as PropType<ISegmentOption[]>,
    required: true
  },
  modelValue: {
    type: Number,
    default: 0
  },
  size: {
    type: String,
    default: 'small',
    validator: (value: string) => ['regular', 'small', 'xsmall'].includes(value)
  }
},
  emits: ["update:modelValue"],
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item) => {
      return (_openBlock(), _createBlock(BaseSegment, {
        key: item.value,
        label: item.label,
        selected: __props.modelValue === item.value,
        count: item.count,
        size: __props.size,
        "data-dusk": item.label.replaceAll(' ', '-'),
        style: {"margin":"-1px"},
        onClick: ($event: any) => (_ctx.$emit('update:modelValue', item.value))
      }, null, 8, ["label", "selected", "count", "size", "data-dusk", "onClick"]))
    }), 128))
  ]))
}
}

})