import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-6 h-full sm:max-w-xl py-8 md:max-w-3xl lg:max-w-5xl xl:max-w-6xl sm:mx-auto mx-4" }
const _hoisted_2 = { class: "flex flex-row justify-between" }
const _hoisted_3 = { class: "flex flex-col space-y-2" }
const _hoisted_4 = { class: "font-medium" }
const _hoisted_5 = { class: "flex flex-row items-center space-x-6" }
const _hoisted_6 = { class: "flex flex-col space-y-1" }
const _hoisted_7 = { class: "text-xs font-medium leading-tight text-gray-500" }
const _hoisted_8 = { class: "text-sm font-medium" }
const _hoisted_9 = { class: "flex flex-col space-y-1" }
const _hoisted_10 = { class: "text-xs font-medium leading-tight text-gray-500" }
const _hoisted_11 = { class: "text-sm font-medium" }

import { computed, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { BaseButton } from '@/lib/components/Button';
import { FormConfig, FormSection } from '@/models';
import { i18n } from '@/i18n/i18n';
import FormSummarySection from '@/custom/menicon/components/encounter-summary/FormSummarySection.vue';
import { useSmartFormStore } from '@/stores/smartForm.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'SmartFormSummary',
  props: {
  config: {
    type: Object as PropType<FormConfig>,
    required: true
  },

  participantId: {
    type: String,
    default: null
  },

  formSubmissionId: {
    type: String,
    required: true
  },

  date: {
    type: String,
    required: true
  },

  userName: {
    type: String,
    default: ''
  },

  locale: {
    type: String,
    default: i18n.global.locale.value
  }
},
  setup(__props) {

const { d } = useI18n();
const props = __props;

const smartForm = useSmartFormStore();

const activeSections = computed<Array<FormSection>>(() => props.config.sections.filter(
  (section: FormSection) =>
    smartForm.getItemConditionsMet(props.formSubmissionId, section.conditions) &&
    !section.hide_when_readonly &&
    section.name
));
const formatDate = (date: string) => d(new Date(date), 'shortMonth', props.locale);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(BaseButton), {
          color: "clear",
          size: "small",
          "left-icon": "arrow-left",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.back')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("span", _hoisted_4, _toDisplayString(__props.config.name), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('platform.common.date-created')), 1),
          _createElementVNode("span", _hoisted_8, _toDisplayString(formatDate(__props.date)), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('platform.common.completed-by')), 1),
          _createElementVNode("span", _hoisted_11, _toDisplayString(__props.userName), 1)
        ])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeSections.value, (section) => {
      return (_openBlock(), _createBlock(FormSummarySection, {
        key: section.id,
        section: section,
        "participant-id": __props.participantId,
        "form-submission-id": __props.formSubmissionId,
        locale: __props.locale,
        level: 0
      }, null, 8, ["section", "participant-id", "form-submission-id", "locale"]))
    }), 128))
  ]))
}
}

})