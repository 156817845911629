import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-4" }

import { computed, PropType } from 'vue';
import { FormItem, FormSection } from '@/models';
import { useSmartFormStore } from '@/stores/smartForm.store';
import BaseAlert from '@/lib/components/Banner/BaseAlert.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SmartFormStatus',
  props: {
  formSubmissionId: {
    type: String,
    required: true
  },
  sections: {
    type: Array as PropType<FormSection[]>,
    default: () => []
  },
  excludeTypes: {
    type: Array as PropType<Array<string>>,
    default: () => []
  },
  excludeIds: {
    type: Array as PropType<Array<string>>,
    default: () => []
  }
},
  setup(__props) {

const props = __props;

const smartForm = useSmartFormStore();

const unansweredSections = computed(() => {
  const excludeTypes = [...props.excludeTypes, 'group', 'section', 'spacer', 'table'];
  const getItemsRecursively = (item: FormItem) => {
    if (
      !props.excludeIds.includes(item.id) && // exclude ids
      smartForm.getItemConditionsMet(props.formSubmissionId, item.conditions) // check that the item is visible
    ) {
      if (item.items) {
        const items = item.items.flatMap(getItemsRecursively);
        // if item is not an excluded type and is unanswered, return it with item.items
        if (
          !excludeTypes.includes(item.type) &&
          typeof item.type === 'string' && // if type is undefined, do not include item
          !smartForm.getAnswer(props.formSubmissionId, item.id)
        ) {
          return [...items, item];
        }
        return items;
      } else if (
        !excludeTypes.includes(item.type) &&
        typeof item.type === 'string' &&
        !smartForm.getAnswer(props.formSubmissionId, item.id)
      ) {
        return [item];
      }
    }
    return [];
  };

  return props.sections.reduce((acc, curr) => {
    if (!props.excludeIds.includes(curr.id)) {
      const unanswered = curr.items ? curr.items.flatMap(getItemsRecursively) : [];
      if (unanswered.length) {
        acc.push({
          id: curr.id,
          name: curr.name,
          unanswered: unanswered.length
        });
      }
    }
    return acc;
  }, [] as { id: string; name: string; unanswered: number }[]);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(unansweredSections.value, (section) => {
      return (_openBlock(), _createBlock(BaseAlert, {
        key: section.id,
        color: "alert",
        icon: "warning",
        label: _ctx.$t('platform.error.unanswered-questions', [section.name])
      }, null, 8, ["label"]))
    }), 128))
  ]))
}
}

})