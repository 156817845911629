import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'DataTableCell',
  props: {
  isMuted: {
    type: Boolean,
    default: false
  },
  cellStyleClasses: {
    type: String,
    default: null
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", {
    class: _normalizeClass(["px-5 py-3", [
      __props.isMuted ? 'bg-primary-50' : 'bg-white',
      __props.cellStyleClasses || 'border-gray-200 border-t border-b first:border-l first:rounded-l-lg last:border-r last:rounded-r-lg'
    ]])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})