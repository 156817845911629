import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

import { PropType } from 'vue';
import GroupTreeSegment from './GroupTreeSegment.vue';
import { IGroupSegment } from '@/lib';


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupTree',
  props: {
  item: {
    type: Object as PropType<IGroupSegment>,
    required: true
  },
  depth: {
    type: Number,
    default: 0
  }
},
  emits: ["createChild"],
  setup(__props) {




return (_ctx: any,_cache: any) => {
  const _component_GroupTree = _resolveComponent("GroupTree", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(GroupTreeSegment, {
      class: _normalizeClass(["mb-6", { 'bg-gray-100 text-gray-500': !__props.item.isActive }]),
      style: _normalizeStyle('margin-left: ' + __props.depth * 2 + 'rem'),
      "enable-add": __props.item.add,
      actions: __props.item.actions,
      name: __props.item.name,
      description: __props.item.description,
      label: __props.item.label,
      "data-dusk": __props.item.id,
      onCreateChild: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('createChild', __props.item.id)))
    }, null, 8, ["class", "style", "enable-add", "actions", "name", "description", "label", "data-dusk"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.item.children, (child, index) => {
      return (_openBlock(), _createBlock(_component_GroupTree, {
        key: index,
        depth: +__props.depth + 1,
        item: child,
        onCreateChild: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('createChild', $event)))
      }, null, 8, ["depth", "item"]))
    }), 128))
  ]))
}
}

})