import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';
import MedKitBadge from '@/lib/components/Badge/MedKitBadge.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSegment',
  props: {
  label: {
    type: String,
    required: true
  },
  selected: {
    type: Boolean,
    default: false
  },
  count: {
    type: Number,
    default: null
  },
  size: {
    type: String,
    default: 'regular',
    validator: (value: string) => ['regular', 'small', 'xsmall'].includes(value)
  }
},
  setup(__props) {

const props = __props;
const showCountBadge = computed(() => typeof props.count === 'number');
const sizeClasses = computed(() => {
  if (props.size === 'small') {
    return `h-10 text-sm ${showCountBadge.value ? 'pr-2 pl-4' : 'px-4'}`;
  }
  if (props.size === 'xsmall') {
    return `h-8 text-sm ${showCountBadge.value ? 'pr-2 pl-3' : 'px-3'}`;
  }
  return `h-12 text-base px-${showCountBadge.value ? '5' : '6'}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["focus:outline-none leading-tight rounded-md flex flex-row m-0 items-center border-gray-200 text-center focus:outline text-gray-500 font-medium border-gray-300 select-none cursor-pointer", [__props.selected ? 'bg-white text-gray-900 shadow border' : 'text-gray-600', sizeClasses.value]])
  }, [
    _createTextVNode(_toDisplayString(__props.label) + " ", 1),
    (showCountBadge.value)
      ? (_openBlock(), _createBlock(MedKitBadge, {
          key: 0,
          class: "ml-2",
          kind: __props.selected ? 'low' : 'stable',
          size: "tiny",
          label: __props.count.toString(),
          tint: __props.selected
        }, null, 8, ["kind", "label", "tint"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})