import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

import { PropType } from 'vue';
import { IGroupSegment } from '@/lib';
import GroupTree from './GroupTree.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupTreeList',
  props: {
  items: {
    type: Array as PropType<IGroupSegment[]>,
    default: () => []
  }
},
  emits: ["createChild"],
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item, index) => {
      return (_openBlock(), _createBlock(GroupTree, {
        key: index,
        item: item,
        onCreateChild: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('createChild', $event)))
      }, null, 8, ["item"]))
    }), 128))
  ]))
}
}

})