import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

import { BaseIcon } from '@/lib/components/Icon';


export default /*@__PURE__*/_defineComponent({
  __name: 'DataTableHeading',
  props: {
  showSortButton: {
    type: Boolean,
    default: false
  },

  columnName: {
    type: String,
    default: ''
  },

  isActive: {
    type: Boolean,
    default: false
  },

  currentDirection: {
    type: String,
    default: 'asc'
  },

  customStyle: {
    type: String,
    default: null
  }
},
  emits: ["sort"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const sortClicked = () => {
  const direction = props.isActive && props.currentDirection === 'asc' ? '-' : '';
  // if sorting by multiple columns, add the direction in front of each column name
  const columns = props.columnName.split(',').map((columnName) => `${direction}${columnName}`).join(',');
  emit('sort', columns);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("th", {
    class: "px-5 font-medium text-left whitespace-pre",
    style: _normalizeStyle(__props.customStyle)
  }, [
    (__props.showSortButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "font-medium group focus:outline-none",
          onClick: sortClicked
        }, [
          _renderSlot(_ctx.$slots, "default"),
          _createVNode(_unref(BaseIcon), {
            name: __props.currentDirection === 'asc' ? 'arrow-up' : 'arrow-down',
            class: _normalizeClass(["ml-3 text-xs group-hover:text-primary-500 group-focus:text-primary-500 stroke-1.5", __props.isActive ? 'text-primary-500' : 'text-gray-500'])
          }, null, 8, ["name", "class"])
        ]))
      : _renderSlot(_ctx.$slots, "default", { key: 1 })
  ], 4))
}
}

})