import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-4"
}
const _hoisted_2 = { class: "text-sm text-gray-500 font-medium" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["data-dusk"]
const _hoisted_5 = {
  key: 0,
  class: "text-gray-600 font-normal leading-5",
  "data-dusk": "description"
}
const _hoisted_6 = { key: 1 }

import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { i18n } from '@/i18n/i18n';
import { stringToDuskAttribute } from '@/custom/menicon/helpers/strings.helper';
import { PropType } from 'vue';
import { EncounterTableSummaryData, EncounterTableSummaryDataRow } from '@/custom/menicon/helpers/form-summary.helper';
import MedKitButton from '@/lib/components/Button/MedKitButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FormSummaryTable',
  props: {
  columns: {
    type: Array as PropType<EncounterTableSummaryData['value']['columns']>,
    default: () => []
  },
  rows: {
    type: Array as PropType<EncounterTableSummaryData['value']['rows']>,
    default: () => []
  },
  locale: {
    type: String,
    default: i18n.global.locale.value
  },
  border: {
    type: Boolean,
    default: false
  },
  isPdf: {
    type: Boolean,
    default: false
  },
  description: {
    type: String,
    default: ''
  },
  action: {
    type: Object as PropType<EncounterTableSummaryData['action']>,
    default: null
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (__props.rows?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.rows, (line, key) => {
          return (_openBlock(), _createElementBlock("div", {
            key: key,
            class: _normalizeClass(["grid gap-x-6 gap-y-2", { 'border rounded': __props.border, 'p-4': __props.border && !__props.isPdf, 'px-4 pb-4': __props.border && __props.isPdf }]),
            style: {"grid-template-columns":"repeat(auto-fit, minmax(130px, 1fr))"}
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.columns, (item, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createElementVNode("span", _hoisted_2, [
                  (typeof item === 'object')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(item.class)
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass({ 'mt-4': __props.isPdf })
                        }, [
                          (item.icon)
                            ? (_openBlock(), _createBlock(BaseIcon, {
                                key: 0,
                                name: item.icon,
                                height: "16px",
                                stroke: false
                              }, null, 8, ["name"]))
                            : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("span", null, _toDisplayString(item.value), 1)
                      ], 2))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(item), 1))
                ]),
                _createElementVNode("p", {
                  class: "text-base font-regular text-gray-900 leading-tight break-words",
                  "data-dusk": `${_unref(stringToDuskAttribute)((item as EncounterTableSummaryDataRow).value || (item as string))}-${key}`
                }, [
                  (typeof line[index] === 'undefined' || line[index] === null)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.not-answered', [], { locale: __props.locale })), 1)
                      ], 64))
                    : (typeof line[index] === 'object')
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          class: _normalizeClass((line[index] as EncounterTableSummaryDataRow).class)
                        }, _toDisplayString((line[index] as EncounterTableSummaryDataRow).value), 3))
                      : (line[index])
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createTextVNode(_toDisplayString(line[index]), 1)
                          ], 64))
                        : _createCommentVNode("", true)
                ], 8, _hoisted_4)
              ]))
            }), 128)),
            (__props.action && !__props.isPdf && !key)
              ? (_openBlock(), _createBlock(MedKitButton, {
                  key: 0,
                  size: "small",
                  kind: "secondary",
                  icon: "chevron-right",
                  "icon-position": "end",
                  label: __props.action.label,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit(__props.action.emit, __props.action.args)))
                }, null, 8, ["label"]))
              : _createCommentVNode("", true)
          ], 2))
        }), 128)),
        (__props.description)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(__props.description), 1))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('platform.common.not-answered', [], { locale: __props.locale })), 1))
}
}

})