import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "bg-white select-none flex flex-row w-112 items-stretch justify-between border border-solid border-gray-300 rounded-lg",
  "data-dusk": "notification"
}
const _hoisted_2 = { class: "p-5 flex flex-row items-center" }
const _hoisted_3 = { class: "flex-1 flex flex-col" }
const _hoisted_4 = { class: "font-medium text-base text-gray-900" }
const _hoisted_5 = {
  key: 0,
  class: "text-sm text-gray-500"
}
const _hoisted_6 = { class: "flex flex-col border-solid border-gray-300 border-l" }

import { onMounted, PropType, ref, watch } from 'vue';
import { IAction } from '@/lib';
import { BaseIcon } from '@/lib/components/Icon';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseNotification',
  props: {
  icon: {
    type: String,
    default: null
  },
  color: {
    type: String,
    default: 'primary-500'
  },
  title: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  display: {
    type: Boolean,
    default: false
  },
  autoDismiss: {
    type: Boolean,
    default: true
  },
  dismissAfter: {
    type: Number,
    default: 3000
  },
  action: {
    type: Object as PropType<IAction>,
    default: null
  }
},
  emits: ["dismiss"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const timeout = ref(null);

watch([() => props.autoDismiss, () => props.display, () => props.dismissAfter], () => setDismiss());

onMounted(() => {
  setDismiss();
});
const setDismiss = () => {
  if (props.display && props.autoDismiss) {
    timeout.value = window.setTimeout(() => emit('dismiss'), props.dismissAfter);
  } else if (timeout.value) {
    clearTimeout(timeout.value);
  }
};
const onActionClick = (action: IAction, event: MouseEvent) => {
  action.onClick(event);
  emit('dismiss');
  event.stopPropagation();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (__props.icon)
        ? (_openBlock(), _createBlock(_unref(BaseIcon), {
            key: 0,
            class: _normalizeClass(["text-2xl mr-4", 'text-' + __props.color]),
            name: __props.icon
          }, null, 8, ["class", "name"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(__props.title), 1),
        (__props.label)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(__props.label), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: _normalizeClass(["text-gray-500 text-sm w-full h-full cursor-pointer flex justify-center items-center px-6 hover:text-gray-900", { 'border-b border-solid border-gray-300': __props.action }]),
        "data-dusk": "close-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dismiss')))
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('platform.common.close')), 1)
      ], 2),
      (__props.action)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "text-gray-500 text-sm w-full h-full cursor-pointer flex justify-center items-center px-6 hover:text-gray-900",
            "data-dusk": "action-button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (onActionClick(__props.action, $event)))
          }, [
            _createElementVNode("span", null, _toDisplayString(__props.action.label), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})