import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, PropType } from 'vue';
import BaseIcon from './BaseIcon.vue';
import { GlyphColorName } from './glyph';

type SizeName = 'xsmall' | 'small' | 'regular' | 'large';

interface IGlyphColorDefinition {
  bg: string;
  softText: string;
  softBg: string;
}

type GlyphColorDefinitionList = {
  [key in GlyphColorName]: IGlyphColorDefinition;
};

type SizeClassList = {
  [key in SizeName]: {
    icon: string;
    div: string;
  };
};


export default /*@__PURE__*/_defineComponent({
  __name: 'IconGlyph',
  props: {
  name: {
    type: String,
    required: true
  },
  color: {
    type: String as PropType<GlyphColorName>,
    default: 'primary',
    validator: (value: string) => ['primary', 'secondary', 'success', 'alert', 'danger', 'gray'].includes(value)
  },
  size: {
    type: String as PropType<SizeName>,
    default: 'regular',
    validator: (value: string) => ['xsmall', 'small', 'regular', 'large'].includes(value)
  },
  fill: {
    type: String as PropType<'soft' | 'solid'>,
    default: 'solid',
    validator: (value: string) => ['solid', 'soft'].includes(value)
  }
},
  setup(__props) {

const props = __props;

const colors: GlyphColorDefinitionList = {
  primary: {
    bg: 'primary-500',
    softText: 'primary-500',
    softBg: 'primary-100'
  },

  secondary: {
    bg: 'secondary-500',
    softText: 'secondary-500',
    softBg: 'secondary-100'
  },

  success: {
    bg: 'success-500',
    softText: 'success-500',
    softBg: 'success-100'
  },

  alert: {
    bg: 'alert-400',
    softText: 'alert-600',
    softBg: 'alert-100'
  },

  danger: {
    bg: 'danger-500',
    softText: 'danger-500',
    softBg: 'danger-200'
  },

  gray: {
    bg: 'gray-700',
    softText: 'gray-600',
    softBg: 'gray-100'
  }
};
const sizes: SizeClassList = {
  xsmall: {
    icon: '12px',
    div: 'w-6 h-6 rounded-lg'
  },
  small: {
    icon: '16px',
    div: 'w-8 h-8 rounded-lg'
  },
  regular: {
    icon: '24px',
    div: 'w-12 h-12 rounded-xl'
  },
  large: {
    icon: '32px',
    div: 'w-16 h-16 rounded-2xl'
  }
};

const textColorClass = computed<string>(() => `text-${props.fill === 'soft' ? colors[props.color].softText : 'white'}`);
const bgColorClass = computed<string>(() => `bg-${colors[props.color][props.fill === 'soft' ? 'softBg' : 'bg']}`);
const sizeClasses = computed<string>(() => sizes[props.size].div);
const iconSize = computed<string>(() => sizes[props.size].icon);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center justify-center shadow-lg", [bgColorClass.value, sizeClasses.value, textColorClass.value]])
  }, [
    _createVNode(BaseIcon, {
      name: __props.name,
      stroke: false,
      height: iconSize.value,
      class: "fill-current"
    }, null, 8, ["name", "height"])
  ], 2))
}
}

})