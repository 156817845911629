import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "absolute bottom-0 right-0 mb-12 mr-4 py-6 px-8 bg-danger-500 border border-danger-600 rounded-lg shadow-lg text-white"
}
const _hoisted_3 = { class: "whitespace-no-wrap" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "text-sm font-medium text-danger-300" }
const _hoisted_6 = ["slot-props"]

import {
  PropType,
  computed,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  defineAsyncComponent
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { apiClient } from '@/services/api';
import {
  FormCompletion,
  FormConfig,
  FormItem,
  FormSection,
  FormSubmission
} from '@/models';
import MedKitButton from '@/lib/components/Button/MedKitButton.vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { BaseButton } from '@/lib/components/Button';
import PrefillFormNotification from '@/lib/components/Notification/PrefillFormNotification.vue';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import SmartFormSection from './SmartFormSection.vue';
import { getItemsRecursively } from '@/helpers/smart-form.helper';
import { SavingStatus } from '@/lib/constants';
import { useSmartFormStore } from '@/stores/smartForm.store';
import { useNotificationStore } from '@/stores/notification.store';

export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false
},
  __name: 'SmartForm',
  props: {
  config: {
    type: Object as PropType<FormConfig>,
    required: true
  },
  formId: {
    type: String,
    default: null
  },
  participantId: {
    type: String,
    required: true
  },
  formSubmissionId: {
    type: String,
    required: true
  },
  organisationId: {
    type: String,
    required: true
  },
  prefilledFormSubmission: {
    type: Object as PropType<FormSubmission | null>,
    default: null
  }
},
  emits: ["moveBackToScheduled"],
  setup(__props) {

const SmartFormComplete = defineAsyncComponent(() => import('./SmartFormComplete.vue'));
const SmartFormStatus = defineAsyncComponent(() => import('./SmartFormStatus.vue'));

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const props = __props;


const loading = ref(false);
const smartFormDraft = ref(false);
const showValidationPopup = ref(false);
const unregisterRouterGuard = ref(null);
const smartForm = useSmartFormStore();
const notificationStore = useNotificationStore();

const isPrefilled = computed<boolean>(() => {
  const currentSection = activeSections.value[step.value];
  return !!(
    props.prefilledFormSubmission &&
    ((currentSection &&
        currentSection.prefill &&
        currentSection.prefill.length) ||
      sectionItems.value.some((item) => item.prefill && item.prefill.length))
  );
});
const status = computed<SavingStatus>(() => {
  if (errorCount.value > 0) {
    return SavingStatus.ERROR;
  }
  if (saving.value) {
    return SavingStatus.SAVING;
  }
  if (isDirty.value) {
    return SavingStatus.UNSAVED;
  }
  return SavingStatus.SAVED;
});
const exitDraftLabel = computed<string>(() => {
  if (props.participantId) {
    return t('platform.form.exit-to-list') as string;
  }
  return t('platform.consult.save-and-exit') as string;
});
const hideButtons = computed<boolean>(() => !!(
  activeSections.value[step.value] &&
  activeSections.value[step.value].hide_action_buttons
));
const activeSections = computed<Array<FormSection>>(() => props.config.sections.filter((section: FormSection) =>
  smartForm.getItemConditionsMet(
    props.formSubmissionId,
    section.conditions
  )
));
const completedSection = computed<FormCompletion>(() => {
  if (!Array.isArray(props.config.completion)) {
    return props.config.completion;
  }

  return props.config.completion.filter((complete: FormCompletion) =>
    smartForm.getItemConditionsMet(
      props.formSubmissionId,
      complete.conditions
    )
  )[0];
});
const steps = computed<string[]>(() => activeSections.value.map((section: FormSection) => section.name));
const step = computed<number>(() =>
// We want the URL param to be 1-based, but the value in the component to be zero-based
  Number(route.query.step || 1) - 1
);
const saving = computed<boolean>(() => smartForm.promises.length > 0);
const completed = computed<boolean>(() => smartForm.getCompleted(props.formSubmissionId));
const errorCount = computed<number>(() => smartForm.errors[props.formSubmissionId]
  ? Object.values(smartForm.errors[props.formSubmissionId]).length
  : 0);
const errorsInCurrentStep = computed<Array<string>>(() => {
  const errors = smartForm.errors[props.formSubmissionId];
  if (errors) {
    const errorFormItemIds = Object.keys(errors);
    return currentStepFormItemIds.value.filter((formItemId: string) =>
      errorFormItemIds.some((errorFormItemId) =>
        errorFormItemId.startsWith(formItemId)
      )
    );
  }
  return [];
});
const isDirty = computed<boolean>(() => (
  smartForm.dirty[props.formSubmissionId] &&
  smartForm.dirty[props.formSubmissionId].length > 0
));
const currentStepFormItemIds = computed<Array<string>>(() => {
  if (activeSections.value.length) {
    const activeSection = activeSections.value[step.value];
    // Get the ids of each form item and it's child items into a flat map
    return activeSection && activeSection.items
      ? activeSection.items
        .flatMap(getItemsRecursively)
        .map((item) => item.id)
      : [];
  }
  return [];
});
const sectionItems = computed<Array<FormItem>>(() => {
  const activeSection = activeSections.value[step.value];
  return activeSection && activeSection.items
    ? activeSection.items.flatMap(getItemsRecursively)
    : [];
});

onBeforeMount(() => {
  // Make sure we always start on the first step when navigating to this page
  if (step.value > 0) {
    router.replace(route.path);
  }
});
const back = () => {
  if (step.value === 0) {
    draftAndExit();
    return;
  }

  // Clear validation errors on back
  showValidationPopup.value = false;
  smartForm.clearErrors(props.formSubmissionId);
  router.go(-1);
};
const next = async () => {
  loading.value = true;

  let useDebounce = true;
  if (step.value === steps.value.length - 1) {
    useDebounce = false;
  }
  await validateSection();

  if (isDirty.value) {
    await saveAnswers(useDebounce);
  }
  await Promise.allSettled(smartForm.promises);

  // Clear errors if items are not in the section
  errorsInCurrentStep.value.map((itemId) => {
    const item = sectionItems.value.find(
      (item: FormItem) => item.id === itemId
    );
    if (
      !item ||
      !smartForm.getItemConditionsMet(
        props.formSubmissionId,
        item.conditions
      )
    ) {
      // If the item should not appear on the page, clear the answer, state and the error
      delete smartForm.answers[props.formSubmissionId][itemId];
      smartForm.dirty[props.formSubmissionId] = smartForm.dirty[props.formSubmissionId]?.filter((id) => id !== itemId);
      smartForm.clearError(props.formSubmissionId, itemId);
    }
  });

  if (errorsInCurrentStep.value?.length) {
    return Promise.reject();
  }

  await validateSection();

  try {
    // @ts-ignore
    await router.push({
      ...route,
      query: {
        step: String(step.value + 2) // Add 2 because the URL param is 1-based
      }
    });
  } finally {
    loading.value = false;
    showValidationPopup.value = false;
  }

  return Promise.resolve();
};
const tryNext = async () => {
  try {
    await next();
  } catch (e) {
    showValidationPopup.value = true;
    loading.value = false;
  }
};
const complete = async () => {
  // Push a route so that the final step can hook into the process if needed
  await tryNext();
  if (!errorCount.value) {
    try {
      const route = `v1/anonymous-participants/${props.participantId}/form-submissions/${props.formSubmissionId}`;
      await apiClient.patch(route, {
        completed: true
      });
      disableBack();
      await smartForm.complete(props.formSubmissionId);
    } catch (e) {
      notificationStore.addErrorNotification({
        title: t('platform.form.complete-error')
      });
      back();
    }
  }
};
const draftAndExit = () => {
  smartFormDraft.value = false;
  router.push({
    name: 'participant-forms',
    params: {
      organisationId: props.organisationId,
      participantId: props.participantId
    }
  });
};
const disableBack = () => {
  // Make the back button go back to the participant list instead of going back through steps
  unregisterRouterGuard.value = router.beforeEach((to, from, next) => {
    if (to.query.step && to.query.step < route.query.step) {
      next({
        name: 'participant',
        params: {
          organisationId: props.organisationId,
          participantId: props.participantId
        }
      });
    } else {
      if (unregisterRouterGuard.value) {
        unregisterRouterGuard.value();
      }
      next();
    }
  });
};
onBeforeUnmount(() => {
  if (unregisterRouterGuard.value) {
    unregisterRouterGuard.value();
  }
});
const saveAnswers = async (useDebounce = true) => await smartForm.saveAnswers({
  participantId: props.participantId,
  formSubmissionId: props.formSubmissionId,
  useDebounce
});
const validateSection = async () => {
  const currentSection = activeSections.value[step.value];
  if (currentSection) {
    const route = `v1/anonymous-participants/${props.participantId}/form-submissions/${props.formSubmissionId}/sections/${currentSection.id}`;
    const response = await apiClient.get<{
      id: string;
      validation: {
        passes: boolean;
        errors: { [id: string]: string[] };
      };
    }>(route);

    for (const [itemId, errors] of Object.entries(
      response.data.validation.errors
    )) {
      if (smartForm.getError(props.formSubmissionId, itemId)) {
        continue; // Don't replace existing errors as they are likely to be more specific
      }

      smartForm.errors = {
        ...smartForm.errors,
        [props.formSubmissionId]: {
          ...smartForm.errors[props.formSubmissionId],
          [itemId]: errors[0]
        }
      };
    }
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(WorkflowLayout, {
    name: __props.config.name,
    steps: steps.value,
    "current-step": step.value,
    status: status.value,
    onCancel: _cache[2] || (_cache[2] = ($event: any) => (smartFormDraft.value = true)),
    onSave: saveAnswers
  }, {
    actions: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (showValidationPopup.value && errorCount.value > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('platform.form.error-message', { errorCount: errorCount.value }, errorCount.value)), 1),
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('platform.form.error-instructions')), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      (!hideButtons.value)
        ? (_openBlock(), _createBlock(_unref(BaseButton), {
            key: 0,
            size: "large",
            color: "ghost",
            disabled: loading.value,
            "data-dusk": "back-button",
            onClick: back
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.back')), 1)
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        id: "workflow-footer-btn",
        "slot-props": { disabled: loading.value }
      }, null, 8, _hoisted_6),
      (step.value < activeSections.value.length - 1 && !hideButtons.value)
        ? (_openBlock(), _createBlock(_unref(BaseButton), {
            key: 1,
            size: "large",
            class: "ml-2",
            loading: loading.value,
            "data-dusk": "next-button",
            onClick: tryNext
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.next')) + " ", 1),
              _createVNode(BaseIcon, { name: "arrow-right" })
            ]),
            _: 1
          }, 8, ["loading"]))
        : (!hideButtons.value)
          ? (_openBlock(), _createBlock(_unref(BaseButton), {
              key: 2,
              size: "large",
              color: "success",
              class: "ml-2",
              loading: loading.value,
              "data-dusk": "complete-button",
              onClick: complete
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.complete')) + " ", 1),
                _createVNode(BaseIcon, { name: "arrow-right" })
              ]),
              _: 1
            }, 8, ["loading"]))
          : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (isPrefilled.value)
        ? (_openBlock(), _createBlock(PrefillFormNotification, {
            key: 0,
            class: "max-w-6xl mx-auto mt-16",
            "data-dusk": "prefill-form-notification",
            "created-at": __props.prefilledFormSubmission.created_at,
            username: 
        __props.prefilledFormSubmission.user.given_name +
          ' ' +
          __props.prefilledFormSubmission.user.family_name
      
          }, null, 8, ["created-at", "username"]))
        : _createCommentVNode("", true),
      (!completed.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["p-16", {
        'opacity-50': loading.value,
        'pb-40': showValidationPopup.value && errorCount.value > 0
      }])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeSections.value, (section, sectionIndex) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: section.id
              }, [
                (step.value === sectionIndex)
                  ? (_openBlock(), _createBlock(SmartFormSection, {
                      key: 0,
                      section: section,
                      "participant-id": __props.participantId,
                      "form-submission-id": __props.formSubmissionId,
                      next: tryNext,
                      back: back,
                      class: "max-w-6xl mx-auto"
                    }, null, 8, ["section", "participant-id", "form-submission-id"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
            (__props.config.status?.display && step.value === activeSections.value.length - 1)
              ? (_openBlock(), _createBlock(_unref(SmartFormStatus), {
                  key: 0,
                  class: "max-w-6xl mx-auto mt-4",
                  "form-submission-id": __props.formSubmissionId,
                  "exclude-types": __props.config.status ? __props.config.status.excludeTypes : [],
                  "exclude-ids": __props.config.status ? __props.config.status.excludeIds : [],
                  sections: activeSections.value
                }, null, 8, ["form-submission-id", "exclude-types", "exclude-ids", "sections"]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      (smartFormDraft.value)
        ? (_openBlock(), _createBlock(_unref(SmartFormComplete), {
            key: 2,
            heading: _ctx.$t('platform.consult.exit'),
            body: _ctx.$t('platform.consult.exit-message'),
            icon: "bell-glyph",
            "icon-color": "alert",
            stroke: ""
          }, {
            default: _withCtx(() => [
              _createVNode(MedKitButton, {
                kind: "primary",
                size: "large",
                class: "w-full",
                "data-dusk": "exit-draft-btn",
                label: exitDraftLabel.value,
                onClick: draftAndExit
              }, null, 8, ["label"]),
              _createVNode(MedKitButton, {
                kind: "tertiary",
                size: "large",
                class: "w-full",
                label: _ctx.$t('platform.common.cancel'),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (smartFormDraft.value = false))
              }, null, 8, ["label"])
            ]),
            _: 1
          }, 8, ["heading", "body"]))
        : (completed.value)
          ? (_openBlock(), _createBlock(_unref(SmartFormComplete), _normalizeProps(_mergeProps({ key: 3 }, completedSection.value)), {
              default: _withCtx(() => [
                _createVNode(MedKitButton, {
                  kind: "primary",
                  size: "large",
                  class: "w-full",
                  label: _ctx.$t('platform.participant.back-to-participant'),
                  onClick: draftAndExit
                }, null, 8, ["label"]),
                _createVNode(MedKitButton, {
                  kind: "tertiary",
                  size: "large",
                  class: "w-full",
                  label: _ctx.$t('platform.participant.exit-to-list'),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({
          name: 'participant-list',
          params: {
            organisationId: props.organisationId
          }
        })))
                }, null, 8, ["label"])
              ]),
              _: 1
            }, 16))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name", "steps", "current-step", "status"]))
}
}

})