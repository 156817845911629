import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "font-medium"
}
const _hoisted_3 = { class: "bg-white border border-gray-200 rounded-t-lg p-5 shadow flex flex-row justify-between items-center" }
const _hoisted_4 = { class: "bg-white border border-gray-200 rounded-b-lg px-6 shadow" }
const _hoisted_5 = { class: "flex justify-between my-6 items-end" }
const _hoisted_6 = { class: "flex-col" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = {
  key: 0,
  class: "text-success-800"
}
const _hoisted_9 = {
  key: 0,
  class: "items-center"
}
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = { class: "text-gray-900 text-base mr-2" }
const _hoisted_12 = { class: "text-gray-400" }
const _hoisted_13 = {
  key: 1,
  class: "text-danger-700"
}
const _hoisted_14 = { class: "items-center" }
const _hoisted_15 = { class: "flex" }
const _hoisted_16 = { class: "text-base mr-2" }
const _hoisted_17 = { class: "text-gray-400" }
const _hoisted_18 = {
  key: 2,
  class: "text-primary-500 items-center"
}
const _hoisted_19 = { class: "items-center" }
const _hoisted_20 = { class: "flex" }
const _hoisted_21 = { class: "text-gray-900 text-base mr-2" }
const _hoisted_22 = { class: "text-gray-400" }

import { PropType, computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
// File pond
import vueFilePond from 'vue-filepond';
import {
  FilePondErrorDescription,
  FilePondFile,
  FilePondInitialFile,
  FilePondServerConfigProps,
  FileStatus
} from 'filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { getPercent } from '@/lib/helpers/slider.helpers';
import LoadingBar from '@/lib/components/Loading/LoadingBar.vue';
import { apiClient } from '@/services/api';
import cookieService from '@/services/cookie.service';
import MedKitButton from '@/lib/components/Button/MedKitButton.vue';
import InputTextLabel from '@/lib/components/Helper/InputTextLabel.vue';
import ActionModal from '@/lib/components/Modals/ActionModal.vue';
import { FileAnswer, FileUploaded } from '@/models/file.model';
import { useNotificationStore } from '@/stores/notification.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'FileUploader',
  props: {
  fileSizeLimit: {
    type: String,
    default: '400MB'
  },
  extension: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  downloadAllUrl: {
    type: String,
    default: null
  },
  downloadAllFileName: {
    type: String,
    default: 'uploads.zip'
  },
  readonly: {
    type: Boolean,
    default: false
  },
  isOptional: {
    type: Boolean,
    default: false
  },
  serverUrl: {
    type: String,
    default: '/v1/files'
  },
  modelValue: {
    type: Array as PropType<Array<FileAnswer>>,
    default: () => []
  },
  error: {
    type: String,
    default: null
  }
},
  emits: ["update:modelValue", "change", "uploading"],
  setup(__props, { emit: __emit }) {

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const { t } = useI18n();
const props = __props;
const emit = __emit;

const filePond = ref(null);

const fileList = ref<Array<FileAnswer | FilePondFile>>([]);
const files = ref<Array<FilePondFile | FilePondInitialFile | FileUploaded>>([]);
const uploadingFiles = ref<Array<FileAnswer>>([]);
const fileToRemove = ref<FileAnswer | null>(null);
const filesDownloading = ref<string[]>([]);
const isAllFilesDownlading = ref<boolean>(false);
const notificationStore = useNotificationStore();

const sizeLimitLabel = computed<string>(() => t('platform.file-upload.max-size-note', [props.fileSizeLimit]));
const labelIdle = computed<string>(() => `
<div class="flex w-full justify-between items-center">
  <div class="text-gray-900 text-sm font-medium">
    ${t('platform.file-upload.drag-drop')}
    <span class="btn btn-small btn-ghost px-3 py-2 rounded shadow cursor-pointer text-gray-800">
      ${t('platform.file-upload.browse')}
    </span>
  </div>
  <div>${sizeLimitLabel.value}</div>
</div>`);
const allowFileTypeValidation = computed<boolean>(() => props.extension !== '');
const answer = computed<Array<FileAnswer>>(() => fileList.value.map((file: FileAnswer | FilePondFile) => {
  if ('serverId' in file) {
    const fileFromServer = JSON.parse(file.serverId).data;
    return {
      id: fileFromServer.id,
      originalId: file.id,
      name: fileFromServer.filename,
      size: file.fileSize,
      type: fileFromServer.mime_type,
      extension: file.fileExtension,
      status: file.status,
      created_at: fileFromServer.created_at
    };
  }
  return file;
}));
const server = computed<FilePondServerConfigProps['server']>(() => ({
  url: `${apiClient.defaults.baseURL}${props.serverUrl}`,
  headers: {
    [apiClient.defaults.xsrfHeaderName]: cookieService.getXsrfToken()
  },
  process: {
    url: '/',
    ondata: (formData: FormData) => {
      formData.append('options[allow_all_types]', '1');
      return formData;
    }
  },
  revert: {
    url: '/',
    headers: {
      [apiClient.defaults.xsrfHeaderName]: cookieService.getXsrfToken(),
      'Content-type': 'application/json'
    }
  }
}));
const isFileUploading = computed<boolean>(() => !!uploadingFiles.value.
  find((file) => file.status === FileStatus.PROCESSING || file.status === FileStatus.PROCESSING_QUEUED)
);

watch(isFileUploading, (previous, current) => {
  if (previous !== current) {
    emit('uploading', isFileUploading.value);
  }
});

onMounted(() => {
  if (props.modelValue) {
    fileList.value = [...props.modelValue];
    uploadingFiles.value = [...props.modelValue];
    updateFiles();
  }
});
const handleAddFile = (error: { main: string; sub: string } | null, file: FilePondFile) => {
  const isFilePresent = file.status === FileStatus.PROCESSING_COMPLETE &&
      uploadingFiles.value.find((uploadedFile) => uploadedFile.name === file.filename);
  if (error) {
    uploadingFiles.value.push({
      id: file.id,
      originalId: file.id,
      name: file.filename,
      size: file.fileSize,
      type: file.fileType,
      extension: file.fileExtension,
      status: file.status,
      progress: 0,
      error: error.main
    });
  } else if (!isFilePresent) {
    uploadingFiles.value.push({
      id: file.id,
      originalId: file.id,
      name: file.filename,
      size: file.fileSize,
      type: file.fileType,
      extension: file.fileExtension,
      status: file.status,
      progress: 0,
      isNew: true
    });
  }
};
const handleProcessFileStart = (file: FilePondFile) => {
  uploadingFiles.value.find((f) => f.originalId === file.id && ((f.status = file.status), true));
};
const handleProcessFileProgress = (processingFile: FilePondFile, progress: number) => {
  uploadingFiles.value.find((file) => file.originalId === processingFile.id && ((file.progress = progress), true));
};
const handleProcessFile = (error: FilePondErrorDescription | null, processFile: FilePondFile) => {
  if (error) {
    uploadingFiles.value = uploadingFiles.value.map((file) =>
      file.originalId === processFile.id
        ? {
          ...file,
          status: processFile.status,
          error: t('platform.file-upload.general-error-message')
        }
        : file
    );
  } else {
    fileList.value.push(processFile);

    uploadingFiles.value = uploadingFiles.value.map((file) =>
      file.originalId === processFile.id
        ? {
          ...file,
          status: processFile.status,
          id: JSON.parse(processFile.serverId).data.id,
          name: JSON.parse(processFile.serverId).data.filename
        }
        : file
    );
  }
};
const handleRemoveFile = (error: FilePondErrorDescription | null, file: FilePondFile) => {
  if (!error) {
    fileList.value = fileList.value.filter((data: FileAnswer | FilePondFile) => {
      const fileName = 'serverId' in data ? data.filename : data.name;
      return fileName !== file.filename;
    });

    exposed.save();
  }
};
const handleAbort = (targetFile: FilePondFile) => {
  uploadingFiles.value = uploadingFiles.value.filter((file: FileAnswer) => targetFile.id !== file.id);
};
const updateFiles = () => {
  files.value = fileList.value.map((file: FileAnswer | FilePondFile) => {
    if ('serverId' in file) {
      return file; // when file is uploaded, display the default layout
    }
    // when file has uploaded, display with idle layout(mock file)
    return {
      source: file.id,
      options: {
        type: 'local',
        file: {
          name: file.name,
          size: file.size,
          type: file.type
        }
      }
    };
  });
};
const save = () => {
  updateFiles();
  emit('update:modelValue', answer.value);
  emit('change', answer.value);
};
const getProgress = (progress: string) => getPercent(parseFloat(progress), 0, 1).toFixed(2);
const findFileIndexFromInstance = (targetFile: FileAnswer) => {
  /*
     * This plugin is really tricky:
     * 1. The serverId of previous uploaded file will be the id in our DB, we can use serverId to remove the file.
     * 2. For new uploaded file, the serverId will be null and we need the originalId to find and remove the file.
     */
  if (filePond.value) {
    const allFiles = filePond.value.getFiles();
    if ('isNew' in targetFile) {
      return allFiles.findIndex(
        (file: FilePondFile) => targetFile.isNew === true && targetFile.originalId === file.id
      );
    }
    return allFiles.findIndex((file: FilePondFile) => targetFile.id === file.serverId);
  }
  return;
};
const removeFile = (targetFile: FileAnswer) => {
  try {
    // Tricky plugin: the order is different everytime in getFiles(), need to ensure we remove the correct one.
    if (filePond.value) {
      const allFiles = filePond.value.getFiles();
      const fileIndex = findFileIndexFromInstance(targetFile);
      if (typeof fileIndex === 'number') {
        const uploadingFileIndex = uploadingFiles.value.findIndex((file: FileAnswer) => targetFile.id === file.id);
        filePond.value.removeFile(allFiles[fileIndex], { revert: true });
        uploadingFiles.value.splice(uploadingFileIndex, 1);
      }
    }
  } catch (e) {
    handleError();
  } finally {
    fileToRemove.value = null;
  }
};
const dismissFile = (targetFile: FileAnswer) => {
  uploadingFiles.value = uploadingFiles.value.filter((file: FileAnswer) => file.id !== targetFile.id);
};
const cancelFile = (targetFile: FileAnswer) => {
  try {
    if (filePond.value) {
      const allFiles = filePond.value.getFiles();
      const fileIndex = findFileIndexFromInstance(targetFile);
      if (allFiles && allFiles[fileIndex] && typeof fileIndex === 'number') {
        allFiles[fileIndex].abortProcessing();
      }
    }
  } catch (e) {
    handleError();
  }
};
const downloadFile = async (targetFile: FileAnswer) => {
  try {
    filesDownloading.value.push(targetFile.id);
    const response = await apiClient.get(`${props.serverUrl}/${targetFile.id}/download`, {
      responseType: 'blob',
      timeout: 200000
    }) as Blob;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response);
    link.setAttribute('download', targetFile.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (e) {
    notificationStore.setErrorToast(t('platform.file-upload.file-download-error'));
  } finally {
    const fileIndex = filesDownloading.value.findIndex((id) => id === targetFile.id);
    if (fileIndex > -1) {
      filesDownloading.value.splice(fileIndex, 1);
    }
  }
};
const downloadAllFiles = async () => {
  try {
    isAllFilesDownlading.value = true;
    const response = await apiClient.get(props.downloadAllUrl, {
      responseType: 'blob',
      timeout: 200000
    }) as Blob;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response);
    link.setAttribute('download', props.downloadAllFileName ?? 'files.zip');
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (e) {
    notificationStore.setErrorToast(t('platform.file-upload.file-download-error'));
  } finally {
    isAllFilesDownlading.value = false;
  }
};
const handleError = () => {
  notificationStore.setErrorToast(t('platform.error.request-failure'));
};
const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const exposed = {
  save
};

return (_ctx: any,_cache: any) => {
  return (!__props.readonly || uploadingFiles.value.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(InputTextLabel, {
          label: __props.label,
          "is-optional": __props.isOptional
        }, null, 8, ["label", "is-optional"]),
        _createElementVNode("div", {
          class: _normalizeClass(["pr-1", __props.error ? 'border border-danger-700' : ''])
        }, [
          (uploadingFiles.value.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('platform.file-upload.files', { count: uploadingFiles.value.length })), 1),
                  (__props.readonly && __props.downloadAllUrl && uploadingFiles.value.length > 1)
                    ? (_openBlock(), _createBlock(MedKitButton, {
                        key: 0,
                        kind: "secondary",
                        size: "small",
                        icon: "download",
                        label: _ctx.$t('platform.file-upload.download-all'),
                        loading: isAllFilesDownlading.value,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (!isAllFilesDownlading.value && downloadAllFiles()))
                      }, null, 8, ["label", "loading"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(uploadingFiles.value, (file, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass([uploadingFiles.value.length !== index + 1 ? 'border-b' : ''])
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            (file.status === _unref(FileStatus).PROCESSING_COMPLETE || !file.hasOwnProperty('status'))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                  (!__props.readonly)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                        _createVNode(BaseIcon, {
                                          name: "check",
                                          class: "mr-2"
                                        }),
                                        _createTextVNode(_toDisplayString(_ctx.$t('platform.file-upload.uploaded')), 1)
                                      ]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", _hoisted_10, [
                                    _createElementVNode("div", _hoisted_11, _toDisplayString(file.name) + " - ", 1),
                                    _createElementVNode("div", _hoisted_12, _toDisplayString(formatBytes(file.size)), 1)
                                  ])
                                ]))
                              : (file.error && !__props.readonly)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    _createElementVNode("div", _hoisted_14, [
                                      _createVNode(BaseIcon, {
                                        name: "warning",
                                        class: "mr-2",
                                        height: "1rem"
                                      }),
                                      _createTextVNode(_toDisplayString(file.error), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_15, [
                                      _createElementVNode("div", _hoisted_16, _toDisplayString(file.name) + " - ", 1),
                                      _createElementVNode("div", _hoisted_17, _toDisplayString(formatBytes(file.size)), 1)
                                    ])
                                  ]))
                                : (!__props.readonly)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                      _createElementVNode("div", _hoisted_19, [
                                        _createVNode(BaseIcon, {
                                          name: "loader",
                                          class: "mr-2"
                                        }),
                                        _createTextVNode(_toDisplayString(_ctx.$t('platform.file-upload.file-processing')) + " - " + _toDisplayString(getProgress(file.progress?.toString())) + "% ", 1)
                                      ]),
                                      _createElementVNode("div", _hoisted_20, [
                                        _createElementVNode("div", _hoisted_21, _toDisplayString(file.name) + " - ", 1),
                                        _createElementVNode("div", _hoisted_22, _toDisplayString(formatBytes(file.size)), 1)
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                          ])
                        ]),
                        (__props.readonly)
                          ? (_openBlock(), _createBlock(MedKitButton, {
                              key: 0,
                              kind: "secondary",
                              size: "small",
                              label: _ctx.$t('platform.common.download'),
                              loading: filesDownloading.value.includes(file.id),
                              onClick: ($event: any) => (!filesDownloading.value.includes(file.id) && downloadFile(file))
                            }, null, 8, ["label", "loading", "onClick"]))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              (file.status === _unref(FileStatus).PROCESSING || file.status === _unref(FileStatus).PROCESSING_QUEUED)
                                ? (_openBlock(), _createBlock(MedKitButton, {
                                    key: 0,
                                    label: _ctx.$t('platform.file-upload.button-abort-item-processing'),
                                    kind: "secondary",
                                    size: "small",
                                    onClick: ($event: any) => (cancelFile(file))
                                  }, null, 8, ["label", "onClick"]))
                                : _createCommentVNode("", true),
                              ((file.status === _unref(FileStatus).PROCESSING_COMPLETE || !file.hasOwnProperty('status')) && !isFileUploading.value)
                                ? (_openBlock(), _createBlock(MedKitButton, {
                                    key: 1,
                                    label: _ctx.$t('platform.file-upload.button-remove-item'),
                                    kind: "tertiary",
                                    size: "small",
                                    onClick: ($event: any) => (fileToRemove.value = file)
                                  }, null, 8, ["label", "onClick"]))
                                : _createCommentVNode("", true),
                              (file.error)
                                ? (_openBlock(), _createBlock(MedKitButton, {
                                    key: 2,
                                    label: _ctx.$t('platform.common.dismiss'),
                                    kind: "secondary",
                                    size: "small",
                                    onClick: ($event: any) => (dismissFile(file))
                                  }, null, 8, ["label", "onClick"]))
                                : _createCommentVNode("", true)
                            ], 64))
                      ]),
                      (file.status === _unref(FileStatus).PROCESSING)
                        ? (_openBlock(), _createBlock(LoadingBar, {
                            key: 0,
                            class: "mb-4 -mt-2",
                            value: file.progress * 100,
                            "loading-bar-height": "8px",
                            color: "success-500",
                            "out-custom-class": "h-5 relative w-full rounded-full overflow-hidden bg-primary-50",
                            "inner-custom-class": "m-1.5 rounded"
                          }, null, 8, ["value"]))
                        : _createCommentVNode("", true)
                    ], 2))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          (!__props.readonly)
            ? (_openBlock(), _createBlock(_unref(FilePond), {
                key: 1,
                ref_key: "filePond",
                ref: filePond,
                name: "file",
                "max-parallel-uploads": 10000,
                "label-idle": labelIdle.value,
                "label-invalid-field": _ctx.$t('platform.file-upload.invalid-field'),
                "label-file-waiting-for-size": _ctx.$t('platform.file-upload.file-waiting-for-size'),
                "label-file-size-not-available": _ctx.$t('platform.file-upload.file-size-not-available'),
                "label-file-loading": _ctx.$t('platform.file-upload.file-loading'),
                "label-file-load-error": _ctx.$t('platform.file-upload.file-load-error'),
                "label-file-processing": _ctx.$t('platform.file-upload.file-processing'),
                "label-file-processing-complete": _ctx.$t('platform.file-upload.file-processing-complete'),
                "label-file-processing-aborted": _ctx.$t('platform.file-upload.file-processing-aborted'),
                "label-file-processing-error": _ctx.$t('platform.file-upload.file-processing-error'),
                "label-file-processing-revert-error": _ctx.$t('platform.file-upload.file-processing-revert-error'),
                "label-file-remove-error": _ctx.$t('platform.file-upload.file-remove-error'),
                "label-tap-to-cancel": _ctx.$t('platform.file-upload.tap-to-cancel'),
                "label-tap-to-retry": _ctx.$t('platform.file-upload.tap-to-retry'),
                "label-tap-to-undo": _ctx.$t('platform.file-upload.tap-to-undo'),
                "label-button-remove-item": _ctx.$t('platform.file-upload.button-remove-item'),
                "label-button-abort-item-load": _ctx.$t('platform.file-upload.button-abort-item-load'),
                "label-button-retry-item-load": _ctx.$t('platform.file-upload.button-retry-item-load'),
                "label-button-abort-item-processing": _ctx.$t('platform.file-upload.button-abort-item-processing'),
                "label-button-undo-item-processing": _ctx.$t('platform.file-upload.button-undo-item-processing'),
                "label-button-retry-item-processing": _ctx.$t('platform.file-upload.button-retry-item-processing'),
                "label-button-process-item": _ctx.$t('platform.file-upload.button-process-item'),
                "label-max-file-size-exceeded": _ctx.$t('platform.file-upload.max-file-size-exceeded'),
                "label-max-file-size": _ctx.$t('platform.file-upload.max-file-size'),
                "label-max-total-file-size": _ctx.$t('platform.file-upload.max-total-file-size'),
                "label-file-type-not-allowed": _ctx.$t('platform.file-upload.file-type-not-allowed'),
                "file-validate-type-label-expected-types": _ctx.$t('platform.file-upload.file-validate-type-label-expected-types'),
                "image-validate-size-label-format-error": _ctx.$t('platform.file-upload.image-validate-size-label-format-error'),
                "image-validate-size-label-image-size-too-small": _ctx.$t('platform.file-upload.image-validate-size-label-image-size-too-small'),
                "image-validate-size-label-image-size-too-big": _ctx.$t('platform.file-upload.image-validate-size-label-image-size-too-big'),
                "image-validate-size-label-expected-min-size": _ctx.$t('platform.file-upload.image-validate-size-label-expected-min-size'),
                "image-validate-size-label-expected-max-size": _ctx.$t('platform.file-upload.image-validate-size-label-expected-max-size'),
                "image-validate-size-label-image-resolution-too-low": _ctx.$t('platform.file-upload.image-validate-size-label-image-resolution-too-low'),
                "image-validate-size-label-image-resolution-too-high": _ctx.$t('platform.file-upload.image-validate-size-label-image-resolution-too-high'),
                "image-validate-size-label-expected-min-resolution": _ctx.$t('platform.file-upload.image-validate-size-label-expected-min-resolution'),
                "image-validate-size-label-expected-max-resolution": _ctx.$t('platform.file-upload.image-validate-size-label-expected-max-resolution'),
                class: "mt-4 border-dashed border border-gray-500 rounded",
                "allow-file-type-validation": allowFileTypeValidation.value,
                "accepted-file-types": __props.extension,
                server: server.value,
                "allow-multiple": "true",
                files: files.value,
                "max-file-size": __props.fileSizeLimit,
                onAddfile: handleAddFile,
                onProcessfile: handleProcessFile,
                onProcessfilestart: handleProcessFileStart,
                onProcessfileprogress: handleProcessFileProgress,
                onRemovefile: handleRemoveFile,
                onProcessfiles: save,
                onProcessfileabort: handleAbort
              }, null, 8, ["label-idle", "label-invalid-field", "label-file-waiting-for-size", "label-file-size-not-available", "label-file-loading", "label-file-load-error", "label-file-processing", "label-file-processing-complete", "label-file-processing-aborted", "label-file-processing-error", "label-file-processing-revert-error", "label-file-remove-error", "label-tap-to-cancel", "label-tap-to-retry", "label-tap-to-undo", "label-button-remove-item", "label-button-abort-item-load", "label-button-retry-item-load", "label-button-abort-item-processing", "label-button-undo-item-processing", "label-button-retry-item-processing", "label-button-process-item", "label-max-file-size-exceeded", "label-max-file-size", "label-max-total-file-size", "label-file-type-not-allowed", "file-validate-type-label-expected-types", "image-validate-size-label-format-error", "image-validate-size-label-image-size-too-small", "image-validate-size-label-image-size-too-big", "image-validate-size-label-expected-min-size", "image-validate-size-label-expected-max-size", "image-validate-size-label-image-resolution-too-low", "image-validate-size-label-image-resolution-too-high", "image-validate-size-label-expected-min-resolution", "image-validate-size-label-expected-max-resolution", "allow-file-type-validation", "accepted-file-types", "server", "files", "max-file-size"]))
            : _createCommentVNode("", true)
        ], 2),
        (fileToRemove.value)
          ? (_openBlock(), _createBlock(ActionModal, {
              key: 0,
              icon: "trash",
              "icon-color": "danger",
              "show-close": false,
              title: _ctx.$t('platform.file-upload.remove-confirmation.title'),
              body: _ctx.$t('platform.file-upload.remove-confirmation.body'),
              "primary-action": _ctx.$t('platform.file-upload.remove-confirmation.action'),
              "tertiary-action": _ctx.$t('platform.common.cancel'),
              onClose: _cache[1] || (_cache[1] = ($event: any) => (fileToRemove.value = null)),
              onTertiaryClick: _cache[2] || (_cache[2] = ($event: any) => (fileToRemove.value = null)),
              onPrimaryClick: _cache[3] || (_cache[3] = ($event: any) => (removeFile(fileToRemove.value)))
            }, null, 8, ["title", "body", "primary-action", "tertiary-action"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})