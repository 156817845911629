import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'FooterLink',
  props: {
  to: {
    type: [Object, String],
    default: null
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.to ? 'router-link' : 'a'), _mergeProps({ to: __props.to }, _ctx.$attrs, { class: "flex select-none items-center text-sm mr-6 font-semibold text-gray-400 hover:text-gray-900 focus:text-gray-900" }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["to"]))
}
}

})