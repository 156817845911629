import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ErrorLayout from '@/lib/layouts/ErrorLayout.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'Error503',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ErrorLayout, {
    type: "503",
    title: _ctx.$t('platform.error.503.title'),
    message: _ctx.$t('platform.error.503.message'),
    "show-try-again": ""
  }, null, 8, ["title", "message"]))
}
}

})