import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "absolute inset-0 flex items-center justify-center"
}

import { BaseIcon } from '@/lib/components/Icon';
import { LoadingIcon } from '@/lib/components/Loading';
import { ButtonColors } from '@/lib';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseButton',
  props: {
  rightIcon: {
    type: String,
    default: ''
  },
  leftIcon: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: 'primary',
    validator: (value: ButtonColors) =>
      [
        'primary',
        'primary-600',
        'secondary',
        'success',
        'alert',
        'danger',
        'ghost',
        'muted',
        'gray',
        'gray-200',
        'gray-900',
        'dark',
        'clear',
        'tertiary'
      ].includes(value)
  },
  size: {
    type: String,
    default: 'regular',
    validator: (value: string) => ['sm', 'small', 'regular', 'large', 'xl'].includes(value)
  },
  block: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["relative btn focus:outline-none", [
      __props.disabled ? 'btn-disabled' : `btn-${__props.color}`,
      __props.block ? 'btn-block' : '',
      `btn-${__props.size}`,
      __props.loading ? 'cursor-wait' : ''
    ]]),
    disabled: __props.disabled || __props.loading
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["flex flex-row items-center", { 'opacity-0': __props.loading }])
    }, [
      (__props.leftIcon?.length)
        ? (_openBlock(), _createBlock(_unref(BaseIcon), {
            key: 0,
            name: __props.leftIcon,
            position: "left",
            class: "stroke-1.5"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default"),
      (__props.rightIcon?.length)
        ? (_openBlock(), _createBlock(_unref(BaseIcon), {
            key: 1,
            class: "order-1 stroke-1.5",
            name: __props.rightIcon,
            position: "right"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true)
    ], 2),
    (__props.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_unref(LoadingIcon))
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})