import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row items-center justify-between mb-3"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-xs font-medium cursor-default text-gray-500 capitalize"
}
const _hoisted_4 = ["id", "placeholder", "value", "disabled", "readonly"]
const _hoisted_5 = { class: "relative w-full" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  class: "ml-2"
}

import { onBeforeUnmount, onMounted, ref, useId } from 'vue';
import { ISuggestion } from '@/lib';
import { BaseIcon } from '@/lib/components/Icon';
import { InputTextHelper } from '@/lib/components/Helper';
import { PopoverButton } from '@/lib/components/Popover';
import { ButtonLink, ClearButton } from '@/lib/components/Button';
import { i18n } from '@/i18n/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSearchInput',
  props: {
  modelValue: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  clear: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default() {
      return i18n.global.t('platform.common.search');
    }
  },
  label: {
    type: String,
    default: ''
  },
  suggestions: {
    type: Array,
    default: () => []
  },
  maxHeight: {
    type: String,
    default: 'unset'
  },
  dark: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: null
  },
  iconPosition: {
    type: String,
    default: 'left'
  },
  iconStroke: {
    type: Boolean,
    default: true
  },
  iconHeight: {
    type: String,
    default: '20px'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: null
  },
  alwaysShowResults: {
    type: Boolean,
    default: false
  },
  absolute: {
    type: Boolean,
    default: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  wrap: {
    type: Boolean,
    default: false
  },
  addButtonLink: {
    type: [String, Object],
    default: null
  },
  addButtonLinkLabel: {
    type: String,
    default: null
  },
  size: {
    type: String,
    default: 'regular'
  },
  isOptional: {
    type: Boolean,
    default: false
  },
  maxWidth: {
    type: Boolean,
    default: false
  }
},
  emits: ["submit", "scrolled", "update:modelValue", "change", "blur", "validate"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;
const emit = __emit;

const search = ref(null);
const input = ref(null);
const list = ref(null);

const id = useId();
const isOpen = ref(false);

onMounted(() => {
  window.addEventListener('click', close);
});
onBeforeUnmount(() => {
  window.removeEventListener('click', close);
});
const formatText = (value: string) => value.replace(props.modelValue, `<b>${props.modelValue}</b>`);
const onScroll = (ev: UIEvent) => {
  const target = ev.target as HTMLElement;
  const bottomOfTarget = target.scrollTop + target.offsetHeight >= target.scrollHeight;
  if (bottomOfTarget) {
    emit('scrolled');
  }
};
const close = () => {
  isOpen.value = false;
};
const onInput = (value: string) => {
  (list.value as HTMLElement).scrollTop = 0;
  emit('update:modelValue', value);
  emit('change', value);
};
const onItemClick = (suggestion: ISuggestion) => {
  emit('update:modelValue', suggestion.label);
  emit('change', suggestion);
  emit('submit', suggestion);
};

const onFocus = () => {
  isOpen.value = true;
};

const onBlur = () => {
  emit('blur');
  emit('validate');
};

const keyUp = (event: KeyboardEvent, suggestion: ISuggestion) => {
  if (event.code === 'Enter') {
    emit('update:modelValue', suggestion.label);
    emit('change', suggestion);
    emit('submit', suggestion);
  }
};

__expose({
  close
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'p-4 border bg-white rounded shadow ': !__props.absolute }, "relative"])
  }, [
    (__props.label?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _unref(id),
            class: "inline-block text-base font-medium leading-tight"
          }, _toDisplayString(__props.label), 9, _hoisted_2),
          (__props.isOptional)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref_key: "search",
      ref: search,
      class: _normalizeClass([[
        __props.dark ? 'bg-gray-900' : 'bg-white',
        __props.label?.length ? 'mt-3' : '',
        __props.disabled || __props.readonly ? 'bg-gray-50 border-gray-200' : 'border-gray-300',
        __props.error && __props.error.length
          ? 'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none'
          : '',
        isOpen.value ? 'border-primary-500' : ''
      ], "flex flex-row items-center w-full rounded border border-solid shadow"]),
      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ((_ctx.$refs.input as HTMLInputElement).focus()), ["stop"]))
    }, [
      (__props.icon?.length)
        ? (_openBlock(), _createBlock(_unref(BaseIcon), {
            key: 0,
            class: _normalizeClass(["text-gray-500 flex-none", [__props.iconPosition === 'right' ? 'order-1' : 'mr-2']]),
            position: __props.iconPosition === 'left' ? 'right' : 'left',
            name: __props.icon,
            height: __props.iconHeight,
            stroke: __props.iconStroke
          }, null, 8, ["class", "position", "name", "height", "stroke"]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        id: _unref(id),
        ref_key: "input",
        ref: input,
        class: _normalizeClass([[
          __props.dark ? 'bg-gray-900 text-gray-100' : 'bg-white',
          __props.disabled || __props.readonly ? 'bg-gray-50 border-gray-200 cursor-default' : 'cursor-text',
          __props.size === 'regular' ? 'h-12' : 'h-10'
        ], "focus:outline-none text-medium rounded pl-2 min-w-0 w-full truncate"]),
        type: "text",
        autocomplete: "chrome-off",
        placeholder: __props.placeholder,
        value: __props.modelValue,
        disabled: __props.disabled,
        readonly: __props.readonly,
        onFocus: onFocus,
        onBlur: onBlur,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (onInput(($event.target as HTMLInputElement).value)))
      }, null, 42, _hoisted_4),
      (__props.loading)
        ? (_openBlock(), _createBlock(_unref(BaseIcon), {
            key: 1,
            class: "mr-4",
            "data-dusk": "loading-spinner",
            name: "loading-spinner-small"
          }))
        : _createCommentVNode("", true),
      (__props.clear && !__props.disabled && !__props.readonly && __props.modelValue !='')
        ? (_openBlock(), _createBlock(_unref(ClearButton), {
            key: 2,
            class: "focus:outline-none pointer-events-auto mr-4 ml-2 justify-self-end",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (onInput('')))
          }))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: _normalizeClass(["w-full", [
          __props.suggestions && (__props.suggestions.length || __props.addButtonLink) && (isOpen.value || __props.alwaysShowResults) && !__props.disabled && !__props.readonly
            ? 'block'
            : 'hidden'
        ]]),
        "data-dusk": "search-input-list-wrapper"
      }, [
        _createElementVNode("div", {
          ref_key: "list",
          ref: list,
          class: _normalizeClass(["z-40 mt-2 mr-6 overflow-hidden overflow-y-auto max-h-75", [
            __props.wrap ? '' : 'whitespace-no-wrap',
            __props.maxWidth ? 'w-auto' : 'w-full',
            __props.dark ? 'bg-gray-900' : 'bg-white',
            __props.absolute ? 'absolute p-2 border rounded-lg shadow-lg border-gray-300' : ''
          ]]),
          onScroll: _cache[4] || (_cache[4] = ($event: any) => (onScroll($event as UIEvent)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.suggestions, (suggestion) => {
            return (_openBlock(), _createBlock(_unref(PopoverButton), {
              key: (suggestion as ISuggestion).label,
              dark: __props.dark,
              "data-dusk": "suggestion",
              onBlur: _cache[3] || (_cache[3] = ($event: any) => (isOpen.value = false)),
              onMousedown: ($event: any) => (onItemClick((suggestion as ISuggestion))),
              onKeyup: ($event: any) => (keyUp($event, (suggestion as ISuggestion)))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  innerHTML: formatText((suggestion as ISuggestion).label)
                }, null, 8, _hoisted_6)
              ]),
              _: 2
            }, 1032, ["dark", "onMousedown", "onKeyup"]))
          }), 128)),
          (__props.addButtonLink)
            ? (_openBlock(), _createBlock(_unref(ButtonLink), {
                key: 0,
                class: "w-full border-none text-primary-500 justify-start shadow-none",
                "left-icon": "add",
                color: "muted",
                to: __props.addButtonLink
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(__props.addButtonLinkLabel) + " ", 1),
                  (__props.modelValue)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, " \"" + _toDisplayString(__props.modelValue) + "\" ", 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ], 34)
      ], 2)
    ]),
    _createVNode(_unref(InputTextHelper), {
      error: __props.error,
      description: __props.description
    }, null, 8, ["error", "description"])
  ], 2))
}
}

})