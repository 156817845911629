import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row items-center justify-between mb-3 leading-tight"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-gray-500 font-medium text-xs"
}

import { computed, onBeforeUnmount, onMounted, PropType, ref, useId } from 'vue';
import { i18n, supportedLocales } from '@/i18n/i18n';
import { DatePicker } from 'v-calendar';
import { BaseTextInput } from '@/lib/components/Input';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { localisedMask as getLocalisedMask } from '@/helpers/date.helper';

export interface IMasks {
  input: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseDatePicker',
  props: {
  modelValue: {
    type: String,
    default: ''
  },
  mode: {
    type: String as PropType<'date' | 'dateTime'>,
    default: 'date',
    validators: (value: string) => ['date', 'dateTime'].includes(value)
  },
  isOptional: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: null
  },
  iconPosition: {
    type: String,
    default: 'right'
  },
  iconColor: {
    type: String,
    default: 'black'
  },
  minDate: {
    type: String,
    default: null
  },
  maxDate: {
    type: String,
    default: null
  },
  error: {
    type: String,
    default: null
  },
  description: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  clear: {
    type: Boolean,
    default: false
  },
  /**
   * When providing a custom mask please provide a mask per locale
   * Add the custom mask to all supportedLocales within the i18n file.
   */
  masks: {
    type: Object as PropType<IMasks>,
    default: null
  },
  /**
   * Provide the locale if used within a form schema
   */
  locale: {
    type: String,
    default: null
  }
},
  emits: ["keyup", "update:modelValue", "change"],
  setup(__props, { emit: __emit }) {

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const props = __props;
const emit = __emit;

const datePicker = ref(null);

const id = useId();

const emittedFormat = computed(() => {
  if (props.mode === 'dateTime') {
    return 'YYYY-MM-DD HH:mm';
  }
  return 'YYYY-MM-DD';
});
const is24hr = computed(() => {
  const supportedLocale = supportedLocales.find(
    (localeObj) => localeObj.code === localeToValidate.value
  );
  return supportedLocale?.is24hr ?? true;
});
const minimumDate = computed(() => props.minDate ? dayjs(props.minDate).format() : '');
const maximumDate = computed(() => props.maxDate ? dayjs(props.maxDate).format() : '');
const date = computed(() => props.modelValue ? dayjs(props.modelValue).format() : '');
const localisedDate = computed(() => date.value
  ? dayjs(date.value, emittedFormat.value).local().format(localisedMask.value)
  : '');
const localisedMask = computed(() => {
  const defaultInputMask = 'DD/MM/YYYY';
  if (props.masks) {
    return props.masks.input ? props.masks.input : defaultInputMask;
  }
  return getLocalisedMask(localeToValidate.value, props.mode);
});
const localeToValidate = computed<string>(() => props.locale || i18n.global.locale.value);
const datePickerRef = computed(() => datePicker.value);
const datePickerMasks = computed(() => ({
  masks: {
    input: localisedMask.value
  }
}));

const setDate = (value) => {
  let emitValue = '';
  if (value && dayjs(value).isValid()) {
    const dayjsDate = dayjs(value);
    if (props.mode === 'dateTime') {
      emitValue = dayjsDate.toISOString();
    } else {
      emitValue = dayjs(value).format(emittedFormat.value);
    }
  }

  emitDate(emitValue);
};
onMounted(() => {
  window.addEventListener('wheel', () => clickAway());
  window.addEventListener('resize', () => clickAway());
});
onBeforeUnmount(() => {
  window.removeEventListener('wheel', () => clickAway());
  window.removeEventListener('resize', () => clickAway());
});
const onValueChange = async (value: string | number | Date) => {
  let emitValue = '';
  await showCalendar();

  // User can manually input the date.
  if (typeof value === 'string') {
    if (
      value.length &&
      dayjs(value, localisedMask.value).isValid()
    ) {
      emitValue = dayjs(value, localisedMask.value).format(
        emittedFormat.value
      );

      // We only trigger calendar.move when calendar is displayed and the input date is a valid date.
      if (datePickerRef.value) {
        try {
          await datePickerRef.value.move({
            month: dayjs(value, localisedMask.value).month() + 1,
            year: dayjs(value, localisedMask.value).year()
          });
        } catch (exception) {
          // If input date is not at the valid date range (minDate or maxDate), the calendar will switch to current date.
          await datePickerRef.value.move(new Date());
        }
      }
      emitDate(emitValue);
    } else if (!value.length) {
      emitDate(emitValue);
    }
  }
  await datePickerRef.value.hidePopover();
};
const emitDate = (emitValue: string) => {
  if (emitValue && dayjs(emitValue).isValid() || emitValue === '') {
    emit('update:modelValue', emitValue);
    emit('change', emitValue);
  }
};
const onValueClear = async () => {
  emit('update:modelValue', '');
  await clickAway();
};
const showCalendar = async () => {
  const datePicker = datePickerRef.value;
  if (datePicker) {
    await datePicker.showPopover();
  }
};
const clickAway = async () => {
  const datePicker = datePickerRef.value;
  if (datePicker) {
    await datePicker.hidePopover();
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (__props.label?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _unref(id),
            class: "inline-block text-base font-medium leading-tight"
          }, _toDisplayString(__props.label), 9, _hoisted_2),
          (__props.isOptional)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_unref(DatePicker), {
      ref_key: "datePicker",
      ref: datePicker,
      "model-value": date.value,
      masks: datePickerMasks.value,
      mode: __props.mode,
      is24hr: is24hr.value,
      "min-date": minimumDate.value,
      "max-date": maximumDate.value,
      locale: localeToValidate.value,
      "input-debounce": 500,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (setDate($event)))
    }, {
      default: _withCtx(({ hidePopover }) => [
        _createVNode(_unref(BaseTextInput), {
          "model-value": localisedDate.value,
          placeholder: localisedMask.value.toLowerCase(),
          icon: "calendar",
          "data-dusk": "date-of-birth-input",
          "icon-position": __props.iconPosition,
          "icon-color": __props.iconColor,
          "icon-size": "xl",
          class: "w-full",
          clear: __props.clear,
          error: __props.error,
          "success-message": __props.description,
          onClick: showCalendar,
          onIconClick: showCalendar,
          onBlur: hidePopover,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (onValueChange($event))),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('keyup', $event)), ["enter"])),
          onClear: onValueClear
        }, null, 8, ["model-value", "placeholder", "icon-position", "icon-color", "clear", "error", "success-message", "onBlur"])
      ]),
      _: 1
    }, 8, ["model-value", "masks", "mode", "is24hr", "min-date", "max-date", "locale"])
  ]))
}
}

})