import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { WorkflowFooter, WorkflowNavigation } from '@/lib/components/Workflow';
import { SavingStatus } from '@/lib/constants';
import { onBeforeUnmount, onMounted, PropType, ref, watch } from 'vue';
import { Patient } from '@/models';
import { useNotificationStore } from '@/stores/notification.store';
import { EncounterRequestedAdvice } from '@/custom/menicon/models';


export default /*@__PURE__*/_defineComponent({
  __name: 'WorkflowLayout',
  props: {
  name: {
    type: String,
    required: true
  },

  steps: {
    type: Array as PropType<string[]>,
    required: true
  },

  currentStep: {
    type: Number,
    required: true
  },

  disabled: {
    type: Boolean,
    default: false
  },

  loading: {
    type: Boolean,
    default: false
  },

  patient: {
    type: Object as PropType<Patient>,
    default: null
  },

  lensSupportRequest: {
    type: Object as PropType<EncounterRequestedAdvice>,
    default: null
  },

  status: {
    type: Number,
    default: SavingStatus.UNSET
  }
},
  emits: ["complete", "cancel", "tryAgain"],
  setup(__props, { emit: __emit }) {

const nav = ref(null);
const body = ref(null);
const props = __props;
const emit = __emit;
const notificationStore = useNotificationStore();

const onScroll = () => {
  const rect = nav.value?.$el?.getBoundingClientRect();
  if (rect) {
    if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
      notificationStore.top = rect.height;
    } else {
      notificationStore.top = 0;
    }
  }
};
const handleSubmit = () => {
  emit('complete');
};

onMounted(() => {
  notificationStore.top = nav.value.$el.clientHeight;

  watch(() => props.currentStep, () => {
    body.value.scroll({
      top: 0
    });
  });

  window.addEventListener('scroll', () => onScroll());
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', () => onScroll());
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    class: "absolute inset-0 flex flex-col justify-between h-screen z-40 bg-primary-50",
    onSubmit: _withModifiers(handleSubmit, ["prevent"])
  }, [
    _createVNode(_unref(WorkflowNavigation), {
      ref_key: "nav",
      ref: nav,
      steps: __props.steps,
      "current-step": __props.currentStep,
      patient: __props.patient,
      "lens-support-request": __props.lensSupportRequest,
      disabled: __props.disabled,
      loading: __props.loading,
      class: "flex-shrink-0",
      onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
    }, null, 8, ["steps", "current-step", "patient", "lens-support-request", "disabled", "loading"]),
    _createElementVNode("div", {
      ref_key: "body",
      ref: body,
      class: "flex-grow overflow-y-auto"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 512),
    _createVNode(_unref(WorkflowFooter), {
      name: __props.name,
      status: __props.status,
      loading: __props.loading,
      onTryAgain: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('tryAgain')))
    }, {
      actions: _withCtx(() => [
        _renderSlot(_ctx.$slots, "actions")
      ]),
      _: 3
    }, 8, ["name", "status", "loading"])
  ], 32))
}
}

})