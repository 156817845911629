import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "font-medium text-gray-900 mb-3" }
const _hoisted_3 = {
  class: "flex flex-col",
  "data-dusk": "roles-selector"
}
const _hoisted_4 = ["data-dusk", "onClick"]
const _hoisted_5 = { class: "flex flex-row items-center space-x-4" }
const _hoisted_6 = ["checked"]
const _hoisted_7 = { class: "font-medium" }

import { PropType } from 'vue';
import { Role } from '@/models';
import { i18n } from '@/i18n/i18n';
import InputTextHelper from '@/lib/components/Helper/InputTextHelper.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RolesSelector',
  props: {
  title: {
    type: String,
    default() {
      return i18n.global.t('platform.role.select') as string;
    }
  },
  modelValue: {
    type: Array as PropType<Array<string | number>>,
    default: () => []
  },
  roles: {
    type: Array as PropType<Role[]>,
    default: () => []
  },
  maxHeight: {
    type: String,
    default: '172px'
  },
  showError: {
    type: Boolean,
    default: false
  },
  description: {
    type: String,
    default() {
      return i18n.global.t('platform.role.select-user-roles') as string;
    }
  }
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const onRoleClick = (id: string | number) => {
  if (props.modelValue.includes(id)) {
    emit(
      'update:modelValue',
      props.modelValue.filter((role) => role !== id)
    );
  } else {
    emit('update:modelValue', [...props.modelValue, id]);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(__props.title), 1),
    _createElementVNode("div", {
      class: _normalizeClass(["overflow-y-scroll scrollbar-dark border border-gray-300 rounded", { 'border-danger-700': __props.showError }]),
      style: _normalizeStyle(`max-height: ${__props.maxHeight}`)
    }, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.roles, (role, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: role.id,
            "data-dusk": `role-selector-${role.id}`,
            class: _normalizeClass(["flex flex-row border-gray-300 p-4 justify-between items-center cursor-pointer hover:shadow-md", { 'border-t': index }]),
            onClick: ($event: any) => (onRoleClick(role.id))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("input", {
                type: "checkbox",
                class: "form-checkbox mr-3 w-4 h-4",
                checked: __props.modelValue.includes(role.id)
              }, null, 8, _hoisted_6),
              _createElementVNode("span", _hoisted_7, _toDisplayString(role.name), 1)
            ])
          ], 10, _hoisted_4))
        }), 128))
      ])
    ], 6),
    _createVNode(InputTextHelper, {
      error: __props.showError ? _ctx.$t('platform.error.role-selected-error') : null,
      description: __props.description
    }, null, 8, ["error", "description"])
  ]))
}
}

})