import { Patient } from '@/models';
import { sexOptions } from '@/constants';

export const verifyMrn = (mrn: string): boolean => /^\S+$/.test(mrn);
export const validateName = (name: string): boolean => /^([^0-9]*)$/.test(name);

export const getDobFromISOString = (date: string): Date => {
  const d = new Date(date);
  const year = d.getUTCFullYear();
  const month = d.getUTCMonth();
  const day = d.getUTCDate();
  return new Date(year, month, day);
};
export const getSex = (patient: Partial<Patient>): string | undefined => {
  const sex = sexOptions().find(({ value }) => value === patient.sex);
  return sex?.label || patient.sex;
};
