import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: "loading-auth-service-placeholder",
  "data-dusk": "loading-page",
  class: "absolute inset-0 h-screen z-50 auth-booting bg-gray-50 flex items-center content-center justify-center auth-booting"
}

import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { User } from '@/models';
import { BaseSnackbar, MaintenanceModal, NotificationStack, PageLoading } from '@/lib/components';
import { IdlesService, TokenService } from '@/services';
import { FeatureFlagService, HealthCheckService } from '@/services/api';
import { getBrand } from '@/services/brand.service';
import { logoutService } from '@/services/auth';
import { useSessionStore } from '@/stores/session.store';
import { useUiStore } from '@/stores/ui.store';
import { INotification, ISnackbar } from '@/lib';
import { useNotificationStore } from '@/stores/notification.store';
import MedKitToast from '@/lib/components/Notification/MedKitToast.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

dayjs.extend(utc);

const router = useRouter();
const route = useRoute();

const healthCheckService = new HealthCheckService();
const autoHealthCheckExpired = ref(false);
const healthCheckTimeIndex = ref(0);
const healthCheckInitIndex = ref(0);
const isReady = ref(false);
const intervalId = ref<number | null>(null);
const sessionStore = useSessionStore();
const uiStore = useUiStore();
const notificationStore = useNotificationStore();

const notifications = computed<INotification[]>(() => notificationStore.notifications);
const top = computed<number>(() => notificationStore.top);
const snackbar = computed<ISnackbar | null>(() => notificationStore.snackbar);
const toast = computed(() => notificationStore.toast);
const appId = computed<string | null>(() => sessionStore.uuid);
const currentUser = computed<User>(() => sessionStore.currentUser);
const isMaintenance = computed<boolean>(() => sessionStore.isMaintenance);
const isIdle = computed<boolean>(() => sessionStore.isIdle);
const loading = computed<boolean>(() => uiStore.loading);
const bodyScrollLock = computed<boolean>(() => uiStore.bodyScrollLock);
const routeTitle = computed<string>(() => route.meta?.title as string ?? '');

watch(appId, (id, oldId) => exposed.removeIdle(oldId));
watch(currentUser, () => onCurrentUserChange(currentUser.value));
watch(isMaintenance, (newStatus, oldStatus) => maintenanceHandler(newStatus, oldStatus));
watch(isIdle, (isIdle, wasIdle) => onIsIdleChanged(isIdle, wasIdle));
watch(routeTitle, () => (document.title = routeTitle.value));

onBeforeUnmount(() => {
  exposed.removeIdle();

  window.removeEventListener('beforeunload', () => exposed.removeIdle());
  window.removeEventListener('storage', exposed.onStorageChange);
});
onBeforeMount(async () => {
  window.addEventListener('beforeunload', () => exposed.removeIdle());
  window.addEventListener('storage', exposed.onStorageChange);
  uiStore.headerBranding = await getBrand();

  try {
    const featureFlagService = new FeatureFlagService();
    sessionStore.environmentFeatures = (await featureFlagService.index()).data;
  } catch (e) {
    await router.push({ name: '503' });
  } finally {
    isReady.value = true;
  }

  onCurrentUserChange(currentUser.value);
  if (route.meta?.isAdmin) {
    sessionStore.setCurrentOrganisationId(null);
  }
});

const toggleAutoHealthCheckExpired = () => {
  autoHealthCheckExpired.value = !autoHealthCheckExpired.value;
};
const removeIdle = (id: string | null = appId.value) => {
  if (id) {
    IdlesService.removeIdle(id);
  }
};
const dismissSnackbar = () => {
  notificationStore.snackbar = null;
};
const dismissToast = () => {
  notificationStore.toast = null;
};
const onStorageChange = async () => {
  const accessToken = localStorage.getItem('access_token');
  if (!accessToken && route.name !== 'login') {
    sessionStore.setCurrentUser(null);
    await router.push({ name: 'login' });
  }
};
const onIsIdleChanged = (isIdle: boolean | null, wasIdle: boolean | null) => {
  if (appId.value && isIdle !== null) {
    IdlesService.setActive(appId.value, !isIdle);
    const activeIdles = IdlesService.getActives();
    const accessToken = localStorage.getItem('access_token');
    if (accessToken && isIdle && !wasIdle && !activeIdles.length) {
      notificationStore.addNotification({
        title: 'Inactivity detected. Logging out.',
        color: 'warning',
        icon: 'lock'
      });
      setTimeout(async () => {
        await logoutService();
      }, 3000);
    }
  }
};
const onCurrentUserChange = (currentUser: User | null) => {
  if (currentUser && !intervalId.value) {
    exposed.refreshTokenIfNeeded();
  }
};
const refreshTokenIfNeeded = () => {
  intervalId.value = window.setInterval(async () => {
    const expireDate = localStorage.getItem('expire_date');
    const loadingToken = localStorage.getItem('loading_token');
    if (
      loadingToken === 'false' &&
      expireDate &&
      dayjs(expireDate).diff(dayjs.utc().format('YYYY-MM-DD HH:mm:ss'), 'second') <= 60
    ) {
      TokenService.setLoadingToken('true');
      try {
        await sessionStore.refreshUserToken({
          refresh_token: localStorage.getItem('refresh_token')
        });
        TokenService.setToken(sessionStore.token);
        TokenService.setRefreshToken(sessionStore.refreshToken);
        TokenService.setExpireDate(sessionStore.expireDate);
        TokenService.setLoadingToken('false');
      } catch (e) {
        TokenService.removeToken();
        if (intervalId.value) {
          clearInterval(intervalId.value);
          intervalId.value = null;
        }
      }
    }
  }, 1000);
};
const maintenanceInit = async (limit: number, interval: number) => {
  try {
    await healthCheckService.getHealthStatus();
    sessionStore.isMaintenance = false;
    router.go(0);
  } catch {
    if (healthCheckInitIndex.value === limit) {
      // Trigger manual health check
      exposed.toggleAutoHealthCheckExpired();
    } else if (healthCheckInitIndex.value < limit) {
      exposed.callWithTime(2000, interval);
      healthCheckInitIndex.value++;
    }
  }
};
const callWithTime = (time: number, interval: number): void => {
  clearInterval(interval);
  healthCheckTimeIndex.value++;
  setTimeout(exposed.maintenanceInit, time);
};
const maintenanceHandler = async (newStatus: boolean, oldStatus: boolean) => {
  healthCheckTimeIndex.value = 0;
  healthCheckInitIndex.value = 0;
  const interval = 500;
  // This number is used to trigger health check ping to become manual after 5 secs
  const limit = 5;

  if (newStatus && typeof oldStatus !== 'undefined' && !oldStatus) {
    await exposed.maintenanceInit(limit, interval);
  }
};
const onNotificationsUpdate = (notifications: INotification[]) => {
  notificationStore.notifications = notifications;
};
const exposed = {
  refreshTokenIfNeeded,
  maintenanceInit,
  toggleAutoHealthCheckExpired,
  callWithTime,
  onStorageChange,
  removeIdle
};

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _directive_scroll_lock = _resolveDirective("scroll-lock")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isReady.value)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: "root-router-view",
          class: _normalizeClass([isMaintenance.value && 'filter-blur', "root-router-view relative"])
        }, [
          _createVNode(_unref(NotificationStack), {
            class: "mt-6 mr-6 z-50",
            style: _normalizeStyle('top:' + top.value + 'px;'),
            "display-items": 6,
            "model-value": notifications.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (onNotificationsUpdate($event)))
          }, null, 8, ["style", "model-value"]),
          _createVNode(_unref(MaintenanceModal), { "auto-fetch-limit": autoHealthCheckExpired.value }, null, 8, ["auto-fetch-limit"]),
          _createVNode(_component_RouterView),
          (snackbar.value)
            ? (_openBlock(), _createBlock(_unref(BaseSnackbar), _mergeProps({ key: 0 }, snackbar.value, { onDismiss: dismissSnackbar }), null, 16))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("div", {
            style: _normalizeStyle([
        toast.value?.dark
          ? 'background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%)'
          : 'background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%)'
      , {"z-index":"150"}]),
            class: "flex w-full justify-center items-end fixed py-8 bottom-0 right-0 left-0 h-48 z-150",
            "data-dusk": "app-toast"
          }, [
            _createVNode(MedKitToast, {
              "model-value": !!toast.value,
              text: toast.value?.text ?? '',
              type: toast.value?.type,
              "button-label": toast.value?.buttonLabel ?? _ctx.$t('platform.common.close'),
              "onUpdate:modelValue": dismissToast,
              "onClick:button": _cache[1] || (_cache[1] = ($event: any) => (toast.value?.buttonClick && toast.value.buttonClick()))
            }, null, 8, ["model-value", "text", "type", "button-label"])
          ], 4), [
            [_vShow, toast.value]
          ])
        ], 2)), [
          [_directive_scroll_lock, bodyScrollLock.value]
        ])
      : _createCommentVNode("", true),
    (loading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(PageLoading))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})