import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = {
  key: 1,
  class: "text-base font-medium inline-block"
}

import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputTextLabel',
  props: {
  isOptional: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: null
  },
  actionName: {
    type: String,
    default: null
  },
  iconHeight: {
    type: String,
    default: '20px'
  },
  loading: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: null
  }
},
  emits: ["actionClick"],
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (__props.icon?.length || __props.label?.length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([{ 'text-transparent bg-gray-200 rounded-lg': __props.loading }, "flex items-center justify-between mb-3 space-x-2"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (__props.icon)
            ? (_openBlock(), _createBlock(BaseIcon, {
                key: 0,
                name: __props.icon,
                height: __props.iconHeight,
                position: "left",
                class: _normalizeClass({ invisible: __props.loading }),
                stroke: false
              }, null, 8, ["name", "height", "class"]))
            : _createCommentVNode("", true),
          (__props.label)
            ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(__props.label), 1))
            : _createCommentVNode("", true)
        ]),
        (__props.isOptional)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([__props.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500', "text-xs font-medium cursor-default capitalize ml-3 mt-1"])
            }, _toDisplayString(_ctx.$t('platform.common.optional')), 3))
          : _createCommentVNode("", true),
        (__props.actionName)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: "#",
              class: _normalizeClass(["text-sm font-medium leading-tight ml-3", __props.loading ?
        'text-transparent bg-gray-200 rounded-lg' :
        'text-primary-700 cursor-pointer underline hover:text-primary-800 hover:no-underline'
      ]),
              tabindex: "0",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('actionClick')))
            }, _toDisplayString(__props.actionName), 3))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}
}

})