import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  "data-cy": "login-layout",
  class: "w-full p-10 transition ease-in-out bg-white rounded-lg shadow-lg md:w-2/4 lg:w-2/6"
}
const _hoisted_2 = { class: "mb-8 text-center" }

import BrandingLayout from './BrandingLayout.vue';
import BrandLogo from '@/lib/components/Branding/BrandLogo.vue';
import { useNotificationStore } from '@/stores/notification.store';
import { onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginLayout',
  setup(__props) {

const notificationStore = useNotificationStore();

onMounted(() => {
  notificationStore.notifications = [];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BrandingLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(BrandLogo, {
            "logo-size": "mark",
            "use-header-branding": ""
          })
        ]),
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }))
}
}

})