import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "flex items-start flex-col select-none min-w-5/6 w-5/6" }
const _hoisted_2 = { class: "flex flex-col justify-start p-8" }
const _hoisted_3 = { class: "pt-8 pb-6" }
const _hoisted_4 = { class: "text-xl font-semibold text-gray-900 pb-4" }
const _hoisted_5 = { class: "text-md text-gray-600" }
const _hoisted_6 = { class: "flex flex-row justify-start" }
const _hoisted_7 = { class: "pt-8 pb-4" }
const _hoisted_8 = { class: "text-xl font-semibold text-gray-900 pb-4" }
const _hoisted_9 = { class: "text-md text-gray-600" }
const _hoisted_10 = { class: "flex flex-row justify-start" }
const _hoisted_11 = { class: "h-10" }
const _hoisted_12 = {
  key: 0,
  class: "h-full"
}
const _hoisted_13 = { class: "pl-3 capitalize" }

import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { HealthCheckService } from '@/services/api';

import { BaseIcon } from '@/lib/components/Icon';
import { BaseButton } from '@/lib/components/Button';
import BaseModal from './BaseModal.vue';
import { useSessionStore } from '@/stores/session.store';
import MedKitBadge from '@/lib/components/Badge/MedKitBadge.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MaintenanceModal',
  props: {
  autoFetchLimit: {
    type: Boolean
  }
},
  setup(__props) {

const router = useRouter();


const healthCheckService = new HealthCheckService();
const sessionStore = useSessionStore();
const isCheckingServiceHealth = ref(false);

const isMaintenance = computed(() => sessionStore.isMaintenance);

const toggleIsChecking = (): void => {
  isCheckingServiceHealth.value = !isCheckingServiceHealth.value;
};
const manualHealthCheck = async () => {
  toggleIsChecking();

  try {
    await healthCheckService.getHealthStatus();
    sessionStore.isMaintenance = false;
    toggleIsChecking();
    router.go(0);
  } catch {
    toggleIsChecking();
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "maintenance-fade" }, {
    default: _withCtx(() => [
      (isMaintenance.value)
        ? (_openBlock(), _createBlock(BaseModal, {
            key: 0,
            "dialog-class": "min-w-5/6 w-5/6"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_unref(BaseIcon), {
                    id: "maintenance-svg",
                    position: "left",
                    name: "maintenance",
                    class: _normalizeClass(["text-lg fill-current", __props.autoFetchLimit ? 'text-danger-500' : 'text-alert-500']),
                    height: "50px"
                  }, null, 8, ["class"]),
                  (!__props.autoFetchLimit)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('platform.maintenance-mode.connection-lost-label')), 1),
                          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('platform.maintenance-mode.connection-lost-message')), 1)
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(MedKitBadge, {
                            class: "animate-ripple capitalize",
                            label: `${_ctx.$t('platform.maintenance-mode.reconnecting')} ...`,
                            size: "small",
                            kind: "low"
                          }, null, 8, ["label"])
                        ])
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('platform.maintenance-mode.connection-timeout-label')), 1),
                          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('platform.maintenance-mode.connection-timeout-message')), 1)
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            (isCheckingServiceHealth.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                  _createVNode(MedKitBadge, {
                                    label: `${_ctx.$t('platform.maintenance-mode.reconnecting')} ...`,
                                    size: "small",
                                    kind: "low",
                                    class: "capitalize"
                                  }, null, 8, ["label"])
                                ]))
                              : (_openBlock(), _createBlock(_unref(BaseButton), {
                                  key: 1,
                                  color: "ghost",
                                  onClickOnce: manualHealthCheck
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(BaseIcon), { name: "reload" }),
                                    _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('platform.maintenance-mode.reconnect')), 1)
                                  ]),
                                  _: 1
                                }))
                          ])
                        ])
                      ], 64))
                ])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})