import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "flex flex-col max-h-screen h-full w-full overflow-hidden",
  style: {"background-color":"#000"}
}
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = { class: "flex flex-row items-center" }
const _hoisted_4 = {
  class: "bg-gray-900 h-10 flex items-center justify-center text-white text-sm font-semibold",
  "data-dusk": "patient-name"
}
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col py-3 border-t border-gray-800"
}
const _hoisted_6 = { class: "break-words text-gray-500" }
const _hoisted_7 = {
  "data-dusk": "patient-details-address",
  class: "break-words text-white mt-2"
}
const _hoisted_8 = {
  key: 1,
  class: "flex flex-col ml-2",
  "data-dusk": "lens-support-request-details"
}
const _hoisted_9 = {
  class: "bg-gray-900 h-10 flex items-center justify-center text-white text-sm font-semibold",
  "data-dusk": "lens-support-request-id"
}
const _hoisted_10 = { class: "opacity-80 text-xs font-medium text-white mt-3" }
const _hoisted_11 = {
  key: 2,
  class: "flex w-full h-full items-center justify-center"
}
const _hoisted_12 = { class: "flex justify-between items-end mb-1" }
const _hoisted_13 = {
  key: 0,
  class: "flex flex-col text-white space-y-2 text-sm font-regular leading-normal"
}
const _hoisted_14 = {
  key: 0,
  class: "space-x-2 inline-flex"
}
const _hoisted_15 = { class: "opacity-80" }
const _hoisted_16 = {
  key: 1,
  class: "opacity-80"
}
const _hoisted_17 = {
  key: 2,
  class: "opacity-80"
}
const _hoisted_18 = {
  key: 3,
  class: "opacity-80"
}
const _hoisted_19 = ["onClick"]
const _hoisted_20 = {
  key: 0,
  class: "mb-6 mr-4 flex items-center"
}
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["onClick"]

import { computed, defineAsyncComponent, onBeforeUnmount, onMounted, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import GridLayout from '@/lib/layouts/GridLayout.vue';
import { PatientDetailsCard } from '@/lib/components/PatientSummary';
import { BaseButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';
import { NavDropdown } from '@/lib/components/Navigation';
import SlicesSlider from './SlicesSlider.vue';
import ViewerNavigationSwitch from './ViewerNavigationSwitch.vue';
import ViewerSelect from './ViewerSelect.vue';
import ViewerItem from './ViewerItem.vue';
import ViewerZoomInput from './ViewerZoomInput.vue';
import ViewerThumbnailGallery from './ViewerThumbnailGallery.vue';
import { DicomImageData, IGalleryImage } from '@/lib';
import {
  Image,
  ImageSeries,
  Patient,
  Study
} from '@/models';
import { getSeriesDescription } from '@/helpers/series.helper';
import { getDobFromISOString } from '@/helpers/patient.helper';
import { imageLoaderService } from '@/services';
import {
  DifferenceMapsType,
  EncounterRequestedAdvice,
  KUnit,
  Laterality,
  MeniconImageDetails,
  MeniconImageDetailsScore
} from '@/custom/menicon/models';
import MedKitBadge from '@/lib/components/Badge/MedKitBadge.vue';
import TopographyScansScale from '@/custom/menicon/components/scans/TopographyScansScale.vue';
import BaseTooltip from '../Tooltip/BaseTooltip.vue';

enum ViewerMode {
  SINGLE_MODE,
  COMPARE_MODE
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Viewer',
  props: {
  series: {
    type: Array as PropType<Array<ImageSeries>>,
    default: () => []
  },
  studies: {
    type: Array as PropType<Array<Study>>,
    default: () => []
  },
  imageDetails: {
    type: Object as PropType<Record<string, MeniconImageDetails>>,
    default: () => ({})
  },
  loadingScales: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  unit: {
    type: String as PropType<KUnit>,
    default: KUnit.MM
  },
  zoomMin: {
    type: Number,
    default: 10
  },
  patient: {
    type: Object as PropType<Patient>,
    default: null
  },
  lensSupportRequest: {
    type: Object as PropType<EncounterRequestedAdvice>,
    default: null
  },
  seriesId: {
    type: String,
    default: ''
  },
  studyId: {
    type: String,
    default: ''
  },
  imagesRoute: {
    type: String,
    required: true
  },
  showCompare: {
    type: Boolean,
    default: true
  }
},
  emits: ["close", "updateProcessedImages", "showCornealDetails"],
  setup(__props, { emit: __emit }) {

const SeriesSelector = defineAsyncComponent(() => import('./SeriesSelector.vue'));

const { t, d } = useI18n();
const props = __props;
const emit = __emit;

const nav = ref(null);
const grid = ref(null);
const footer = ref(null);

const layout = ref([1]);
const imageIndexes = ref([0]);
const seriesIndex = ref(0);
const mode = ref<ViewerMode>(ViewerMode.SINGLE_MODE);
const openImagesSelector = ref<number | null>(null);
const selectedSeries = ref<Array<ImageSeries | null>>([null]);
const lock = ref(true);
const focused = ref(0);
const zooms = ref([100]);
const defaultZooms = ref<number[]>([]);
const translations = ref([{ x: 0, y: 0 }]);

const modes = computed(() => [
  {
    name: t('platform.common.view'),
    icon: 'note',
    value: ViewerMode.SINGLE_MODE
  },
  {
    name: t('platform.scans.compare'),
    icon: 'compare',
    value: ViewerMode.COMPARE_MODE
  }
]);
const rows = computed<number>(() => {
  const gcd = (a: number, b: number): number => (a ? gcd(b % a, a) : b);
  const lcm = (a: number, b: number): number => (a * b) / gcd(a, b);
  return layout.value.reduce(lcm);
});
const layoutOptions = [
  { icon: '1x1', name: '1x1', value: [1, 1] },
  { icon: '1x2', name: '1x2', value: [1, 2] },
  { icon: '2x2', name: '2x2', value: [2, 2] }
];
const patientName = computed<string>(() => props.patient ? `${props.patient.last_name}, ${props.patient.first_name}` : '');
const patientInfos = computed<Record<string, string>>(() => {
  if (props.patient) {
    return {
      [t('platform.patient.dob')]: formatDob(props.patient.date_of_birth),
      [t('platform.patient.age')]: props.patient.age?.toString(),
      [t('platform.patient.sex')]: props.patient.sex,
      [t('platform.patient.ethnicity')]: props.patient.ethnicity
        ? props.patient.ethnicity.title
        : t('platform.patient.not-specified'),
      [t('platform.patient.phone')]: props.patient.contact_number
    };
  }
  if (props.lensSupportRequest) {
    return {
      [t('platform.patient.age')]: props.lensSupportRequest.patient_age?.toString()
    };
  }
  return {};
});
const currentImages = computed<Array<Image|null>>(() => selectedSeries.value
  .map((series, index) => (series ? series.images[imageIndexes.value[index]] : null))
);
const referenceLines = computed(() => currentImages.value.map((image) =>
  image
    ? {
      x1: image.octAlignmentX1,
      y1: image.octAlignmentY1,
      x2: image.octAlignmentX2,
      y2: image.octAlignmentY2,
      radius: image.octAlignmentRadius
    }
    : null
));
const imageSeries = computed<Image[][]>(() => selectedSeries.value
  .map((series) => (series ? series.images.sort((a, b) => a.order - b.order) : []))
);
const imageIds = computed<string[][]>(() => imageSeries.value.map((images) => images.map((image) => `${props.imagesRoute}/${image.id}`)));
const dimensions = computed(() => currentImages.value.map((image) => {
  if (image) {
    return {
      widthMm: image.widthMm,
      heightMm: image.heightMm
    };
  }
  return {
    widthMm: 0,
    heightMm: 0
  };
}));
const imageData = computed<DicomImageData[]>(() => currentImages.value.map((image) => {
  if (image) {
    const series = props.studies
      .map((study) => study.series.find((s) => s.images.find((i) => i.id === image.id)))
      .find((s) => s);
    if (series) {
      const lowerDescription = image.description?.toLowerCase();
      const type = lowerDescription?.includes(DifferenceMapsType.AXIAL) ? DifferenceMapsType.AXIAL :
        lowerDescription?.includes(DifferenceMapsType.TANGENTIAL) ? DifferenceMapsType.TANGENTIAL : null;
      const scales = (type && props.imageDetails[series.id]?.legend?.[type]) || null;
      const score = props.imageDetails[series.id]?.scoringDetails || null;
      const mlsId = series.topographerParams?.mlsId;
      const laterality = series.laterality;
      return {
        description: getSeriesDescription(series),
        acquisitionDateTime: dayjs(series.seriesDate).format('MMM D, YYYY h:mm:ss A'),
        dimensions:
          image.widthMm && image.heightMm ? `${image.widthMm.toFixed(1)}mmx${image.heightMm.toFixed(1)}mm` : '',
        type,
        scales,
        score,
        laterality,
        mlsId
      };
    }
  }
  return {};
}));
const galleryImages = computed<IGalleryImage[]>(() =>
  props.series
    .filter((series) => series.images && series.images.length)
    .map((series) => {
      let firstImage = series.images.find((image) => image.order === 1);
      firstImage = firstImage ? firstImage : series.images[0];
      return {
        id: series.id,
        image: null,
        imageUri: `${props.imagesRoute}/${firstImage.id}`,
        thumbnailUri: firstImage.thumbnailUri ? firstImage.thumbnailUri : `${props.imagesRoute}/${firstImage.id}`,
        placeholder: series.laterality ? series.laterality : '',
        date: series.seriesDate,
        description: getSeriesDescription(series),
        data: {
          thumbIndex: series.thumbIndex
        }
      };
    })
    .sort((a, b) => a.data.thumbIndex - b.data.thumbIndex) // sort by thumb index
);
const viewerItemsLength = computed(() => layout.value.reduce((a, b) => a + b, 0));

watch(() => props.series, () => {
  selectedSeries.value = selectedSeries.value.slice().map((s) => {
    if (s) {
      return props.series.find((series) => series.id === s.id) || null;
    }
    return null;
  });
});
onMounted(() => {
  // Open viewer on series with id seriesId
  if (props.seriesId && props.seriesId.length) {
    const index = props.series.findIndex((s) => s.id === props.seriesId);
    if (index > 0) {
      seriesIndex.value = index;
    }
  }
  selectedSeries.value = [props.series[seriesIndex.value]];

  window.addEventListener('keydown', (ev) => onKeydown(ev));
  window.addEventListener('wheel', (ev) => onScroll(ev as WheelEvent));
});
onBeforeUnmount(() => {
  window.removeEventListener('keydown', (ev) => onKeydown(ev));
  window.removeEventListener('wheel', (ev) => onScroll(ev as WheelEvent));
});
const getSliderStyle = (index = 0) => {
  const galleryHeight = 80;
  const footerHeight = footer.value?.[index]?.offsetHeight ?? 0;
  const viewerHeight = (grid.value?.$el?.offsetHeight ?? 0) / getLayout(index);
  const height = viewerHeight - footerHeight - galleryHeight;
  return {
    top: index <= 1 ? `${nav.value?.offsetHeight ?? 0}px` : `${(viewerHeight - footerHeight - height) / 2}px`,
    height: `${height}px`
  };
};
const resetViewport = (index: number) => {
  // reset zoom and pan
  if (lock.value) {
    zooms.value = [...zooms.value].map((v, i) => defaultZooms.value[i]);
    translations.value = [...translations.value].map(() => ({ x: 0, y: 0 }));
  } else {
    zooms.value.splice(index, 1, defaultZooms.value[index]);
    translations.value.splice(index, 1, { x: 0, y: 0 });
  }
};
const onKeydown = (ev: KeyboardEvent) => {
  if (focused.value >= 0) {
    const currentIndex = imageIndexes.value[focused.value];
    if (ev.key === 'ArrowUp') {
      updateImageIndex(focused.value, currentIndex - 1);
    } else if (ev.key === 'ArrowDown') {
      updateImageIndex(focused.value, currentIndex + 1);
    } else if (ev.key === 'PageDown') {
      updateImageIndex(focused.value, currentIndex + 10);
    } else if (ev.key === 'PageUp') {
      updateImageIndex(focused.value, currentIndex - 10);
    } else if (ev.key === 'Home') {
      updateImageIndex(focused.value, 0);
    } else if (ev.key === 'End') {
      updateImageIndex(focused.value, imageIds.value[focused.value].length);
    }
  }
};
const onScroll = (event: WheelEvent) => {
  if (focused.value >= 0) {
    const currentIndex = imageIndexes.value[focused.value];
    if (event.deltaY > 0) {
      updateImageIndex(focused.value, currentIndex + 1);
    } else {
      updateImageIndex(focused.value, currentIndex - 1);
    }
  }
};
const updateImageIndex = (viewer: number, index: number) => {
  if (mode.value === ViewerMode.COMPARE_MODE && lock.value) {
    imageIndexes.value.map((value, vIndex) => setImageIndex(vIndex, index));
  } else {
    setImageIndex(viewer, index);
  }
};
const setImageIndex = (viewer: number, index: number) => {
  const length = imageIds.value[viewer].length;
  if (selectedSeries.value[viewer] && length > 1) {
    if (index >= length - 1) {
      imageIndexes.value.splice(viewer, 1, length - 1);
    } else if (index < 0) {
      imageIndexes.value.splice(viewer, 1, 0);
    } else {
      imageIndexes.value.splice(viewer, 1, index);
    }
  }
};
const onSeriesChange = () => {
  imageIndexes.value.splice(0, 1, 0);
  selectedSeries.value.splice(0, 1, props.series[seriesIndex.value]);
  imageLoaderService.unshiftSeries(props.studyId, props.imagesRoute, props.series[seriesIndex.value], () =>
    emit('updateProcessedImages')
  );
};
const changeMode = (mode: number) => {
  if (mode === ViewerMode.SINGLE_MODE) {
    setLayout([1]);
  } else {
    setLayout([1, 1]);
  }
};
const selectSeries = (value: ImageSeries) => {
  if (openImagesSelector.value) {
    imageIndexes.value.splice(openImagesSelector.value, 1, 0);
    selectedSeries.value.splice(openImagesSelector.value, 1, value);
    openImagesSelector.value = null;
    if (lock.value) {
      updateImageIndex(0, imageIndexes.value[0]);
    }
  }
};
const translate = (index: number, value: { x: number; y: number }) => {
  if (lock.value) {
    translations.value = [...translations.value].map(() => ({ ...value }));
  } else {
    translations.value.splice(index, 1, {
      ...value
    });
  }
};
const setZoom = (index: number, value: { value: number; default: boolean }) => {
  if (value.default) {
    defaultZooms.value[index] = value.value;
  }
  if (lock.value) {
    zooms.value = [...zooms.value].map((v, i) => value.value - defaultZooms.value[index] + defaultZooms.value[i]);
  } else {
    zooms.value.splice(index, 1, value.value);
  }
};
const setLock = (value: boolean) => {
  lock.value = value;
  if (value) {
    zooms.value = [...zooms.value].map(() => zooms.value[0]);
    translations.value = [...translations.value].map(() => translations.value[0]);
    imageIndexes.value.map((value, viewer) => setImageIndex(viewer, imageIndexes.value[0]));
  }
};
const formatDob = (dob: string) => d(getDobFromISOString(dob), 'short');
const getLayout = (index: number): number | null => {
  let res: number | null = null;
  let found = false;
  layout.value.reduce((acc, val, i) => {
    const total = acc + val;
    if (!found && total > index) {
      res = layout.value[i];
      found = true;
    }
    return total;
  }, 0);
  return res;
};
const setLayout = (value: Array<number>) => {
  const nbViewers = value.reduce((a, b) => a + b, 0);
  const diff = viewerItemsLength.value - nbViewers; // calculate the difference of viewers
  const indexes = [...imageIndexes.value];
  const selected = [...selectedSeries.value];
  const newZooms = [...zooms.value];
  const newDefaultZooms = [...defaultZooms.value];
  const newTranslations = [...translations.value];
  if (diff > 0) {
    // If there are less viewers, remove the data of the last ones
    indexes.splice(nbViewers);
    selected.splice(nbViewers);
    newZooms.splice(nbViewers);
    newTranslations.splice(nbViewers);
  } else if (diff < 0) {
    // If there are more viewers, add default data
    for (let i = 0; i > diff; i--) {
      indexes.push(0);
      newDefaultZooms.push(100);
      newZooms.push(100);
      newTranslations.push({ x: 0, y: 0 });
      selected.push(null);
    }
  }
  imageIndexes.value = indexes;
  selectedSeries.value = selected;
  defaultZooms.value = newDefaultZooms;
  zooms.value = newZooms;
  translations.value = newTranslations;
  layout.value = value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref_key: "nav",
      ref: nav,
      class: "flex flex-row justify-between items-start w-full p-6 absolute top-0 z-10 top-nav",
      style: {"background":"linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%)"}
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          type: "button",
          "data-dusk": "viewer-close-button",
          class: "flex items-center justify-center bg-gray-900 text-white text-sm font-medium focus:outline-none rounded h-10 w-10 mr-2 hover:bg-gray-800",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_unref(BaseIcon), { name: "close" })
        ]),
        (__props.showCompare)
          ? (_openBlock(), _createBlock(ViewerNavigationSwitch, {
              key: 0,
              modelValue: mode.value,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event: any) => ((mode).value = $event)),
                _cache[2] || (_cache[2] = ($event: any) => (changeMode($event)))
              ],
              items: modes.value
            }, null, 8, ["modelValue", "items"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(ViewerSelect, {
          class: _normalizeClass(mode.value === ViewerMode.COMPARE_MODE ? 'visible ml-1' : 'invisible'),
          "model-value": layout.value,
          items: layoutOptions,
          lock: lock.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (setLayout($event))),
          onLock: _cache[4] || (_cache[4] = ($event: any) => (setLock($event)))
        }, null, 8, ["class", "model-value", "lock"]),
        (__props.patient)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "flex flex-col ml-2",
              "data-dusk": "patient-details",
              style: _normalizeStyle(patientName.value?.length ? '' : 'visibility: collapse')
            }, [
              _createVNode(_unref(NavDropdown), {
                dark: "",
                "button-class": "px-4",
                "menu-class": "",
                scrollable: false
              }, {
                button: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(patientName.value), 1)
                ]),
                menu: _withCtx(() => [
                  _createVNode(_unref(PatientDetailsCard), {
                    id: __props.patient.mrn,
                    class: "w-72 -m-2 z-20",
                    "first-name": _ctx.$t('platform.patient.details'),
                    dark: "",
                    "show-initials": false,
                    infos: patientInfos.value
                  }, {
                    default: _withCtx(() => [
                      (__props.patient.address)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('platform.common.address')), 1),
                            _createElementVNode("span", _hoisted_7, _toDisplayString(__props.patient.address.formatted_address), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["id", "first-name", "infos"])
                ]),
                _: 1
              })
            ], 4))
          : (__props.lensSupportRequest)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_unref(NavDropdown), {
                  dark: "",
                  "button-class": "px-4 shadow-xl rounded-md",
                  "menu-class": "",
                  scrollable: false
                }, {
                  button: _withCtx(() => [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('custom.menicon.lens-support.request.number', { id: __props.lensSupportRequest.request_id })), 1)
                  ]),
                  menu: _withCtx(() => [
                    _createVNode(_unref(PatientDetailsCard), {
                      class: "w-72 -m-2 z-20",
                      "first-name": _ctx.$t('platform.patient.details'),
                      dark: "",
                      "show-initials": false,
                      infos: patientInfos.value
                    }, null, 8, ["first-name", "infos"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
      ])
    ], 512),
    _createVNode(GridLayout, {
      ref_key: "grid",
      ref: grid,
      cols: layout.value.length,
      rows: rows.value
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array(viewerItemsLength.value), (_, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["relative", `row-span-${rows.value / getLayout(index)}`]),
            style: {"height":"inherit"}
          }, [
            (currentImages.value[index]?.referenceImageId && referenceLines.value[index])
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "absolute left-0 w-46 h-32 z-30 ml-6",
                  style: _normalizeStyle(index < layout.value.length ? `top: 80px` : 'top: 4px')
                }, [
                  _createVNode(ViewerItem, {
                    class: "rounded border border-gray-800",
                    "image-ids": [`${__props.imagesRoute}/${currentImages.value[index].referenceImageId}`],
                    "reference-line": referenceLines.value[index],
                    "enable-reference-line": ""
                  }, null, 8, ["image-ids", "reference-line"])
                ], 4))
              : _createCommentVNode("", true),
            _createVNode(ViewerItem, {
              class: _normalizeClass(["border-gray-500", {
            'border-r': mode.value === ViewerMode.COMPARE_MODE,
            'border-t': index >= layout.value.length
          }]),
              zoom: zooms.value[index] / 100,
              "zoom-min": __props.zoomMin,
              "enable-pan": "",
              "enable-zoom": "",
              translation: translations.value[index],
              "image-ids": imageIds.value[index],
              "current-image-index": imageIndexes.value[index],
              dimensions: dimensions.value[index],
              "scale-loading": __props.loadingScales.includes(selectedSeries.value[index]?.id),
              onSetZoom: ($event: any) => (setZoom(index, $event)),
              onTranslate: ($event: any) => (translate(index, $event)),
              onMouseover: ($event: any) => (focused.value = index)
            }, {
              default: _withCtx(() => [
                (imageData.value[index]?.scales?.length)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      style: _normalizeStyle(getSliderStyle(index)),
                      class: _normalizeClass(["left-0 absolute px-6", {
              'flex items-center justify-center': (!index && layout.value[0] === 1) || (index && layout.value[1] === 1)
            }])
                    }, [
                      _createVNode(TopographyScansScale, {
                        ref_for: true,
                        ref: `scale${index}`,
                        unit: __props.unit,
                        type: imageData.value[index].type,
                        height: getSliderStyle(index).height,
                        "legend-items": imageData.value[index].scales
                      }, null, 8, ["unit", "type", "height", "legend-items"])
                    ], 6))
                  : _createCommentVNode("", true),
                (imageIds.value[index].length > 1)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      style: _normalizeStyle(getSliderStyle(index)),
                      class: "flex flex-col right-0 absolute items-center justify-center px-4 h-full"
                    }, [
                      _createVNode(SlicesSlider, {
                        modelValue: imageIndexes.value[index],
                        "onUpdate:modelValue": [($event: any) => ((imageIndexes.value[index]) = $event), ($event: any) => (updateImageIndex(index, $event))],
                        max: imageIds.value[index].length - 1,
                        size: "small",
                        color: "white",
                        class: "w-px flex flex-1",
                        "enable-shortcuts": false
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "max"]),
                      _createElementVNode("span", _hoisted_10, _toDisplayString(imageIndexes.value[index] + 1) + "/" + _toDisplayString(imageIds.value[index].length), 1)
                    ], 4))
                  : _createCommentVNode("", true),
                (mode.value === ViewerMode.COMPARE_MODE && index > 0 && !imageIds.value[index].length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(_unref(BaseButton), {
                        size: "small",
                        "left-icon": "add",
                        onClick: ($event: any) => (openImagesSelector.value = index)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.scans.add-image')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  ref_for: true,
                  ref_key: "footer",
                  ref: footer,
                  class: "absolute bottom-0 right-0 left-0"
                }, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["flex flex-col bottom-0 mb-6 space-y-5", imageData.value[index]?.scales?.length &&
                  (_ctx.$refs[`scale${index}`]?.[0]?.$el.offsetHeight + _ctx.$refs[`scale${index}`]?.[0]?.$el.offsetParent?.offsetTop >=
                    _ctx.$refs.footer?.[index]?.offsetTop) ?
                    'ml-28' : 'ml-6'])
                    }, [
                      (imageData.value[index])
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            (imageData.value[index].score)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('custom.menicon.encounter.patient-scans.scan-coverage')), 1),
                                  _createVNode(BaseTooltip, {
                                    position: "after",
                                    "horizontal-alignment": "right"
                                  }, {
                                    target: _withCtx(() => [
                                      _createVNode(_unref(BaseIcon), {
                                        name: "info",
                                        height: "14px",
                                        class: "text-white hover:text-gray-300 focus:text-gray-300 stroke-1.5"
                                      })
                                    ]),
                                    content: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.encounter.patient-scans.scan-coverage-info')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(MedKitBadge, {
                                    label: imageData.value[index].score.topographyDataScore === _unref(MeniconImageDetailsScore).UNKNOWN ?
                        _ctx.$t('custom.menicon.scans.score.Unknown') : `${imageData.value[index].score.scorePercentage}%`,
                                    kind: "stable",
                                    size: "small"
                                  }, null, 8, ["label"])
                                ]))
                              : _createCommentVNode("", true),
                            (imageData.value[index].description)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(imageData.value[index].description), 1))
                              : _createCommentVNode("", true),
                            (imageData.value[index].dimensions)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(imageData.value[index].dimensions), 1))
                              : _createCommentVNode("", true),
                            (imageData.value[index].acquisitionDateTime)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(imageData.value[index].acquisitionDateTime), 1))
                              : _createCommentVNode("", true),
                            (imageData.value[index].mlsId && imageData.value[index].laterality)
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 4,
                                  class: "flex items-center py-3 px-4 space-x-3 bg-gray-900 rounded text-white font-medium",
                                  style: {"width":"max-content"},
                                  onClick: ($event: any) => (_ctx.$emit('showCornealDetails', {
                      laterality: imageData.value[index].laterality === 'R' ? _unref(Laterality).right : _unref(Laterality).left,
                      mlsId: imageData.value[index].mlsId
                    }))
                                }, [
                                  _createVNode(_unref(BaseIcon), { name: "info" }),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('custom.menicon.lens-ordering.view-corneal-details')), 1)
                                ], 8, _hoisted_19))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ], 2),
                    (imageIds.value[index].length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          (index > 0)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                type: "button",
                                class: "flex items-center bg-gray-900 text-white text-sm font-medium focus:outline-none rounded mr-2 px-4 h-10",
                                onClick: ($event: any) => (openImagesSelector.value = index)
                              }, [
                                _createVNode(_unref(BaseIcon), {
                                  name: "section",
                                  position: "left"
                                }),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('platform.scans.change-image')), 1)
                              ], 8, _hoisted_21))
                            : _createCommentVNode("", true),
                          (
                    translations.value[index].x !== 0 || translations.value[index].y !== 0 ||
                      (defaultZooms.value[index] && zooms.value[index] !== defaultZooms.value[index])
                  )
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 1,
                                type: "button",
                                class: "flex items-center bg-gray-900 text-gray-500 hover:text-white focus:text-white focus:outline-none p-3 rounded mr-2",
                                onClick: ($event: any) => (resetViewport(index))
                              }, [
                                _createVNode(_unref(BaseIcon), { name: "undo" })
                              ], 8, _hoisted_22))
                            : _createCommentVNode("", true),
                          (defaultZooms.value[index])
                            ? (_openBlock(), _createBlock(ViewerZoomInput, {
                                key: 2,
                                "model-value": zooms.value[index] - defaultZooms.value[index] + 100,
                                class: "z-10 h-10",
                                min: __props.zoomMin,
                                step: 10,
                                "onUpdate:modelValue": ($event: any) => (
                    setZoom(index, {
                      value: $event - 100 + defaultZooms.value[index],
                      default: false
                    })
                  )
                              }, null, 8, ["model-value", "min", "onUpdate:modelValue"]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(ViewerThumbnailGallery, {
                    modelValue: seriesIndex.value,
                    "onUpdate:modelValue": [
                      _cache[5] || (_cache[5] = ($event: any) => ((seriesIndex).value = $event)),
                      _cache[6] || (_cache[6] = ($event: any) => (onSeriesChange()))
                    ],
                    style: {"background":"rgba(28, 28, 28, 0.9)"},
                    class: _normalizeClass(mode.value === ViewerMode.SINGLE_MODE && galleryImages.value.length ? 'visible' : 'hidden'),
                    images: galleryImages.value
                  }, null, 8, ["modelValue", "class", "images"])
                ], 512)
              ]),
              _: 2
            }, 1032, ["class", "zoom", "zoom-min", "translation", "image-ids", "current-image-index", "dimensions", "scale-loading", "onSetZoom", "onTranslate", "onMouseover"])
          ], 2))
        }), 128))
      ]),
      _: 1
    }, 8, ["cols", "rows"]),
    (openImagesSelector.value)
      ? (_openBlock(), _createBlock(_unref(SeriesSelector), {
          key: 0,
          class: "fixed inset-0 z-50",
          studies: __props.studies,
          "current-study-id": __props.studyId,
          onClose: _cache[7] || (_cache[7] = ($event: any) => (openImagesSelector.value = null)),
          onSelect: _cache[8] || (_cache[8] = ($event: any) => (selectSeries($event)))
        }, null, 8, ["studies", "current-study-id"]))
      : _createCommentVNode("", true)
  ]))
}
}

})