import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row justify-between items-center mb-3"
}
const _hoisted_3 = { class: "flex flex-row items-center" }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["id", "value", "placeholder", "maxlength", "rows", "readonly", "disabled"]

import { InputTextHelper } from '@/lib/components/Helper';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { useId } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseTextarea',
  props: {
  modelValue: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  maxLength: {
    type: Number,
    default: null
  },
  rows: {
    type: Number,
    default: 5
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  hoverBorder: {
    type: Boolean,
    default: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  isOptional: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: null
  },
  instructions: {
    type: String,
    default: null
  },
  icon: {
    type: String,
    default: null
  },
  restrictLength: {
    type: Boolean,
    default: true
  }
},
  emits: ["update:modelValue", "change"],
  setup(__props) {




const id = useId();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.label?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (__props.icon?.length)
              ? (_openBlock(), _createBlock(BaseIcon, {
                  key: 0,
                  name: __props.icon,
                  height: "20px",
                  position: "left",
                  class: _normalizeClass({ invisible: __props.loading }),
                  stroke: false
                }, null, 8, ["name", "class"]))
              : _createCommentVNode("", true),
            _createElementVNode("label", {
              for: _unref(id),
              class: _normalizeClass(["inline-block text-base font-medium whitespace-pre-line", { 'text-transparent bg-gray-200 rounded-lg h-5': __props.loading }])
            }, _toDisplayString(__props.label), 11, _hoisted_4)
          ]),
          (__props.isOptional)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(["text-xs font-medium", __props.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500'])
              }, _toDisplayString(_ctx.$t('platform.common.optional')), 3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", _mergeProps({
      id: _unref(id),
      value: __props.loading ? '' : __props.modelValue,
      placeholder: __props.loading || __props.readonly ? '' : __props.placeholder,
      maxlength: __props.restrictLength ? __props.maxLength?.toString() : null,
      rows: __props.rows,
      readonly: __props.readonly,
      disabled: __props.disabled || __props.loading,
      class: ["w-full p-4 rounded border shadow", {
        'text-gray-600 bg-gray-50': __props.disabled && !__props.hoverBorder,
        'hover:border-gray-500 text-gray-600 bg-gray-50 border-transparent': __props.disabled && __props.hoverBorder,
        'text-gray-900 bg-gray-50': __props.readonly,
        'text-gray-400': __props.modelValue === '',
        'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none': __props.error
      }],
      style: {"resize":"none"}
    }, _ctx.$attrs, {
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).value))),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change', ($event.target as HTMLInputElement).value)))
    }), null, 16, _hoisted_5),
    _createVNode(_unref(InputTextHelper), {
      error: __props.error,
      description: __props.instructions,
      "right-description": __props.maxLength ? _ctx.$t('platform.instructions.maximum-characters', [__props.modelValue?.length ?? 0, __props.maxLength]) : null
    }, null, 8, ["error", "description", "right-description"])
  ]))
}
}

})