import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["data-dusk"]
const _hoisted_2 = { class: "space-y-10" }
const _hoisted_3 = { class: "p-10 flex items-center justify-center" }

import { computed, PropType, ref } from 'vue';
import { FormItem, FormSection } from '@/models';
import SmartFormItem from './SmartFormItem.vue';
import PageLoading from '@/lib/components/Loading/PageLoading.vue';
import { useSmartFormStore } from '@/stores/smartForm.store';
import { stringToDuskAttribute } from '@/custom/menicon/helpers/strings.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'SmartFormSection',
  props: {
  section: {
    type: Object as PropType<FormSection | FormItem>,
    required: true
  },
  participantId: {
    type: String,
    default: null
  },
  patientId: {
    type: String,
    default: null
  },
  formSubmissionId: {
    type: String,
    required: true
  },
  next: {
    type: Function,
    required: true
  },
  back: {
    type: Function,
    required: true
  },
  level: {
    type: Number,
    default: 0
  },
  itemsDivClass: {
    type: String,
    default: ''
  },
  readonly: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {

const props = __props;


const loadings = ref<string[]>([]);
const smartForm = useSmartFormStore();

const conditionsMet = computed(() => smartForm.getItemConditionsMet(props.formSubmissionId, props.section.conditions));
const duskAttribute = computed(() => props.section.heading ? stringToDuskAttribute(props.section.heading) : 'smart-form-section');

const checkLoading = (event: string) => {
  if (loadings.value.includes(event)) {
    loadings.value.splice(loadings.value.indexOf(event), 1);
  } else {
    loadings.value.push(event);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_SmartFormSection = _resolveComponent("SmartFormSection", true)!

  return (conditionsMet.value)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: _normalizeClass(["space-y-10", { 'mt-6': __props.level > 0 }]),
        "data-dusk": duskAttribute.value
      }, [
        _createElementVNode("div", null, [
          (__props.section.heading?.length)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent('h' + (__props.level + 1)), {
                key: 0,
                class: _normalizeClass(["font-semibold text-gray-900", __props.level === 0 ? 'text-2xl' : 'text-xl pt-10 border-t border-gray-300']),
                "data-dusk": "section-heading"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(__props.section.heading), 1)
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          (__props.section.instructions && __props.section.instructions.length)
            ? (_openBlock(), _createElementBlock("p", {
                key: 1,
                class: _normalizeClass(["mt-2 text-gray-500", __props.level === 0 ? 'text-lg' : 'text-base'])
              }, _toDisplayString(__props.section.instructions), 3))
            : _createCommentVNode("", true)
        ]),
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(__props.itemsDivClass)
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.section.items, (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: item.id
              }, [
                (item.type === 'section')
                  ? (_openBlock(), _createBlock(_component_SmartFormSection, {
                      key: 0,
                      section: item,
                      "participant-id": __props.participantId,
                      "patient-id": __props.patientId,
                      "form-submission-id": __props.formSubmissionId,
                      next: __props.next,
                      back: __props.back,
                      level: __props.level + 1,
                      readonly: __props.readonly
                    }, null, 8, ["section", "participant-id", "patient-id", "form-submission-id", "next", "back", "level", "readonly"]))
                  : (_openBlock(), _createBlock(SmartFormItem, {
                      key: item.id,
                      item: item,
                      "participant-id": __props.participantId,
                      "patient-id": __props.patientId,
                      "form-submission-id": __props.formSubmissionId,
                      loading: loadings.value.length !== 0,
                      next: __props.next,
                      back: __props.back,
                      readonly: __props.readonly,
                      onLoading: checkLoading
                    }, null, 8, ["item", "participant-id", "patient-id", "form-submission-id", "loading", "next", "back", "readonly"]))
              ], 64))
            }), 128))
          ]),
          _renderSlot(_ctx.$slots, "default")
        ], 2), [
          [_vShow, loadings.value.length === 0]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createVNode(PageLoading)
        ], 512), [
          [_vShow, loadings.value.length !== 0]
        ])
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})