import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["tabIndex"]
const _hoisted_2 = ["tabIndex"]
const _hoisted_3 = ["tabIndex"]

import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
// The code has been copied from vue-focus-lock and updated to Vue 3
// This needs some improvement
// https://github.com/theKashey/vue-focus-lock
import { constants, focusInside, focusIsHidden, moveFocusInside } from 'focus-lock';

const hidden = '';


export default /*@__PURE__*/_defineComponent({
  __name: 'Lock',
  props: {
  returnFocus: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  noFocusGuards: {
    type: [Boolean, String],
    default: false
  },
  group: {
    type: String,
    default: ''
  }
},
  setup(__props) {

function deferAction(action) {
  const setImmediate = window.setImmediate;
  if (typeof setImmediate === 'undefined') {
    setTimeout(action, 1);
  } else {
    setImmediate(action);
  }
}

let lastActiveTrap: any = 0;
let lastActiveFocus: any = null;

let focusWasOutsideWindow = false;

const isFreeFocus = () => document?.activeElement === document.body || focusIsHidden();

const activateTrap = () => {
  let result: any = false;
  if (lastActiveTrap) {
    const { observed, onActivation } = lastActiveTrap;
    if (focusWasOutsideWindow || !isFreeFocus() || !lastActiveFocus) {
      if (observed && !focusInside(observed)) {
        onActivation();
        result = moveFocusInside(observed, lastActiveFocus);
      }
      focusWasOutsideWindow = false;
      lastActiveFocus = document && document.activeElement;
    }
  }
  return result;
};

const reducePropsToState = (propsList) => propsList.filter(({ disabled }) => !disabled).slice(-1)[0];

const handleStateChangeOnClient = (trap) => {
  if (lastActiveTrap !== trap) {
    lastActiveTrap = null;
  }
  lastActiveTrap = trap;
  if (trap) {
    activateTrap();
    deferAction(activateTrap);
  }
};

let instances: any[] = [];

const emitChange = () => {
  handleStateChangeOnClient(reducePropsToState(instances));
};

const onTrap = (event) => {
  if (activateTrap() && event) {
    // prevent scroll jump
    event.stopPropagation();
    event.preventDefault();
  }
};

const onWindowBlur = () => {
  focusWasOutsideWindow = true;
};

const props = __props;

const data: any = {};
const groupAttr = computed(() => ({ [constants.FOCUS_GROUP]: props.group }));
const hasLeadingGuards = computed(() => props.noFocusGuards !== true);
const hasTailingGuards = computed(() => hasLeadingGuards.value && props.noFocusGuards !== 'tail');
const originalFocusedElement = ref(null);
const root = ref(null);

watch(() => props.disabled, () => {
  data.disabled = props.disabled;
  emitChange();
});

onMounted(() => {
  data.vue = this;
  data.observed = root.value.querySelector('[data-lock]');

  data.disabled = props.disabled;
  data.onActivation = () => {
    originalFocusedElement.value = originalFocusedElement.value || (document && document.activeElement);
  };

  if (!instances.length) {
    document.addEventListener('focusin', onTrap, true);
    document.addEventListener('focusout', onBlur);
    window.addEventListener('blur', onWindowBlur);
  }
  instances.push(data);
  emitChange();
});
onBeforeUnmount(() => {
  instances = instances.filter(({ vue }) => vue !== this);
  if (!instances.length) {
    document.removeEventListener('focusin', onTrap, true);
    document.removeEventListener('focusout', onBlur);
    window.removeEventListener('blur', onWindowBlur);
  }
  if (props.returnFocus && originalFocusedElement.value && originalFocusedElement.value.focus) {
    originalFocusedElement.value.focus();
  }
  emitChange();
});
const onBlur = () => {
  deferAction(emitChange);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "root",
    ref: root
  }, [
    (hasLeadingGuards.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          tabIndex: __props.disabled ? -1 : 0,
          style: hidden,
          "aria-hidden": "true"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (hasLeadingGuards.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          tabIndex: __props.disabled ? -1 : 1,
          style: hidden,
          "aria-hidden": "true"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _mergeProps(groupAttr.value, {
      "data-lock": "",
      onFocusout: onBlur
    }), [
      _renderSlot(_ctx.$slots, "default")
    ], 16),
    (hasTailingGuards.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          tabIndex: __props.disabled ? -1 : 0,
          style: hidden,
          "aria-hidden": "true"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ], 512))
}
}

})