import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-8" }
const _hoisted_2 = { class: "text-xl font-bold" }
const _hoisted_3 = { class: "flex flex-row flex-wrap mt-8 -mb-2 -mr-2" }
const _hoisted_4 = {
  key: 0,
  class: "text-center font-semibold"
}

import { PropType } from 'vue';
import dayjs from 'dayjs';
import { Study } from '@/models';
import StudyGalleryItem from './StudyGalleryItem.vue';
import { i18n } from '@/i18n/i18n';
import { MeniconImageDetails } from '@/custom/menicon/models';


export default /*@__PURE__*/_defineComponent({
  __name: 'StudyGallery',
  props: {
  studies: {
    type: Array as PropType<Study[]>,
    default: () => []
  },
  imageDetails: {
    type: Object as PropType<Record<string, MeniconImageDetails>>,
    default: () => ({})
  },
  seriesLoad: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  dark: {
    type: Boolean,
    default: false
  },
  scanHoverMessage: {
    type: String,
    default() {
      return i18n.global.t('platform.scans.open-in-viewer');
    }
  }
},
  emits: ["open"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const openViewer = (studyId: string, seriesId = '') => {
  emit('open', { studyId, seriesId });
};
const formatDate = (date: string) => dayjs(date).format('D MMM YYYY');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.studies, (study) => {
      return (_openBlock(), _createElementBlock("div", {
        key: study.id
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(formatDate(study.studyTime)), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(study.series, (imageSeries) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: imageSeries.id
            }, [
              (imageSeries.images.length)
                ? (_openBlock(), _createBlock(StudyGalleryItem, {
                    key: 0,
                    "image-series": imageSeries,
                    loading: __props.seriesLoad.includes(imageSeries.id),
                    score: props.imageDetails[imageSeries.id]?.scoringDetails || null,
                    "scan-hover-message": __props.scanHoverMessage,
                    "hover-class": __props.dark ? 'border border-white' : 'shadow-lg',
                    class: _normalizeClass(["w-75 h-53 mr-2 mb-2", __props.dark ? 'border border-gray-800' : '']),
                    onClick: ($event: any) => (openViewer(study.id, imageSeries.id))
                  }, null, 8, ["image-series", "loading", "score", "scan-hover-message", "hover-class", "class", "onClick"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ]))
    }), 128)),
    (__props.studies.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('platform.scans.no-studies')), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})