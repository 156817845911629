import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row justify-between items-center"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-xs text-gray-500 font-medium"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["id", "data-cy", "onClick"]
const _hoisted_6 = { class: "truncate" }
const _hoisted_7 = ["id", "value", "placeholder", "disabled", "readonly"]

import { computed, onBeforeMount, onBeforeUnmount, PropType, ref, useId } from 'vue';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { CountryCode } from 'libphonenumber-js';
import * as helper from '@/lib/helpers/phone-number-input.helper';
import { countries, ICountry } from '@/assets/data/country_dial_info';
import { BaseIcon } from '@/lib/components/Icon';
import { InputTextHelper } from '@/lib/components/Helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'PhoneNumberInput',
  props: {
  modelValue: {
    type: String as PropType<string | null>,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  error: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  isOptional: {
    type: Boolean,
    default: false
  }
},
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { t } = useI18n();
const props = __props;
const emit = __emit;

const countriesList = ref(null);
const root = ref(null);

const id = useId();
const countryCode = ref<CountryCode>('GB');
const countriesOpened = ref(false);
const focused = ref(false);

const sortedCountries = computed(() =>
  countries.sort((a, b) => t(`platform.countries.${a.code}`).localeCompare(t(`platform.countries.${b.code}`)))
);
const selectedCountry = computed<ICountry>(() => helper.getCountry(countryCode.value));
const placeholder = computed<string>(() => helper.getPlaceholder(countryCode.value));
const phoneNumber = computed(() => props.modelValue ? helper.getNumber(props.modelValue, countryCode.value) : null);
const isValid = computed(() => !props.modelValue?.length || phoneNumber.value?.isValid());
const formattedNumber = computed(() => phoneNumber.value?.formatNational() ?? props.modelValue);
const ipApiUrlPath = computed(() => {
  const apiUrl = 'https://pro.ip-api.com/json';
  const apiKey = process.env.VUE_APP_IP_API_KEY || null;
  return apiKey ? `${apiUrl}?key=${apiKey}` : apiUrl;
});

onBeforeMount(async () => {
  window.addEventListener('click', () => (countriesOpened.value = false));
  if (props.modelValue?.length) {
    const modelValueCountryCode = helper.getCountryCodeFromInternationalPhoneNumber(props.modelValue);
    if (modelValueCountryCode) {
      countryCode.value = modelValueCountryCode;
    }
  } else {
    try {
      const countryCodeFromApi = (await axios.get(ipApiUrlPath.value)).data.countryCode;
      if (countryCodeFromApi && countryCodeFromApi.length) {
        countryCode.value = countryCodeFromApi;
      } else {
        console.error('We could not get a valid country code.The phone number country has been set to default (GB).');
      }
    } catch (e) {
      console.error('We could not get the user country. The phone number country has been set to default (GB).');
    }
  }

  window.addEventListener('keydown', (ev) => onKeyDown(ev));
});
onBeforeUnmount(() => {
  window.removeEventListener('click', () => (countriesOpened.value = false));
  window.removeEventListener('keydown', (ev) => onKeyDown(ev));
});
const onKeyDown = (ev: KeyboardEvent) => {
  const menu = countriesList.value;
  if (countriesOpened.value && menu) {
    const elements = document.getElementsByClassName(`countries-${ev.key.toLowerCase()}`);
    if (elements.length) {
      menu.scrollTop = (elements[0] as HTMLElement).offsetTop;
    }
  }
};
const toggle = () => {
  if (props.disabled) {
    return;
  }
  countriesOpened.value = !countriesOpened.value;
  if (countriesOpened.value) {
    const el = root.value.querySelector(`#${countryCode.value}`);
    const container = countriesList.value;
    if (el && container) {
      (container as HTMLElement).scrollTop = (el as HTMLElement).offsetTop;
    }
  }
};
const updateCountry = (code: CountryCode) => {
  countryCode.value = code;
  updateValue(formattedNumber.value);
};
const updateValue = (value: string) => {
  countriesOpened.value = false;
  const number = helper.getNumber(value, countryCode.value);
  emit('update:modelValue', number ? number.formatInternational() : value);
};
__expose({
  isValid
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "root",
    ref: root
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _unref(id),
            class: "flex text-base font-medium leading-tight"
          }, _toDisplayString(__props.label), 9, _hoisted_2),
          (__props.isOptional)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([{
        'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none':
          __props.error || (!isValid.value && __props.modelValue),
        'bg-gray-50 border-transparent': __props.readonly,
        'border-primary-500': focused.value
      }, "mt-3 flex flex-row h-12 bg-white border border-solid rounded text-medium w-full shadow hover:border-gray-500 transition-all duration-100 ease-in-out focus:shadow-md"]),
      "data-cy": "phone-number-outline"
    }, [
      _createElementVNode("div", {
        class: "relative",
        onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createElementVNode("div", {
          "data-dusk": "country",
          class: "inline-flex items-center w-32 h-12 cursor-pointer select-none",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (toggle()))
        }, [
          (countryCode.value)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                style: {"transform":"scale(0.5)"},
                class: _normalizeClass('-ml-3 flag flag-' + countryCode.value.toLowerCase())
              }, null, 2))
            : _createCommentVNode("", true),
          (selectedCountry.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(selectedCountry.value.dialCode), 1))
            : _createCommentVNode("", true),
          _createVNode(_unref(BaseIcon), {
            name: "chevron-down",
            position: "right"
          })
        ]),
        (!__props.readonly)
          ? (_openBlock(), _createElementBlock("ul", {
              key: 0,
              ref_key: "countriesList",
              ref: countriesList,
              "data-cy": "country-list",
              class: _normalizeClass([countriesOpened.value ? 'visible z-50' : 'invisible', "absolute h-40 overflow-y-scroll bg-white cursor-pointer shadow-lg border border-solid border-gray-300 rounded"])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedCountries.value, (country) => {
                return (_openBlock(), _createElementBlock("li", {
                  id: country.code,
                  key: country.code,
                  "data-cy": `country-${country.code}`,
                  class: _normalizeClass(["flex flex-row items-center h-8", [
              country.code === countryCode.value ? 'bg-primary-500 text-white hover:bg-primary-700' : 'hover:bg-gray-100',
              `countries-${_ctx.$t(`platform.countries.${country.code}`).substring(0, 1).toLowerCase()}`
            ]]),
                  onClick: ($event: any) => (updateCountry(country.code as CountryCode))
                }, [
                  _createElementVNode("span", {
                    style: {"transform":"scale(0.4)"},
                    class: _normalizeClass('flag flag-' + country.code.toLowerCase())
                  }, null, 2),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t(`platform.countries.${country.code}`)), 1)
                ], 10, _hoisted_5))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "w-px my-2 bg-gray-300" }, null, -1)),
      _createElementVNode("input", {
        id: _unref(id),
        class: _normalizeClass([[isValid.value && !__props.error ? 'border-gray-300' : 'border-danger-700', __props.readonly ? 'bg-gray-50' : ''], "px-3 w-full min-w-0 h-full border-none focus:outline-none"]),
        type: "tel",
        "data-dusk": "phone-number-input",
        value: formattedNumber.value,
        placeholder: placeholder.value,
        disabled: __props.disabled,
        readonly: __props.readonly,
        onFocus: _cache[2] || (_cache[2] = ($event: any) => (focused.value = true)),
        onBlur: _cache[3] || (_cache[3] = ($event: any) => (focused.value = false)),
        onInput: _cache[4] || (_cache[4] = ($event: any) => (updateValue(($event.target as HTMLInputElement).value)))
      }, null, 42, _hoisted_7)
    ], 2),
    _createVNode(_unref(InputTextHelper), {
      "data-cy": "input-error",
      error: __props.error || !isValid.value ? __props.error || _ctx.$t('platform.error.phone-error') : '',
      description: __props.description
    }, null, 8, ["error", "description"])
  ], 512))
}
}

})