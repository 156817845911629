import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "p-2 bg-white border rounded-lg border-gray-200" }
const _hoisted_2 = {
  key: 0,
  class: "px-4 py-4 mb-2 text-sm font-semibold leading-none text-gray-900 border-b border-gray-200"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarNavigationGroup',
  props: {
  title: {
    type: String,
    default: null
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.title?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(__props.title), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})