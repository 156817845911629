import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "max-w-4xl py-10 grid grid-cols-2" }
const _hoisted_2 = { class: "flex items-center px-10" }
const _hoisted_3 = { class: "text-xl font-bold text-gray-900" }
const _hoisted_4 = { class: "mt-6" }
const _hoisted_5 = { class: "mt-6 font-semibold text-gray-900" }
const _hoisted_6 = { class: "block p-4 mt-6 text-sm border rounded bg-gray-100" }
const _hoisted_7 = { class: "text-xl font-bold text-gray-900" }
const _hoisted_8 = { class: "mt-6 text-gray-900" }
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = {
  key: 0,
  class: "p-4 mt-4 text-sm text-white rounded bg-danger-500"
}
const _hoisted_11 = { class: "mt-8" }
const _hoisted_12 = { class: "flex items-center px-10 text-center border-l" }
const _hoisted_13 = { class: "mt-8 text-xl font-bold text-gray-900" }
const _hoisted_14 = { class: "mt-4 text-gray-900" }

import { ref } from 'vue';
import { SupportTicketsService } from '@/services/api';
import { BaseButton, ButtonLink } from '@/lib/components/Button';
import BaseModal from './BaseModal.vue';
import { useSessionStore } from '@/stores/session.store';

const subject = 'In-app Support Request';

export default /*@__PURE__*/_defineComponent({
  __name: 'SupportModal',
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const sending = ref(false);
const body = ref('');
const error = ref(false);
const ticketNumber = ref('');
const supportTicketService = new SupportTicketsService();
const sessionStore = useSessionStore();
const send = async () => {
  try {
    error.value = false;
    sending.value = true;

    const response = await supportTicketService.create({
      user_id: sessionStore.currentUser?.id,
      organisational_unit_id: sessionStore.currentOrganisationId,
      subject,
      body: body.value
    });

    ticketNumber.value = response.data.id;
  } catch (e) {
    error.value = true;
    throw e;
  } finally {
    sending.value = false;
  }
};
const close = () => {
  emit('close');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseModal, { onClose: close }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            (ticketNumber.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('platform.support.request-received')), 1),
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('platform.support.thanks-message')), 1),
                  _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('platform.support.support-reference')), 1),
                  _createElementVNode("code", _hoisted_6, _toDisplayString(ticketNumber.value), 1),
                  _createVNode(_unref(BaseButton), {
                    size: "large",
                    color: "ghost",
                    class: "mt-8",
                    onClick: close
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('platform.common.close')), 1)
                    ]),
                    _: 1
                  })
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t('platform.support.email-support')), 1),
                  _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('platform.support.provide-details')), 1),
                  _withDirectives(_createElementVNode("textarea", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((body).value = $event)),
                    required: "",
                    class: "w-full mt-4 form-textarea",
                    placeholder: _ctx.$t('platform.common.message'),
                    rows: "3"
                  }, null, 8, _hoisted_9), [
                    [_vModelText, body.value]
                  ]),
                  (error.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('platform.support.error-message')), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_unref(BaseButton), {
                      size: "large",
                      disabled: body.value.length === 0,
                      loading: sending.value,
                      onClick: send
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.support.send-message')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "loading"]),
                    _createVNode(_unref(BaseButton), {
                      size: "large",
                      color: "ghost",
                      class: "ml-4",
                      disabled: sending.value,
                      onClick: close
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ])
                ], 64))
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", null, [
            _cache[1] || (_cache[1] = _createElementVNode("svg", {
              role: "img",
              "aria-label": "Teamviewer",
              class: "inline-block h-18",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 1024 1024"
            }, [
              _createElementVNode("defs", null, [
                _createElementVNode("linearGradient", {
                  id: "a",
                  x1: "511.61",
                  y1: "1026.9",
                  x2: "511.61",
                  gradientUnits: "userSpaceOnUse",
                  y2: "-.324"
                }, [
                  _createElementVNode("stop", {
                    offset: "0",
                    "stop-color": "#096fd2"
                  }),
                  _createElementVNode("stop", {
                    offset: "1",
                    "stop-color": "#0e8ee9"
                  })
                ]),
                _createElementVNode("linearGradient", {
                  id: "b",
                  x1: "412.95",
                  y1: "648.08",
                  x2: "412.86",
                  gradientUnits: "userSpaceOnUse",
                  y2: "376.45"
                }, [
                  _createElementVNode("stop", {
                    offset: "0",
                    "stop-color": "#096fd2"
                  }),
                  _createElementVNode("stop", {
                    offset: "1",
                    "stop-color": "#0e8ee9"
                  })
                ])
              ]),
              _createElementVNode("path", {
                fill: "url(#a)",
                class: "cls-1",
                d: "M917 0H105.8A107.2 105.8 0 000 106.3v811.4A107.2 105.8 0 00106.4 1024h811.2A107.2 105.8 0 001024 917.7V106.3A107.2 105.8 0 00917 0zM511.6 935.4A426.2 420.6 0 0188.7 512 426.2 420.6 0 01511.6 88.6 426.2 420.6 0 01934.8 512a426.2 420.6 0 01-423.2 423.4z"
              }),
              _createElementVNode("path", {
                fill: "url(#b)",
                class: "cls-1",
                d: "M904.2 512L610.7 376.3l23.58 86.95h-245.2l23.58-86.95-293.5 135.8 293.7 135.7-23.58-86.95h245.2L610.9 647.8l293.3-135.7"
              })
            ], -1)),
            _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.$t('platform.support.launch-teamviewer')), 1),
            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('platform.support.launch-teamviewer-description')), 1),
            _createVNode(_unref(ButtonLink), {
              size: "large",
              color: "ghost",
              class: "mt-8",
              href: "teamviewer10://control"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.support.launch-teamviewer')), 1)
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})