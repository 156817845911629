import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import QRCodeVue from 'qrcode.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseQRCodeViewer',
  props: {
  value: {
    type: String,
    required: true
  },
  size: {
    type: Number,
    default: 200
  }
},
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(QRCodeVue, {
    value: __props.value,
    size: __props.size
  }, null, 8, ["value", "size"]))
}
}

})