import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

import { BaseIcon } from '@/lib/components/Icon';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'NavLink',
  props: {
  to: {
    type: [String, Object],
    default: null
  },
  icon: {
    type: String,
    default: null
  }
},
  setup(__props) {


const currentRoute = useRoute();
const isRouteActive = (path: string) => currentRoute?.path.includes(path);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.to ? 'router-link' : 'a'), { to: __props.to }, {
    default: _withCtx(({ route }) => [
      _createElementVNode("div", {
        class: _normalizeClass([[
        isRouteActive(route.path)
          ? 'text-dark-500 border-primary-500'
          : 'text-gray-400 border-transparent group-hover:text-gray-900 group-hover:border-gray-400 group-focus:text-gray-900 group-focus:border-gray-400'
      ], "flex items-center font-semibold border-b-2 mx-5 focus:outline-none group h-full"])
      }, [
        (__props.icon)
          ? (_openBlock(), _createBlock(_unref(BaseIcon), {
              key: 0,
              name: __props.icon,
              class: "mr-2"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ]),
    _: 3
  }, 8, ["to"]))
}
}

})