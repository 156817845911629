import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row items-center space-x-4"
}

import { PropType, computed } from 'vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import MedKitButton from '@/lib/components/Button/MedKitButton.vue';
import MedKitBadge from '@/lib/components/Badge/MedKitBadge.vue';
import { getMedKitKindFromColor } from '@/lib/helpers/color.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseAlert',
  props: {
  color: {
    type: String,
    default: 'primary',
    validator: (value: string) => ['primary', 'success', 'alert', 'danger'].includes(value)
  },
  label: {
    type: [Array, String],
    default: null
  },
  badge: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: ''
  },
  iconStyle: {
    type: String,
    default: 'margin-top: 2px'
  },
  labelClass: {
    type: String,
    default: ''
  },
  list: {
    type: Boolean,
    default: false
  },
  button: {
    type: String,
    default: null
  },
  buttonSize: {
    type: String as PropType<'small' | 'medium'>,
    default: 'small',
    validator: (value: string) => ['small', 'medium'].includes(value)
  },
  buttonLoading: {
    type: Boolean,
    default: false
  }
},
  emits: ["buttonClick"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const labelArray = computed<string[]>(() => Array.isArray(props.label) ? props.label as string[] : [props.label]);
const textColorClass = computed<string>(() => `text-${props.color}-800`);
const bgColorClass = computed<string>(() => `bg-${props.color}-100`);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-row min-h-12 text-left space-x-4 rounded-md px-6 py-4 items-start", [textColorClass.value, bgColorClass.value]])
  }, [
    (__props.badge || __props.icon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (__props.badge)
            ? (_openBlock(), _createBlock(MedKitBadge, {
                key: 0,
                tint: "",
                label: __props.badge,
                kind: _unref(getMedKitKindFromColor)(__props.color),
                size: "small"
              }, null, 8, ["label", "kind"]))
            : _createCommentVNode("", true),
          (__props.icon)
            ? (_openBlock(), _createBlock(BaseIcon, {
                key: 1,
                name: __props.icon,
                height: "16px",
                style: _normalizeStyle(__props.iconStyle)
              }, null, 8, ["name", "style"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-col flex-1 w-full leading-tight", __props.labelClass])
    }, [
      (__props.label)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(labelArray.value, (str, index) => {
            return (_openBlock(), _createElementBlock("span", { key: str }, [
              (__props.list && __props.label.length > 1)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(index + 1) + ". ", 1)
                  ], 64))
                : _createCommentVNode("", true),
              _createTextVNode(_toDisplayString(str), 1)
            ]))
          }), 128))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    (__props.button)
      ? (_openBlock(), _createBlock(MedKitButton, {
          key: 1,
          label: __props.button,
          size: __props.buttonSize,
          kind: "secondary",
          loading: __props.buttonLoading,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('buttonClick')))
        }, null, 8, ["label", "size", "loading"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})