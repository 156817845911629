import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }

import { computed, PropType } from 'vue';
import { IPermission } from '@/lib';
import PermissionGroupItem from './PermissionGroupItem.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PermissionGroup',
  props: {
  modelValue: {
    type: Array as PropType<Array<IPermission>>,
    default: () => []
  },
  category: {
    type: String,
    default: null
  },
  readonly: {
    type: Boolean,
    default: false
  }
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;


const isCategoryChecked = computed(() => props.modelValue
  .filter((permission) => permission.isChecked).length === props.modelValue.length
);
const onCategoryChange = (value: boolean) => {
  // //Update parent status
  const array = props.modelValue.map((permission) => ({
    ...permission,
    isChecked: value
  }));
  emit('update:modelValue', array);
};
const onPermissionChange = (permissionIndex: number, value: boolean) => {
  const array = [...props.modelValue];
  // // Update children status
  if (value) {
    array[permissionIndex].isChecked = value;
    emit('update:modelValue', array);
  } else {
    array[permissionIndex].isChecked = value;
    emit('update:modelValue', array);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(PermissionGroupItem, {
        label: __props.category,
        readonly: __props.readonly,
        "model-value": isCategoryChecked.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (onCategoryChange($event)))
      }, null, 8, ["label", "readonly", "model-value"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.modelValue, (permission, permissionIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: permission.key,
          class: "mt-3 ml-6"
        }, [
          _createVNode(PermissionGroupItem, {
            label: permission.name,
            "model-value": permission.isChecked,
            readonly: __props.readonly,
            "onUpdate:modelValue": ($event: any) => (onPermissionChange(permissionIndex, $event))
          }, null, 8, ["label", "model-value", "readonly", "onUpdate:modelValue"])
        ]))
      }), 128))
    ])
  ]))
}
}

})