import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block" }

import { onBeforeUnmount, PropType, ref, watch } from 'vue';
import { TooltipPosition } from '@/lib';
import * as helper from '@/lib/helpers/tooltip.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseTooltip',
  props: {
  position: {
    type: String as PropType<TooltipPosition>,
    default: 'below',
    validator: (value: string) => ['before', 'after', 'above', 'right', 'left', 'below', 'top', 'bottom'].includes(value)
  },
  horizontalAlignment: {
    type: String as PropType<'center' | 'right' | 'left'>,
    default: 'center',
    validator: (value: string) => ['center', 'right', 'left'].includes(value)
  },
  showDelay: {
    default: 0,
    type: Number
  },
  hideDelay: {
    default: 0,
    type: Number
  },
  disabled: {
    default: false,
    type: Boolean
  },
  fullWidth: {
    default: false,
    type: Boolean
  },
  contentWidth: {
    default: '',
    type: String
  }
},
  setup(__props, { expose: __expose }) {

const props = __props;

const target = ref(null);
const content = ref(null);

const showTooltip = ref(false);
const initListeners = ref(false);
const style = ref('');
const hideTimeoutId = ref<number | null>(null);
const showTimeoutId = ref<number | null>(null);

watch(() => props.position, () => setTooltipPosition());
onBeforeUnmount(() => {
  window.removeEventListener('wheel', () => setTooltipPosition());
  window.removeEventListener('scroll', () => setTooltipPosition());
  window.removeEventListener('resize', () => setTooltipPosition());
});

const show = (delay: number = props.showDelay) => {
  if (hideTimeoutId.value) {
    window.clearTimeout(hideTimeoutId.value);
    hideTimeoutId.value = null;
  }

  showTimeoutId.value = window.setTimeout(() => {
    if (!initListeners.value) {
      window.addEventListener('wheel', () => setTooltipPosition());
      window.addEventListener('scroll', () => setTooltipPosition());
      window.addEventListener('resize', () => setTooltipPosition());
      initListeners.value = true;
    }
    showTooltip.value = true;
    setTooltipPosition();
    showTimeoutId.value = null;
  }, delay);
};
const hide = (delay: number = props.hideDelay) => {
  if (showTimeoutId.value) {
    window.clearTimeout(showTimeoutId.value);
    showTimeoutId.value = null;
  }

  hideTimeoutId.value = window.setTimeout(() => {
    showTooltip.value = false;
    hideTimeoutId.value = null;
  }, delay);
};
const setTooltipPosition = () => {
  const contentEl = content.value as HTMLElement;
  if (contentEl && showTooltip.value) {
    style.value = helper.isElementVisible(contentEl.getBoundingClientRect())
      ? getTooltipPosition(props.position, props.horizontalAlignment)
      : getTooltipPosition(helper.getFallbackPosition(props.position), props.horizontalAlignment);
  }
};
const getTooltipPosition = (position: TooltipPosition, horizontalAlignment: 'center' | 'right' | 'left') => helper.getTooltipStyle(
  position,
  content.value as HTMLElement,
  target.value as HTMLElement,
  horizontalAlignment
);

__expose({
  show,
  hide
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref_key: "target",
      ref: target,
      style: {"width":"max-content"},
      onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (show())),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (hide())),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (hide()))
    }, [
      _renderSlot(_ctx.$slots, "target")
    ], 544),
    _createElementVNode("div", {
      ref_key: "content",
      ref: content,
      class: _normalizeClass(["fixed bg-gray-900 shadow-lg rounded-lg text-xs font-semibold text-white px-2 py-1 z-50 max-w-xs transition-opacity ease-out duration-200", [
        showTooltip.value && !__props.disabled ? 'visible opacity-100' : 'invisible opacity-0',
        __props.position === 'before' || __props.position === 'left'
          ? 'mr-2'
          : __props.position === 'after' || __props.position === 'right'
            ? 'ml-2'
            : __props.position === 'above' || __props.position === 'top'
              ? 'mb-2'
              : __props.position === 'below' || __props.position === 'bottom'
                ? 'mt-2'
                : ''
      ]]),
      style: _normalizeStyle([style.value, __props.fullWidth ? 'min-width: max-content' : '', __props.contentWidth ? `min-width: ${__props.contentWidth}` : ''])
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 6)
  ]))
}
}

})