import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

import { computed, PropType } from 'vue';
import { BaseIcon } from '@/lib/components';


const buttonKinds = [
  'primary',
  'primary:positive',
  'primary:destructive',
  'secondary',
  'repeater',
  'dark',
  'tertiary'
] as const;
// eslint-disable-next-line @typescript-eslint/no-type-alias
type ButtonKind = typeof buttonKinds[number];
const validateIsButtonKind = (value: ButtonKind): boolean => buttonKinds.includes(value);
const buttonRenderTypes = ['button', 'a'] as const;
// eslint-disable-next-line @typescript-eslint/no-type-alias
type ButtonRenderType = typeof buttonRenderTypes[number];
const validateIsButtonRenderType = (value: ButtonRenderType): boolean => buttonRenderTypes.includes(value);
const buttonSizes = ['tiny', 'small', 'medium', 'large'] as const;
// eslint-disable-next-line @typescript-eslint/no-type-alias
type ButtonSize = typeof buttonSizes[number];
const validateIsButtonSize = (value: ButtonSize): boolean => buttonSizes.includes(value);
const buttonIconPositions = ['start', 'end', 'only', 'none'] as const;
// eslint-disable-next-line @typescript-eslint/no-type-alias
type ButtonIconPosition = typeof buttonIconPositions[number];
const validateIsButtonIconPosition = (value: ButtonIconPosition): boolean => buttonIconPositions.includes(value);
const __default__ = {
  name: 'MedKitButton'
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
  /**
   * Defines whether an icon is shown, at the start or end of the label or only an icon is shown instead of a label. (irrespective of language direction).
   */
  iconPosition: {
    type: String as PropType<ButtonIconPosition>,
    default: (): ButtonIconPosition => 'end',
    validator: validateIsButtonIconPosition
  },
  /**
   * Defines whether an icon is shown and which icon to display.
   */
  icon: {
    type: String,
    default: ''
  },
  /**
   * Describes the hierarchy and intent of the button
   */
  kind: {
    type: String as PropType<ButtonKind>,
    default: (): ButtonKind => 'secondary',
    validator: validateIsButtonKind
  },
  /**
   * The text label of the button.
   */
  label: {
    type: String,
    required: true
  },
  /**
   * The loading state of the button
   */
  loading: {
    type: Boolean,
    default: false
  },
  /**
   * Defines how the button is rendered.
   */
  renderAs: {
    type: String as PropType<ButtonRenderType>,
    default: (): ButtonRenderType => 'button',
    validator: validateIsButtonRenderType
  },
  /**
   * The size of the button.
   */
  size: {
    type: String as PropType<ButtonSize>,
    default: (): ButtonSize => 'large',
    validator: validateIsButtonSize
  },
  /** The title attribute for the button */
  title: {
    type: String,
    default: ''
  },
  /**
   * Defines the type attribute if rendered as a button. e.g 'button' or 'submit'.
   */
  type: {
    type: String
  }
},
  setup(__props) {

const props = __props;

const kindClasses = computed((): string => {
  switch (props.kind) {
  case 'primary':
    return 'bg-primary-500 text-white shadow-md border-none mk-btn-primary focus:shadow-lg';
  case 'primary:positive':
    return 'bg-success-800 text-white shadow-md border-none mk-btn-primary-positive focus:shadow-lg';
  case 'primary:destructive':
    return 'bg-danger-600 text-white shadow border-none mk-btn-primary-destructive focus:shadow-lg';
  case 'secondary':
    return 'bg-white text-gray-900 border-gray-300 border border-solid shadow-md mk-btn-secondary focus:shadow-lg';
  case 'dark':
    return 'bg-gray-900 text-white border-gray-300 border border-solid shadow-md mk-btn-secondary focus:shadow-lg';
  case 'tertiary':
    return 'bg-transparent text-primary-700 border border-solid border-transparent hover:bg-primary-100 font-medium active:bg-primary-200';
  case 'repeater':
    return props.size === 'large'
      ? 'text-primary-800 bg-primary-100 border border-solid border-primary-500 border-dashed hover:bg-primary-200 active:bg-primary-300'
      : 'text-steel-800 border border-solid bg-transparent border-steel-400 border-dashed hover:bg-primary-100 active:bg-primary-200';
  default:
    return '';
  }
});

const iconColor = computed((): string => {
  switch (props.kind) {
  case 'primary':
  case 'primary:positive':
  case 'primary:destructive':
  case 'dark':
    return 'text-white';
  case 'secondary':
  case 'tertiary':
    return 'text-gray-900';
  case 'repeater':
    return props.size === 'large' ? 'text-primary-800' : 'text-steel-800';
  default:
    return '';
  }
});

const sizeClasses = computed((): string => {
  if (props.iconPosition === 'only') {
    switch (props.size) {
    case 'medium':
      return 'h-10 w-10';
    case 'small':
      return 'h-8 w-8';
    case 'tiny':
      return 'h-6 w-6';
    default:
      return 'h-12 w-12';
    }
  }

  switch (props.size) {
  case 'medium':
    return 'h-10 px-3 label-small';
  case 'small':
    return 'h-8 px-2 label-small';
  case 'tiny':
    return 'h-6 px-2 label-x-small';
  default:
    return 'h-12 px-4 label';
  }
});

const gutterClasses = computed(() => {
  switch (props.size) {
  case 'medium':
    return 'gap-x-3';
  case 'small':
    return 'gap-x-3';
  case 'tiny':
    return 'gap-x-2';
  default:
    return 'gap-x-4';
  }
});

const typeAttribute = computed((): string =>
  props.renderAs === 'button' && typeof props.type === 'undefined' ? 'button' : props.type
);

const iconOnly = computed((): boolean => props.iconPosition === 'only');

const showIcon = computed(
  (): boolean =>
    props.icon &&
    props.iconPosition !== 'none' &&
    !(props.loading && (props.iconPosition === 'end' || props.iconPosition === 'only'))
);

const buttonTitle = computed(() => {
  if (props.title) {
    return props.title;
  }

  return iconOnly.value ? props.label : '';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.renderAs), {
    type: typeAttribute.value,
    class: _normalizeClass(["w-max inline-flex items-center justify-center rounded-md cursor-pointer focus-visible-ring transition-outline-colors ease-in-cube duration-130 focus:outline-none", [sizeClasses.value, kindClasses.value, __props.loading && __props.iconPosition !== 'only' ? gutterClasses.value : '']]),
    title: buttonTitle.value
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["inline-flex items-center justify-center", [gutterClasses.value, __props.iconPosition === 'start' ? 'flex-row-reverse' : '']])
      }, [
        (!iconOnly.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(__props.label), 1))
          : _createCommentVNode("", true),
        (showIcon.value)
          ? (_openBlock(), _createBlock(_unref(BaseIcon), {
              key: 1,
              name: __props.icon,
              class: _normalizeClass(iconColor.value),
              height: "16px"
            }, null, 8, ["name", "class"]))
          : _createCommentVNode("", true)
      ], 2),
      (__props.loading)
        ? (_openBlock(), _createElementBlock("svg", {
            key: 0,
            class: _normalizeClass(["mk-icon mk-indeterminate-loader stroke-current", iconColor.value]),
            width: "16",
            height: "16",
            viewBox: "0 0 16 16",
            stroke: "#000",
            "stroke-width": "1",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("circle", {
              cx: "8",
              cy: "8",
              r: "7"
            }, null, -1)
          ]), 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["type", "class", "title"]))
}
}

})