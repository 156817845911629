import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"max-width":"0","max-height":"0","overflow":"hidden"}
}

import { onBeforeUnmount, onMounted, ref } from 'vue';
import Lock from '@/lib/components/Modals/Lock.vue';
import { useUiStore } from '@/stores/ui.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseModal',
  props: {
  verticalAlign: {
    type: String,
    default: 'center',
    validator: (value: string) => ['top', 'center'].includes(value)
  },

  bgColor: {
    type: String,
    default: 'white'
  },

  dialogClass: {
    type: String,
    default: ''
  },

  danger: {
    type: Boolean,
    default: false
  },

  closeOnBackgroundClick: {
    type: Boolean,
    default: true
  },

  dark: {
    type: Boolean,
    default: false
  },

  preventAutofocus: {
    type: Boolean,
    default: false
  }
},
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const dialog = ref(null);
const uiStore = useUiStore();

onMounted(() => {
  registerCloseOnEscapeHandler();
  if (dialog.value) {
    dialog.value.scrollTop = 0;
  }
  uiStore.bodyScrollLock = true;
});
onBeforeUnmount(() => {
  document.removeEventListener('keydown', escapeHandler);
  uiStore.bodyScrollLock = false;
});
const onBackgroundClick = (event: MouseEvent) => {
  const element = event.target as HTMLInputElement;
  if (props.closeOnBackgroundClick && element?.id === 'outer-modal') {
    emit('close');
  }
};
const registerCloseOnEscapeHandler = () => {
  document.addEventListener('keydown', escapeHandler);
};
const escapeHandler = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    emit('close');
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    appear: "",
    "enter-active-class": "transition-opacity duration-150 ease-out",
    "leave-active-class": "transition-opacity duration-150 ease-in",
    "enter-from-class": "opacity-0",
    "leave-from-class": "opacity-0"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        id: "outer-modal",
        class: _normalizeClass(["fixed inset-0 z-100 flex justify-center filter-blur-none", __props.verticalAlign === 'center' ? 'items-center' : 'pt-10']),
        style: _normalizeStyle([__props.dark ? 'background-color: rgba(0, 0, 0, 0.8)' : 'background-color: rgba(246, 246, 246, 0.9)', 'margin: 0 !important;']),
        "data-dusk": "modal-box",
        onMousedown: _cache[0] || (_cache[0] = ($event: any) => (onBackgroundClick($event)))
      }, [
        _createVNode(Lock, { "return-focus": "" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              ref_key: "dialog",
              ref: dialog,
              role: "dialog",
              style: {"height":"max-content"},
              class: _normalizeClass([[__props.danger ? 'shadow-outline-danger-transparent' : 'shadow-lg', 'bg-' + __props.bgColor, __props.dialogClass], "rounded-xl max-h-screen overflow-y-auto"])
            }, [
              (__props.preventAutofocus)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
                    _createElementVNode("input", { autofocus: "" }, null, -1)
                  ])))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "default")
            ], 2)
          ]),
          _: 3
        })
      ], 38)
    ]),
    _: 3
  }))
}
}

})