import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

import { ref } from 'vue';
import { BaseIcon } from '@/lib/components/Icon';


export default /*@__PURE__*/_defineComponent({
  __name: 'NavDropdown',
  props: {
  dark: {
    type: Boolean,
    default: false
  },
  highlight: {
    type: Boolean,
    default: false
  },
  underline: {
    type: Boolean,
    default: true
  },
  scrollable: {
    type: Boolean,
    default: true
  },
  buttonClass: {
    type: String,
    default: 'px-8'
  },
  menuClass: {
    type: String,
    default: 'mr-6'
  },
  disabled: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {



const isOpen = ref(false);
const toggle = () => {
  isOpen.value = !isOpen.value;
};
const close = () => {
  isOpen.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative",
    onKeyup: _withKeys(close, ["esc"])
  }, [
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["h-full focus:outline-none group", [__props.highlight ? 'bg-primary-500' : (__props.dark ? 'bg-gray-900' : ''), __props.buttonClass]]),
      disabled: __props.disabled,
      onClick: toggle
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex items-center h-full", {
          'border-b-2 border-transparent': !__props.dark,
          'group-hover:border-gray-400 group-focus:border-gray-400': !__props.disabled && __props.underline
        }])
      }, [
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "button")
        ]),
        _createVNode(_unref(BaseIcon), {
          name: isOpen.value ? 'chevron-up' : 'chevron-down',
          class: _normalizeClass(["stroke-1.5", __props.dark ? 'text-gray-400' : 'text-gray-900']),
          position: "right"
        }, null, 8, ["name", "class"])
      ], 2)
    ], 10, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass([isOpen.value ? 'block' : 'hidden', __props.dark ? 'scrollbar-dark' : '']),
      onWheel: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([[
          __props.dark ? '' : 'bg-white border border-gray-300',
          __props.menuClass,
          __props.scrollable ? 'overflow-hidden overflow-y-auto max-h-125' : ''
        ], "absolute right-0 z-50 mt-4 rounded-lg shadow-lg"]),
        onClick: close
      }, [
        _renderSlot(_ctx.$slots, "menu")
      ], 2),
      _createElementVNode("button", {
        type: "button",
        tabindex: "-1",
        class: "fixed inset-0 z-30 block w-full h-full cursor-default",
        onClick: close
      })
    ], 34)
  ], 32))
}
}

})