import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white flex rounded-lg shadow p-3" }
const _hoisted_2 = { class: "ml-4" }
const _hoisted_3 = { class: "font-bold text-gray-900" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "font-bold" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { getDobFromISOString } from '@/helpers/patient.helper';
import dayjs from 'dayjs';


export default /*@__PURE__*/_defineComponent({
  __name: 'PrefillFormNotification',
  props: {
  createdAt: {
    type: String,
    required: true
  },
  username: {
    type: String,
    required: true
  }
},
  setup(__props) {

const { d } = useI18n();
const props = __props;

const date = computed(() => d(getDobFromISOString(props.createdAt), 'shortMonth'));
const time = computed(() => dayjs(props.createdAt).format('hh:mm A'));

return (_ctx: any,_cache: any) => {
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseIcon, {
      name: "primary-vector",
      height: "48px",
      stroke: false
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('platform.form.prefill-form-notification-title')), 1),
      _createVNode(_component_i18n_t, {
        class: "text-gray-500",
        keypath: "platform.form.prefill-form-notification-sub-title",
        tag: "div"
      }, {
        date: _withCtx(() => [
          _createElementVNode("span", _hoisted_4, _toDisplayString(date.value), 1)
        ]),
        time: _withCtx(() => [
          _createElementVNode("span", _hoisted_5, _toDisplayString(time.value), 1)
        ]),
        username: _withCtx(() => [
          _createElementVNode("span", _hoisted_6, _toDisplayString(__props.username), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})