import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]


export default /*@__PURE__*/_defineComponent({
  __name: 'PaginationButton',
  props: {
  disabled: {
    type: Boolean,
    default: false
  },
  active: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["flex items-center justify-center w-8 h-8 rounded-full bg-white transition-all duration-150 focus:outline-none", {
      'cursor-default': __props.disabled,
      'text-gray-900 bg-gray-200': __props.active && !__props.disabled,
      'focus:text-gray-900 focus:bg-gray-200 hover:text-gray-900 hover:bg-gray-200 cursor-pointer': !__props.active && !__props.disabled
    }]),
    disabled: __props.disabled
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}
}

})