import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = ["data-dusk"]
const _hoisted_2 = { class: "flex flex-row items-center space-x-4" }

import FormSummaryItem from './FormSummaryItem.vue';
import { FormItem, FormSection } from '@/models';
import { i18n } from '@/i18n/i18n';
import { computed, PropType } from 'vue';
import { useSmartFormStore } from '@/stores/smartForm.store';
import { stringToDuskAttribute } from '@/custom/menicon/helpers/strings.helper';
import MedKitButton from '@/lib/components/Button/MedKitButton.vue';
import MedKitBadge from '@/lib/components/Badge/MedKitBadge.vue';
import { formSchemaSectionToCopyableText } from '@/custom/menicon/helpers/encounter-copy.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'FormSummarySection',
  props: {
  section: {
    type: Object as PropType<FormSection | FormItem>,
    required: true
  },
  formSubmissionId: {
    type: String,
    required: true
  },
  level: {
    type: Number,
    default: 0
  },
  card: {
    type: Boolean,
    default: true
  },
  locale: {
    type: String,
    default: i18n.global.locale.value
  },
  isPdf: {
    type: Boolean,
    default: false
  },
  requestId: {
    type: String,
    default: null
  },
  participantId: {
    type: String,
    default: null
  },
  isIncomplete: {
    type: Boolean,
    default: false
  }
},
  emits: ["copyToClipboard"],
  setup(__props) {

const props = __props;


const smartForm = useSmartFormStore();

const conditionsMet = computed<boolean>(() =>
  smartForm.getItemConditionsMet(props.formSubmissionId, props.section.conditions)
);

const duskAttribute = computed<string>(() =>
  props.section.heading ? stringToDuskAttribute(props.section.heading) : ''
);

return (_ctx: any,_cache: any) => {
  const _component_FormSummarySection = _resolveComponent("FormSummarySection", true)!

  return (conditionsMet.value)
    ? (_openBlock(), _createElementBlock("section", {
        key: 0,
        class: _normalizeClass(["flex flex-col space-y-4 summary-section", {
      'bg-white rounded-lg border border-gray-200 shadow': __props.level === 0 && __props.card,
      'p-10': __props.level === 0 && __props.card && !__props.isPdf,
      'p-6': __props.level === 0 && __props.card && __props.isPdf
    }]),
        "data-dusk": duskAttribute.value
      }, [
        (__props.section.heading || __props.isIncomplete)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["flex flex-row items-center space-x-4 justify-between section-heading", __props.level ? 'mt-4' : 'mb-4'])
            }, [
              _createElementVNode("div", _hoisted_2, [
                (__props.section.heading)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent('h' + (__props.level + 2)), {
                      key: 0,
                      class: _normalizeClass(["font-semibold text-gray-900", __props.level ? 'text-base leading-normal' : 'text-xl'])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(__props.section.heading), 1)
                      ]),
                      _: 1
                    }, 8, ["class"]))
                  : _createCommentVNode("", true),
                (__props.isIncomplete)
                  ? (_openBlock(), _createBlock(MedKitBadge, {
                      key: 1,
                      kind: "high",
                      size: "small",
                      label: _ctx.$t('custom.menicon.encounter.summary.incomplete')
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true)
              ]),
              (!__props.requestId && !__props.participantId && !__props.isPdf && !__props.level)
                ? (_openBlock(), _createBlock(MedKitButton, {
                    key: 0,
                    size: "medium",
                    kind: "tertiary",
                    label: _ctx.$t('custom.menicon.encounter.summary.download.copy-one'),
                    icon: "copy",
                    "icon-position": "start",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('copyToClipboard', _unref(formSchemaSectionToCopyableText)(__props.section, __props.formSubmissionId, __props.locale))))
                  }, null, 8, ["label"]))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.section.items, (item) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.id
          }, [
            (item.type === 'section')
              ? (_openBlock(), _createBlock(_component_FormSummarySection, {
                  key: 0,
                  section: item,
                  "form-submission-id": __props.formSubmissionId,
                  level: __props.level + 1,
                  locale: __props.locale,
                  "is-pdf": __props.isPdf
                }, null, 8, ["section", "form-submission-id", "level", "locale", "is-pdf"]))
              : (_openBlock(), _createBlock(FormSummaryItem, {
                  key: item.id,
                  item: item,
                  "form-submission-id": __props.formSubmissionId,
                  locale: __props.locale,
                  "is-pdf": __props.isPdf
                }, null, 8, ["item", "form-submission-id", "locale", "is-pdf"]))
          ], 64))
        }), 128))
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})