import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  "data-cy": "action-modal",
  class: "relative flex flex-col select-none max-w-sm"
}
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row pt-6 pb-4 pr-6"
}
const _hoisted_3 = { class: "text-xl font-semibold text-center text-gray-900" }
const _hoisted_4 = {
  key: 1,
  class: "mt-4 text-center text-gray-500 whitespace-pre-line"
}
const _hoisted_5 = { class: "space-y-3 mt-8 w-full" }

import { PropType } from 'vue';
import { BaseIcon } from '@/lib/components/Icon';
import BaseModal from './BaseModal.vue';
import IconGlyph from '@/lib/components/Icon/IconGlyph.vue';
import MedKitButton from '@/lib/components/Button/MedKitButton.vue';
import { stringToDuskAttribute } from '@/custom/menicon/helpers/strings.helper';
import { GlyphColorName } from '../Icon/glyph';


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionModal',
  props: {
  iconColor: {
    type: String as PropType<GlyphColorName>,
    default: 'primary'
  },
  icon: {
    type: String,
    default: null
  },
  title: {
    type: String,
    default: ''
  },
  body: {
    type: String,
    default: ''
  },
  primaryAction: {
    type: String,
    default: null
  },
  primaryLoading: {
    type: Boolean,
    default: false
  },
  secondaryLoading: {
    type: Boolean,
    default: false
  },
  destructiveLoading: {
    type: Boolean,
    default: false
  },
  secondaryAction: {
    type: String,
    default: null
  },
  tertiaryAction: {
    type: String,
    default: null
  },
  destructiveAction: {
    type: String,
    default: null
  },
  danger: {
    type: Boolean,
    default: false
  },
  showClose: {
    type: Boolean,
    default: true
  }
},
  emits: ["close", "primaryClick", "secondaryClick", "tertiaryClick", "destructiveClick"],
  setup(__props, { emit: __emit }) {


const emit = __emit;

const close = () => {
  emit('close');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseModal, {
    danger: __props.danger,
    onClose: close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (__props.showClose)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _createElementVNode("button", {
                type: "button",
                onClick: close
              }, [
                _createVNode(_unref(BaseIcon), {
                  class: "text-gray-400",
                  name: "close"
                })
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["flex flex-col items-center px-8 pb-8", { 'pt-10': !__props.showClose }])
        }, [
          (__props.icon)
            ? (_openBlock(), _createBlock(IconGlyph, {
                key: 0,
                class: "mb-5",
                name: __props.icon,
                color: __props.iconColor,
                size: "large"
              }, null, 8, ["name", "color"]))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_3, _toDisplayString(__props.title), 1),
          (__props.body.length)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(__props.body), 1))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "body"),
          _createElementVNode("div", _hoisted_5, [
            (__props.primaryAction)
              ? (_openBlock(), _createBlock(MedKitButton, {
                  key: 0,
                  kind: "primary",
                  size: "large",
                  class: "w-full",
                  loading: __props.primaryLoading,
                  label: __props.primaryAction,
                  "data-dusk": `${_unref(stringToDuskAttribute)(__props.primaryAction)}-btn`,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (!__props.primaryLoading && _ctx.$emit('primaryClick', $event)))
                }, null, 8, ["loading", "label", "data-dusk"]))
              : _createCommentVNode("", true),
            (__props.secondaryAction)
              ? (_openBlock(), _createBlock(MedKitButton, {
                  key: 1,
                  kind: "secondary",
                  size: "large",
                  class: "w-full",
                  label: __props.secondaryAction,
                  loading: __props.secondaryLoading,
                  "data-dusk": `${_unref(stringToDuskAttribute)(__props.secondaryAction)}-btn`,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('secondaryClick', $event)))
                }, null, 8, ["label", "loading", "data-dusk"]))
              : _createCommentVNode("", true),
            (__props.destructiveAction)
              ? (_openBlock(), _createBlock(MedKitButton, {
                  key: 2,
                  kind: "primary:destructive",
                  size: "large",
                  class: "w-full",
                  label: __props.destructiveAction,
                  loading: __props.destructiveLoading,
                  "data-dusk": `${_unref(stringToDuskAttribute)(__props.destructiveAction)}-btn`,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('destructiveClick', $event)))
                }, null, 8, ["label", "loading", "data-dusk"]))
              : _createCommentVNode("", true),
            (__props.tertiaryAction)
              ? (_openBlock(), _createBlock(MedKitButton, {
                  key: 3,
                  kind: "tertiary",
                  size: "large",
                  class: "w-full",
                  label: __props.tertiaryAction,
                  "data-dusk": `${_unref(stringToDuskAttribute)(__props.tertiaryAction)}-btn`,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('tertiaryClick', $event)))
                }, null, 8, ["label", "data-dusk"]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default")
          ])
        ], 2)
      ])
    ]),
    _: 3
  }, 8, ["danger"]))
}
}

})