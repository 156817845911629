import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-10 flex items-center justify-center"
}
const _hoisted_2 = {
  key: 0,
  class: "p-10 w-full h-full inline-flex flex-col items-center justify-center"
}
const _hoisted_3 = { class: "font-medium text-base text-gray-900 mb-4" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "mb-4 legal-policy-text"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = ["id", "innerHTML"]

import { PropType, computed, ref, watch, onBeforeMount } from 'vue';
import PageLoading from '@/lib/components/Loading/PageLoading.vue';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import axios from 'axios';
import { BaseAlert } from '@/lib/components/Banner';
import { DEFAULT_LEGAL_REGION } from '@/constants/legal';
import { CustomerBranding, Customer } from '@/models';


export default /*@__PURE__*/_defineComponent({
  __name: 'LegalPolicy',
  props: {
  type: {
    type: String,
    validator: (value: string) => ['privacy-policy', 'terms-service'].includes(value),
    default: 'privacy-policy'
  },
  countryCode: {
    type: String,
    default: ''
  },
  /**
   * Force all links to open in a new tab
   * Used when we don't want to take user away from current page - e.g new patient registration
   */
  forceLinksNewTab: {
    type: Boolean,
    default: false
  },
  branding: {
    type: Array as PropType<Customer['branding'][]>,
    default: () => []
  },
  customers: {
    type: Array as PropType<Customer[]>,
    default: () => []
  }
},
  emits: ["fetch-start", "fetch-success"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const legalPolicyHtmls = ref<{ brand: string, content: HTMLElement }[]>([]);
const loading = ref(true);
const error = ref(false);
const isFallback = ref(false);
const bpmBrand = CustomerBranding.BPM;
const controller = ref<AbortController | null>(null);

const isEnglishRegion = computed<boolean>(() => props.countryCode.includes('en') || ['gb', 'us', 'au', 'sg'].includes(props.countryCode));
const customerBrands = computed<Customer['branding'][]>(() => props.customers.length > 0
  ? props.customers
    .filter((customer) => customer.branding !== bpmBrand && customer.legal_regions.includes(props.countryCode))
    .map((customer) => customer.branding)
  : props.branding.filter((branding) => branding !== bpmBrand));

watch(customerBrands, async () => {
  await exposed.fetchPolicyHtml();
});
watch(() => props.countryCode, async () => {
  await exposed.fetchPolicyHtml();
});

onBeforeMount(async () => {
  await fetchPolicyHtml();
});
const getHtmlUrl = (countryCode: string, branding: string): string => {
  const baseUrl = process.env.VUE_APP_LEGAL_BASE_URL ?? '';
  return `${baseUrl}/${branding}/${countryCode}/${props.type}`;
};
const fetchPolicyHtml = async () => {
  controller.value?.abort();
  controller.value = new AbortController();
  loading.value = true;
  error.value = false;
  emit('fetch-start');

  const policiesHtml = [];
  const brands = [bpmBrand, ...customerBrands.value];

  const allPromises = brands.map(async (brand) => {
    const brandedPolicy = await loadPolicy(props.countryCode, brand);
    if (brandedPolicy) {
      policiesHtml.push({
        brand,
        content: brandedPolicy.innerHTML
      });
    }
  });

  const results = await Promise.allSettled(allPromises);
  if (policiesHtml.length) {
    legalPolicyHtmls.value = policiesHtml.sort((a, b) => (a.brand > b.brand) ? 1 : -1);
    emit('fetch-success');
  } else if (results.some((result) => result.status === 'rejected' && !axios.isCancel(result.reason))) {
    error.value = true;
  }

  loading.value = false;
};
const loadPolicy = async (countryCode: string, branding: string) => {
  try {
    if (props.countryCode === countryCode && branding === bpmBrand) {
      isFallback.value = false;
    }
    const responseData = (await axios.get(exposed.getHtmlUrl(countryCode, branding), {
      signal: controller.value.signal
    })).data;
    return removeHtmlClassAttrs(responseData);
  } catch (e) {
    if (!axios.isCancel(e)) {
      // BPM will always fallback to gb
      if (branding === bpmBrand && countryCode !== DEFAULT_LEGAL_REGION) {
        isFallback.value = true;
        return loadPolicy(DEFAULT_LEGAL_REGION, bpmBrand);
      }
    }
  }
};
const removeHtmlClassAttrs = (htmlElement: string): HTMLElement => {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(htmlElement, 'text/html');
  const element = parsedHtml.querySelectorAll('div, h2, h3, p, a');
  for (let i = 0; i < element.length; i++) {
    if (element[i].className) {
      element[i].className = '';
    }
  }

  if (props.forceLinksNewTab) {
    const anchorTags = parsedHtml.getElementsByTagName('a');
    for (let i = 0; i < anchorTags.length; i++) {
      anchorTags[i].setAttribute('target', '_blank');
    }
  }

  return parsedHtml.body;
};
const exposed = {
  fetchPolicyHtml,
  getHtmlUrl
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (loading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(PageLoading)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (error.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('platform.legal.error-loading-policy')), 1),
                _createVNode(BaseButton, {
                  color: "primary",
                  "left-icon": "reload",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (fetchPolicyHtml()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.error.try-again')), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (!error.value && legalPolicyHtmls.value.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (legalPolicyHtmls.value.length > 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("h1", null, _toDisplayString(_ctx.$t(`platform.legal.${__props.type}.combined`)), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('platform.legal.navigation-header')), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(legalPolicyHtmls.value, (legalPolicy) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: `href-${legalPolicy.brand}`
                        }, [
                          (legalPolicy.content)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: `#${legalPolicy.brand}`
                              }, _toDisplayString(_ctx.$t(`platform.legal.${__props.type}.title`)) + " (" + _toDisplayString(_ctx.$t(`platform.legal.${legalPolicy.brand}`)) + ") ", 9, _hoisted_6))
                            : _createCommentVNode("", true),
                          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
                        ], 64))
                      }), 128)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('platform.legal.disclaimer')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(legalPolicyHtmls.value, (legalPolicyHtml, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    (legalPolicyHtmls.value.length > 1)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                          _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)),
                          _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1))
                        ], 64))
                      : _createCommentVNode("", true),
                    (isFallback.value && !isEnglishRegion.value && legalPolicyHtml.brand === _unref(bpmBrand))
                      ? (_openBlock(), _createBlock(_unref(BaseAlert), {
                          key: 1,
                          label: _ctx.$t('platform.legal.fallback-alert'),
                          icon: "info",
                          class: "mb-4"
                        }, null, 8, ["label"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      id: legalPolicyHtml.brand,
                      class: "legal-policy-text",
                      innerHTML: legalPolicyHtml.content
                    }, null, 8, _hoisted_7)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}
}

})