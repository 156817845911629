import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

import { BaseIcon } from '@/lib/components/Icon';


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonLink',
  props: {
  /**
   * A vue-Router object or string specifying the URL to navigate to
   */
  to: {
    type: [String, Object],
    default: null
  },
  rightIcon: {
    type: String,
    default: ''
  },
  leftIcon: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: 'primary',
    validator: (value: string) =>
      ['primary', 'secondary', 'tertiary', 'success', 'alert', 'danger', 'ghost', 'muted', 'clear'].includes(value)
  },
  size: {
    type: String,
    default: 'regular',
    validator: (value: string) => ['small', 'regular', 'large', 'xl'].includes(value)
  },
  block: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.to ? 'router-link' : 'a'), _mergeProps({
    to: __props.to,
    class: ["btn", [__props.disabled ? 'btn-disabled' : `btn-${__props.color}`, __props.block ? 'btn-block' : '', `btn-${__props.size}`]],
    disabled: __props.disabled
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      (__props.leftIcon?.length)
        ? (_openBlock(), _createBlock(_unref(BaseIcon), {
            key: 0,
            name: __props.leftIcon,
            position: "left",
            class: "stroke-1.5"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      (__props.rightIcon?.length)
        ? (_openBlock(), _createBlock(_unref(BaseIcon), {
            key: 1,
            class: "order-1 stroke-1.5",
            name: __props.rightIcon,
            position: "right"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["to", "disabled", "class"]))
}
}

})