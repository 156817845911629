import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row items-center justify-between mb-3"
}
const _hoisted_3 = { class: "flex flex-row items-center space-x-3" }
const _hoisted_4 = ["name", "disabled", "readonly", "placeholder", "value"]

import { computed, PropType, ref } from 'vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import InputTextHelper from '@/lib/components/Helper/InputTextHelper.vue';
import { getClosestStepMultiple } from '@/lib/helpers/number.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'UnitsNumberInput',
  props: {
  label: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: ''
  },
  units: {
    type: String,
    default: null
  },
  min: {
    type: Number,
    default: 0.0
  },
  max: {
    type: Number,
    default: 0.0
  },
  step: {
    type: Number,
    default: 1
  },
  precision: {
    type: Number as PropType<number | null>,
    default: null
  },
  modelValue: {
    // I made this a string because:
    // we want intermediate states where a user is typing '-' followed by a number, without showing NaN
    type: String,
    default: '0'
  },
  readonly: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  inputClass: {
    type: String,
    default: 'h-12'
  },
  error: {
    type: String,
    default: null
  },
  isOptional: {
    type: Boolean,
    default: false
  },
  autoCorrect: {
    type: Boolean,
    default: false
  }
},
  emits: ["update:modelValue", "format"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const input = ref(null);

const focused = ref(false);

const formattedValue = computed<string>(() =>
  props.precision && props.modelValue?.length ? (+props.modelValue).toFixed(props.precision) : props.modelValue
);
const validateInput = (ev: KeyboardEvent): boolean => {
  const target = ev.target as HTMLInputElement;

  const targetValue = target.value;

  if (ev.ctrlKey || ev.key === 'Delete' || ev.key === 'Backspace') {
    return true;
  }

  const isArrow = ['ArrowLeft', 'ArrowRight'].includes(ev.key);
  const isNumber = /[0-9]|\.|\b/.test(ev.key);

  const caretPosition = target.selectionStart;

  // check for valid negative number input
  const isHyphen = ev.key === '-';
  const isHyphenAlreadyPresent = targetValue.includes('-');
  const isValidHyphen = !isHyphen || (!isHyphenAlreadyPresent && caretPosition === 0);

  // check for valid . input
  const isFullStop = ev.key === '.';
  const isFullStopAlreadyPresent = targetValue.includes('.');
  const isValidFullStop = !isFullStop || !isFullStopAlreadyPresent;

  // check for alphabetical characters
  const isAlphabeticalChar = /[a-zA-Z]/.test(ev.key) && ev.key?.length === 1;

  // general check
  const isValid = isNumber || isArrow || isHyphen;

  if (isAlphabeticalChar || !isValidHyphen || !isValidFullStop || !isValid) {
    ev.preventDefault();
  }

  return isValid;
};
const validateChange = (value: string) => {
  const numberValue = Number(value);
  if (!numberValue && numberValue !== 0) {
    // maybe set an error for this input
  } else if (props.autoCorrect && props.step) {
    let validatedInput = '';
    if (value.length) {
      validatedInput = getClosestStepMultiple(numberValue, {
        step: props.step,
        min: props.min,
        max: props.max
      }).toString();
    }
    emit('format', validatedInput);
  } else {
    emit('format', numberValue.toString());
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ((__props.label?.length) || _ctx.$slots.action)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", {
            class: _normalizeClass(["inline-block text-base font-medium leading-tight", { 'text-transparent bg-gray-200 rounded-lg': __props.loading }])
          }, _toDisplayString(__props.label), 3),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.$slots.action)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([__props.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-primary-500 cursor-pointer', "flex items-center text-sm font-medium text-right"])
                }, [
                  _renderSlot(_ctx.$slots, "action")
                ], 2))
              : _createCommentVNode("", true),
            (__props.isOptional)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass([__props.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500', "text-xs font-medium cursor-default capitalize"])
                }, _toDisplayString(_ctx.$t('platform.common.optional')), 3))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["inline-flex border rounded object-contain max-w-12 justify-between", {
        [`${__props.inputClass}`]: __props.inputClass,
        'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none':
          __props.error,
        'bg-gray-100 text-gray-400': __props.disabled && !__props.readonly,
        'bg-gray-50 text-gray-900 border-gray-200': __props.readonly,
        'border-primary-500': focused.value && !__props.disabled
      }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["inline-flex w-full text-base font-medium text-center items-center bg-white rounded text-gray-900 pl-3", { 'bg-gray-100 text-gray-400': __props.disabled && !__props.readonly, 'bg-gray-50 text-gray-900 border-gray-200': __props.readonly }]),
        onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => ((_ctx.$refs.input as HTMLInputElement).focus()), ["stop"]))
      }, [
        (__props.icon)
          ? (_openBlock(), _createBlock(BaseIcon, {
              key: 0,
              name: __props.icon,
              stroke: false,
              class: "mr-3"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          ref_key: "input",
          ref: input,
          class: _normalizeClass(["border-none outline-none rounded min-w-0 w-full", { 'bg-gray-100 text-gray-400': __props.disabled && !__props.readonly, 'bg-gray-50 text-gray-900': __props.readonly }]),
          type: "text",
          name: __props.name,
          disabled: __props.disabled,
          readonly: __props.readonly,
          placeholder: __props.placeholder,
          value: formattedValue.value,
          onFocus: _cache[0] || (_cache[0] = ($event: any) => (focused.value = true)),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (focused.value = false)),
          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).value))),
          onKeydown: _cache[3] || (_cache[3] = ($event: any) => (validateInput($event))),
          onChange: _cache[4] || (_cache[4] = ($event: any) => (validateChange(($event.target as HTMLInputElement).value)))
        }, null, 42, _hoisted_4), [
          [_vShow, !__props.loading]
        ])
      ], 2),
      (__props.units)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["inline-flex justify-center items-center bg-white text-base font-small text-center border-l rounded rounded-l-none pr-4 pl-4", { 'bg-gray-100 text-gray-400': __props.disabled && !__props.readonly, 'bg-gray-50 text-gray-900 border-gray-200': __props.readonly }])
          }, _toDisplayString(__props.units), 3))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(InputTextHelper, {
      error: __props.error,
      description: __props.description
    }, null, 8, ["error", "description"])
  ]))
}
}

})