import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-full w-full flex flex-col items-center justify-center space-y-6"
}
const _hoisted_2 = { class: "text-white font-semibold text-lg" }
const _hoisted_3 = {
  key: 1,
  "data-dusk": "viewer-loader",
  style: {"box-shadow":"inset 0 0 0 1000px rgba(0, 0, 0, 0.9)"},
  class: "absolute text-white inset-0 z-10 flex items-center justify-center"
}

import { computed, onBeforeUnmount, onMounted, PropType, ref, watch } from 'vue';
import ResizeObserver from 'resize-observer-polyfill';
// @ts-ignore
import cornerstone from 'cornerstone-core/dist/cornerstone.min.js';
// @ts-ignore
import cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.min.js';
import { LoadingIcon } from '@/lib/components/Loading';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import { arrayEquals } from '@/helpers/array.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewerItem',
  props: {
  enableZoom: {
    type: Boolean,
    default: false
  },
  enablePan: {
    type: Boolean,
    default: false
  },
  enableReferenceLine: {
    type: Boolean,
    default: false
  },
  referenceLine: {
    type: Object as PropType<{
      x1: number;
      y1: number;
      x2: number;
      y2: number;
      radius: number | null;
    }>,
    default: null
  },
  imageIds: {
    type: Array as PropType<string[]>,
    default: () => [],
    validator: (value: Array<string>) => !value.find((v) => !v.startsWith('http'))
  },
  translation: {
    type: Object as PropType<{ x: number; y: number }>,
    default: () => ({ x: 0, y: 0 })
  },
  scaleLoading: {
    type: Boolean,
    default: false
  },
  currentImageIndex: {
    type: Number,
    default: 0
  },
  zoom: {
    type: Number,
    default: 1
  },
  zoomMin: {
    type: Number,
    default: 10
  },
  zoomMax: {
    type: Number,
    default: 1000
  },
  dimensions: {
    type: Object as PropType<{
      widthMm: number;
      heightMm: number;
    }>,
    default: () => ({
      widthMm: 0,
      heightMm: 0
    })
  }
},
  emits: ["setZoom", "translate"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

// template refs
const viewer = ref(null);
const referenceLineCanvas = ref(null);

const loading = ref(false);
const isDefaultZoomSet = ref(false);
const isElementEnabled = ref(false);
const isError = ref(false);
const loadingImageId = ref<string | null>(null); // current loading image id
const observer = ref<ResizeObserver | null>(null);
const image = ref({
  height: 0,
  width: 0
});

// transformations
const zooming = ref(false);
const translating = ref(false);
const enabled = ref(false);
const initialScale = ref(1); // current image initial scale
const initialZoom = ref(1);

const pixelSpacing = computed(() => ({
  columnPixelSpacing: props.dimensions.widthMm / image.value.width,
  rowPixelSpacing: props.dimensions.heightMm / image.value.height
}));

// update displayed image
watch(() => props.imageIds, (current, previous) => {
  if (!arrayEquals(previous, current)) {
    if (enabled.value) {
      onImagesUpdate();
    } else {
      init();
    }
  }
});
watch(() => props.currentImageIndex, () => {
  if (isDefaultZoomSet.value) {
    loadImage(props.currentImageIndex, props.translation);
  } else {
    reload();
  }
});
// watch transformations update (zoom/pan/redFree)
watch(() => props.zoom, () => setZoom(props.zoom));
watch(() => props.translation, () => setTranslation(props.translation));
watch(() => props.referenceLine, () => drawReferenceLine());

onMounted(() => {
  initialZoom.value = props.zoom;

  if (props.imageIds.length) {
    init();
  }
  cornerstone.metaData.addProvider(metadataProvider);
});
onBeforeUnmount(() => {
  if (observer.value) {
    observer.value.disconnect();
  }
  if (enabled.value) {
    const element = viewer.value as HTMLElement;
    element.removeEventListener('cornerstoneimagerendered', (ev) => onRender(ev as CustomEvent));
    cornerstone.disable(element);
  }
});

// @ts-ignore
const metadataProvider = (type: string, imageId: string) => {
  if (
    type === 'imagePlaneModule' &&
    imageId === props.imageIds[props.currentImageIndex]
  ) {
    return pixelSpacing.value;
  }
};
const init = () => {
  cornerstoneTools.init();

  const element = viewer.value as HTMLElement;
  cornerstone.enable(element);
  isElementEnabled.value = true;

  // update viewport on element resize
  observer.value = new ResizeObserver((entries) => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      if (isError.value) {
        isDefaultZoomSet.value = false;
      } else {
        reload();
      }
    });
  });
  observer.value.observe(element);

  onImagesUpdate();
  drawReferenceLine();

  // Pan Tool
  if (props.enablePan) {
    cornerstoneTools.addToolForElement(viewer.value, cornerstoneTools.PanTool);
    cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 4 }); // Pan on middle mouse button
    cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 1 }); // Pan on left mouse button
  }
  // If the image is rendered (image changed, transformation), update translate/zoom/shaders
  element.addEventListener('cornerstoneimagerendered', (ev) => onRender(ev as CustomEvent));

  // Add zoom (depends on the initial scale of the image)
  if (props.enableZoom) {
    cornerstoneTools.addToolForElement(viewer.value, cornerstoneTools.ZoomTool, {
      configuration: {
        minScale: (props.zoomMin * initialScale.value) / 100,
        maxScale: (props.zoomMax * initialScale.value) / 100,
        invert: true
      }
    });
    cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 2 });
  }
  enabled.value = true;
};

const reload = async () => {
  if (isElementEnabled.value) {
    cornerstone.resize(viewer.value);
    await loadImage(props.currentImageIndex, props.translation);
    setDefaultZoom();
  }
};
// Mouse events
const onMouseDown = (ev: MouseEvent) => {
  if (ev.buttons === 2) {
    zooming.value = true;
  }
  if (ev.buttons === 4 || ev.buttons === 1) {
    translating.value = true;
  }
};

const onMouseUp = () => {
  zooming.value = false;
  translating.value = false;
};

const onContextMenu = (ev: Event) => {
  ev.preventDefault(); // disable context menu on Viewer
  return false;
};

// Transformations
const onRender = (ev: CustomEvent) => {
  if (zooming.value && props.enableZoom && ev.detail.viewport?.scale) {
    emit('setZoom', {
      value: (ev.detail.viewport.scale * 100) / initialScale.value,
      default: false
    });
  } else if (translating.value && props.enablePan) {
    emit('translate', ev.detail.viewport.translation);
  }

  drawReferenceLine();
};

const setZoom = (zoom: number) => {
  if (props.enableZoom && isElementEnabled.value) {
    const element = viewer.value;
    const viewport = cornerstone.getViewport(element);
    if (viewport) {
      viewport.scale = zoom * initialScale.value;
      cornerstone.setViewport(element, viewport);
    }
  }
};

const setTranslation = (translation: { x: number; y: number }) => {
  if (props.enablePan && isElementEnabled.value) {
    const element = viewer.value;
    const viewport = cornerstone.getViewport(element);
    if (viewport) {
      viewport.translation = translation;
      cornerstone.setViewport(element, viewport);
    }
  }
};

const drawReferenceLine = () => {
  if (props.enableReferenceLine) {
    const canvas = referenceLineCanvas.value as HTMLCanvasElement;
    if (canvas.getContext) {
      const context = canvas.getContext('2d');
      if (context) {
        context.clearRect(0, 0, canvas.width, canvas.height);
        if (props.referenceLine) {
          const midX = canvas.width / 2;
          const midY = canvas.height / 2;
          const scaleX = (initialScale.value * canvas.width) / canvas.clientWidth;
          const scaleY = (initialScale.value * canvas.height) / canvas.clientHeight;
          const x1 = midX + (props.referenceLine.x1 - image.value.width / 2) * scaleX;
          const y1 = midY + (props.referenceLine.y1 - image.value.height / 2) * scaleY;

          context.beginPath();
          context.lineWidth = 2;
          context.strokeStyle = '#a9d96c';
          context.fillStyle = '#a9d96c';
          if (props.referenceLine.radius) {
            const radiusX = props.referenceLine.radius * scaleX;
            const radiusY = props.referenceLine.radius * scaleY;
            context.fillRect(x1, y1, 2, 2);
            context.ellipse(x1, y1, radiusX, radiusY, 0, 0, 2 * Math.PI);
          } else {
            const x2 = midX + (props.referenceLine.x2 - image.value.width / 2) * scaleX;
            const y2 = midY + (props.referenceLine.y2 - image.value.height / 2) * scaleY;
            context.moveTo(x1, y1);
            context.lineTo(x2, y2);
          }
          context.stroke();
        }
      }
    }
  }
};

// Images
const onImagesUpdate = async () => {
  if (props.imageIds?.length) {
    await loadImage(props.currentImageIndex);
    setDefaultZoom();
    emit('translate', { x: 0, y: 0 }); // reset translation
  }
};

const setDefaultZoom = () => {
  try {
    isDefaultZoomSet.value = false;
    const enabledElement = cornerstone.getEnabledElement(viewer.value);
    if (enabledElement?.image && enabledElement?.canvas) {
      const width = enabledElement.image.width;
      const height = enabledElement.image.height;
      const verticalScale = enabledElement.canvas.height / (height * initialScale.value);
      const horizontalScale = enabledElement.canvas.width / (width * initialScale.value);

      // Apply new scale
      initialZoom.value = Math.min(horizontalScale, verticalScale);
      emit('setZoom', { value: initialZoom.value * 100, default: true }); // reset zoom
      isDefaultZoomSet.value = true;
    }
  } catch (e) {
    console.error(e);
  }
};

/**
   * Load image and apply transformation
   * @param index Index of the image to load
   * @param translation Translation to apply
   */
const loadImage = async (index: number, translation = { x: 0, y: 0 }) => {
  const id = props.imageIds[index];
  if (id) {
    const element = viewer.value;
    isError.value = false;
    loading.value = true;
    loadingImageId.value = id;
    try {
      // Cache image
      const cornerstoneImage = await cornerstone.loadAndCacheImage(id);
      const viewport = cornerstone.getDefaultViewportForImage(element, cornerstoneImage);
      initialScale.value = viewport.scale;
      // Apply zoom and translation on image viewport
      cornerstone.setViewport(element, {
        ...viewport,
        scale: props.zoom * initialScale.value,
        translation,
        voi: {
          windowWidth: 255,
          windowCenter: 127
        }
      });
      if (loadingImageId.value === id) {
        image.value = {
          width: cornerstoneImage.width,
          height: cornerstoneImage.height
        };
        cornerstone.displayImage(element, cornerstoneImage);
      }
    } catch (e) {
      isError.value = true;
    } finally {
      loading.value = false;
    }
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    ref_key: "viewer",
    ref: viewer,
    class: "h-full w-full relative",
    style: {"background-color":"#0e0e0e"}
  }, _ctx.$attrs, {
    onContextmenu: _cache[0] || (_cache[0] = ($event: any) => (onContextMenu($event))),
    onMouseup: _cache[1] || (_cache[1] = ($event: any) => (onMouseUp())),
    onMousedown: _cache[2] || (_cache[2] = ($event: any) => (onMouseDown($event)))
  }), [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("canvas", {
      ref_key: "referenceLineCanvas",
      ref: referenceLineCanvas,
      class: _normalizeClass(["h-full w-full absolute inset-0 z-10", [__props.enableReferenceLine ? '' : 'hidden']])
    }, null, 2),
    (isError.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('platform.scans.loading-error')), 1),
          _createVNode(BaseButton, {
            "left-icon": "reload",
            size: "large",
            onClick: reload
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.reload')), 1)
            ]),
            _: 1
          })
        ]))
      : (loading.value || __props.scaleLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_unref(LoadingIcon))
          ]))
        : _createCommentVNode("", true)
  ], 16))
}
}

})