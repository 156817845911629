import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-white flex flex-col" }
const _hoisted_2 = { class: "bg-gray-900 h-10 flex items-center justify-center text-white text-sm font-semibold" }
const _hoisted_3 = { class: "text-sm" }
const _hoisted_4 = {
  class: "bg-gray-900 p-4",
  style: {"width":"max-content"}
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "text-sm text-white font-medium flex flex-shrink-0" }

import { PropType, ref, watch } from 'vue';
import { BaseIcon } from '@/lib/components/Icon';
import { BaseSwitch } from '@/lib/components/Switch';
import { NavDropdown } from '@/lib/components/Navigation';


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewerSelect',
  props: {
  items: {
    type: Array as PropType<{
      icon: string;
      name: string;
      value: number[];
    }[]>,
    required: true
  },
  modelValue: {
    type: Array as PropType<number[]>,
    default: () => []
  },
  lock: {
    type: Boolean,
    default: true
  }
},
  emits: ["update:modelValue", "lock"],
  setup(__props) {

const props = __props;


const selectedItem = ref<{
  icon: string;
  name: string;
  value: number[];
} | null>(null);

watch(() => props.modelValue, () => {
  selectedItem.value =
    props.items.find((item) => JSON.stringify(item.value) === JSON.stringify(props.modelValue)) || null;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (selectedItem.value)
      ? (_openBlock(), _createBlock(_unref(NavDropdown), {
          key: 0,
          dark: "",
          "button-class": "px-4",
          "menu-class": "",
          scrollable: false
        }, {
          button: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (selectedItem.value.icon && selectedItem.value.icon.length)
                ? (_openBlock(), _createBlock(_unref(BaseIcon), {
                    key: 0,
                    class: "mr-3 text-white",
                    name: selectedItem.value.icon
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_3, _toDisplayString(selectedItem.value.name), 1)
            ])
          ]),
          menu: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item) => {
                return (_openBlock(), _createElementBlock("button", {
                  key: item.value.join('x'),
                  type: "button",
                  class: _normalizeClass([{ 'bg-primary-500': selectedItem.value.name === item.name }, "flex w-46 items-center w-full px-4 py-3 font-semibold text-left rounded outline-none text-white hover:bg-gray-500 focus:bg-gray-500"]),
                  onClick: ($event: any) => (_ctx.$emit('update:modelValue', item.value))
                }, [
                  _createVNode(_unref(BaseIcon), {
                    name: item.icon,
                    position: "left"
                  }, null, 8, ["name"]),
                  _createTextVNode(" " + _toDisplayString(item.name), 1)
                ], 10, _hoisted_5))
              }), 128)),
              _createElementVNode("div", {
                class: "border-t border-gray-800 flex flex-row items-center py-4 justify-between space-x-4",
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('platform.scans.lock-views')), 1),
                _createVNode(_unref(BaseSwitch), {
                  "model-value": __props.lock,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('lock', $event)))
                }, null, 8, ["model-value"])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})