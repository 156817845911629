import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "break-words" }
const _hoisted_2 = {
  key: 0,
  class: "whitespace-no-wrap"
}
const _hoisted_3 = {
  key: 1,
  class: "flex flex-row justify-between pt-3 text-sm font-regular text-gray-600 leading-4 space-x-4 leading-snug"
}
const _hoisted_4 = {
  key: 0,
  class: "whitespace-no-wrap"
}




export default /*@__PURE__*/_defineComponent({
  __name: 'InputTextHelper',
  props: {
    rightDescription: { default: '' },
    description: { default: '' },
    error: { default: '' },
    loading: { type: Boolean, default: false },
    border: { type: Boolean, default: false }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_ctx.error && !_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["flex flex-row justify-between pt-3 text-sm font-medium text-danger-800 leading-4 space-x-4", _ctx.border ? 'pt-1 mt-2 border-t border-danger-800' : 'pt-3'])
      }, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.error), 1),
        (_ctx.rightDescription)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.rightDescription), 1))
          : _createCommentVNode("", true)
      ], 2))
    : ((_ctx.description || _ctx.rightDescription) && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.description?.split('\n'), (line, index) => {
              return (_openBlock(), _createElementBlock("p", {
                key: index,
                class: "break-words"
              }, _toDisplayString(line), 1))
            }), 128))
          ]),
          (_ctx.rightDescription)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.rightDescription), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
}
}

})