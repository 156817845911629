import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "relative w-full z-20"
}
const _hoisted_2 = {
  class: "border absolute w-full bg-white",
  "data-dusk": "address-results"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "p-4" }
const _hoisted_5 = {
  key: 1,
  class: "relative w-full z-20"
}
const _hoisted_6 = { class: "border absolute w-full bg-white p-4" }

import { PropType, onMounted, ref } from 'vue';
import debounce from 'lodash-es/debounce';
import { Address, AutocompletePrediction } from '@/models';
import { IOption } from '@/lib';
import { GoogleApiService } from '@/services/api';
import { BaseTextInput } from '@/lib/components/Input';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressAutocomplete',
  props: {
  label: {
    type: String,
    default: ''
  },
  error: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: ''
  },
  modelValue: {
    type: Object as PropType<Address>,
    default: () => ({
      formatted_address: ''
    })
  },
  isOptional: {
    type: Boolean,
    default: false
  }
},
  emits: ["change", "update:modelValue", "blur"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const apiService = new GoogleApiService();
const autocompleteResults = ref<Array<IOption>>([]);
const addressText = ref('');
const loading = ref(false);
const lastCommittedAddressText = ref('');
const getAutocompleteDebounced = debounce(() => getAutocomplete(), 500);

onMounted(() => {
  addressText.value = props.modelValue?.formatted_address || '';
  lastCommittedAddressText.value = props.modelValue?.formatted_address || '';
});
const clearAutocompleteResults = () => {
  autocompleteResults.value = [];
};
const handleChangedTextInput = (value: string) => {
  if (value === '') {
    clearAddress();
  } else {
    getAutocompleteDebounced();
  }
};
const clearAddress = () => {
  lastCommittedAddressText.value = '';
  const clearAddress: Address = {
    formatted_address: ''
  };
  emit('change', clearAddress);
  emit('update:modelValue', clearAddress);
};
const getAutocomplete = async () => {
  const input = addressText.value ? addressText.value : '';
  loading.value = true;

  try {
    if (input.length > 4) {
      const autocompletePredictions = await apiService.placeAutocomplete(input);

      if (autocompletePredictions.predictions) {
        autocompleteResults.value = autocompletePredictions.predictions.map((prediction: AutocompletePrediction) => ({
          label: prediction.description,
          value: prediction.place_id
        }));
      }
    }
  } catch (e) {
    autocompleteResults.value = [];
  }

  loading.value = false;
};
const getPlaceDetails = async (place_id: string) => {
  loading.value = true;

  try {
    const detailsResults = await apiService.placeDetails(place_id);
    const newAddress: Address = {
      formatted_address: detailsResults.result.formatted_address || '',
      place_id: detailsResults.result.place_id,
      geometry: detailsResults.result.geometry,
      address_components: detailsResults.result.address_components
    };

    addressText.value = newAddress.formatted_address;
    lastCommittedAddressText.value = newAddress.formatted_address;

    emit('change', newAddress);
    emit('update:modelValue', newAddress);
  } catch (e) {
    console.warn('Error fetching address details');
  }

  clearAutocompleteResults();
  loading.value = false;
};
const clickAway = () => {
  addressText.value = lastCommittedAddressText.value;
  clearAutocompleteResults();
  emit('blur');
};

return (_ctx: any,_cache: any) => {
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { id: "mapAttr" }, null, -1)),
    _withDirectives(_createVNode(_unref(BaseTextInput), {
      modelValue: addressText.value,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((addressText).value = $event)),
        handleChangedTextInput
      ],
      label: __props.label,
      "is-optional": __props.isOptional,
      clear: "",
      "data-dusk": "address-input",
      placeholder: __props.placeholder,
      error: __props.error
    }, null, 8, ["modelValue", "label", "is-optional", "placeholder", "error"]), [
      [_directive_click_away, clickAway]
    ]),
    (!loading.value && autocompleteResults.value.length)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(autocompleteResults.value, (result, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "cursor-pointer hover:bg-gray-100",
                "data-dusk": "address-result",
                onClick: ($event: any) => (getPlaceDetails(result.value))
              }, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(result.label), 1)
              ], 8, _hoisted_3))
            }), 128))
          ])
        ])), [
          [_directive_click_away, clickAway]
        ])
      : (loading.value)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('platform.common.loading')) + "... ", 1)
          ])), [
            [_directive_click_away, clickAway]
          ])
        : _createCommentVNode("", true)
  ]))
}
}

})