import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col fixed top-0 z-10 right-0" }

import { PropType } from 'vue';
import { INotification } from '@/lib';
import BaseNotification from './BaseNotification.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseNotificationStack',
  props: {
  displayItems: {
    type: Number,
    default: 3
  },
  modelValue: {
    type: Array as PropType<Array<INotification>>,
    default: () => []
  },
  autoDismiss: {
    type: Boolean,
    default: true
  },
  dismissAfter: {
    type: Number,
    default: 6000
  }
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const dismissNotification = (id: string) => {
  const notifications = [...props.modelValue].filter((notification: INotification) => notification.id !== id);
  emit('update:modelValue', notifications);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.modelValue, (notification, index) => {
      return (_openBlock(), _createBlock(BaseNotification, {
        key: notification.id,
        action: notification.action,
        color: notification.color,
        icon: notification.icon,
        title: notification.title,
        label: notification.label,
        display: index < __props.displayItems,
        "dismiss-after": __props.dismissAfter,
        "auto-dismiss": __props.autoDismiss,
        class: _normalizeClass(
        index < __props.displayItems
          ? 'mb-4 z-20'
          : index === __props.displayItems
            ? 'opacity-75 mt-0 z-10 overflow-hidden'
            : index === __props.displayItems + 1
              ? 'opacity-50 mt-0 z-10 overflow-hidden'
              : 'opacity-0'
      ),
        style: _normalizeStyle(
        index === __props.displayItems
          ? 'transform: translateY(-110%);'
          : index === __props.displayItems + 1
            ? 'transform: translateY(-200%);'
            : ''
      ),
        onDismiss: ($event: any) => (dismissNotification(notification.id))
      }, null, 8, ["action", "color", "icon", "title", "label", "display", "dismiss-after", "auto-dismiss", "class", "style", "onDismiss"]))
    }), 128))
  ]))
}
}

})