import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "rounded-lg bg-gray-300 px-1"
}

import { ClearButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseChip',
  props: {
  imageUrl: {
    type: String,
    default: null
  },
  icon: {
    type: String,
    default: null
  },
  count: {
    type: Number,
    default: null
  },
  removable: {
    type: Boolean,
    default: false
  },
  dark: {
    type: Boolean,
    default: false
  }
},
  emits: ["removed"],
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    style: {"min-width":"max-content"},
    class: ["p-2 space-x-2 shadow inline-flex items-center text-sm font-medium select-none rounded-full", __props.dark ? 'bg-gray-800 text-white' : 'bg-white text-gray-900 border']
  }, _ctx.$attrs), [
    (__props.imageUrl?.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "w-4 h-4 block rounded-full bg-cover bg-no-repeat bg-center",
          style: _normalizeStyle('background-image: url(' + __props.imageUrl + ')')
        }, null, 4))
      : _createCommentVNode("", true),
    (__props.icon?.length)
      ? (_openBlock(), _createBlock(_unref(BaseIcon), {
          key: 1,
          name: __props.icon,
          class: "w-4 h-4"
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    (__props.count && __props.count > 1)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(__props.count), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (__props.removable)
      ? (_openBlock(), _createBlock(_unref(ClearButton), {
          key: 3,
          dark: __props.dark,
          class: "",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('removed')), ["stop"]))
        }, null, 8, ["dark"]))
      : _createCommentVNode("", true)
  ], 16))
}
}

})