import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "table-auto border bg-white" }

import { IColumn } from '@/lib';
import { PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Table',
  props: {
  columns: {
    type: Array as PropType<IColumn[]>,
    required: true
  },
  rows: {
    type: Array,
    required: true
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.columns, (col) => {
          return (_openBlock(), _createElementBlock("th", {
            key: col.name,
            class: "py-2 border font-semibold text-gray-900"
          }, _toDisplayString(col.label), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.rows, (row, i) => {
        return (_openBlock(), _createElementBlock("tr", { key: i }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.columns, (col) => {
            return (_openBlock(), _createElementBlock("td", {
              key: col.name,
              class: "py-2 text-center text-gray-900 border"
            }, _toDisplayString(row[col.name]), 1))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}
}

})