import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap gap-3" }
const _hoisted_2 = {
  key: 0,
  class: "pt-1 mt-2 text-sm font-medium text-danger-800 leading-4 border-t border-danger-700"
}

import { PropType } from 'vue';
import BaseRadio from './BaseRadio.vue';
import InputTextLabel from '@/lib/components/Helper/InputTextLabel.vue';

interface IRadioGroupOption {
  label: string;
  value: any;
  title?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioGroup',
  props: {
  icon: {
    type: String,
    default: null
  },
  iconHeight: {
    type: String,
    default: '20px'
  },
  label: {
    type: String,
    default: null
  },
  actionName: {
    type: String,
    default: null
  },
  name: {
    type: String,
    default: null
  },
  options: {
    type: Array as PropType<string[] | IRadioGroupOption[]>,
    required: true
  },
  modelValue: {
    type: [String, Boolean],
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: null
  },
  light: {
    type: Boolean,
    default: false
  },
  isOptional: {
    type: Boolean,
    default: false
  },
  labelPosition: {
    type: String,
    validator: (value: string) => ['left', 'right'].includes(value),
    default: 'right'
  }
},
  emits: ["update:modelValue", "change", "actionClick"],
  setup(__props, { emit: __emit }) {


const emit = __emit;
const onUpdate = (value: any) => {
  emit('update:modelValue', value);
  emit('change', value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(InputTextLabel, {
      label: __props.label,
      icon: __props.icon,
      "icon-height": __props.iconHeight,
      loading: __props.loading,
      "is-optional": __props.isOptional,
      "action-name": __props.actionName,
      onActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('actionClick')))
    }, null, 8, ["label", "icon", "icon-height", "loading", "is-optional", "action-name"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
        return (_openBlock(), _createBlock(BaseRadio, {
          key: (option as IRadioGroupOption).label ?? option as string,
          "model-value": __props.modelValue,
          title: (option as IRadioGroupOption).title,
          label: (option as IRadioGroupOption).label ?? option as string,
          value: (option as IRadioGroupOption).label ? (option as IRadioGroupOption).value : option,
          readonly: __props.readonly,
          loading: __props.loading,
          light: __props.light,
          name: __props.name,
          "label-position": __props.labelPosition,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (onUpdate($event)))
        }, null, 8, ["model-value", "title", "label", "value", "readonly", "loading", "light", "name", "label-position"]))
      }), 128))
    ]),
    (__props.error)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(__props.error), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})