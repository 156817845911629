import { BadgeKind } from '@/lib/components/Badge';

export const getMedKitKindFromColor = (color: string): BadgeKind => {
  switch (color) {
  case 'success':
    return 'medium';
  case 'alert':
    return 'high';
  case 'danger':
    return 'critical';
  case 'gray':
    return 'stable';
  default:
    return `low`;
  }
};
