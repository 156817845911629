import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-xs font-medium text-gray-500 float-right text-justify leading-4 mt-1"
}

import { computed, onBeforeMount, PropType, ref } from 'vue';
import pickBy from 'lodash-es/pickBy';
import { BaseButton } from '@/lib/components/Button';
import { BaseIcon } from '@/lib/components/Icon';
import { InputTextHelper } from '@/lib/components/Helper';
import SmartFormRepeaterItem from './SmartFormRepeaterItem.vue';
import { FormItem } from '@/models';
import { useSmartFormStore } from '@/stores/smartForm.store';
import { stringToDuskAttribute } from '@/custom/menicon/helpers/strings.helper';
import { v4 } from 'uuid';


export default /*@__PURE__*/_defineComponent({
  __name: 'SmartFormRepeater',
  props: {
  id: {
    type: String,
    required: true
  },

  modelValue: {
    type: Array as PropType<Record<string, any>[]>,
    default: () => []
  },

  error: {
    type: String,
    default: null
  },

  itemName: {
    type: String,
    default: ''
  },

  item: {
    type: Object as PropType<FormItem>,
    required: true
  },

  formSubmissionId: {
    type: String,
    required: true
  },

  readonly: {
    type: Boolean,
    default: false
  }
},
  emits: ["update:modelValue", "input", "change"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const smartForm = useSmartFormStore();
const rows = ref([]);

const rowTemplate = computed(() => props.item.items
  ?.reduce((row, item) => ({ ...row, [item.id]: null, id: v4() }), {}) || {}
);
const populatedAnswers = computed(() =>
// Removing any empty values from each row
  rows.value.map((row) => pickBy(row))
);
const isRequired = computed(() => {
  if (!props.item.rules) {
    return false;
  }

  return props.item.rules.split('|').some((rule) => rule.includes('required'));
});

onBeforeMount(() => {
  rows.value = props.modelValue?.length ? [...props.modelValue] : [{ ...rowTemplate.value }];
});

const getDuskAttribute = (label: string, rowIndex: number) => `${stringToDuskAttribute(label)}-${rowIndex}`;
const rowItemError = (rowIndex: number, itemId: string) => smartForm.getError(
  props.formSubmissionId,
  `${props.id}.${rowIndex}.${itemId}`
);
const addRow = () => {
  rows.value.push({ ...rowTemplate.value });
};
const removeRow = (index: number) => {
  rows.value.splice(index, 1);
  emitInputEvent();
  emitChangeEvent();

  if (rows.value.length === 0) {
    addRow();
  }
};
const emitInputEvent = () => {
  emit('update:modelValue', populatedAnswers.value);
  emit('input', populatedAnswers.value);
};
const emitChangeEvent = () => {
  emit('change', populatedAnswers.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "grid gap-x-4 gap-y-3",
      style: _normalizeStyle(`grid-template-columns: repeat(${__props.item.items.length}, minmax(0, 1fr)) 50px;`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.item.items, (i) => {
        return (_openBlock(), _createElementBlock("label", {
          key: i.id,
          class: "block text-sm text-gray-500 font-medium"
        }, [
          _createTextVNode(_toDisplayString(i.label) + " ", 1),
          (i.optional)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rows.value, (row, rowIndex) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.item.items, (i) => {
            return (_openBlock(), _createBlock(SmartFormRepeaterItem, {
              key: `${rowIndex}-${i.id}`,
              modelValue: rows.value[rowIndex][i.id],
              "onUpdate:modelValue": [($event: any) => ((rows.value[rowIndex][i.id]) = $event), emitInputEvent],
              readonly: __props.readonly,
              item: i,
              error: rowItemError(rowIndex, i.id),
              "form-submission-id": __props.formSubmissionId,
              "data-dusk": getDuskAttribute(i.label, rowIndex),
              onChange: emitChangeEvent
            }, null, 8, ["modelValue", "onUpdate:modelValue", "readonly", "item", "error", "form-submission-id", "data-dusk"]))
          }), 128)),
          (!isRequired.value || rows.value.length > 1)
            ? (_openBlock(), _createBlock(_unref(BaseButton), {
                key: `${rowIndex}-delete`,
                "data-dusk": `repeater-item-delete-${rowIndex}`,
                class: _normalizeClass({ invisible: __props.readonly }),
                color: "ghost",
                size: "large",
                onClick: ($event: any) => (removeRow(rowIndex))
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseIcon), { name: "delete" })
                ]),
                _: 2
              }, 1032, ["data-dusk", "class", "onClick"]))
            : (_openBlock(), _createElementBlock("div", {
                key: `${rowIndex}-delete-spacer`
              }))
        ], 64))
      }), 256))
    ], 4),
    (!__props.readonly)
      ? (_openBlock(), _createBlock(_unref(BaseButton), {
          key: 0,
          color: "ghost",
          "left-icon": "add",
          class: "mt-3",
          "data-dusk": "add-button",
          onClick: addRow
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(__props.itemName
          ? _ctx.$t('platform.common.add-', [__props.itemName])
          : _ctx.$t('platform.common.add')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!__props.readonly)
      ? (_openBlock(), _createBlock(_unref(InputTextHelper), {
          key: 1,
          error: __props.error
        }, null, 8, ["error"]))
      : _createCommentVNode("", true)
  ]))
}
}

})