import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ease-linear transition-all duration-300 w-full" }


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingBar',
  props: {
  outCustomClass: {
    type: String,
    default: 'relative block'
  },
  innerCustomClass: {
    type: String,
    default: ''
  },
  loadingBarHeight: {
    type: String,
    default: '2px'
  },
  color: {
    type: String,
    default: 'primary-500'
  },
  value: {
    type: Number,
    default: 0
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (__props.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(__props.outCustomClass),
        "data-dusk": "loading-bar"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass([['bg-' + __props.color, __props.innerCustomClass], "absolute transition-width duration-300 z-40 top-0 left-0 opacity-75"]),
            style: _normalizeStyle('width:' + __props.value + '%; height: ' + __props.loadingBarHeight)
          }, null, 6)
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})