import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-8 max-w-sm text-center" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-center mt-4"
}
const _hoisted_3 = { class: "text-lg font-semibold leading-tight text-gray-900 mt-6 mb-4" }
const _hoisted_4 = {
  key: 1,
  class: "space-y-3 mt-8"
}

import { BaseModal } from '@/lib/components/Modals';
import IconGlyph from '@/lib/components/Icon/IconGlyph.vue';
import { PropType } from 'vue';
import { GlyphColorName } from '../Icon/glyph';


export default /*@__PURE__*/_defineComponent({
  __name: 'SmartFormComplete',
  props: {
  heading: {
    type: String,
    required: true
  },
  body: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    default: 'check-glyph'
  },
  iconColor: {
    type: String as PropType<GlyphColorName>,
    default: 'success'
  },
  stroke: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseModal), { "close-on-background-click": false }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (__props.icon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(IconGlyph, {
                name: __props.icon,
                color: __props.iconColor,
                size: "large"
              }, null, 8, ["name", "color"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("h2", _hoisted_3, _toDisplayString(__props.heading), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.body.split('\n'), (line) => {
          return (_openBlock(), _createElementBlock("p", {
            key: line,
            class: "text-gray-600"
          }, _toDisplayString(line), 1))
        }), 128)),
        (_ctx.$slots.default)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default")
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }))
}
}

})