import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]


export default /*@__PURE__*/_defineComponent({
  __name: 'SpecialityButton',
  props: {
  disabled: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _mergeProps({
    type: "button",
    class: "inline-flex flex-row content-center focus-none justify-center h-8 p-3 px-3 py-2 text-xs leading-none bg-white border border-solid rounded-full align-center w-14 border-gray-300 focus:outline-none focus:border-primary-500 hover:border-primary-500 focus:shadow-lg text-gray-400 hover:text-primary-500 focus:text-primary-500"
  }, _ctx.$attrs, { disabled: __props.disabled }), " • • • ", 16, _hoisted_1))
}
}

})