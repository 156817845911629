import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, unref as _unref, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["data-dusk"]
const _hoisted_2 = { class: "max-w-screen-2xl mx-auto w-full flex" }
const _hoisted_3 = { class: "flex items-center justify-center mx-4 space-x-4 text-blurple-500 font-medium" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex flex-grow" }
const _hoisted_7 = { class: "flex flex-row space-x-6 mr-4" }
const _hoisted_8 = { class: "flex items-center" }
const _hoisted_9 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_10 = {
  key: 1,
  class: "space-y-1 flex h-full justify-center flex-col leading-none text-right px-2"
}
const _hoisted_11 = {
  "data-dusk": "current-organisation-name",
  class: "font-medium text-gray-900 text-sm"
}
const _hoisted_12 = {
  "data-dusk": "current-customer-name",
  class: "font-medium text-gray-500 text-xs"
}
const _hoisted_13 = { class: "space-y-1 flex flex-col leading-none text-right" }
const _hoisted_14 = {
  "data-dusk": "current-organisation-name",
  class: "font-medium text-gray-900 text-sm"
}
const _hoisted_15 = {
  "data-dusk": "current-customer-name",
  class: "font-medium text-gray-500 text-xs"
}
const _hoisted_16 = { class: "flex h-full items-center" }
const _hoisted_17 = {
  key: 0,
  class: "space-y-4 p-4 flex flex-col items-center justify-center h-36 min-w-72"
}
const _hoisted_18 = { class: "font-medium" }
const _hoisted_19 = { class: "flex flex-row items-center justify-between space-x-4 w-full" }
const _hoisted_20 = { class: "flex flex-col space-y-1 leading-none font-medium" }
const _hoisted_21 = { class: "text-sm text-gray-900" }
const _hoisted_22 = { class: "text-xs text-gray-500" }
const _hoisted_23 = {
  key: 1,
  class: "border-t my-2 -mx-2"
}
const _hoisted_24 = {
  key: 4,
  class: "w-px my-3 bg-gray-300"
}
const _hoisted_25 = { class: "flex items-center" }
const _hoisted_26 = { class: "ml-4 font-medium text-base text-gray-900" }
const _hoisted_27 = {
  class: "w-56 p-4",
  "data-cy": "nav-account-menu"
}
const _hoisted_28 = { class: "text-sm font-semibold text-gray-500" }
const _hoisted_29 = {
  key: 0,
  class: "font-semibold text-gray-900 break-words whitespace-normal",
  "data-cy": "nav-account-name"
}
const _hoisted_30 = { class: "py-2" }
const _hoisted_31 = { class: "pt-2" }

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { logoutService } from '@/services/auth';
import { Customer, Organisation } from '@/models';
import { BaseAvatar } from '@/lib/components/Avatar';
import SupportModal from '@/lib/components/Modals/SupportModal.vue';
import { BasePopover, PopoverButton, PopoverLink } from '@/lib/components/Popover';
import NavDropdownButton from './NavDropdownButton.vue';
import BrandLogo from '@/lib/components/Branding/BrandLogo.vue';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import MedKitButton from '@/lib/components/Button/MedKitButton.vue';
import MedKitBadge from '@/lib/components/Badge/MedKitBadge.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MainNavigation',
  setup(__props) {

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const orgsPopover = ref(null);
const settingsPopover = ref(null);
const showSupportModal = ref(false);
const logout = logoutService;
const sessionStore = useSessionStore();
const notificationStore = useNotificationStore();

const env = computed(() => process.env.VUE_APP_ENV);
const accountSettingsLink = computed(() => {
  if (route.meta?.isAdmin) {
    return {
      name: 'domain-admin-profile'
    };
  }
  if (route.meta?.isLensSupport) {
    return {
      name: 'lens-support-profile'
    };
  }
  if (sessionStore.currentOrganisationId) {
    return {
      name: 'organisation-user-profile',
      params: {
        organisationId: sessionStore.currentOrganisationId
      }
    };
  }
  return {
    name: 'profile'
  };
});
const canViewOrganisationSettings = computed<boolean>(() => sessionStore.canViewOrganisationSettings);
const isAdmin = computed<boolean>(() => !!(route.meta?.isAdmin &&
  !sessionStore.currentOrganisationId && sessionStore.currentUser?.is_admin));
const isLensSupport =computed<boolean>(() => !!(route.meta?.isLensSupport &&
  !sessionStore.currentOrganisationId && sessionStore.currentUser?.is_lens_support));
const user = computed(() => sessionStore.currentUser);
const customers = computed(() => sessionStore.customers);
const customerId = computed(() => sessionStore.customerId);
const showCustomerNavigationButton = computed<boolean>(() =>
  (user.value?.is_admin && customers.value.length > 0) ||
  (user.value?.is_lens_support && customers.value.length > 0) ||
  (user.value?.is_lens_support && user.value?.is_admin) ||
  // If there is more than 1 customer or the current page is from a different customer
  (customers.value.length > 1 ||
    (!sessionStore.customerFromUrl.find((c) => c.id === customerId.value) && customers.value.length > 0)
  ) ||
  sessionStore.organisationsLoadFailed
);
const userName = computed(() => ({
  given_name: sessionStore.currentUser?.given_name,
  family_name: sessionStore.currentUser?.family_name
}));
const url = computed<string>(() => sessionStore.currentUser?.avatar_url);
const currentOrganisation = computed(() => sessionStore.currentOrganisation);
const organisations = computed<Array<Organisation>>(() => sessionStore.customerOrganisations || []);

const accessDomainAdmin = () => {
  if (!isAdmin.value) {
    router.push({ name: 'domain-admin' });
  }
};
const accessLensSupport = async () => {
  if (!isLensSupport.value) {
    await router.push({ name: 'lens-support' });
  }
};
const equalToCurrentUrl = (customer: Customer): boolean => customer.primary_url === window.location.origin;
const accessCustomer = (customer: Customer) => {
  if (equalToCurrentUrl(customer)) {
    sessionStore.setCurrentOrganisationIdForCustomer(customer.id);
    router.push({
      name: 'patient-list',
      params: {
        organisationId: sessionStore.currentOrganisationId
      }
    });
  } else {
    window.open(`${customer.primary_url}?customer=${customer.id}`, '_blank');
  }
};
const switchOrganisation = async (organisation: Organisation) => {
  if (currentOrganisation.value?.id !== organisation.id) {
    sessionStore.setCurrentOrganisationId(organisation.id);
    notificationStore.snackbar = {
      label: t('platform.org-unit.switch-message', [organisation.name]),
      autoDismiss: true,
      dismissAfter: 6000,
      color: 'success',
      icon: 'tick'
    };
    const routePermission = route.meta?.permission as string;
    const permissions = sessionStore.permissions || [];
    // When we switch from domain admin to an org or
    // When we switch an org and we don't have the permission to access the page anymore
    // We should redirect the user
    if ((routePermission && !permissions.includes(routePermission))) {
      if (route.path.includes('account-settings')) {
        await router.push({ name: 'organisation-user-settings', params: { organisationId: organisation.id } });
      } else if (route.path.includes('organisation-settings')) {
        await router.push({ name: 'organisation-settings', params: { organisationId: organisation.id } });
      } else if (permissions.includes('anonymous-participant:read')) {
        await router.push({ name: 'participant-list', params: { organisationId: organisation.id } });
      } else if (permissions.includes('patient:read')) {
        await router.push({ name: 'patient-list', params: { organisationId: organisation.id } });
      } else {
        await router.push({ name: 'organisation-user-settings', params: { organisationId: organisation.id } });
      }
    } else {
      await router.replace({
        name: route.name,
        params: { ...route.params, organisationId: organisation.id }
      });
    }
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    "data-dusk": `customer-${customerId.value}-nav`,
    class: "flex bg-white border-b h-18 border-gray-300"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BrandLogo, {
          "use-default": isAdmin.value || isLensSupport.value
        }, null, 8, ["use-default"]),
        (isAdmin.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('platform.navigation.system-admin')), 1))
          : (isLensSupport.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('custom.menicon.lens-support.lens-support')), 1))
            : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "nav")
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "right-content")
        ]),
        (env.value !== 'prod')
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(MedKitBadge, {
                kind: "critical",
                tint: "",
                label: "Environment: UAT",
                size: "small"
              })
            ]))
          : _createCommentVNode("", true),
        (!isAdmin.value && organisations.value.length === 1 && currentOrganisation.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(currentOrganisation.value.name), 1),
              _createElementVNode("span", _hoisted_12, _toDisplayString(currentOrganisation.value.domain?.customer?.name), 1)
            ]))
          : (!isAdmin.value && organisations.value.length > 1)
            ? (_openBlock(), _createBlock(_unref(BasePopover), {
                key: 2,
                ref_key: "orgsPopover",
                ref: orgsPopover,
                position: "left"
              }, {
                button: _withCtx(() => [
                  _createVNode(NavDropdownButton, {
                    "is-open": (_ctx.$refs?.orgsPopover as InstanceType<typeof BasePopover>)?.isOpen,
                    "data-dusk": "org-nav-btn",
                    class: "px-2"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("span", _hoisted_14, _toDisplayString(currentOrganisation.value.name), 1),
                        _createElementVNode("span", _hoisted_15, _toDisplayString(currentOrganisation.value.domain?.customer?.name), 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["is-open"])
                ]),
                menu: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(organisations.value, (organisation) => {
                    return (_openBlock(), _createBlock(_unref(PopoverButton), {
                      key: organisation.id,
                      active: organisation.id === currentOrganisation.value.id && !isAdmin.value,
                      "data-dusk": `org-${organisation.id}`,
                      onClick: ($event: any) => (switchOrganisation(organisation))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(organisation.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["active", "data-dusk", "onClick"]))
                  }), 128))
                ]),
                _: 1
              }, 512))
            : _createCommentVNode("", true),
        (showCustomerNavigationButton.value)
          ? (_openBlock(), _createBlock(_unref(BasePopover), {
              key: 3,
              wrap: "",
              position: "left"
            }, {
              button: _withCtx(() => [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(BaseButton, {
                    color: "muted",
                    size: "small",
                    class: "w-8",
                    "data-dusk": "customer-nav-btn",
                    "aria-label": "Domain menu"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(BaseIcon, {
                        name: "multidot",
                        stroke: false
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              menu: _withCtx(() => [
                (_unref(sessionStore).organisationsLoadFailed)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('platform.org-unit.load-error')), 1),
                      _createVNode(MedKitButton, {
                        size: "small",
                        kind: "primary",
                        label: _ctx.$t('platform.common.reload'),
                        icon: "refresh",
                        loading: _unref(sessionStore).organisationsLoading,
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(sessionStore).fetchOrganisations()), ["stop"]))
                      }, null, 8, ["label", "loading"])
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(customers.value, (customer) => {
                  return (_openBlock(), _createBlock(_unref(PopoverButton), {
                    key: customer.id,
                    class: "max-w-80",
                    active: !isAdmin.value && customer.id === customerId.value,
                    "data-dusk": `customer-${customer.id}`,
                    onClick: ($event: any) => (accessCustomer(customer))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("span", _hoisted_21, _toDisplayString(customer.platform_name), 1),
                          _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('platform.navigation.customer-organisations', {customer: customer.name})), 1)
                        ]),
                        _createElementVNode("div", null, [
                          (!equalToCurrentUrl(customer))
                            ? (_openBlock(), _createBlock(BaseIcon, {
                                key: 0,
                                class: "text-gray-400",
                                height: "16px",
                                name: "export"
                              }))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["active", "data-dusk", "onClick"]))
                }), 128)),
                ((user.value?.is_admin || user.value?.is_lens_support) && (customers.value.length || _unref(sessionStore).organisationsLoadFailed))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_23))
                  : _createCommentVNode("", true),
                (user.value?.is_lens_support)
                  ? (_openBlock(), _createBlock(_unref(PopoverButton), {
                      key: 2,
                      active: isLensSupport.value,
                      "data-dusk": "lens-support",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (accessLensSupport()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.lens-support.lens-support')), 1)
                      ]),
                      _: 1
                    }, 8, ["active"]))
                  : _createCommentVNode("", true),
                (user.value?.is_admin)
                  ? (_openBlock(), _createBlock(_unref(PopoverButton), {
                      key: 3,
                      active: isAdmin.value,
                      "data-dusk": "domain-admin",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (accessDomainAdmin()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.navigation.system-admin')), 1)
                      ]),
                      _: 1
                    }, 8, ["active"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_24)),
        (user.value)
          ? (_openBlock(), _createBlock(_unref(BasePopover), {
              key: 5,
              ref_key: "settingsPopover",
              ref: settingsPopover,
              position: "left"
            }, {
              button: _withCtx(() => [
                _createVNode(NavDropdownButton, {
                  "is-open": !!(_ctx.$refs?.settingsPopover as InstanceType<typeof BasePopover>)?.isOpen,
                  class: "px-2",
                  "data-dusk": "nav-account-btn"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_25, [
                      _createVNode(_unref(BaseAvatar), {
                        url: url.value,
                        "user-name": userName.value
                      }, null, 8, ["url", "user-name"]),
                      _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t('platform.common.account')), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["is-open"])
              ]),
              menu: _withCtx(() => [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('platform.navigation.signed-in-as')), 1),
                  (user.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(user.value.given_name) + " " + _toDisplayString(user.value.family_name), 1))
                    : _createCommentVNode("", true)
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1)),
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_unref(PopoverLink), { to: accountSettingsLink.value }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.account-settings')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"]),
                  (canViewOrganisationSettings.value && !isAdmin.value && currentOrganisation.value?.id)
                    ? (_openBlock(), _createBlock(_unref(PopoverLink), {
                        key: 0,
                        "data-dusk": "my-organisation-link",
                        to: { name: 'organisation-settings', params: { organisationId: currentOrganisation.value.id } }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.settings.organisation-settings')), 1)
                        ]),
                        _: 1
                      }, 8, ["to"]))
                    : _createCommentVNode("", true)
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1)),
                _createElementVNode("div", _hoisted_31, [
                  (!isAdmin.value && currentOrganisation.value?.id)
                    ? (_openBlock(), _createBlock(_unref(PopoverButton), {
                        key: 0,
                        icon: "support",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (showSupportModal.value = true))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.support')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(PopoverButton), {
                    "data-dusk": "logout-btn",
                    icon: "signout",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(logout)()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('platform.common.sign-out')), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 512))
          : _createCommentVNode("", true)
      ]),
      (showSupportModal.value)
        ? (_openBlock(), _createBlock(SupportModal, {
            key: 0,
            onClose: _cache[5] || (_cache[5] = ($event: any) => (showSupportModal.value = false))
          }))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}
}

})