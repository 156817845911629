import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex flex-row space-x-3 justify-between" }
const _hoisted_2 = { class: "flex flex-row space-x-2 items-center" }

import { BaseIcon } from '@/lib/components/Icon';
import BaseTooltip from '../Tooltip/BaseTooltip.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Question',
  props: {
  level: {
    type: Number,
    default: 2
  },
  loading: {
    type: Boolean,
    default: false
  },
  card: {
    type: Boolean,
    default: false
  },
  isOptional: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: ''
  },
  info: {
    type: String,
    default: ''
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["space-y-6", { 'bg-white border border-gray-200 rounded-lg p-6 shadow': __props.card }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (__props.label)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["font-semibold", [
            __props.level === 1 ? 'text-lg' : '',
            __props.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-900'
          ]])
            }, _toDisplayString(__props.label), 3))
          : _createCommentVNode("", true),
        (__props.info)
          ? (_openBlock(), _createBlock(BaseTooltip, {
              key: 1,
              position: "after"
            }, {
              target: _withCtx(() => [
                _createVNode(_unref(BaseIcon), {
                  name: "info",
                  height: "14px",
                  class: "text-gray-400 hover:text-gray-900 focus:text-gray-900 stroke-1.5"
                })
              ]),
              content: _withCtx(() => [
                _createTextVNode(_toDisplayString(__props.info), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      (__props.isOptional)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([__props.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500', "text-sm font-medium text-gray-600 capitalize"])
          }, _toDisplayString(_ctx.$t('platform.common.optional')), 3))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})