import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "value"]

import { onBeforeMount, PropType, ref, useId } from 'vue';
import { ColorName, PositionName } from '@/lib';
import { InputTextHelper } from '@/lib/components/Helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSwitch',
  props: {
  modelValue: {
    type: Boolean,
    default: false
  },
  description: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  error: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  dark: {
    type: Boolean,
    default: false
  },
  labelPosition: {
    type: String as PropType<PositionName>,
    default: 'left',
    validator: (value: string) => ['left', 'right'].includes(value)
  },
  labelClass: {
    type: String,
    default: 'leading-none text-sm px-3 font-medium'
  },
  wrapperClass: {
    type: String,
    default: ''
  },
  color: {
    type: String as PropType<ColorName>,
    default: 'primary-500'
  }
},
  emits: ["input", "update:modelValue", "change", "check"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const id = useId();
const inputListeners = ref({});

const toggleValue = () => {
  if (!props.disabled) {
    emit('input', !props.modelValue);
    emit('update:modelValue', !props.modelValue);
    emit('change', !props.modelValue);
    emit('check', !props.modelValue);
  }
};
onBeforeMount(() => {
  inputListeners.value = {
    input: (ev: UIEvent) => emit('update:modelValue', (ev.target as HTMLInputElement).checked)
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["text-gray-600 select-none inline-flex items-center", {
        'border border-danger-700 hover:border-danger-700 focus:border-danger-700 p-2 rounded':
          __props.error && __props.error.length,
        [`${__props.wrapperClass}`]: __props.wrapperClass
      }])
    }, [
      (__props.label?.length)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _unref(id),
            style: {"width":"max-content"},
            class: _normalizeClass([[__props.labelPosition === 'left' ? '' : 'order-1', __props.labelClass, __props.modelValue ? 'text-gray-900' : 'text-gray-600'], "relative"])
          }, _toDisplayString(__props.label), 11, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("input", _mergeProps({
        id: _unref(id),
        class: "w-0 outline-none hidden",
        type: "checkbox"
      }, _ctx.$attrs, {
        value: __props.modelValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).checked)))
      }), null, 16, _hoisted_3),
      _createElementVNode("div", {
        class: _normalizeClass([[
          __props.modelValue ? 'bg-' + __props.color : __props.dark ? 'bg-black' : 'bg-gray-300',
          __props.disabled || __props.readonly ? 'cursor-default' : 'cursor-pointer'
        ], "rounded-lg w-8 h-4"]),
        "data-dusk": "switch",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleValue()))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([__props.modelValue ? 'enabled' : '', "oval bg-white relative transition-all duration-200 ease-in-out rounded-full h-3 w-3"])
        }, null, 2)
      ], 2)
    ], 2),
    _createVNode(_unref(InputTextHelper), {
      error: __props.error,
      description: __props.description
    }, null, 8, ["error", "description"])
  ]))
}
}

})