import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

import { BaseIcon } from '@/lib/components/Icon';


export default /*@__PURE__*/_defineComponent({
  __name: 'PopoverLink',
  props: {
  to: {
    type: [String, Object],
    default: null
  },

  icon: {
    type: String,
    default: null
  },
  active: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.to ? 'router-link' : 'a'), {
    to: __props.to,
    class: _normalizeClass([{ 'bg-gray-100': __props.active }, "flex items-center w-full px-4 py-3 font-medium rounded outline-none text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"])
  }, {
    default: _withCtx(() => [
      (__props.icon)
        ? (_openBlock(), _createBlock(_unref(BaseIcon), {
            key: 0,
            name: __props.icon,
            class: "mr-3"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["to", "class"]))
}
}

})