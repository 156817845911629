import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "w-full h-full flex flex-col justify-center items-center text-center p-30 space-y-6" }
const _hoisted_2 = { class: "flex flex-col space-y-3" }
const _hoisted_3 = { class: "text-lg font-semibold leading-tight" }
const _hoisted_4 = {
  key: 0,
  class: "text-gray-500 font-normal"
}
const _hoisted_5 = {
  key: 0,
  class: "flex flex-row space-x-3"
}

import { IconGlyph } from '@/lib/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'EmptyState',
  props: {
  title: {
    type: String,
    required: true
  },
  details: {
    type: String,
    default: null
  },
  icon: {
    type: String,
    default: 'user-glyph-32'
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(IconGlyph), {
      name: __props.icon,
      size: "large",
      "data-dusk": `${__props.icon}-icon`
    }, null, 8, ["name", "data-dusk"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(__props.title), 1),
      (__props.details)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(__props.details), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})