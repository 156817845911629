import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex space-x-3" }
const _hoisted_2 = {
  key: 0,
  class: "mt-1"
}
const _hoisted_3 = ["data-dusk"]
const _hoisted_4 = {
  key: 0,
  class: "text-gray-600 text-sm font-normal leading-tight"
}

import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { PropType } from 'vue';
import { stringToDuskAttribute } from '@/custom/menicon/helpers/strings.helper';
import { EncounterGridSummaryData } from '@/custom/menicon/helpers/form-summary.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'FormSummaryGrid',
  props: {
  value: {
    type: Array as PropType<EncounterGridSummaryData['value']>,
    default: () => []
  },
  isPdf: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["grid w-full gap-x-2", `grid-cols-${__props.value.length}`])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.value, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `${item.value}${index}`
      }, [
        _createElementVNode("div", _hoisted_1, [
          (item.icon && __props.isPdf)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(BaseIcon, {
                  name: item.icon,
                  stroke: false
                }, null, 8, ["name"])
              ]))
            : (item.icon)
              ? (_openBlock(), _createBlock(BaseIcon, {
                  key: 1,
                  name: item.icon,
                  stroke: false
                }, null, 8, ["name"]))
              : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: "-mt-1 flex-1",
            "data-dusk": _unref(stringToDuskAttribute)(item.icon)
          }, _toDisplayString(item.value), 9, _hoisted_3)
        ]),
        (item.description)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.description), 1))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 2))
}
}

})