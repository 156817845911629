import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "h-full focus:outline-none group cursor-pointer"
}

import { BaseIcon } from '@/lib/components/Icon';


export default /*@__PURE__*/_defineComponent({
  __name: 'NavDropdownButton',
  props: {
  isOpen: {
    type: Boolean,
    default: false
  },
  isActive: {
    type: Boolean,
    default: false
  },
  iconColour: {
    type: String,
    default: ''
  }
},
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["flex items-center h-full border-b-2", {
        'border-primary-500':__props.isActive,
        'border-transparent group-hover:border-gray-400 group-focus:border-gray-400':!__props.isActive
      }])
    }, [
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_unref(BaseIcon), {
        name: __props.isOpen ? 'chevron-up' : 'chevron-down',
        class: _normalizeClass([__props.iconColour, "stroke-1.5"]),
        position: "right"
      }, null, 8, ["name", "class"])
    ], 2)
  ]))
}
}

})