import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/images/chrome.png'


const _hoisted_1 = { class: "space-y-8" }
const _hoisted_2 = { class: "space-y-4 text-gray-900 text-center" }
const _hoisted_3 = { class: "text-xl font-semibold" }
const _hoisted_4 = {
  target: "_blank",
  href: "https://www.google.com/intl/en/chrome/?standalone=1",
  class: "underline"
}
const _hoisted_5 = { class: "flex flex-col items-center space-y-4 border border-gray-300 rounded-lg p-4" }
const _hoisted_6 = {
  target: "_blank",
  href: "https://www.google.com/intl/en/chrome/?standalone=1",
  class: "text-sm font-medium text-primary-500"
}

import LoginLayout from '@/lib/layouts/LoginLayout.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'UnsupportedBrowser',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createBlock(LoginLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('platform.error.unsupported-browser.title')), 1),
          _createVNode(_component_i18n_t, {
            keypath: "platform.error.unsupported-browser.description",
            tag: "div",
            class: "text-lg font-regular leading-normal"
          }, {
            chrome: _withCtx(() => [
              _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$t('platform.error.unsupported-browser.chrome')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            src: _imports_0,
            class: "block w-20",
            alt: "Chrome Logo"
          }, null, -1)),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "border-t border-gray-400 w-full" }, null, -1)),
          _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$t('platform.error.unsupported-browser.download-chrome')), 1)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})