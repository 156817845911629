import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex bg-white border-b h-18" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  "data-dusk": "workflow-header",
  class: "relative flex justify-center flex-1 overflow-hidden"
}
const _hoisted_4 = {
  key: 0,
  class: "relative flex items-center justify-center h-2 mt-2"
}
const _hoisted_5 = { class: "flex flex-col text-left" }
const _hoisted_6 = { class: "flex flex-col py-3 border-t border-gray-100" }
const _hoisted_7 = { class: "break-words text-gray-400" }
const _hoisted_8 = { class: "break-words text-gray-900 mt-2" }
const _hoisted_9 = {
  key: 1,
  class: "flex h-full border-l items-center px-6"
}
const _hoisted_10 = { class: "flex flex-col text-left" }

import { computed, PropType, ref, watch } from 'vue';
import { TranslateResult, useI18n } from 'vue-i18n';
import HorizontalScroll from '@/lib/layouts/HorizontalScrollableLayout.vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import NavDropdown from '@/lib/components/Navigation/NavDropdown.vue';
import PatientDetailsCard from '@/lib/components/PatientSummary/PatientDetailsCard.vue';
import { Patient } from '@/models';
import { getDobFromISOString } from '@/helpers/patient.helper';
import { sexOptions } from '@/constants';
import { EncounterRequestedAdvice } from '@/custom/menicon/models';


export default /*@__PURE__*/_defineComponent({
  __name: 'WorkflowNavigation',
  props: {
  steps: {
    type: Array as PropType<string[]>,
    required: true
  },
  currentStep: {
    type: Number,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  patient: {
    type: Object as PropType<Patient | null>,
    default: null
  },
  lensSupportRequest: {
    type: Object as PropType<EncounterRequestedAdvice>,
    default: null
  }
},
  emits: ["cancel"],
  setup(__props) {

const { t, d } = useI18n();
const props = __props;


const scrollContainer = ref(null);

const firstIndex = computed<number>(() => props.steps.findIndex((step) => step && step.length));
const patientInfos = computed<Record<string, string>>(() => props.patient
  ? {
    [t('platform.patient.dob') as string]: formatDob(props.patient.date_of_birth),
    [t('platform.patient.age') as string]: props.patient.age?.toString(),
    [t('platform.patient.sex') as string]: getSex(props.patient.sex),
    [t('platform.patient.ethnicity') as string]: props.patient.ethnicity
      ? props.patient.ethnicity.title
      : t('platform.patient.not-specified'),
    [t('platform.patient.phone') as string]: props.patient.contact_number
  }
  : {});

watch(() => props.currentStep, () => centerCurrentStep());

const centerCurrentStep = () => {
  if (scrollContainer.value?.$el) {
    const scrollContainerEl = scrollContainer.value.$el;
    if (scrollContainerEl) {
      const index = Math.max(0, Math.min(props.steps.length - 1, props.currentStep));
      const currentStepElement = scrollContainerEl.children[index] as HTMLElement;
      const currentStepElementOffsetCenter = currentStepElement.offsetLeft + currentStepElement.clientWidth / 2;
      const scrollContainerCenter = scrollContainerEl.clientWidth / 2;

      scrollContainerEl.scroll({
        left: currentStepElementOffsetCenter - scrollContainerCenter,
        behavior: 'smooth'
      });
    }
  }
};
const formatDob = (dob: string) => d(getDobFromISOString(dob), 'short');
const getSex = (sex?: string): string | TranslateResult | undefined => {
  const sexOption = sexOptions().find((option) => option.value === sex);
  return sexOption ? sexOption.label : sex;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      "data-dusk": "workflow-close-btn",
      class: _normalizeClass(["flex items-center justify-center text-gray-900 w-18 focus:outline-none group border-r", __props.disabled ? 'text-gray-300 cursor-not-allowed' : 'text-gray-900']),
      disabled: __props.disabled,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
    }, [
      _createVNode(BaseIcon, {
        name: "close",
        class: _normalizeClass(["text-2xl stroke-1.5", {
          'transform duration-150 group-hover:scale-110 group-focus:scale-110': !__props.disabled
        }])
      }, null, 8, ["class"])
    ], 10, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "absolute top-0 left-0 z-40 w-10 h-full",
        style: {"background":"linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)"}
      }, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "absolute top-0 right-0 z-40 w-10 h-full",
        style: {"background":"linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)"}
      }, null, -1)),
      (__props.steps.length > 1)
        ? (_openBlock(), _createBlock(HorizontalScroll, {
            key: 0,
            ref_key: "scrollContainer",
            ref: scrollContainer,
            class: "flex h-full select-none scrollbars-hidden",
            onMouseleave: centerCurrentStep
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.steps, (step, index) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "flex items-center"
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass({
              'pl-5': index === firstIndex.value,
              'pr-5': index === __props.steps.length - 1
            })
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["px-5 text-sm font-semibold whitespace-no-wrap", {
                'text-success-500': index < __props.currentStep,
                'text-gray-900': index === __props.currentStep,
                'text-gray-400': index > __props.currentStep
              }])
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass({
                  'bg-gray-200 text-transparent h-3 rounded-lg': __props.loading
                })
                      }, _toDisplayString(step), 3)
                    ], 2),
                    (!__props.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          (index !== firstIndex.value)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(["absolute left-0 w-1/2 border-t-2", index <= __props.currentStep ? 'border-success-500' : 'border-gray-300'])
                              }, null, 2))
                            : _createCommentVNode("", true),
                          (index !== __props.steps.length - 1)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(["absolute right-0 w-1/2 border-t-2", index < __props.currentStep ? 'border-success-500' : 'border-gray-300'])
                              }, null, 2))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", {
                            class: _normalizeClass(["absolute w-2 h-2 rounded-full", {
                  'bg-success-500': index < __props.currentStep,
                  'bg-white border-2 border-success-500': index === __props.currentStep,
                  'bg-gray-300': index > __props.currentStep
                }])
                          }, null, 2)
                        ]))
                      : _createCommentVNode("", true)
                  ], 2)
                ])), [
                  [_vShow, step]
                ])
              }), 128))
            ]),
            _: 1
          }, 512))
        : _createCommentVNode("", true)
    ]),
    (__props.patient)
      ? (_openBlock(), _createBlock(NavDropdown, {
          key: 0,
          class: "border-l",
          scrollable: false,
          disabled: __props.loading
        }, {
          button: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", {
                style: {"max-width":"max-content"},
                class: _normalizeClass(__props.loading ? 'bg-gray-200 text-transparent rounded-lg h-3' : 'font-medium text-sm text-gray-900')
              }, _toDisplayString(__props.patient.last_name) + ", " + _toDisplayString(__props.patient.first_name), 3),
              _createElementVNode("span", {
                style: {"max-width":"max-content"},
                class: _normalizeClass(
              __props.loading ? 'bg-gray-200 text-transparent rounded-lg h-3' : 'text-sm font-medium uppercase text-gray-600'
            )
              }, " ID: " + _toDisplayString(__props.patient.mrn), 3)
            ])
          ]),
          menu: _withCtx(() => [
            _createVNode(PatientDetailsCard, {
              id: __props.patient.mrn,
              class: "w-72 -m-2 z-20",
              "first-name": _ctx.$t('platform.patient.details'),
              "show-initials": false,
              infos: patientInfos.value,
              loading: __props.loading
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('platform.common.address')), 1),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(__props.patient.address?.formatted_address ?? null), 1)
                ])
              ]),
              _: 1
            }, 8, ["id", "first-name", "infos", "loading"])
          ]),
          _: 1
        }, 8, ["disabled"]))
      : (__props.lensSupportRequest)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", {
                style: {"max-width":"max-content"},
                class: _normalizeClass(__props.loading ? 'bg-gray-200 text-transparent rounded-lg h-3' : 'font-medium text-sm text-gray-900')
              }, _toDisplayString(_ctx.$t('custom.menicon.lens-support.request.number', { id: __props.lensSupportRequest.request_id })), 3),
              _createElementVNode("span", {
                style: {"max-width":"max-content"},
                class: _normalizeClass(
            __props.loading ? 'bg-gray-200 text-transparent rounded-lg h-3' : 'text-sm font-medium text-gray-600'
          )
              }, _toDisplayString(_ctx.$t('platform.patient.years', { age: __props.lensSupportRequest.patient_age })), 3)
            ])
          ]))
        : _createCommentVNode("", true)
  ]))
}
}

})