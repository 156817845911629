import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { PropType } from 'vue';
import { BaseSlider } from '@/lib/components/Slider';
import { ColorName } from '@/lib';


export default /*@__PURE__*/_defineComponent({
  __name: 'SlicesSlider',
  props: {
  modelValue: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 0
  },
  enableShortcuts: {
    type: Boolean,
    default: true
  },
  color: {
    type: String as PropType<ColorName>,
    default: 'primary-500'
  },
  size: {
    type: String,
    default: 'regular',
    validator: (value: string) => ['small', 'regular'].includes(value)
  }
},
  emits: ["update:modelValue", "change"],
  setup(__props, { emit: __emit }) {


const emit = __emit;

const onInput = (value: number) => {
  emit('update:modelValue', value);
  emit('change', value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
    onMousedown: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createVNode(_unref(BaseSlider), {
      class: "h-full",
      max: __props.max,
      min: 0,
      step: 1,
      "model-value": __props.modelValue,
      color: __props.color,
      size: __props.size,
      "enable-shortcuts": __props.enableShortcuts,
      vertical: "",
      dark: "",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (onInput($event)))
    }, null, 8, ["max", "model-value", "color", "size", "enable-shortcuts"])
  ], 32))
}
}

})