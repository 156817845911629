import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["colspan"]
const _hoisted_4 = ["data-dusk", "onClick", "onMouseover"]

import { computed, PropType } from 'vue';
import DataTableLayout from './DataTableLayout.vue';
import DataTableCell from './DataTableCell.vue';
import DataTableHeading from './DataTableHeading.vue';
import { IColumn } from '@/lib';
import { i18n } from '@/i18n/i18n';
import EmptyState from '@/custom/menicon/components/EmptyState.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DataTable',
  props: {
  loading: {
    type: Boolean,
    default: false
  },

  columns: {
    type: Array as PropType<IColumn[]>,
    required: true
  },

  rows: {
    // eslint-disable-next-line no-undef
    type: Array as PropType<(T & {
      id?: string;
      isMuted?: boolean;
    })[]>,
    required: true
  },

  sort: {
    type: String,
    default: ''
  },

  showTableHeader: {
    type: Boolean,
    default: true
  },

  selectedRowIds: {
    type: Array as PropType<string[]>,
    default: () => []
  },

  noDataMessage: {
    type: String,
    default() {
      return i18n.global.t('platform.common.table-no-results');
    }
  },

  noDataDetails: {
    type: String,
    default: ''
  },

  noDataIcon: {
    type: String,
    default: 'list'
  },

  customHeadingStyle: {
    type: String,
    default: null
  },

  customHeadingClasses: {
    type: String,
    default: null
  },

  cellStyleClasses: {
    type: String,
    default: null
  },

  displayValueInCell: {
    type: Boolean,
    default: true
  }
},
  emits: ["sort", "row-click", "row-hover", "row-hover-leave"],
  setup(__props) {

const props = __props;



const sortColumn = computed(() =>
// Remove the leading '-' of all properties if it exists
  props.sort.split(',').map((sortColumn) => sortColumn.replace(/^-/, '')).join(',')
);
const sortDirection = computed(() => props.sort.substr(0, 1) === '-' ? 'desc' : 'asc');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DataTableLayout, {
    class: _normalizeClass({ 'opacity-50 pointer-events-none': __props.loading && __props.rows.length > 0 })
  }, {
    default: _withCtx(() => [
      (__props.showTableHeader)
        ? (_openBlock(), _createElementBlock("thead", _hoisted_1, [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.columns, (column, i) => {
                return (_openBlock(), _createBlock(DataTableHeading, {
                  key: column.name,
                  "data-dusk": `table-th-${i}`,
                  "show-sort-button": column.sortable,
                  "column-name": column.name,
                  "is-active": sortColumn.value === column.name,
                  "current-direction": sortColumn.value === column.name ? sortDirection.value : 'asc',
                  "custom-style": __props.customHeadingStyle,
                  class: _normalizeClass([
            column.align && column.width
              ? 'text-' + column.align + ' w-' + column.width
              : column.align
                ? 'text-' + column.align
                : 'text-left',
            sortColumn.value === column.name ? 'text-gray-900' : 'text-gray-500',
            __props.customHeadingClasses
          ]),
                  onSort: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('sort', $event)))
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "columnLabel", { column: column }, () => [
                      _createTextVNode(_toDisplayString(column.label), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["data-dusk", "show-sort-button", "column-name", "is-active", "current-direction", "custom-style", "class"]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("tbody", null, [
        (__props.rows.length === 0 || __props.loading)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
              _createElementVNode("td", {
                colspan: __props.columns.length,
                class: _normalizeClass([[__props.cellStyleClasses ?? 'border border-gray-200', __props.loading && 'py-16'], "font-semibold text-center text-gray-900 bg-white rounded-lg"])
              }, [
                (__props.loading)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('platform.common.loading')), 1)
                    ], 64))
                  : (_openBlock(), _createBlock(EmptyState, {
                      key: 1,
                      title: __props.noDataMessage,
                      icon: __props.noDataIcon,
                      details: __props.noDataDetails
                    }, null, 8, ["title", "icon", "details"]))
              ], 10, _hoisted_3)
            ]))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.rows, (row, i) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: i,
                class: _normalizeClass({
          'text-gray-900': __props.selectedRowIds.includes(row.id),
          'bg-primary-50': row.isMuted,
          'bg-white': !row.isMuted
        }),
                style: _normalizeStyle(`--animation-delay: ${i * 6}0ms;`),
                "data-dusk": `table-row-${i}`,
                onClick: ($event: any) => (_ctx.$emit('row-click', row)),
                onMouseover: ($event: any) => (_ctx.$emit('row-hover', i)),
                onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('row-hover-leave')))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.columns, (column) => {
                  return (_openBlock(), _createBlock(DataTableCell, {
                    key: column.name,
                    class: _normalizeClass(`text-${column.align || 'left'}`),
                    "cell-style-classes": __props.cellStyleClasses,
                    "is-muted": row.isMuted,
                    "data-dusk": `table-row-${i}-${column.name}`
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, `cell.${column.name}`, {
                        row: row,
                        value: row[column.name],
                        rowIndex: i
                      }, () => [
                        (__props.displayValueInCell)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(_toDisplayString(row[column.name]), 1)
                            ], 64))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["class", "cell-style-classes", "is-muted", "data-dusk"]))
                }), 128))
              ], 46, _hoisted_4))
            }), 128))
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})