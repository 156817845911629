import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseBanner',
  props: {
  color: {
    type: String,
    default: 'alert',
    validator: (value: string) => ['alert', 'gray'].includes(value)
  },

  label: {
    type: String,
    default: null
  }
},
  setup(__props) {

const props = __props;
const textColorClass = computed<string>(() => {
  if (props.color === 'alert') {
    return 'text-gray-900';
  }
  return 'text-white';
});
const bgColorClass = computed<string>(() => {
  if (props.color === 'alert') {
    return 'bg-alert-300';
  }
  return 'bg-gray-900';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center justify-center w-full h-8 text-xs leading-none font-semibold", [bgColorClass.value, textColorClass.value]])
  }, [
    _createElementVNode("p", null, _toDisplayString(__props.label), 1)
  ], 2))
}
}

})