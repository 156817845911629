import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createVNode as _createVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-between leading-tight mb-3"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_5 = {
  key: 0,
  class: "text-gray-500 font-medium text-xs"
}
const _hoisted_6 = {
  key: 1,
  class: "text-xs font-semibold text-primary-500"
}
const _hoisted_7 = {
  key: 0,
  class: "inline-block align-top ml-2"
}
const _hoisted_8 = { class: "h-4 w-4 bg-primary-500 rounded flex justify-center" }
const _hoisted_9 = { class: "text-white bg-primary-500 text-2xs font-semibold self-center" }
const _hoisted_10 = { class: "w-full flex flex-col" }

import { computed, nextTick, onMounted, PropType, ref, useId } from 'vue';
import { ICheckboxOption } from '@/lib';
import BaseCheckbox from '@/lib/components/Checkbox/BaseCheckbox.vue';
import BasePopover from '@/lib/components/Popover/BasePopover.vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { InputTextHelper } from '@/lib/components/Helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSelectCheckbox',
  props: {
  modelValue: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  description: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  isOptional: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: ''
  },
  options: {
    type: Array as PropType<{ id?: string; value?: string; label: string; disabled?: boolean; }[]>,
    default: () => []
  },
  error: {
    type: String,
    default: null
  },
  lineHeight: {
    type: Number,
    default: 1
  },
  isFilter: {
    type: Boolean,
    default: false
  }
},
  emits: ["update:modelValue", "update:listObject"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;
const emit = __emit;

const selectCheckboxPopover = ref(null);
const baseCheckbox = ref(null);

const id = useId();
const selectedValues = ref<ICheckboxOption[]>([]);

const formattedValue = computed(() => {
  const selectedOptions = props.options.filter((option) =>
    props.modelValue.includes(option.value)
  );
  return selectedOptions.map((o) => o.label).join(', ');
});
const checkboxModel = computed(() => props.modelValue);
const badgeNumber = computed(() => selectedValues.value.length);
const placeholderText = computed(() => {
  if (props.isFilter) {
    return props.placeholder;
  }
  return formattedValue.value || props.placeholder;
});

onMounted(() => {
  if (props.isFilter) {
    nextTick(() => {
      selectedValues.value = props.options.filter((option) => props.modelValue.includes(option.id))
        .map((option) => ({
          id: option.id,
          label: option.label,
          checked: true
        }));
    });
  }
});
const openPopup = () => {
  (selectCheckboxPopover.value as InstanceType<typeof BasePopover>).open();
};
const updateModelArray = (item: ICheckboxOption) => {
  if (item.checked) {
    selectedValues.value.push(item);
  } else {
    selectedValues.value = selectedValues.value.filter(
      (it) => it.id !== item.id
    );
  }
  emit('update:listObject', selectedValues.value);
};
const clearAllSelections = () => {
  baseCheckbox.value.map((el) => {
    el.uncheck();
  });
  selectedValues.value = [];
};
__expose({
  clearAllSelections
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BasePopover, {
    ref_key: "selectCheckboxPopover",
    ref: selectCheckboxPopover,
    width: __props.isFilter ? 'unset; min-width:100%': '100%',
    "close-on-selection": false,
    class: _normalizeClass(["inline-flex flex-col", {'w-full':!__props.isFilter}])
  }, {
    menu: _withCtx(() => [
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
          return (_openBlock(), _createBlock(BaseCheckbox, {
            key: __props.isFilter ? option.id : option.value,
            ref_for: true,
            ref_key: "baseCheckbox",
            ref: baseCheckbox,
            "model-value": checkboxModel.value,
            "data-dusk": "base-select-checkbox-input",
            label: option.label,
            value: __props.isFilter ? option.id : option.value,
            border: false,
            shadow: false,
            "hover-bg-class": "bg-gray-100",
            "onUpdate:listObject": _cache[0] || (_cache[0] = ($event: any) => (updateModelArray($event))),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
          }, null, 8, ["model-value", "label", "value"]))
        }), 128))
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        ((__props.label?.length) || _ctx.$slots.action)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("label", {
                for: _unref(id),
                class: "text-base font-medium leading-tight"
              }, _toDisplayString(__props.label), 9, _hoisted_3),
              _createElementVNode("div", _hoisted_4, [
                (__props.isOptional)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
                  : _createCommentVNode("", true),
                (_ctx.$slots.action)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _renderSlot(_ctx.$slots, "action")
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          tabindex: "0",
          class: _normalizeClass(["flex cursor-pointer items-center bg-white border h-10", {
          'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none':
            __props.error,
          'rounded-md': __props.isFilter,
          'rounded w-full': !__props.isFilter,
        }]),
          onKeyup: _withKeys(openPopup, ["enter","space"])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["ml-4 appearance-none bg-white focus:outline-none", {
            'text-gray-400': __props.modelValue.length === 0,
            'leading-none': __props.lineHeight === 1,
            'leading-tight': __props.lineHeight > 1,
            'pr-4 w-full': !__props.isFilter,
            'text-sm font-medium text-gray-800 whitespace-no-wrap': __props.isFilter,
          }])
          }, _toDisplayString(placeholderText.value), 3),
          (__props.isFilter && badgeNumber.value > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(badgeNumber.value), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(BaseIcon, {
            height: "1.25em",
            class: "text-gray-500 rounded-full p-px stroke-1.5 ml-3 mr-4",
            name: "chevron-down"
          })
        ], 34),
        _createVNode(_unref(InputTextHelper), {
          error: __props.error,
          description: __props.description
        }, null, 8, ["error", "description"])
      ])
    ]),
    _: 3
  }, 8, ["width", "class"]))
}
}

})