import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HorizontalScrollableLayout',
  setup(__props) {

const container = ref(null);

onMounted(() => {
  const scrollHorizontally = (event: WheelEvent) => {
    event.preventDefault();
    // Because we're preventing the default, we need to capture and handle both Y and X movement
    const delta = Math.max(-1, Math.min(1, event.deltaY + event.deltaX)) * -1;
    container.value.scrollLeft -= delta * 40;
  };

  container.value.addEventListener('wheel', scrollHorizontally, false);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    ref_key: "container",
    ref: container,
    class: "overflow-x-scroll overflow-y-none"
  }, _ctx.$attrs), [
    _renderSlot(_ctx.$slots, "default")
  ], 16))
}
}

})