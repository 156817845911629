import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

import { BaseIcon } from '@/lib/components/Icon';


export default /*@__PURE__*/_defineComponent({
  __name: 'PopoverButton',
  props: {
  disabled: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: null
  },
  dark: {
    type: Boolean,
    default: false
  },
  active: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _mergeProps({
    type: "button",
    class: [[
      __props.dark
        ? 'bg-gray-900 hover:bg-gray-500 hover:text-white focus:bg-gray-500 focus:text-white'
        : 'bg-white hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900',
      __props.active ? (__props.dark ? 'bg-gray-500' : 'bg-gray-100') : '',
      __props.disabled && 'btn-disabled'
    ], "flex items-center w-full p-3 font-medium text-left rounded focus:outline-none text-gray-500"],
    disabled: __props.disabled
  }, _ctx.$attrs), [
    (__props.icon)
      ? (_openBlock(), _createBlock(_unref(BaseIcon), {
          key: 0,
          name: __props.icon,
          position: "left"
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 16, _hoisted_1))
}
}

})