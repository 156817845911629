import { i18n } from '@/i18n/i18n';

export const ORGANISATION_NAME_MAX_LENGTH = 50;
export const ORGANISATION_DESCRIPTION_MAX_LENGTH = 30;
export const FEATURES = {
  VIRTUAL_DOCTOR: 'virtual-doctor',
  MYOPIA_PROGNOSIS: 'myopia-prognosis',
  BLOOM_DAY: 'menicon-bloom-day', // disabled on J&J environments
  SIX_MONTH_OPTIONAL_ORDERING: '6-month-optional-ordering', // enabled for US only
  DIRECT_ORDER: 'direct-order' // disabled for French domain only
};

export const sexOptions = () => [
  { value: 'Female', label: i18n.global.t('platform.patient.female') as string },
  { value: 'Male', label: i18n.global.t('platform.patient.male') as string }
];

export const participantGenderOptions = () => [
  emptyOption(),
  ...sexOptions(),
  {
    value: 'Prefer not to say',
    label: i18n.global.t('platform.common.prefer-not-to-say') as string
  }
];

export const emptyOption = () => ({ value: '', label: i18n.global.t('platform.common.select-option') });
