import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "flex flex-row w-full relative items-center" }
const _hoisted_2 = { class: "relative z-30 flex justify-center flex-1 overflow-hidden" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "absolute right-0 bottom-0 h-full z-30" }
const _hoisted_5 = { class: "absolute left-0 bottom-0 h-full z-30" }

import { PropType, ref, watch } from 'vue';
import { IGalleryImage } from '@/lib';
import { BaseIcon } from '@/lib/components/Icon';
import HorizontalScroll from '@/lib/layouts/HorizontalScrollableLayout.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewerThumbnailGallery',
  props: {
  images: {
    type: Array as PropType<IGalleryImage[]>,
    default: () => []
  },
  modelValue: {
    type: Number,
    default: 0
  }
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const scrollContainer = ref(null);

watch(() => props.modelValue, () => centerValue());

const centerValue = () => {
  const index = Math.max(0, Math.min(props.images.length - 1, props.modelValue));
  const currentImageElement = scrollContainer.value.$el.children[index] as HTMLElement;
  const currentImageElementOffsetCenter = currentImageElement.offsetLeft + currentImageElement.clientWidth / 2;
  const scrollContainerCenter = scrollContainer.value.$el.clientWidth / 2;
  scrollContainer.value.$el.scroll({
    left: currentImageElementOffsetCenter - scrollContainerCenter,
    behavior: 'smooth'
  });
};
const prev = () => {
  if (props.modelValue > 0) {
    emit('update:modelValue', props.modelValue - 1);
  }
};
const next = () => {
  if (props.modelValue < props.images.length - 1) {
    emit('update:modelValue', props.modelValue + 1);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(HorizontalScroll, {
        ref_key: "scrollContainer",
        ref: scrollContainer,
        class: "flex h-full select-none scrollbars-hidden"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.images, (image, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass([{ 'pl-5': index === 0, 'pr-5': index === __props.images.length - 1 }, "py-3"])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["w-20 h-14 cursor-pointer mr-3 flex items-center justify-center select-none text-lg font-medium leading-tight text-center text-white rounded-lg bg-no-repeat bg-center bg-cover hover:opacity-100 transition ease-in-out duration-150", {
              'opacity-25': __props.modelValue !== index,
              'border border-primary-500': __props.modelValue === index
            }]),
                style: _normalizeStyle('background-image: url(' + (image.thumbnailUri ? image.thumbnailUri : image.image) + ')'),
                onClick: ($event: any) => (_ctx.$emit('update:modelValue', index))
              }, _toDisplayString(image.placeholder), 15, _hoisted_3)
            ], 2))
          }), 128))
        ]),
        _: 1
      }, 512)
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "absolute w-full h-full z-10 flex justify-between pointer-events-none" }, [
      _createElementVNode("div", {
        class: "w-20 h-full",
        style: {"background":"linear-gradient(-270deg, #161616 0%, rgba(16, 16, 16, 0.0001) 100%)"}
      }),
      _createElementVNode("div", {
        class: "w-20 h-full",
        style: {"background":"linear-gradient(270deg, #161616 0%, rgba(16, 16, 16, 0.0001) 100%)"}
      })
    ], -1)),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        type: "button",
        class: "px-8 h-full z-20 focus:outline-none border-none opacity-50 hover:opacity-100",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (next()))
      }, [
        _createVNode(_unref(BaseIcon), {
          class: "text-white",
          name: "right-arrow"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        type: "button",
        class: "px-8 h-full z-20 focus:outline-none border-none opacity-50 hover:opacity-100",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (prev()))
      }, [
        _createVNode(_unref(BaseIcon), {
          class: "text-white",
          name: "left-arrow"
        })
      ])
    ])
  ]))
}
}

})