import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createVNode as _createVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-between leading-tight mb-3"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_5 = {
  key: 0,
  class: "text-gray-500 font-medium text-xs"
}
const _hoisted_6 = {
  key: 1,
  class: "text-xs font-semibold text-primary-500"
}
const _hoisted_7 = { class: "w-full flex flex-col" }

import { computed, PropType, ref, useId } from 'vue';
import { IOption } from '@/lib';
import BasePopover from '@/lib/components/Popover/BasePopover.vue';
import PopoverButton from '@/lib/components/Popover/PopoverButton.vue';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import { InputTextHelper } from '@/lib/components/Helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSelectDropdown',
  props: {
  modelValue: {
    type: String as PropType<IOption['value']>,
    default: null
  },
  label: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  isOptional: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: ''
  },
  options: {
    type: Array as PropType<IOption[]>,
    default: () => []
  },
  error: {
    type: String,
    default: null
  },
  lineHeight: {
    type: Number,
    default: 1
  }
},
  emits: ["update:modelValue"],
  setup(__props) {

const props = __props;


const selectDropdownPopover = ref<InstanceType<typeof BasePopover>>(null);

const id = useId();

const selectedLabel = computed(() => {
  const selected = props.options.find((option: IOption) => selectedOption.value === option.value);
  return selected?.label;
});
const selectedOption = computed(() => props.modelValue);
const openPopup = () => {
  selectDropdownPopover.value?.open();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BasePopover, {
    ref_key: "selectDropdownPopover",
    ref: selectDropdownPopover,
    width: "100%",
    class: "w-full inline-flex flex-col"
  }, {
    menu: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
          return (_openBlock(), _createBlock(PopoverButton, {
            key: option.value,
            class: "my-1",
            active: selectedOption.value === option.value,
            onClick: ($event: any) => (_ctx.$emit('update:modelValue', option.value))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(option.label), 1)
            ]),
            _: 2
          }, 1032, ["active", "onClick"]))
        }), 128))
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        ((__props.label?.length) || _ctx.$slots.action)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("label", {
                for: _unref(id),
                class: "text-base font-medium leading-tight"
              }, _toDisplayString(__props.label), 9, _hoisted_3),
              _createElementVNode("div", _hoisted_4, [
                (__props.isOptional)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
                  : _createCommentVNode("", true),
                (_ctx.$slots.action)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _renderSlot(_ctx.$slots, "action")
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          tabindex: "0",
          class: _normalizeClass(["flex cursor-pointer items-center w-full bg-white border rounded h-12", {
          'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none':
            __props.error
        }]),
          onKeyup: _withKeys(openPopup, ["enter","space"])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["w-full ml-5 appearance-none bg-white focus:outline-none", {
            'text-gray-400': !__props.modelValue,
            'leading-none': __props.lineHeight === 1,
            'leading-tight': __props.lineHeight > 1
          }])
          }, _toDisplayString(selectedLabel.value || __props.placeholder), 3),
          _createVNode(BaseIcon, {
            height: "1.25em",
            class: "text-gray-500 rounded-full p-px stroke-1.5 ml-3 mr-3",
            name: "chevron-down"
          })
        ], 34),
        _createVNode(_unref(InputTextHelper), {
          error: __props.error,
          description: __props.description
        }, null, 8, ["error", "description"])
      ])
    ]),
    _: 3
  }, 512))
}
}

})