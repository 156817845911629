import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-between space-x-8" }
const _hoisted_2 = { class: "flex flex-col flex-shrink" }
const _hoisted_3 = { class: "text-lg font-bold text-gray-900" }
const _hoisted_4 = { class: "text-gray-600 mt-4 leading-5" }
const _hoisted_5 = { class: "flex flex-row items-center justify-between mt-8 bg-gray-50 h-14 pl-6 pr-3 border rounded border-gray-300" }
const _hoisted_6 = { class: "space-x-3" }
const _hoisted_7 = { class: "text-gray-900" }

import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { OrganisationDeviceService } from '@/services/api';
import { BaseCard } from '@/lib/components/Card';
import { ActionModal } from '@/lib/components/Modals';
import { BaseButton } from '@/lib/components/Button';
import useClipboard from 'vue-clipboard3';
import { useNotificationStore } from '@/stores/notification.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'DeviceKey',
  props: {
  deviceId: {
    type: String,
    required: true
  },
  organisationId: {
    type: String,
    required: true
  }
},
  emits: ["download"],
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const props = __props;


const deviceKey = ref('**********************');
const saving = ref(false);
const deviceKeyIsReady = ref(false);
const deviceKeyModal = ref(false);
const notificationStore = useNotificationStore();

const deviceService = computed<OrganisationDeviceService>(() => new OrganisationDeviceService(props.organisationId));

onBeforeMount(() => {
  deviceKey.value = route.query.deviceKey ? route.query.deviceKey.toString() : '**********************';
  deviceKeyIsReady.value = !!route.query.copyDeviceKey;
});
const onCopy = async () => {
  try {
    const { toClipboard } = useClipboard();
    await toClipboard(deviceKey.value);
    await notificationStore.addSuccessNotification({
      title: t('platform.device.copy-key-success')
    });
  } catch (error) {
    await notificationStore.addErrorNotification({
      title: t('platform.device.copy-key-error')
    });
  }
};
const generateDeviceKey = async () => {
  try {
    if (deviceService.value) {
      saving.value = true;
      const response = await deviceService.value.generateDeviceKey(props.deviceId);
      deviceKey.value = response.key;
      deviceKeyIsReady.value = true;
      await notificationStore.addSuccessNotification({
        title: t('platform.device.regenerate-key-success')
      });
    }
  } catch (error) {
    await notificationStore.addErrorNotification({
      title: t('platform.device.regenerate-key-error')
    });
  } finally {
    saving.value = false;
    deviceKeyModal.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createBlock(_unref(BaseCard), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('platform.device.key')), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('platform.device.key-instructions')), 1)
        ]),
        _createVNode(_unref(BaseButton), {
          "data-dusk": "download-installer-button",
          color: "secondary",
          class: "flex flex-shrink-0",
          "left-icon": "add",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('download')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.device.download')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(deviceKey.value), 1),
          (deviceKeyIsReady.value)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "border-none outline-none text-primary-500 font-bold text-xs",
                "data-dusk": "copy-key-button",
                onClick: onCopy
              }, _toDisplayString(_ctx.$t('platform.common.copy').toUpperCase()), 1))
            : _createCommentVNode("", true)
        ]),
        _withDirectives((_openBlock(), _createBlock(_unref(BaseButton), {
          "data-dusk": "regenerate-key-button",
          color: "ghost",
          size: "small",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (deviceKeyModal.value = true))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.regenerate')), 1)
          ]),
          _: 1
        })), [
          [_directive_allow, 'device:update']
        ])
      ]),
      (deviceKeyModal.value)
        ? (_openBlock(), _createBlock(_unref(ActionModal), {
            key: 0,
            "data-dusk": "device-key-modal",
            title: _ctx.$t('platform.device.regenerate-key'),
            body: _ctx.$t('platform.device.regenerate-key-message'),
            "primary-action": _ctx.$t('platform.device.confirm-regenerate'),
            "tertiary-action": _ctx.$t('platform.common.cancel'),
            onPrimaryClick: _cache[2] || (_cache[2] = ($event: any) => (generateDeviceKey())),
            onTertiaryClick: _cache[3] || (_cache[3] = ($event: any) => (deviceKeyModal.value = false)),
            onClose: _cache[4] || (_cache[4] = ($event: any) => (deviceKeyModal.value = false))
          }, null, 8, ["title", "body", "primary-action", "tertiary-action"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})