import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full max-w-lg text-center" }
const _hoisted_2 = { class: "font-light leading-none text-6xl text-primary-300" }
const _hoisted_3 = { class: "text-2xl font-bold text-primary-900 mt-2" }
const _hoisted_4 = { class: "mt-6 text-base leading-normal text-gray-600" }
const _hoisted_5 = { class: "mt-8" }
const _hoisted_6 = { class: "absolute bottom-0 mb-10" }

import { BaseButton } from '@/lib/components/Button';
import FloatingBrandShapesLayout from './FloatingBrandShapesLayout.vue';
import { i18n } from '@/i18n/i18n';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import ButtonLink from '@/lib/components/Button/ButtonLink.vue';
import { useSessionStore } from '@/stores/session.store';
import { CustomerBranding } from '@/models';


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorLayout',
  props: {
  type: {
    type: String,
    default(): string {
      return i18n.global.t('platform.error.error') as string;
    }
  },
  title: {
    type: String,
    default(): string {
      return i18n.global.t('platform.error.error') as string;
    }
  },
  message: {
    type: String,
    default(): string {
      return i18n.global.t('platform.error.error-found') as string;
    }
  },
  showGoBack: {
    type: Boolean,
    default: false
  },
  showTryAgain: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {

const sessionStore = useSessionStore();
const router = useRouter();


const branding = computed(() => sessionStore.customerBranding);

const goBack = async () => {
  await router.back();
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createBlock(FloatingBrandShapesLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(__props.type), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(__props.title), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(__props.message), 1),
        _renderSlot(_ctx.$slots, "default"),
        _createElementVNode("div", _hoisted_5, [
          (__props.showGoBack)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_unref(BaseButton), {
                  size: "large",
                  color: "primary",
                  onClick: goBack
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.error.go-back')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_RouterLink, {
                  to: "/",
                  class: "block mt-4 text-link"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.error.return-home')), 1)
                  ]),
                  _: 1
                })
              ], 64))
            : (__props.showTryAgain)
              ? (_openBlock(), _createBlock(ButtonLink, {
                  key: 1,
                  href: "/",
                  size: "large",
                  "left-icon": "reload",
                  color: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.error.try-again')), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(ButtonLink, {
                  key: 2,
                  to: "/",
                  size: "large",
                  color: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.error.return-home-only')), 1)
                  ]),
                  _: 1
                }))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (branding.value !== _unref(CustomerBranding).BPM)
          ? (_openBlock(), _createBlock(_component_InlineSvg, {
              key: 0,
              src: require('@/assets/images/logo-bigpicture-poweredby.svg'),
              class: "h-8"
            }, null, 8, ["src"]))
          : (_openBlock(), _createBlock(_component_InlineSvg, {
              key: 1,
              class: "h-6",
              src: require('@/assets/images/logo-bigpicture-text.svg')
            }, null, 8, ["src"]))
      ])
    ]),
    _: 3
  }))
}
}

})