export enum VaUnit {
  SIX_SIX = '6/6',
  TWENTY_TWENTY = '20/20',
  DECIMAL = 'decimal',
  LOG_MAR = 'logMar',
  MONOYER = 'Monoyer'
}

export enum KUnit {
  MM = 'mm',
  DIOPTRES = 'D'
}
