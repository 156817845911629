import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-row rounded h-10" }
const _hoisted_2 = ["onClick"]

import { BaseIcon } from '@/lib/components/Icon';
import { PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewerNavigationSwitch',
  props: {
  items: {
    type: Array as PropType<{ name: string; icon: string; value: number; }[]>,
    required: true,
    validator: (value: any[]) => value.length === 2
  },
  modelValue: {
    type: Number,
    default: 0
  }
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const changeMode = (mode: number) => {
  if (mode !== props.modelValue) {
    emit('update:modelValue', mode);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item) => {
      return (_openBlock(), _createElementBlock("button", {
        key: item.value,
        type: "button",
        class: _normalizeClass(["rounded px-4 text-center cursor-pointer text-sm font-medium select-none text-white", [__props.modelValue === item.value ? 'bg-primary-500 rounded' : 'bg-gray-900']]),
        onClick: ($event: any) => (changeMode(item.value))
      }, [
        (item.icon?.length)
          ? (_openBlock(), _createBlock(_unref(BaseIcon), {
              key: 0,
              class: "text-gray-300 text-base mr-3",
              name: item.icon
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(item.name), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}
}

})