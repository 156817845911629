import { Customer, CustomerBranding, CustomerUrl, Feature, Organisation, Permission, User } from '@/models';
import { defineStore } from 'pinia';
import { CustomersService, OrganisationService, UserService } from '@/services/api';
import { getDefaultLocale, i18n, switchLocale } from '@/i18n/i18n';
import { v4 } from 'uuid';
import { IdlesService } from '@/services';
import { IdleJs } from '@sbourouis/idle-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DEFAULT_LEGAL_REGION } from '@/constants/legal';
import { useNotificationStore } from '@/stores/notification.store';
import { AxiosError } from 'axios';

dayjs.extend(utc);
const userService = new UserService();
const customersService = new CustomersService();

interface State {
  environmentFeatures: Feature[],
  currentUser: User | null;
  organisations: Organisation[];
  currentOrganisationId: string | null;
  customerId: string | null;
  adminCustomers: Customer[];
  adminCustomer: Customer | null;
  token: string | null;
  refreshToken: string | null;
  expireDate: string | null;
  isMaintenance: boolean;
  uuid: string | null;
  isIdle: boolean;
  organisationsLoading: boolean;
  organisationsLoadFailed: boolean;
  idle: any | null;
}

const initialState: State = {
  environmentFeatures: [],
  currentUser: null,
  customerId: null,
  token: null,
  refreshToken: null,
  adminCustomers: [],
  organisations: [],
  adminCustomer: null,
  expireDate: null,
  isMaintenance: false,
  isIdle: false,
  idle: null,
  uuid: null,
  currentOrganisationId: null,
  organisationsLoading: false,
  organisationsLoadFailed: false
};

export const useSessionStore = defineStore('session', {
  state: (): State => ({
    ...initialState
  }),
  getters: {
    policiesToAgreeTo(): Customer[] {
      const policies = [];
      const userAgreedToAdminCustomerPolicy = this.currentUser?.accepted_customers
        .find((acceptedCustomer) => acceptedCustomer.id === this.adminCustomer?.id);
      if (this.adminCustomer && !userAgreedToAdminCustomerPolicy) {
        policies.push(this.adminCustomer);
      }
      if (this.currentUser?.is_admin || this.currentUser?.is_lens_support) {
        const adminUnacceptedPolicies = this.adminCustomers
          .filter((customer) => customer.id !== this.adminCustomer?.id && customer.has_terms_of_service &&
          !this.currentUser?.accepted_customers.find((acceptedCustomer) => acceptedCustomer.id === customer.id)
          );
        return [...policies, ...adminUnacceptedPolicies];
      } else if (this.customerId && this.currentOrganisation?.domain.customer.has_terms_of_service &&
        !this.currentUser?.accepted_customers
          .find((acceptedCustomer) => acceptedCustomer.id === this.currentOrganisation.domain.customer.id)) {
        return [...policies, this.currentOrganisation.domain.customer];
      }
      return policies;
    },
    currentOrganisation: (state: State): Organisation | null => state.organisations
      ?.find((organisation) => organisation.id === state.currentOrganisationId) ?? null,
    currentCustomer(): Customer | null {
      if (this.currentOrganisation?.domain?.customer.id) {
        return this.customers
          .find((customer) => customer.id === this.currentOrganisation?.domain?.customer?.id) ?? null;
      }
      return null;
    },
    getOrganisation: (state: State) => (organisationId: string): Organisation | null => state.organisations
      .find((organisation) => organisation.id === organisationId) ?? null,
    permissions(): string[] {
      if (this.currentOrganisation?.permissions) {
        return this.currentOrganisation.permissions.map((permission: Permission) => permission.key);
      }
      return [];
    },
    features(): Feature[] {
      return [
        ...this.environmentFeatures,
        ...(this.currentOrganisation?.domain?.features ?? [])
      ];
    },
    legalRegions(): string[] {
      const regions = this.currentOrganisation?.domain?.customer.legal_regions;
      return regions?.length ? regions : [DEFAULT_LEGAL_REGION];
    },
    customerBranding(): CustomerBranding {
      return this.currentOrganisation?.domain?.customer.branding ?? CustomerBranding.BPM;
    },
    customerFromUrl(): Customer[] {
      return this.customers.filter((customer: Customer) =>
        customer.urls.some((customerUrl: CustomerUrl) => window.location.origin === customerUrl.url)
      ) ?? null;
    },
    customerOrganisationsFromUrl(): Organisation[] {
      return this.organisations.filter((organisation: Organisation) =>
        (this.customerFromUrl ?? []).find((customer) => customer.id === organisation.domain?.customer?.id)
      );
    },
    customerOrganisations(): Organisation[] {
      return this.customerId ? this.organisations
        ?.filter((organisation) => organisation.domain?.customer?.id === this.customerId)
        .sort((a: Organisation, b: Organisation) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())) : [];
    },
    customers: (state: State): Customer[] => {
      const userCustomers = state.organisations?.reduce((customers, organisation) => {
        const customer = organisation.domain?.customer;
        return {
          ...customers,
          ...(customer ? { [customer.id]: customer } : {})
        };
      }, {} as Record<string, Customer>) ?? {};

      return Object.values(userCustomers);
    },
    isCustomer(): (customerBranding: CustomerBranding) => boolean {
      return (customerBranding: CustomerBranding): boolean => {
        if (customerBranding === CustomerBranding.MENICON) {
          return this.customerBranding === CustomerBranding.MENICON ||
            this.customerBranding === CustomerBranding.JNJ;
        }

        return this.customerBranding === customerBranding;
      };
    },
    canViewOrganisationSettings(): boolean {
      return !!this.permissions.find((permission) => [
        'user:read', 'role:read', 'device:read', 'org-unit-preference:update'
      ].includes(permission));
    }
  },
  actions: {
    async login(credential) {
      const response = await userService.login(credential);
      this.token = response.access_token;
      this.refreshToken = response.refresh_token;
      this.setExpireDate(response.expires_in);
      return response;
    },
    async fetchAdminCustomer() {
      this.adminCustomer = (await customersService.index({
        params: {
          'filter[is_admin]': '1'
        }
      })).data?.[0] ?? null;
    },
    setCurrentOrganisationId(organisationId: string | null) {
      this.currentOrganisationId = organisationId;

      if (organisationId) {
        window.localStorage.setItem('organisationId', organisationId);
        this.customerId = this.currentOrganisation?.domain?.customer?.id ?? null;
      } else {
        window.localStorage.removeItem('organisationId');
        this.customerId = null;
      }
    },
    setCurrentOrganisationIdForCustomer(customerId: string | null) {
      if (customerId) {
        const organisationId = this.organisations
          ?.find((organisation) => organisation.domain?.customer?.id === customerId)?.id ?? null;
        this.setCurrentOrganisationId(organisationId);
      } else {
        this.setCurrentOrganisationId(null);
      }
    },
    async refreshUserToken(params) {
      const response = await userService.refreshToken(params);
      this.token = response.access_token;
      this.refreshToken = response.refresh_token;
      this.setExpireDate(response.expires_in);
      return response;
    },
    setExpireDate(expiresIn: number) {
      this.expireDate = dayjs.utc().add(expiresIn, 'second').format('YYYY-MM-DD HH:mm:ss');
    },
    async revokeUserToken(params) {
      return await userService.revokeToken(params);
    },
    async fetchUserOTP(params) {
      return await userService.mfaAssociate(params);
    },
    async fetchUserSms(params) {
      return await userService.mfaAssociate(params);
    },
    async fetchUserSmsChallenge(params) {
      return await userService.mfaChallenge(params);
    },
    async fetchCurrentUser(ignoreError = false) {
      const user = await userService.getCurrent({
        params: {
          ignoreError
        }
      });
      if (user) {
        this.setCurrentUser(user);
        if (user.is_admin || user.is_lens_support) {
          this.adminCustomers = await userService.getUserCustomers();
        }

        const locale = user.locale;
        if (locale) {
          switchLocale(locale);
        }
      }
    },
    async fetchOrganisations() {
      const notificationStore = useNotificationStore();
      try {
        this.organisationsLoading = true;
        this.organisations = await userService.getCurrentOrganisations({
          params: {
            append: 'features'
          }
        });
        if (this.organisationsLoadFailed) {
          notificationStore.toast = null;
          this.organisationsLoadFailed = false;
        }
      } catch (e) {
        this.organisationsLoadFailed = true;
        const errorLabel = i18n.global.t(`platform.org-unit.${(e as AxiosError).code === 'ECONNABORTED' ? 'timeout' : 'fetch'}-error`);
        notificationStore.setErrorToast(errorLabel, {
          buttonLabel: i18n.global.t('platform.error.retry'),
          buttonClick: () => this.fetchOrganisations()
        });
      } finally {
        this.organisationsLoading = false;
      }
    },
    async fetchOrganisation(organisationId: string) {
      try {
        const organisationService = new OrganisationService();
        const index = this.organisations.findIndex((organisation) => organisation.id === organisationId);
        if (index > -1) {
          const organisation = await organisationService.fetch(organisationId, {
            params: {
              include: 'permissions',
              append: 'features'
            }
          });
          this.organisations.splice(index, 1, {
            ...this.organisations[index],
            ...organisation
          });
        }
      } catch (e) {
        const notificationStore = useNotificationStore();
        notificationStore.setErrorToast(i18n.global.t('platform.org-unit.fetch-one-error'), {
          buttonLabel: i18n.global.t('platform.error.retry'),
          buttonClick: () => this.fetchOrganisation(organisationId)
        });
      }
    },
    async fetchCurrentUserAndSetOrganisation(ignoreError = false) {
      await this.fetchCurrentUser(ignoreError);
      await this.fetchOrganisations();
      if (!this.currentOrganisationId && !this.customerId) {
        const savedOrganisationId = window.localStorage.getItem('organisationId');

        if (!savedOrganisationId) {
          this.setCurrentOrganisationIdForCustomer(this.customerFromUrl[0]?.id);
          return;
        }

        const organisationInResponse = this.organisations.find(
          (organisation: Organisation) => organisation.id === savedOrganisationId &&
            organisation.domain?.customer?.id === this.customerFromUrl[0]?.id
        );

        if (organisationInResponse) {
          this.setCurrentOrganisationId(organisationInResponse?.id ?? null);
        } else {
          this.setCurrentOrganisationIdForCustomer(this.customerFromUrl[0]?.id);
        }
      }
    },
    async saveUser(user) {
      const response = await userService.updateCurrent(user);
      this.setCurrentUser({
        ...(this.currentUser ? this.currentUser : {}),
        ...response
      });
      return response;
    },
    isPermissionEnabled(permissionKey: string) {
      return this.permissions.includes(permissionKey);
    },
    setCurrentUser(user: User | null) {
      try {
        this.currentUser = user;

        if (user) {
          if (user.locale !== i18n.global.locale.value) {
            switchLocale(user.locale);
          }
          if (this.idle) {
            this.idle.stop();
          }
          const idleTime = user.inactivity_timeout_seconds * 1000;
          const idle = new IdleJs({
            idle: idleTime, // idle time in ms
            onIdle: () => {
              this.isIdle = true;
            },
            keepTracking: true, // set it to false if you want to be notified only on the first idleness change
            startAtIdle: true // set it to true if you want to start in the idle state
          });
          this.isIdle = false;
          this.idle = idle;
          idle.start();

          if (!this.uuid) {
            const id = v4();
            this.uuid = id;
            IdlesService.addIdle(id);
          } else if (!IdlesService.hasIdle(this.uuid)) {
            IdlesService.addIdle(this.uuid);
          }
        } else {
          this.idle?.stop();
          Object.assign(this, { ...initialState, environmentFeatures: this.environmentFeatures });
          switchLocale(getDefaultLocale());
        }
      } catch (e) {
        console.error('Failed to set current user as ', user);
        console.error(e);
      }
    }
  }
});
