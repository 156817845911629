import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode } from "vue"

import { PropType } from 'vue';
import BaseCheckbox from './BaseCheckbox.vue';
import { InputTextHelper } from '@/lib/components/Helper';
import { IOption } from '@/lib';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckboxGroup',
  props: {
  label: {
    type: String,
    default: null
  },

  options: {
    type: Array as PropType<string[] | IOption[]>,
    required: true
  },

  modelValue: {
    type: [Boolean, Array] as PropType<boolean | string[] | number[]>,
    required: true
  },

  disabled: {
    type: Boolean,
    default: false
  },

  readonly: {
    type: Boolean,
    default: false
  },

  loading: {
    type: Boolean,
    default: false
  },

  dark: {
    type: Boolean,
    default: false
  },

  fullWidth: {
    type: Boolean,
    default: false
  },

  checkboxClass: {
    type: String,
    default: ''
  },

  description: {
    type: String,
    default: ''
  },

  error: {
    type: String,
    default: null
  },

  displayErrorMessage: {
    type: Boolean,
    default: true
  }
},
  emits: ["input", "update:modelValue", "change"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const onUpdate = (value: typeof props.modelValue) => {
  emit('input', value);
  emit('update:modelValue', value);
  emit('change', value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["text-base font-medium mb-3 inline-block", { 'text-white': __props.dark }])
        }, _toDisplayString(__props.label), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex gap-4", [__props.fullWidth ? 'flex-col w-full' : 'flex-wrap']])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
        return (_openBlock(), _createBlock(BaseCheckbox, {
          key: (option as IOption).value ?? option,
          "model-value": __props.modelValue,
          label: (option as IOption).label ?? option as string,
          value: (option as IOption).label ? (option as IOption).value : option as string,
          disabled: __props.disabled,
          loading: __props.loading,
          dark: __props.dark,
          readonly: __props.readonly,
          "checkbox-class": __props.checkboxClass,
          "display-error": false,
          "label-class": 'pl-4',
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (onUpdate($event)))
        }, null, 8, ["model-value", "label", "value", "disabled", "loading", "dark", "readonly", "checkbox-class"]))
      }), 128))
    ], 2),
    _createVNode(_unref(InputTextHelper), {
      error: __props.displayErrorMessage ? __props.error : '',
      loading: __props.loading,
      description: __props.description,
      border: true
    }, null, 8, ["error", "loading", "description"])
  ]))
}
}

})