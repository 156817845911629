import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = ["data-dusk"]
const _hoisted_2 = ["data-dusk"]
const _hoisted_3 = { class: "flex items-center space-x-3" }
const _hoisted_4 = {
  key: 1,
  class: "text-sm font-medium text-gray-600 capitalize"
}

import { computed, defineAsyncComponent, onBeforeUnmount, PropType, ref, watch } from 'vue';
import { Table } from '@/lib/components/DataTable';
import { BaseSelect, SelectInput } from '@/lib/components/Select';
import { BaseIcon } from '@/lib/components/Icon';
import { FileUpload } from '@/lib/components/Upload';
import { BaseTextarea } from '@/lib/components/Textarea';
import { BaseTextInput } from '@/lib/components/Input';
import { RadioJammerModal } from '@/lib/components/Modals';
import { RadioGroup } from '@/lib/components/Radio';
import { BaseDatePicker } from '@/lib/components/Date';
import { CheckboxGroup } from '@/lib/components/Checkbox';
import { FormItem } from '@/models';
import SmartFormRepeater from './SmartFormRepeater.vue';
import { getAnswer } from '@/helpers/smart-form.helper';
import { useSmartFormStore } from '@/stores/smartForm.store';
import { stringToDuskAttribute } from '@/custom/menicon/helpers/strings.helper';
import BaseAlert from '@/lib/components/Banner/BaseAlert.vue';
import MedKitBadge from '@/lib/components/Badge/MedKitBadge.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SmartFormItem',
  props: {
  item: {
    type: Object as PropType<FormItem>,
    required: true
  },
  participantId: {
    type: String,
    default: null
  },
  patientId: {
    type: String,
    default: null
  },
  formSubmissionId: {
    type: String,
    required: true
  },
  next: {
    type: Function,
    required: true
  },
  back: {
    type: Function,
    required: true
  },
  level: {
    type: Number,
    default: 0
  },
  loading: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  }
},
  emits: ["loading"],
  setup(__props) {

const props = __props;


const componentTypes = {
  text: BaseTextInput,
  checkbox: CheckboxGroup,
  date: BaseDatePicker,
  table: Table,
  select: BaseSelect,
  textarea: BaseTextarea,
  radio: RadioGroup,
  'radio-jammer': RadioJammerModal,
  selectInput: SelectInput,
  repeater: SmartFormRepeater,
  file: FileUpload,
  alert: BaseAlert,
  badge: MedKitBadge
};
const validating = ref(false);
const smartForm = useSmartFormStore();

const componentType = computed(() => {
  if (props.item.type === 'component') {
    return defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "component-[request]" */
          `@/${props.item.component}.vue`
        )
    );
  }

  return componentTypes[props.item.type];
});
const isPrefilled = computed(() => smartForm.getItemIsPrefilled(
  props.formSubmissionId,
  props.item.id
));
const answer = computed(() => getAnswer(smartForm, props.formSubmissionId, props.item));
const conditionsMet = computed(() => smartForm.getItemConditionsMet(
  props.formSubmissionId,
  props.item.conditions
));
const error = computed(() => smartForm.getError(props.formSubmissionId, props.item.id));
const hyphenatedLabel = computed(() => {
  const label = props.item?.label || props.item.props?.label || props.item.props?.icon;
  return label ? stringToDuskAttribute(label) : '';
});

watch(conditionsMet, () => exposed.clearAnswerWhenConditionsNotMet);

onBeforeUnmount(() => {
  exposed.clearAnswerWhenConditionsNotMet();
});
const setAnswer = (value) => {
  smartForm.answers = {
    ...smartForm.answers,
    [props.formSubmissionId]: {
      ...smartForm.answers[props.formSubmissionId],
      [props.item.id]: {
        value,
        is_prefilled: false
      }
    }
  };

  smartForm.dirty = {
    ...smartForm.dirty,
    [props.formSubmissionId]: [
      ...new Set([
        ...(smartForm.dirty[props.formSubmissionId] || []),
        props.item.id
      ])
    ]
  };
};
const clearAnswerWhenConditionsNotMet = () => {
  if (
    !conditionsMet.value &&
    typeof answer.value !== 'undefined' &&
    answer.value !== null
  ) {
    exposed.setAnswer(null);
    exposed.saveAnswers();
  }
};
const saveAnswers = async () => {
  validating.value = true;
  await smartForm.saveAnswers({
    participantId: props.participantId,
    patientId: props.patientId,
    formSubmissionId: props.formSubmissionId,
    validateItems: props.item.validateItems,
    useDebounce: true
  });
  validating.value = false;
};
const gridClasses = (item: FormItem): string => {
  if (item.layout === 'grid') {
    let layoutClasses = `mt-6 grid gap-4 grid-cols-${item.cols || 1}`;
    if (item.justifyItems) {
      layoutClasses += ` justify-items-${item.justifyItems}`;
    }
    if (item.alignItems) {
      layoutClasses += ` items-${item.alignItems}`;
    }
    return layoutClasses;
  }

  return 'space-y-12';
};
const exposed = {
  clearAnswerWhenConditionsNotMet,
  setAnswer,
  saveAnswers
};

return (_ctx: any,_cache: any) => {
  const _component_SmartFormItem = _resolveComponent("SmartFormItem", true)!

  return (conditionsMet.value && __props.item.type !== 'hidden')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["space-y-12", {
      'bg-white border rounded-lg shadow': __props.item.container === 'card',
      'p-8': __props.item.container === 'card' && (__props.item.type !== 'yes-no' || !answer.value),
      'px-8 pt-8': __props.item.container === 'card' && __props.item.type === 'yes-no' && answer.value
    }]),
        "data-dusk": hyphenatedLabel.value
      }, [
        _createElementVNode("div", {
          class: _normalizeClass({
        'flex items-center justify-between': __props.item.type === 'yes-no'
      }),
          "data-dusk": hyphenatedLabel.value
        }, [
          (__props.item.label || __props.item.icon)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent('h' + (__props.level + 2)), {
                key: 0,
                class: _normalizeClass([{
          'font-bold': __props.item.bold
        }, "mb-6 text-lg font-semibold text-gray-900"]),
                "data-dusk": "heading"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    (__props.item.icon)
                      ? (_openBlock(), _createBlock(_unref(BaseIcon), {
                          key: 0,
                          name: __props.item.icon,
                          height: "30px",
                          color: "white"
                        }, null, 8, ["name"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", null, _toDisplayString(__props.item.label), 1),
                    (__props.item.label && __props.item.optional)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          (componentType.value && __props.item.type !== 'group' && conditionsMet.value)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(componentType.value), _mergeProps({
                key: 1,
                id: __props.item.id,
                "model-value": answer.value,
                "data-dusk": __props.item.id,
                error: error.value,
                item: __props.item
              }, __props.item.props, {
                description: 
          isPrefilled.value ? __props.item.prefillDescription : __props.item.props?.description
        ,
                "patient-id": __props.patientId,
                "participant-id": __props.participantId,
                "form-submission-id": __props.formSubmissionId,
                validating: validating.value,
                next: __props.next,
                back: __props.back,
                loading: __props.loading,
                readonly: __props.readonly,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (!__props.readonly && setAnswer($event))),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (!__props.readonly && saveAnswers())),
                onLoading: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('loading', $event)))
              }), null, 16, ["id", "model-value", "data-dusk", "error", "item", "description", "patient-id", "participant-id", "form-submission-id", "validating", "next", "back", "loading", "readonly"]))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2),
        (__props.item.items && __props.item.type !== 'repeater' && __props.item.type !== 'yes-no')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(gridClasses(__props.item))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.item.items, (subitem, index) => {
                return (_openBlock(), _createBlock(_component_SmartFormItem, {
                  key: subitem.id,
                  item: subitem,
                  "patient-id": __props.patientId,
                  "participant-id": __props.participantId,
                  "form-submission-id": __props.formSubmissionId,
                  next: __props.next,
                  back: __props.back,
                  level: __props.level + 1,
                  validating: validating.value,
                  readonly: __props.readonly,
                  class: _normalizeClass({
          'border-t border-gray-300': __props.item.layout === 'list' && index,
          'py-6': __props.item.layout === 'list'
        }),
                  "data-dusk": "item"
                }, null, 8, ["item", "patient-id", "participant-id", "form-submission-id", "next", "back", "level", "validating", "readonly", "class"]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true),
        (__props.item.items && __props.item.type === 'yes-no' && answer.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass([
        __props.item.layout === 'grid'
          ? 'mt-6 grid gap-4 grid-cols-' + __props.item.cols || 1
          : '',
        __props.item.container === 'card' ? 'border-t bg-gray-100 p-4 -m-8' : ''
      ])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.item.items, (subitem) => {
                return (_openBlock(), _createBlock(_component_SmartFormItem, {
                  key: subitem.id,
                  item: subitem,
                  "patient-id": __props.patientId,
                  "participant-id": __props.participantId,
                  "form-submission-id": __props.formSubmissionId,
                  next: __props.next,
                  back: __props.back,
                  level: __props.level + 1,
                  readonly: __props.readonly,
                  "data-dusk": "item"
                }, null, 8, ["item", "patient-id", "participant-id", "form-submission-id", "next", "back", "level", "readonly"]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})