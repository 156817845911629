import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from './../../../js/assets/images/brand/brand-shape-1.png'
import _imports_1 from './../../../js/assets/images/brand/brand-shape-2.png'
import _imports_2 from './../../../js/assets/images/brand/brand-shape-3.png'


const _hoisted_1 = {
  key: 0,
  class: "floating-brand-shapes"
}
const _hoisted_2 = { class: "min-h-screen px-8 flex flex-col items-center justify-center relative z-10 md:p-12" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col w-full"
}

import { computed } from 'vue';
import { useUiStore } from '@/stores/ui.store';
import { CustomerBranding } from '@/models';


export default /*@__PURE__*/_defineComponent({
  __name: 'BrandingLayout',
  setup(__props) {

const uiStore = useUiStore();
const branding = computed(() => uiStore.headerBranding);
const isJNJ = computed(() => branding.value === CustomerBranding.JNJ);
const isDefaultBranding = computed(() => branding.value === CustomerBranding.BPM);
const style = computed(() => isJNJ.value ? 'background: linear-gradient(0deg, #58B0E3 0%, #2A5CAA 100%);' : '');
const bgFooterImage = computed(() => branding.value && !isDefaultBranding.value ?
  require(`@/assets/branding/login-page/bg-footer-${branding.value}.svg`) : null);

return (_ctx: any,_cache: any) => {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createElementBlock("div", {
    class: "relative min-h-screen bg-primary-100",
    style: _normalizeStyle(style.value)
  }, [
    (!isJNJ.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createStaticVNode("<div class=\"floating-brand-shape\"><img src=\"" + _imports_0 + "\" alt=\"\"></div><div class=\"floating-brand-shape\"><img src=\"" + _imports_1 + "\" alt=\"\"></div><div class=\"floating-brand-shape\"><img src=\"" + _imports_2 + "\" alt=\"\"></div>", 3)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default"),
      (branding.value && !isDefaultBranding.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (isJNJ.value)
              ? (_openBlock(), _createBlock(_component_InlineSvg, {
                  key: 0,
                  src: require('@/assets/images/logo-bigpicture-poweredby-negative.svg'),
                  class: "h-12 mt-12 mb-20 w-full text-center"
                }, null, 8, ["src"]))
              : (_openBlock(), _createBlock(_component_InlineSvg, {
                  key: 1,
                  src: require('@/assets/images/logo-bigpicture-poweredby.svg'),
                  class: "h-12 mt-12 mb-20 w-full text-center"
                }, null, 8, ["src"])),
            (bgFooterImage.value)
              ? (_openBlock(), _createBlock(_component_InlineSvg, {
                  key: 2,
                  src: bgFooterImage.value,
                  class: "absolute left-0 bottom-0 w-full h-auto"
                }, null, 8, ["src"]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createBlock(_component_InlineSvg, {
            key: 1,
            src: require('@/assets/images/logo-bigpicture-text.svg'),
            class: "h-8 mt-12 mb-20 w-full text-center"
          }, null, 8, ["src"]))
    ])
  ], 4))
}
}

})