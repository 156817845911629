import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TopographyScansScaleItem',
  props: {
  color: {
    type: String,
    required: true
  },
  value: {
    type: Number,
    required: true
  },
  isFirst: {
    type: Boolean,
    default: false
  },
  isLast: {
    type: Boolean,
    default: false
  },
  vertical: {
    type: Boolean,
    default: false
  },
  height: {
    type: String,
    default: '30px'
  },
  width: {
    type: String,
    default: '32px'
  }
},
  setup(__props, { expose: __expose }) {


const text = ref(null);

__expose({
  text
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["items-center", __props.vertical ? 'flex flex-col space-y-2' : 'inline-flex space-x-3']),
    style: _normalizeStyle(`${__props.vertical ? 'height' : 'width'}: max-content`)
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle(`background: ${__props.color}; height: ${__props.height};width: ${__props.width}`),
      class: _normalizeClass({
        'rounded-t-md': __props.isFirst && !__props.vertical,
        'rounded-b-md': __props.isLast && !__props.vertical,
        'rounded-l-md': __props.isFirst && __props.vertical,
        'rounded-r-md': __props.isLast && __props.vertical
      })
    }, null, 6),
    _createElementVNode("p", {
      ref_key: "text",
      ref: text,
      class: "opacity-80 text-white text-sm font-normal leading-normal"
    }, _toDisplayString(__props.value), 513)
  ], 6))
}
}

})