import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onBeforeMount, onBeforeUnmount, onMounted, PropType, ref, watch } from 'vue';
import ResizeObserver from 'resize-observer-polyfill';
import { ColorName } from '@/lib';
import * as helper from '@/lib/helpers/slider.helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSlider',
  props: {
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 100
  },
  step: {
    type: Number,
    default: 1,
    validator: (value: number) => value > 0
  },
  modelValue: {
    type: Number,
    default: 0
  },
  disabled: {
    type: Boolean,
    default: false
  },
  vertical: {
    type: Boolean,
    default: false
  },
  dark: {
    type: Boolean,
    default: false
  },
  color: {
    type: String as PropType<ColorName>,
    default: 'primary-500'
  },
  enableShortcuts: {
    type: Boolean,
    default: true
  },
  size: {
    type: String,
    default: 'regular',
    validator: (value: string) => ['small', 'regular'].includes(value)
  }
},
  emits: ["update:modelValue", "change"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const slider = ref(null);
const root = ref(null);

const boundingClientRect = ref<Partial<DOMRect>>({
  height: 0,
  width: 0,
  x: 0,
  y: 0
});
const percent = ref(0);
const isThumbMoving = ref(false);
const arrowsEnabled = ref(false);

watch([() => props.modelValue, () => props.min, () => props.max], () => onPropertyChange());

onBeforeMount(() => {
  percent.value = helper.getPercent(props.modelValue, props.min, props.max);
  window.addEventListener('mouseup', () => (isThumbMoving.value = false));
  window.addEventListener('mousemove', onThumbMouseMove);
});
onMounted(() => {
  onResize();
  new ResizeObserver((entries) => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      onResize();
    });
  }).observe(root.value as HTMLElement);
  window.addEventListener('resize', onResize);
  window.addEventListener('click', () => (arrowsEnabled.value = false));
  if (props.enableShortcuts) {
    window.addEventListener('keydown', onKeyDown);
  }
});
onBeforeUnmount(() => {
  window.removeEventListener('mouseup', () => (isThumbMoving.value = false));
  window.removeEventListener('mousemove', onThumbMouseMove);
  window.removeEventListener('resize', onResize);
  window.removeEventListener('click', () => (arrowsEnabled.value = false));
  if (props.enableShortcuts) {
    window.removeEventListener('keydown', onKeyDown);
  }
});
const onThumbMouseDown = () => {
  isThumbMoving.value = true;
  arrowsEnabled.value = true;
};
const onThumbMouseMove = (ev: MouseEvent) => {
  if (isThumbMoving.value) {
    updateValue(ev.clientX, ev.clientY);
  }
};
const onResize = () => {
  if (slider.value) {
    boundingClientRect.value = (slider.value as HTMLElement).getBoundingClientRect();
  }
};
const onKeyDown = (ev: KeyboardEvent) => {
  if (arrowsEnabled.value && !props.disabled && props.enableShortcuts) {
    if (ev.key === 'ArrowUp' || ev.key === 'ArrowLeft') {
      const newValue = props.modelValue - props.step;
      if (newValue >= props.min) {
        emit('update:modelValue', newValue);
      }
    }
    if (ev.key === 'ArrowDown' || ev.key === 'ArrowRight') {
      const newValue = props.modelValue + props.step;
      if (newValue <= props.max) {
        emit('update:modelValue', newValue);
      }
    }
  }
};
const onPropertyChange = () => {
  if (!helper.checkProps(props.min, props.max, props.modelValue, props.step)) {
    throw new Error('The given properties are incorrect.');
  }
  percent.value = helper.getPercent(props.modelValue, props.min, props.max);
};
const updateValue = (clientX: number, clientY: number) => {
  if (!props.disabled) {
    const newValue = props.vertical
      ? helper.getValueFromPositionVertical(
        clientY,
        props.min,
        props.max,
        boundingClientRect.value.top,
        boundingClientRect.value.height
      )
      : helper.getValueFromPosition(
        clientX,
        props.min,
        props.max,
        boundingClientRect.value.left,
        boundingClientRect.value.width
      );
    if (newValue !== props.modelValue && newValue <= props.max && newValue >= props.min) {
      emit('update:modelValue', newValue);
      emit('change', newValue);
    }
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "root",
    ref: root,
    class: "select-none relative flex-grow min-h-32",
    onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
    onMousedown: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (updateValue($event.clientX, $event.clientY)), ["stop"]))
  }, [
    _createElementVNode("div", {
      ref_key: "slider",
      ref: slider,
      class: _normalizeClass(["absolute", {
        'h-full': __props.vertical,
        'w-px': __props.vertical && __props.size === 'small',
        'w-2': __props.vertical && __props.size === 'regular',
        'w-full': !__props.vertical,
        'h-px': !__props.vertical && __props.size === 'small',
        'h-2': !__props.vertical && __props.size === 'regular'
      }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["absolute", {
          'h-full': __props.vertical,
          'w-px': __props.vertical && __props.size === 'small',
          'w-1': __props.vertical && __props.size === 'regular',
          'w-full': !__props.vertical,
          'h-px': !__props.vertical && __props.size === 'small',
          'h-1': !__props.vertical && __props.size === 'regular',
          'bg-gray-800': __props.dark,
          'bg-gray-300': !__props.dark
        }]),
        style: _normalizeStyle(
          __props.vertical
            ? 'transform-origin: 100% 100%; transform: translateX(0px) scale3d(1,' + (100 - percent.value) / 100 + ', 1;'
            : 'transform-origin: 100% 100%; transform: translateX(0px) scale3d(' + (100 - percent.value) / 100 + ', 1, 1;'
        )
      }, null, 6),
      _createElementVNode("div", {
        class: _normalizeClass([[
          __props.disabled ? 'bg-gray-300' : 'bg-' + __props.color,
          __props.vertical ? (__props.size === 'small' ? 'h-full w-px' : 'h-full w-1') : __props.size === 'small' ? 'w-full h-px' : 'w-full h-1'
        ], "absolute"]),
        style: _normalizeStyle(
          __props.vertical
            ? 'transform-origin: 0 0; transform: translateX(0px) scale3d(1, ' + percent.value / 100 + ', 1)'
            : 'transform-origin: 0 0; transform: translateX(0px) scale3d(' + percent.value / 100 + ', 1, 1)'
        )
      }, null, 6)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute", __props.vertical ? 'h-full' : 'w-full']),
      style: _normalizeStyle(
        __props.vertical
          ? 'transform: translateY(-' + (100 - percent.value) + '%)'
          : 'transform: translateX(-' + (100 - percent.value) + '%)'
      )
    }, [
      _createElementVNode("button", {
        type: "button",
        style: _normalizeStyle(__props.size === 'small' ? 'right: -5px; bottom: -5px;' : 'right: -10px; bottom: -10px;'),
        class: _normalizeClass(["absolute rounded-full cursor-pointer focus:outline-none", [__props.disabled ? 'bg-gray-300' : 'cursor-grab bg-' + __props.color, __props.size === 'small' ? 'h-2 w-2' : 'h-4 w-4']]),
        onMousedown: _cache[0] || (_cache[0] = ($event: any) => (onThumbMouseDown())),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (onThumbMouseDown()))
      }, null, 38)
    ], 6),
    _renderSlot(_ctx.$slots, "default")
  ], 544))
}
}

})