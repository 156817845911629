import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_2 = { class: "text-base font-semibold text-gray-900" }
const _hoisted_3 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_4 = {
  key: 0,
  class: "inline-block mr-4 font-semibold text-xs"
}
const _hoisted_5 = {
  key: 0,
  class: "text-gray-500"
}
const _hoisted_6 = {
  key: 1,
  class: "text-gray-500"
}
const _hoisted_7 = {
  key: 2,
  class: "text-success-500"
}

import { SavingStatus } from '@/lib/constants';
import BrandLogo from '@/lib/components/Branding/BrandLogo.vue';
import { onBeforeUnmount, onMounted, Ref, ref } from 'vue';
import ResizeObserver from 'resize-observer-polyfill';


export default /*@__PURE__*/_defineComponent({
  __name: 'WorkflowFooter',
  props: {
  name: {
    type: String,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  status: {
    type: Number,
    default: SavingStatus.UNSET
  }
},
  emits: ["tryAgain"],
  setup(__props) {




const middleFooter = ref(null);
const footer = ref(null);
const middleFooterLeft = ref(0);
const observer: Ref<ResizeObserver | null> = ref(null);

onMounted(() => {
  if (middleFooter.value) {
    observer.value = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        const footerCenter = (footer.value?.offsetWidth ?? 0) / 2;
        const contentCenter = (middleFooter.value?.offsetWidth ?? 0) / 2;
        middleFooterLeft.value = footerCenter - contentCenter;
      });
    });
    observer.value.observe(middleFooter.value);
    observer.value.observe(footer.value);
  }
});

onBeforeUnmount(() => {
  observer.value?.disconnect();
});

return (_ctx: any,_cache: any) => {
  const _component_portal_target = _resolveComponent("portal-target")!

  return (_openBlock(), _createElementBlock("footer", {
    ref_key: "footer",
    ref: footer,
    class: "flex items-center relative justify-between h-16 p-3 bg-white border-t border-gray-300"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{ invisible: __props.loading }, "flex items-center space-x-4"])
    }, [
      _createVNode(BrandLogo, { "use-default": false }),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('platform.form.workflow')), 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString(__props.name), 1)
      ])
    ], 2),
    _createElementVNode("div", {
      class: "absolute",
      style: _normalizeStyle(`left: ${middleFooterLeft.value}px; height: ${footer.value?.offsetHeight}`)
    }, [
      _createElementVNode("div", {
        ref_key: "middleFooter",
        ref: middleFooter,
        class: "flex items-center justify-center h-full"
      }, [
        _createVNode(_component_portal_target, { name: "workflow-footer-middle" })
      ], 512)
    ], 4),
    _createElementVNode("div", {
      class: _normalizeClass({ invisible: __props.loading })
    }, [
      _createElementVNode("div", _hoisted_3, [
        (__props.status && __props.status !== _unref(SavingStatus).UNSET)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (__props.status === _unref(SavingStatus).SAVING)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('platform.form.saving-changes')), 1))
                : (__props.status === _unref(SavingStatus).UNSAVED || __props.status === _unref(SavingStatus).ERROR)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createTextVNode(_toDisplayString(_ctx.$t('platform.form.unsaved-changes')) + " ", 1),
                      (__props.status === _unref(SavingStatus).ERROR)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: "text-primary-500 underline",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('tryAgain')))
                          }, _toDisplayString(_ctx.$t('platform.error.try-again')), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('platform.form.all-changes-saved')), 1))
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "actions")
      ])
    ], 2)
  ], 512))
}
}

})