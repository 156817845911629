import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withKeys as _withKeys, mergeProps as _mergeProps, withDirectives as _withDirectives, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row items-center justify-between mb-3 space-x-2"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "flex flex-row items-center space-x-3" }
const _hoisted_4 = ["id", "disabled", "readonly", "value", "placeholder"]

import { onBeforeMount, ref, useId } from 'vue';
import { BaseIcon } from '@/lib/components/Icon';
import { ClearButton } from '@/lib/components/Button';
import { InputTextHelper } from '@/lib/components/Helper';
import debounce from 'lodash-es/debounce';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseTextInput',
  props: {
  count: {
    type: Number,
    default: null
  },
  modelValue: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  inputClass: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: null
  },
  iconPosition: {
    type: String,
    default: 'left'
  },
  iconColor: {
    type: String,
    default: 'gray-500'
  },
  iconStroke: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  hoverBorder: {
    type: Boolean,
    default: true
  },
  readonly: {
    type: Boolean,
    default: false
  },
  clear: {
    type: Boolean,
    default: false
  },
  successMessage: {
    type: String,
    default: null
  },
  error: {
    type: String,
    default: null
  },
  loading: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'regular',
    validator: (value: string) => ['small', 'regular'].includes(value)
  },
  iconHeight: {
    type: String,
    default: '20px'
  },
  symbol: {
    type: String,
    default: null
  },
  fullHeight: {
    type: Boolean,
    default: false
  },
  decimalPlaces: {
    type: Number,
    default: 0
  },
  isOptional: {
    type: Boolean,
    default: false
  }
},
  emits: ["icon-click", "update:modelValue", "keydownEvent", "change", "clear"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const inputRef = ref(null);
const id = useId();
const focused = ref(false);
const changeDebounce = debounce(() => emit('change'), 100);

onBeforeMount(() => {
  if (props.modelValue?.length) {
    exposed.updateValue();
  }
});
const focus = () => {
  if (!props.disabled && !focused.value) {
    focused.value = true;
    inputRef.value.focus();
  }
};
const blur = () => {
  if (!props.disabled) {
    focused.value = false;
    exposed.updateValue();
  }
};
const updateValue = () => {
  const isNumber = /^-?\d+(\.)?\d*$/.test(props.modelValue);
  if (props.decimalPlaces > 0 && isNumber) {
    const value = parseFloat(props.modelValue)
      .toFixed(props.decimalPlaces + 1)
      .slice(0, -1);
    if (props.modelValue !== value) {
      emit('update:modelValue', value);
      changeDebounce();
    }
  }
};

const onClear = () => {
  emit('update:modelValue', '');
  changeDebounce();
  emit('clear');
};
const exposed = {
  updateValue
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (__props.label?.length || _ctx.$slots.action)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _unref(id),
            class: _normalizeClass(["inline-block text-base font-medium leading-tight", { 'text-transparent bg-gray-200 rounded-lg': __props.loading }])
          }, _toDisplayString(__props.label), 11, _hoisted_2),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.$slots.action)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([__props.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-primary-500 cursor-pointer', "flex items-center text-sm font-medium text-right"])
                }, [
                  _renderSlot(_ctx.$slots, "action")
                ], 2))
              : _createCommentVNode("", true),
            (__props.isOptional)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass([__props.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500', "text-xs font-medium cursor-default capitalize"])
                }, _toDisplayString(_ctx.$t('platform.common.optional')), 3))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([{
        'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none':
          __props.error && __props.error.length,
        'h-full': __props.fullHeight,
        'hover:border-gray-500': __props.hoverBorder,
        'bg-gray-50 border-transparent': __props.disabled && __props.hoverBorder,
        'bg-gray-50 border-gray-200': __props.readonly && __props.hoverBorder,
        'bg-gray-50': (__props.disabled || __props.readonly) && !__props.hoverBorder,
        'text-gray-600': __props.disabled,
        'border-primary-500': focused.value && !__props.disabled,
        'h-10': __props.size === 'small',
        'h-12': __props.size === 'regular',
        inputClass: __props.inputClass
      }, "flex flex-row bg-white text-gray-900 shadow items-center w-full overflow-hidden transition-all duration-100 ease-in-out border rounded focus:shadow-md"])
    }, [
      _renderSlot(_ctx.$slots, "left"),
      (__props.icon?.length && !__props.loading)
        ? (_openBlock(), _createBlock(_unref(BaseIcon), {
            key: 0,
            class: _normalizeClass(['text-' + __props.iconColor, __props.iconPosition === 'right' ? 'order-1' : '']),
            position: __props.iconPosition === 'left' ? 'right' : 'left',
            name: __props.icon,
            height: __props.iconHeight,
            stroke: __props.iconStroke,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('icon-click')))
          }, null, 8, ["class", "position", "name", "height", "stroke"]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", _mergeProps({
        id: _unref(id),
        ref_key: "inputRef",
        ref: inputRef,
        disabled: __props.disabled,
        readonly: __props.readonly,
        autocomplete: "chrome-off",
        class: [[
          __props.disabled || __props.readonly ? 'bg-gray-50 border-gray-200' : 'bg-white',
          __props.disabled ? 'text-gray-600 cursor-default' : 'text-gray-900',
          __props.size === 'small' ? 'text-sm h-10' : 'h-12',
          __props.icon ? 'px-3' : 'px-4'
        ], "w-full border-none focus:outline-none"]
      }, _ctx.$attrs, {
        value: __props.modelValue,
        placeholder: __props.readonly ? '' : __props.placeholder,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).value))),
        onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('keydownEvent')), ["enter"])),
        onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(changeDebounce) && _unref(changeDebounce)(...args))),
        onFocus: focus,
        onBlur: blur
      }), null, 16, _hoisted_4), [
        [_vShow, !__props.loading]
      ]),
      (__props.count && !__props.loading)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(["flex mr-2 h-full items-center text-sm font-medium", __props.modelValue.length > __props.count ? 'text-danger-800' : 'text-gray-500'])
          }, _toDisplayString(__props.modelValue.length) + "/" + _toDisplayString(__props.count), 3))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "use-default"),
      (__props.clear && !__props.loading && __props.modelValue.length)
        ? (_openBlock(), _createBlock(_unref(ClearButton), {
            key: 2,
            class: "mr-4",
            onClick: _withModifiers(onClear, ["prevent"])
          }))
        : _createCommentVNode("", true),
      (__props.symbol && !__props.loading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(["inline-flex h-full justify-center items-center bg-white text-base font-small text-center border-gray-200 border-l rounded rounded-l-none px-4", { 'bg-gray-50 cursor-default': __props.disabled || __props.readonly, 'text-gray-600': __props.disabled }])
          }, _toDisplayString(__props.symbol), 3))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_unref(InputTextHelper), {
      error: __props.error,
      loading: __props.loading,
      description: __props.successMessage
    }, null, 8, ["error", "loading", "description"])
  ]))
}
}

})