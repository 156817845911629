import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"box-shadow":"inset 0 0 0 1000px rgba(0, 0, 0, 0.5)"},
  class: "absolute inset-0 z-10 flex items-center justify-center"
}

import { computed, PropType, ref } from 'vue';
import dayjs from 'dayjs';
import { getSeriesDescription } from '@/helpers/series.helper';
import { ImageSeries } from '@/models';
import { BaseButton } from '@/lib/components/Button';
import { LoadingIcon } from '@/lib/components/Loading';
import { ZephyrImageService } from '@/services/api';
import { i18n } from '@/i18n/i18n';
import { MeniconImageDetails, MeniconImageDetailsScore } from '@/custom/menicon/models';
import MedKitBadge from '@/lib/components/Badge/MedKitBadge.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'StudyGalleryItem',
  props: {
  imageSeries: {
    type: Object as PropType<ImageSeries>,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  score: {
    type: Object as PropType<MeniconImageDetails['scoringDetails']>,
    default: null
  },
  scanHoverMessage: {
    type: String,
    default() {
      return i18n.global.t('platform.scans.open-in-viewer');
    }
  },
  hoverClass: {
    type: String,
    default: 'shadow-lg'
  }
},
  setup(__props) {

const props = __props;

const hover = ref(false);
const zephyrImageService = new ZephyrImageService();

const description = computed(() => getSeriesDescription(props.imageSeries));
const thumbnailUri = computed(() => {
  const image = props.imageSeries.images.find((image) => image.thumbnailUri);
  if (image) {
    return image.thumbnailUri;
  } else if (props.imageSeries.images.length) {
    const route = zephyrImageService.getEndpointUrl();
    return `${route}/${props.imageSeries.images[0].id}?lossy=1`;
  }
  return null;
});
const formatDate = (date: string) => dayjs(date).format('MMM D, YYYY h:mma');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center justify-center cursor-pointer bg-cover bg-no-repeat bg-center bg-gray-900 rounded-lg overflow-hidden text-white relative", hover.value ? __props.hoverClass : '']),
    style: _normalizeStyle(`background-image: url('${thumbnailUri.value}');${
      hover.value ? 'box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5)' : ''
    }`),
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (hover.value = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (hover.value = false))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-col absolute bottom-0 left-0 z-20 m-6 text-xs font-regular transition ease-in duration-75 space-y-1", hover.value ? 'opacity-25' : 'opacity-1'])
    }, [
      (__props.score)
        ? (_openBlock(), _createBlock(MedKitBadge, {
            key: 0,
            label: __props.score.topographyDataScore === _unref(MeniconImageDetailsScore).UNKNOWN ?
          _ctx.$t('custom.menicon.scans.score.Unknown') : `${__props.score.scorePercentage}%`,
            kind: "stable",
            size: "tiny"
          }, null, 8, ["label"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString(description.value), 1),
      _createElementVNode("span", null, _toDisplayString(formatDate(__props.imageSeries.seriesDate)), 1)
    ], 2),
    _createVNode(_unref(BaseButton), {
      class: _normalizeClass(["text-white bg-transparent text-sm font-medium z-20 transition duration-150", hover.value ? 'opacity-1 top-0' : 'opacity-0 top-10']),
      "left-icon": "add",
      color: "muted",
      size: "small"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(__props.scanHoverMessage), 1)
      ]),
      _: 1
    }, 8, ["class"]),
    _cache[2] || (_cache[2] = _createElementVNode("div", {
      class: "absolute bottom-0 z-10 h-full w-full",
      style: {"background":"linear-gradient(0, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 75%)"}
    }, null, -1)),
    (__props.loading && !hover.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(LoadingIcon))
        ]))
      : _createCommentVNode("", true)
  ], 38))
}
}

})