import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "flex flex-col h-full w-full",
  style: {"background":"rgba(0, 0, 0, 0.8)"}
}
const _hoisted_2 = { class: "flex flex-row justify-between h-16 bg-gray-900 border-b border-gray-800 py-3" }
const _hoisted_3 = { class: "border-l border-gray-800 flex justify-center items-center text-base font-semibold text-gray-500 p-3" }
const _hoisted_4 = { class: "text-gray-300" }
const _hoisted_5 = { class: "bg-gray-900 w-46 p-2" }
const _hoisted_6 = ["onClick"]

import { computed, onMounted, PropType, ref } from 'vue';
import dayjs from 'dayjs';
import uniq from 'lodash-es/uniq';
import { BaseIcon } from '@/lib/components/Icon';
import { NavDropdown } from '@/lib/components/Navigation';
import { ImageSeries, Study } from '@/models';
import StudyGallery from './StudyGallery.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SeriesSelector',
  props: {
  excludeSeries: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  studies: {
    type: Array as PropType<Study[]>,
    default: () => []
  },
  currentStudyId: {
    type: String,
    default: ''
  }
},
  emits: ["close", "select"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const year = ref(-1);

const currentStudyYear = computed(() => {
  const study = props.studies.find((study) => study.id === props.currentStudyId);
  return study ? dayjs(study.studyTime).year() : years.value[0];
});
const filteredStudies = computed(() => props.studies
  .map((study) => ({
    ...study,
    series: study.series.filter((s) => !props.excludeSeries.includes(s.id))
  }))
  .filter((study) => study.series.length && study.series.find((series) => series.images.length)));
const studiesOfSelectedYear = computed(() => filteredStudies.value.filter((study) =>
  dayjs(study.studyTime).year() === year.value)
);
const years = computed<number[]>(() => uniq(filteredStudies.value
  .map((study) => dayjs(study.studyTime).year()).sort((a, b) => b - a))
);

onMounted(() => {
  if (years.value.length) {
    year.value = currentStudyYear.value;
  }
});
const selectSeries = (value: { studyId: string; seriesId: string }) => {
  const study = props.studies.find((study) => study.id === value.studyId);
  if (study) {
    const series = study.series.find((s) => s.id === value.seriesId);
    emit('select', series);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        class: "flex items-center justify-center w-14 focus:outline-none group text-white border-r border-gray-800",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_unref(BaseIcon), {
          name: "close",
          class: "text-xl stroke-1.5"
        })
      ]),
      (years.value.length)
        ? (_openBlock(), _createBlock(_unref(NavDropdown), {
            key: 0,
            dark: "",
            "menu-class": "bg-gray-900 mr-2",
            "button-class": "pr-6"
          }, {
            button: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _cache[2] || (_cache[2] = _createTextVNode(" Year: ")),
                _createElementVNode("span", _hoisted_4, _toDisplayString(year.value), 1)
              ])
            ]),
            menu: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(years.value, (y) => {
                  return (_openBlock(), _createElementBlock("button", {
                    key: y,
                    type: "button",
                    class: "flex w-46 items-center w-full px-4 py-3 font-semibold text-left rounded outline-none text-white hover:bg-gray-500 focus:bg-gray-500",
                    onClick: ($event: any) => (year.value = y)
                  }, _toDisplayString(y), 9, _hoisted_6))
                }), 128))
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    (filteredStudies.value.length)
      ? (_openBlock(), _createBlock(StudyGallery, {
          key: 0,
          class: "overflow-y-scroll p-12 text-white h-full",
          studies: studiesOfSelectedYear.value,
          "scan-hover-message": _ctx.$t('platform.scans.select-scan'),
          dark: "",
          onOpen: _cache[1] || (_cache[1] = ($event: any) => (selectSeries($event)))
        }, null, 8, ["studies", "scan-hover-message"]))
      : _createCommentVNode("", true)
  ]))
}
}

})