import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center" }
const _hoisted_2 = {
  key: 0,
  "data-dusk": "patient-details-initials",
  class: "flex items-center justify-center w-10 h-10 mr-4 text-base font-semibold rounded-full bg-primary-200 text-primary-500"
}
const _hoisted_3 = { class: "flex flex-col overflow-hidden" }
const _hoisted_4 = {
  key: 0,
  class: "mt-5 -mb-4 text-sm font-medium"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["data-dusk"]
const _hoisted_7 = ["data-dusk"]

import { computed, PropType } from 'vue';
import { capitalize } from '@/lib/helpers/string.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'PatientDetailsCard',
  props: {
  firstName: {
    type: String,
    default: ''
  },
  lastName: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  },
  dark: {
    type: Boolean,
    default: false
  },
  showInitials: {
    type: Boolean,
    default: true
  },
  infos: {
    type: Object as PropType<Record<string, string[] | string>>,
    default: () => ({})
  }
},
  setup(__props) {

const props = __props;

const name = computed(() => `${props.lastName.length ? `${props.lastName}, ` : ''}${props.firstName}`);
const initials = computed(() => `${props.lastName.charAt(0)}${props.firstName.charAt(0)}`.toUpperCase());
const formatInfoKey = (key: string) => capitalize(key);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["p-5 rounded-lg", __props.dark ? 'bg-gray-900 border' : 'bg-white border'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (__props.showInitials)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(initials.value), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (name.value.length)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              "data-dusk": "patient-details-name",
              class: _normalizeClass(["text-base font-bold", __props.dark ? 'text-white' : 'text-gray-900'])
            }, _toDisplayString(name.value), 3))
          : _createCommentVNode("", true),
        (__props.id)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              "data-dusk": "patient-details-mrn",
              class: _normalizeClass(["text-sm uppercase font-medium break-words", __props.dark ? 'text-gray-500' : 'text-gray-400'])
            }, "MRN: " + _toDisplayString(__props.id), 3))
          : _createCommentVNode("", true)
      ])
    ]),
    (__props.infos && Object.keys(__props.infos).length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.infos, (info, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: _normalizeClass([__props.dark ? 'border-gray-800' : 'border-gray-100', "grid grid-cols-2 py-3 border-t"])
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(["break-words", __props.dark ? 'text-gray-500' : 'text-gray-400'])
              }, _toDisplayString(formatInfoKey(key)), 3),
              (Array.isArray(info))
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(info, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        "data-dusk": `patient-details-${key}-${index}`,
                        class: _normalizeClass(["break-words w-full", __props.dark ? 'text-white' : 'text-gray-900'])
                      }, _toDisplayString(item), 11, _hoisted_6))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    "data-dusk": `patient-details-${key}`,
                    class: _normalizeClass(["break-words w-full", __props.dark ? 'text-white' : 'text-gray-900'])
                  }, _toDisplayString(info), 11, _hoisted_7))
            ], 2))
          }), 128)),
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})