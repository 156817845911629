import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "sticky bottom-0 bg-white" }

import { computed, onBeforeUnmount, onMounted, PropType, ref, watch } from 'vue';
import { initPopper, Instance as PopperInstance, updatePopper } from '@/lib/helpers/popover.helper';

const style = '';

export default /*@__PURE__*/_defineComponent({
  __name: 'BasePopover',
  props: {
  position: {
    type: String as PropType<'right' | 'left'>,
    default: 'right'
  },
  width: {
    type: String,
    default: 'unset'
  },
  dark: {
    type: Boolean,
    default: false
  },
  wrap: {
    type: Boolean,
    default: false
  },
  closeOnSelection: {
    type: Boolean,
    default: true
  },
  hasPadding: {
    type: Boolean,
    default: true
  }
},
  setup(__props, { expose: __expose }) {

const props = __props;

const main = ref(null);
const popper = ref<PopperInstance | null>(null);
const button = ref(null);
const defaultSlot = ref(null);
const menu = ref(null);

const isOpen = ref(false);
const maxHeight = ref<null | number>(null);

const menuStyle = computed(() => `width: ${props.wrap ? 'max-content' : props.width};${maxHeight.value ? `max-height:${maxHeight.value}px` : ''}`);

onMounted(() => {
  if (main.value && menu.value) {
    updateWatchPopper();
  }
  popoverClickListener();
});
onBeforeUnmount(() => {
  window.removeEventListener('click', close);
  window.removeEventListener('resize', setMenuMaxHeight);
});
const updateWatchPopper = () => {
  watch(() => props.position, async (newVal) => {
    if (popper.value) {
      await updatePopper(popper.value, newVal);
    }
  });
  watch(isOpen, async () => {
    if (!popper.value) {
      popper.value = initPopper(main.value as HTMLElement, menu.value as HTMLElement, props.position);
      setMenuMaxHeight();
      window.addEventListener('resize', () => setMenuMaxHeight());
    }
    await updatePopper(popper.value);
  });
};
const setMenuMaxHeight = () => {
  const parentEl = (defaultSlot.value || button.value) as HTMLElement;
  const parentElRect = parentEl?.getBoundingClientRect();
  if (!parentElRect) {
    return;
  }
  const heightToSet = window.innerHeight - parentElRect.bottom - 40;
  maxHeight.value = heightToSet > 180 ? heightToSet : 180;
};
const popoverClickListener = () => {
  window.addEventListener('click', (e) => {
    const element = e.target as HTMLElement;
    const menuEl = menu.value as HTMLElement;
    if (isOpen.value) {
      if (props.closeOnSelection || (!props.closeOnSelection && !menuEl?.contains(element))) {
        close();
      }
    } else if (main.value?.contains(element)) {
      open();
    }
  });
};
const close = () => {
  isOpen.value = false;
};
const open = () => {
  isOpen.value = true;
};
__expose({
  isOpen,
  open
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "main",
    ref: main,
    class: "relative inline-flex focus:outline-none",
    onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (close()), ["esc"]))
  }, [
    (_ctx.$slots.button)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref_key: "button",
          ref: button,
          role: "button",
          class: "z-10 w-full focus:outline-none"
        }, [
          _renderSlot(_ctx.$slots, "button")
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          ref_key: "defaultSlot",
          ref: defaultSlot
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 512))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute left-0 flex flex-col w-full h-full transition duration-100 ease-in-out", isOpen.value ? 'z-50 opacity-100 visible' : 'z-0 opacity-0 w-0 h-0 invisible']),
      style: style
    }, [
      _createElementVNode("div", {
        ref_key: "menu",
        ref: menu,
        "data-dusk": "popover-menu",
        style: _normalizeStyle(menuStyle.value),
        class: _normalizeClass(["absolute top-0 flex overflow-auto scrollbar-dark flex-col border rounded-lg shadow-lg", [
          __props.wrap ? '' : 'whitespace-no-wrap',
          __props.dark ? 'bg-gray-900 border-gray-900' : 'bg-white border-gray-300',
          isOpen.value ? 'visible opacity-100 z-50' : 'invisible opacity-0',
          __props.position === 'left' ? 'right-0' : 'left-0',
          { 'p-3': __props.hasPadding },
        ]])
      }, [
        _renderSlot(_ctx.$slots, "menu"),
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "footer")
        ])
      ], 6)
    ], 2)
  ], 544))
}
}

})