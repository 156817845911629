import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-between items-center" }
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = { class: "text-gray-900 text-lg font-medium" }

import { BaseIcon } from '@/lib/components/Icon';
import { BaseButton } from '@/lib/components/Button';


export default /*@__PURE__*/_defineComponent({
  __name: 'CardHeader',
  props: {
  title: {
    type: String,
    default: ''
  }
},
  emits: ["back"],
  setup(__props, { emit: __emit }) {


const emit = __emit;
const goBack = () => {
  emit('back');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(BaseButton), {
        class: "mr-4 w-8 h-8",
        size: "small",
        color: "ghost",
        onClick: goBack
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseIcon), {
            name: "arrow-left",
            class: "stroke-1.5"
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, _toDisplayString(__props.title), 1)
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})