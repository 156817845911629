import { IOption } from '@/lib';
import { i18n } from '@/i18n/i18n';
import { FormAnswer, FormAnswerValue, FormItem, ImageSeries, MedicationPrescriptionAnswerValue, Study } from '@/models';
import { defaultRoutes, doseUnitOptions, frequencyOptions } from '@/custom/medication/constants';
import {
  DifferenceMapsPattern,
  Encounter,
  EncounterTypeName,
  Journey,
  Laterality,
  LensRecommendation,
  LensSummarySection,
  Order,
  OrderItem,
  PrescriptionParams,
  VaUnit
} from '@/custom/menicon/models';
import dayjs from 'dayjs';
import { getDifferenceMapsAssessment } from '@/custom/menicon/helpers/difference-maps.helper';
import {
  COMPRESSION_FACTOR_PARAMETER_NAME,
  encounterWithDominantEyeQuestion,
  K_VALUES_PRECISION,
  lensParametersUnits,
  MATERIAL_PARAMETER_NAME,
  REORDER_REASONS,
  reorderReasonsOptions,
  TRIAL_LENS_PARAMETER_NAME
} from '@/custom/menicon/constants';
import { isSet } from '@/custom/menicon/helpers/validators.helper';
import {
  getPrescriptionOrderInformation,
  getVaOptions,
  isInitialMeasurement
} from '@/custom/menicon/helpers/encounter.helper';
import { isRepeaterAnswer } from '@/helpers/smart-form.helper';
import { localisedMask } from '@/helpers/date.helper';

export const convertMedicationAnswerToTableAnswer = (
  medication: FormItem,
  value: MedicationPrescriptionAnswerValue[],
  locale = i18n.global.locale.value
): EncounterTableSummaryData['value'] => {
  const routes = defaultRoutes(locale);
  const frequencies = frequencyOptions(locale);
  const doseUnits = doseUnitOptions(locale);
  const rows: string[][] = value
    .filter((medication) => medication?.medication?.name?.value)
    .map(({ medication, dose, prn, frequency, route, doseUnit }: MedicationPrescriptionAnswerValue) => [
      medication?.name?.value,
      routes.find(({ value }) => value === route?.value)?.label ?? i18n.global.t('platform.common.not-answered', [], { locale }),
      dose?.value ? `${dose.value}${doseUnits.find(({ value }) => value === doseUnit?.value)?.label ?? ''}` : i18n.global.t('platform.common.not-answered', [], { locale }),
      frequencies.find(({ value }) => value === frequency?.value)?.label ?? i18n.global.t('platform.common.not-answered', [], { locale }),
      formatBooleanAnswer(prn?.value ?? false, locale)
    ]);
  const columns: string[] = [
    i18n.global.t('platform.medication.medication', [], { locale }),
    i18n.global.t('platform.medication.route', [], { locale }),
    i18n.global.t('platform.medication.dose', [], { locale }),
    i18n.global.t('platform.medication.frequency', [], { locale }),
    i18n.global.t('platform.medication.prn', [], { locale })
  ];
  return { columns, rows, border: true };
};

export const convertRepeaterAnswerToTableAnswer = (
  repeater: FormItem,
  value: FormAnswer[],
  locale = i18n.global.locale.value
): EncounterTableSummaryData['value'] => {
  const columns = repeater.items?.map(({ label }) => label) || [];
  const rows = [];
  for (const [key, line] of Object.entries(value)) {
    const colIds = repeater.items?.map(({ id }) => id) || [];
    rows[key] = colIds.map((colId) => {
      const item = repeater.items?.find(({ id }) => id === colId);
      return formatFormSchemaAnswer(line[colId], item, locale);
    });
  }

  return { columns, rows, border: true };
};

export const formatFormSchemaAnswer =
  (answer: FormAnswerValue, item: FormItem, locale = i18n.global.locale.value): any => {
    if (typeof answer === 'undefined') {
      return i18n.global.t('platform.common.not-answered', [], { locale });
    }

    const localAnswer = answer.value;

    if (item.type === 'repeater' && isRepeaterAnswer(localAnswer)) {
      return convertRepeaterAnswerToTableAnswer(item, localAnswer as FormAnswer[], locale);
    }

    if (item.component === 'custom/medication/MedicationQuestion' && isRepeaterAnswer(localAnswer)) {
      return convertMedicationAnswerToTableAnswer(item, localAnswer as MedicationPrescriptionAnswerValue[], locale);
    }

    if (item.type === 'slider' && item.props && typeof localAnswer === 'number') {
      return item.props.options[localAnswer];
    }

    if (Array.isArray(localAnswer)) {
      if (item.type === 'checkbox' && item.props) {
        return localAnswer
          .map((localAnswer) => {
            const options = item.props?.options || [];
            const option = options && options.find((opt: IOption) => opt.value === localAnswer);
            return option ? option.label : null;
          })
          .filter(Boolean)
          .join(', ');
      } else if (item.type === 'file') {
        return i18n.global.t('platform.file-upload.count-message', [localAnswer.length], { locale });
      }
      return localAnswer.join(', ');
    }

    if (typeof localAnswer === 'boolean') {
      return formatBooleanAnswer(localAnswer, locale);
    }

    if (item.props?.options && Array.isArray(item.props.options) &&
      !item.props.options.find(({ label, value }) => !label || !value)) {
      const option = item.props.options.find((opt: IOption) => opt.value === localAnswer);
      if (option) {
        return option.label;
      }
    }

    if (item.type === 'date' && dayjs(localAnswer).isValid()) {
      return dayjs(localAnswer).format(localisedMask(locale, item.props?.mode || 'date'));
    }

    if (item.props?.symbol && typeof localAnswer === 'string') {
      return `${localAnswer} ${item.props.symbol}`;
    }

    return localAnswer;
  };

export const shouldDisplayFormSchemaQuestion = (item: FormItem): boolean => (
  (item.label || item.props?.label) &&
  (!['group', 'component', 'repeater', 'spacer', 'list-item', 'alert'].includes(item.type) ||
    (item.type === 'group' && item.layout === 'grid') ||
    item.type === 'repeater' ||
    (item.type === 'component' && item.component === 'custom/medication/MedicationQuestion'))
);

export enum EncounterSummaryDataType {
  GRID = 'grid',
  TABLE = 'table',
  HEADING = 'heading',
  ALERT = 'alert',
  STRING = 'string'
}

export enum EncounterSummaryDataDisplay {
  ROW = 'row',
  COLUMN = 'column'
}

export interface EncounterQuestionSummaryData {
  type: EncounterSummaryDataType;
  label: string | string[];
  description?: string;
  dataDusk?: string;
  laterality?: Laterality | 'both' | 'spacer';
  value?: any;
  action?: {
    emit: string; // name of the emit
    args: any; // args of the emit
    label: string; // label of the button
  };
  display?: EncounterSummaryDataDisplay;
  class?: {
    line?: string;
    question?: string;
  };
}

export interface EncounterTableSummaryDataRow {
  value: string;
  class: string;
}

export interface EncounterTableSummaryData extends EncounterQuestionSummaryData {
  label: '';
  type: EncounterSummaryDataType.TABLE;
  description?: string;
  laterality?: Laterality | 'both' | 'spacer';
  action?: {
    emit: string; // name of the emit
    args: any; // args of the emit
    label: string; // label of the button
  };
  value: {
    columns: Array<string | {
      icon: string;
      value: string;
      class: string;
    }>;
    rows: Array<string | EncounterTableSummaryDataRow>[];
    border?: boolean;
  };
}

export interface EncounterHeadingSummaryData extends EncounterQuestionSummaryData {
  label: string;
  type: EncounterSummaryDataType.HEADING;
}

export interface EncounterAlertSummaryData extends EncounterQuestionSummaryData {
  label: string | string[];
  type: EncounterSummaryDataType.ALERT;
}

export interface EncounterStringSummaryData extends EncounterQuestionSummaryData {
  label: string;
  type: EncounterSummaryDataType.STRING;
  display: EncounterSummaryDataDisplay;
  value: string;
}

export interface EncounterGridSummaryItem {
  icon?: string;
  value?: string;
  description?: string;
}

export interface EncounterGridSummaryData extends EncounterQuestionSummaryData {
  label: string;
  type: EncounterSummaryDataType.GRID;
  display: EncounterSummaryDataDisplay;
  value: EncounterGridSummaryItem[];
}

export interface EncounterSummaryData {
  vaAndRefraction: EncounterQuestionSummaryData[];
  distanceVa: EncounterQuestionSummaryData[];
  measurements: EncounterQuestionSummaryData[];
  lensOrdering: EncounterQuestionSummaryData[];
  slitLamp: EncounterQuestionSummaryData[];
  preFittingSlitLamp: EncounterQuestionSummaryData[];
  differenceMaps: EncounterQuestionSummaryData[];
  reasonForOrder: EncounterQuestionSummaryData[];
  lensRecommendation: EncounterQuestionSummaryData[];
  lensFittingAdvice: EncounterQuestionSummaryData[];
}

export const getEncounterSummaryData = (
  encounter: Encounter,
  journey: Journey,
  scans: Record<Laterality, ImageSeries | null> = { right: null, left: null },
  order: Order | null = null,
  locale: string = i18n.global.locale.value
): EncounterSummaryData => ({
  vaAndRefraction: [
    ...(isInitialMeasurement(encounter) ? [
      {
        label: i18n.global.t('custom.menicon.lens-ordering.select-required-eyes', [], { locale }),
        type: EncounterSummaryDataType.STRING,
        value: encounter.laterality
          ? i18n.global.t(`platform.eyes.${encounter.laterality}-eye`, [], { locale })
          : i18n.global.t('platform.eyes.both-eyes', [], { locale })
      } as EncounterStringSummaryData
    ] : []),
    getVARefractionData(encounter, Laterality.right, locale),
    getVARefractionData(encounter, Laterality.left, locale),
    ...(encounter.type === EncounterTypeName.QUICK_INITIAL_MEASUREMENT ?
      [] : [getVARefractionData(encounter, 'both', locale)]),
    ...(encounter.type === EncounterTypeName.INITIAL_MEASUREMENT ||
    encounter.type === EncounterTypeName.BLOOM_DAY_6_OR_12_MONTHS_PROGRESS_ASSESSMENT ||
    encounter.type === EncounterTypeName.BLOOM_NIGHT_6_OR_12_MONTHS_ASSESSMENT
      ? [
        {
          label: i18n.global.t('custom.menicon.encounter.va-refraction.cyclopegic', [], { locale }),
          type: EncounterSummaryDataType.STRING,
          value: formatBooleanAnswer(encounter.is_cyclopegic_refraction, locale)
        } as EncounterStringSummaryData
      ]
      : [])
  ],
  distanceVa: [
    ...(encounter.type === EncounterTypeName.QUICK_LENS_EFFICACY_ASSESSMENT ? [] : [{
      label: i18n.global.t('platform.eyes.distance-va', [], { locale }),
      type: EncounterSummaryDataType.GRID,
      display: EncounterSummaryDataDisplay.ROW,
      value: [
        {
          icon: 'right-eye',
          value: getVaLabel(encounter.va_unit, encounter.r_distance_visual_acuity, locale) || i18n.global.t('platform.common.not-answered', [], { locale })
        },
        {
          icon: 'left-eye',
          value: getVaLabel(encounter.va_unit, encounter.l_distance_visual_acuity, locale) || i18n.global.t('platform.common.not-answered', [], { locale })
        },
        {
          icon: 'both-eyes',
          value:
            getVaLabel(encounter.va_unit, encounter.combined_distance_visual_acuity, locale) || i18n.global.t('platform.common.not-answered', [], { locale })
        }
      ]
    } as EncounterGridSummaryData]),
    ...(encounter.type === EncounterTypeName.BLOOM_DAY_6_OR_12_MONTHS_PROGRESS_ASSESSMENT
      ? [
        {
          label: i18n.global.t(
            'custom.menicon.encounter.bloom-day-6-or-12-assessment.va-satisfactory',
            [],
            { locale }
          ),
          type: EncounterSummaryDataType.GRID,
          display: EncounterSummaryDataDisplay.ROW,
          value: [
            {
              icon: 'right-eye',
              value: formatBooleanAnswer(encounter.r_is_va_satisfactory, locale)
            },
            {
              icon: 'left-eye',
              value: formatBooleanAnswer(encounter.l_is_va_satisfactory, locale)
            }
          ]
        } as EncounterGridSummaryData
      ]
      : [
        {
          label: i18n.global.t('custom.menicon.encounter.va.satisfactory', [], { locale }),
          type: EncounterSummaryDataType.STRING,
          display: EncounterSummaryDataDisplay.ROW,
          value: formatBooleanAnswer(encounter.is_va_satisfactory, locale)
        } as EncounterStringSummaryData
      ]),
    ...(encounterWithDominantEyeQuestion.includes(encounter.type) && journey.dominant_eye ? [{
      label: i18n.global.t('custom.menicon.encounter.va.dominant-eye', [], { locale }),
      type: EncounterSummaryDataType.STRING,
      display: EncounterSummaryDataDisplay.ROW,
      value: i18n.global.t(`platform.eyes.${journey.dominant_eye}-eye`, [], { locale })
    } as EncounterStringSummaryData] : [])
  ],
  measurements: getMeasurementsSummary(encounter, journey, scans, locale),
  lensOrdering: [
    ...(order && encounter?.lens_support_info?.completed_at ? [
        {
          type: EncounterSummaryDataType.ALERT,
          label: [
            i18n.global.t('custom.menicon.lens-support.lens-recommendation.advice-alert-1', {
              name: `${encounter.lens_support_info.reviewer?.given_name} ${encounter.lens_support_info.reviewer?.family_name}`,
              date: i18n.global.d(encounter.lens_support_info.completed_at, 'shortMonth', locale)
            }, { locale }),
            i18n.global.t('custom.menicon.lens-support.lens-recommendation.advice-alert-2', {
              id: encounter.lens_support_info.request_id
            }, { locale })
          ]
        } as EncounterAlertSummaryData
    ] : []),
    ...(getPrescriptionOrderInformation(encounter, journey, order) ? [{
      type: EncounterSummaryDataType.ALERT,
      label: getPrescriptionOrderInformation(encounter, journey, order, true, locale)
    } as EncounterAlertSummaryData] : []),
    ...((order?.orderItems ?? [])
      .sort((a: OrderItem) => a.prescription.laterality === Laterality.right ? -1 : 1)
      .flatMap((orderItem: OrderItem) => getLens({
        parameters: orderItem.prescription.parameters,
        quantity: +orderItem.quantity,
        unit_of_measurement: orderItem.unit_of_measurement,
        laterality: orderItem.prescription.laterality
      }, locale))),
    ...encounter?.lens_support_info?.completed_at ?
      [{
        label: i18n.global.t('custom.menicon.lens-support.advice-notes', [], { locale }),
        type: EncounterSummaryDataType.STRING,
        value: encounter.lens_support_info.advice_note || i18n.global.t('platform.common.not-answered', [], { locale })
      } as EncounterStringSummaryData] : [],
    ...(order ? [{
      label: i18n.global.t('custom.menicon.lens-ordering.order-comments-label', [], { locale }),
      type: EncounterSummaryDataType.STRING,
      value: order.comments || i18n.global.t('platform.common.not-answered', [], { locale })
    } as EncounterStringSummaryData] : [])
  ],
  differenceMaps: [
    {
      label: i18n.global.t('custom.menicon.difference-maps.axial-dm', [], { locale }),
      type: EncounterSummaryDataType.HEADING
    },
    {
      label: i18n.global.t('custom.menicon.difference-maps.questions.treatment-zone', [], { locale }),
      type: EncounterSummaryDataType.GRID,
      display: EncounterSummaryDataDisplay.ROW,
      value: [
        ...(!journey?.laterality || journey?.laterality === Laterality.right
          ? [
            {
              icon: 'right-eye',
              value: formatBooleanAnswer(encounter.r_is_expected_treatment_zone, locale),
              description: getDifferenceMapsDescription(
                encounter.lens_support_info?.original_answers?.[0]?.r_is_expected_treatment_zone,
                encounter.r_is_expected_treatment_zone,
                locale)
            }
          ]
          : [{}]),
        ...(!journey?.laterality || journey?.laterality === Laterality.left
          ? [
            {
              icon: 'left-eye',
              value: formatBooleanAnswer(encounter.l_is_expected_treatment_zone, locale),
              description: getDifferenceMapsDescription(
                encounter.lens_support_info?.original_answers?.[0]?.l_is_expected_treatment_zone,
                encounter.l_is_expected_treatment_zone,
                locale)
            }
          ]
          : [])
      ]
    } as EncounterGridSummaryData,
    {
      label: i18n.global.t('custom.menicon.difference-maps.tangential-dm', [], { locale }),
      type: EncounterSummaryDataType.HEADING
    },
    {
      label: i18n.global.t('custom.menicon.difference-maps.questions.centration', [], { locale }),
      type: EncounterSummaryDataType.GRID,
      display: EncounterSummaryDataDisplay.ROW,
      value: [
        ...(!journey?.laterality || journey?.laterality === Laterality.right
          ? [
            {
              icon: 'right-eye',
              value: formatBooleanAnswer(encounter.r_is_expected_centration, locale),
              description: getDifferenceMapsDescription(
                encounter.lens_support_info?.original_answers?.[0]?.r_is_expected_centration,
                encounter.r_is_expected_centration,
                locale)
            }
          ]
          : [{}]),
        ...(!journey?.laterality || journey?.laterality === Laterality.left
          ? [
            {
              icon: 'left-eye',
              value: formatBooleanAnswer(encounter.l_is_expected_centration, locale),
              description: getDifferenceMapsDescription(
                encounter.lens_support_info?.original_answers?.[0]?.l_is_expected_centration,
                encounter.l_is_expected_centration,
                locale)
            }
          ]
          : [])
      ]
    } as EncounterGridSummaryData,
    ...getPatternQuestions(encounter, locale),
    {
      label: i18n.global.t('custom.menicon.difference-maps.summary', [], { locale }),
      type: EncounterSummaryDataType.GRID,
      display: EncounterSummaryDataDisplay.ROW,
      class: {
        line: 'py-3 bg-gray-50 rounded-md mt-4',
        question: 'text-gray-900 pl-3 font-semibold'
      },
      value: [
        getDifferenceMapsSummary(encounter, Laterality.right, locale),
        getDifferenceMapsSummary(encounter, Laterality.left, locale)
      ]
    } as EncounterGridSummaryData
  ],
  slitLamp: getSlitLampData(encounter.slit_lamp_conditions,
    encounter.is_slit_lamp_condition_none,
    encounter.slit_lamp_details,
    locale),
  preFittingSlitLamp: getSlitLampData(encounter.pre_fitting_slit_lamp_conditions,
    encounter.pre_fitting_is_slit_lamp_condition_none,
    encounter.pre_fitting_slit_lamp_details,
    locale),
  reasonForOrder: [
    {
      label: i18n.global.t('custom.menicon.encounter.reason-for-order.question', [], { locale }),
      type: EncounterSummaryDataType.STRING,
      display: EncounterSummaryDataDisplay.ROW,
      value: getReorderReasons(encounter.reorder_reasons)
    } as EncounterStringSummaryData,
    ...(encounter.reorder_reasons?.includes(REORDER_REASONS.OTHER_REASON) ? [{
      label: i18n.global.t('custom.menicon.encounter.reason-for-order.other-reason-details', [], { locale }),
      display: EncounterSummaryDataDisplay.ROW,
      type: EncounterSummaryDataType.STRING,
      value: encounter.reorder_other_details || i18n.global.t('platform.common.not-answered', [], { locale })
    } as EncounterStringSummaryData] : []),
    {
      label: i18n.global.t('custom.menicon.encounter.reason-for-order.details', [], { locale }),
      type: EncounterSummaryDataType.STRING,
      display: EncounterSummaryDataDisplay.ROW,
      value: encounter.reorder_details || i18n.global.t('platform.common.not-answered', [], { locale })
    } as EncounterStringSummaryData
  ],
  lensRecommendation: !order && encounter.lens_support_info?.completed_at && !encounter.lens_support_info?.ended_at ? [
    ...(encounter.lens_support_info.lens_recommendations as LensRecommendation[])
      .sort((a: LensRecommendation) => a.laterality === Laterality.right ? -1 : 1)
      .flatMap((lensRecommendation: LensRecommendation) => getLens(lensRecommendation, locale)),
    {
      label: i18n.global.t('custom.menicon.lens-support.advice-notes', [], { locale }),
      type: EncounterSummaryDataType.STRING,
      value: encounter.lens_support_info.advice_note || i18n.global.t('platform.common.not-answered', [], { locale })
    } as EncounterStringSummaryData
  ] : [],
  lensFittingAdvice: encounter.lens_support_info ? [
    {
      label: i18n.global.t('custom.menicon.lens-support.request.reason', [], { locale }),
      type: EncounterSummaryDataType.STRING,
      display: EncounterSummaryDataDisplay.COLUMN,
      value: encounter.lens_support_info.request_reason
    } as EncounterStringSummaryData,
    {
      label: i18n.global.t('custom.menicon.lens-support.request.file-upload', [], { locale }),
      type: EncounterSummaryDataType.STRING,
      display: EncounterSummaryDataDisplay.COLUMN,
      value: i18n.global.t('platform.file-upload.files', { count: encounter.lens_support_info.files?.length || 0 }, { locale })
    } as EncounterStringSummaryData
  ]: []
});

const getMeasurementsSummary =
  (encounter: Encounter, journey: Journey, scans: Record<Laterality, ImageSeries>, locale = i18n.global.locale):
    EncounterTableSummaryData[] => {
    const showActionColumn = (encounter.r_mls_id && scans.right) || (encounter.l_mls_id && scans.left);
    const measurementsColumns = [
      i18n.global.t('platform.eyes.flat-k', [], { locale }),
      i18n.global.t('platform.eyes.steep-k', [], { locale }),
      i18n.global.t('platform.eyes.flat-k-axis', [], { locale }),
      ...(isInitialMeasurement(encounter)
        ? [i18n.global.t('platform.eyes.hvid', [], { locale })] : [])
    ];
    const showRightEyeData = journey.laterality !== Laterality.left || isInitialMeasurement(encounter);
    const showLeftEyeData = journey.laterality !== Laterality.right || isInitialMeasurement(encounter);
    const rightEyeData: EncounterTableSummaryData = {
      label: '',
      type: EncounterSummaryDataType.TABLE,
      description: encounter.lens_support_info?.original_answers?.length
        && encounter.lens_support_info?.original_answers?.[0].r_mls_id
        && encounter.lens_support_info?.original_answers?.[0].r_mls_id !== encounter.r_mls_id
        ? i18n.global.t('custom.menicon.lens-support.patient-scans.original-scan-changed', [], { locale })
        : '',
      value: {
        columns: [
          {
            icon: 'right-eye',
            value: i18n.global.t('platform.eyes.right-eye', [], { locale }),
            class: 'flex items-center space-x-3 text-base font-semibold text-black'
          },
          ...measurementsColumns,
          ...(showActionColumn && !encounter.r_mls_id ? [''] : [])
        ],
        rows: [
          [
            encounter.r_mls_id
              ? {
                class: 'text-gray-500 text-sm font-medium',
                value: scans.right ? i18n.global.d(scans.right.seriesDate, 'dateTime', locale) :
                  i18n.global.t('custom.menicon.encounter.patient-scans.date-not-available', [], { locale })
              }
              : '',
            isSet(encounter.r_flat_k)
              ? `${(+(encounter.r_flat_k as string)).toFixed(K_VALUES_PRECISION)} ${encounter.k_unit}`
              : i18n.global.t('platform.common.not-answered', [], { locale }),
            isSet(encounter.r_steep_k)
              ? `${(+(encounter.r_steep_k as string)).toFixed(K_VALUES_PRECISION)} ${encounter.k_unit}`
              : i18n.global.t('platform.common.not-answered', [], { locale }),
            isSet(encounter.r_flat_k_axis)
              ? `${(+(encounter.r_flat_k_axis as string)).toFixed(K_VALUES_PRECISION)}˚`
              : i18n.global.t('platform.common.not-answered', [], { locale }),
            ...(isInitialMeasurement(encounter)
              ? [
                isSet(encounter.r_hvid)
                  ? `${Math.round(+(encounter.r_hvid as string) * 1000) / 1000} mm`
                  : i18n.global.t('platform.common.not-answered', [], { locale })
              ]
              : []),
            ...(showActionColumn && !encounter.r_mls_id ? [''] : [])
          ]
        ]
      },
      ...(encounter.r_mls_id && scans.right ? {
        action: {
          emit: 'viewScan',
          args: { seriesId: scans.right.id, studyId: scans.right.study_id },
          label: i18n.global.t('platform.topography-scans.view-scan')
        }
      } : {})
    };
    const leftEyeData: EncounterTableSummaryData = {
      label: '',
      type: EncounterSummaryDataType.TABLE,
      description: encounter.lens_support_info?.original_answers?.length
      && encounter.lens_support_info?.original_answers?.[0].l_mls_id
      && encounter.lens_support_info?.original_answers?.[0].l_mls_id !== encounter.l_mls_id
        ? i18n.global.t('custom.menicon.lens-support.patient-scans.original-scan-changed', [], { locale })
        : '',
      value: {
        columns: [
          {
            icon: 'left-eye',
            value: i18n.global.t('platform.eyes.left-eye', [], { locale }),
            class: 'flex items-center space-x-3 text-base font-semibold text-black'
          },
          ...measurementsColumns,
          ...(showActionColumn && !encounter.l_mls_id ? [''] : [])
        ],
        rows: [
          [
            encounter.l_mls_id
              ? {
                class: 'text-gray-500 text-sm font-medium',
                value: scans.left ? i18n.global.d(scans.left.seriesDate, 'dateTime', locale) :
                  i18n.global.t('custom.menicon.encounter.patient-scans.date-not-available', [], { locale })
              }
              : '',
            isSet(encounter.l_flat_k)
              ? `${(+(encounter.l_flat_k as string)).toFixed(K_VALUES_PRECISION)} ${encounter.k_unit}`
              : i18n.global.t('platform.common.not-answered', [], { locale }),
            isSet(encounter.l_steep_k)
              ? `${(+(encounter.l_steep_k as string)).toFixed(K_VALUES_PRECISION)} ${encounter.k_unit}`
              : i18n.global.t('platform.common.not-answered', [], { locale }),
            isSet(encounter.l_flat_k_axis)
              ? `${(+(encounter.l_flat_k_axis as string)).toFixed(K_VALUES_PRECISION)}˚`
              : i18n.global.t('platform.common.not-answered', [], { locale }),
            ...(isInitialMeasurement(encounter) ? [
              isSet(encounter.l_hvid)
                ? `${Math.round(+(encounter.l_hvid as string) * 1000) / 1000} mm`
                : i18n.global.t('platform.common.not-answered', [], { locale })
            ] : []),
            ...(showActionColumn && !encounter.l_mls_id ? [''] : [])
          ]
        ]
      },
      ...(encounter.l_mls_id && scans.left ? {
        action: {
          emit: 'viewScan',
          args: { seriesId: scans.left.id, studyId: scans.left.study_id },
          label: i18n.global.t('platform.topography-scans.view-scan')
        }
      } : {})
    };
    return [
      ...(showRightEyeData ? [rightEyeData] : []),
      ...(showLeftEyeData ? [leftEyeData] : [])
    ];
  };

export const getDifferenceMapsSummary = (encounter: Encounter, laterality: Laterality, locale = i18n.global.locale.value):
EncounterGridSummaryItem | object => {
  const [isExpectedTreatmentZone, isExpectedCentration, pattern] = laterality === Laterality.right ? [
    encounter.r_is_expected_treatment_zone,
    encounter.r_is_expected_centration,
    encounter.r_pattern
  ] : [
    encounter.l_is_expected_treatment_zone,
    encounter.l_is_expected_centration,
    encounter.l_pattern
  ];
  const originalAnswers = encounter.lens_support_info?.original_answers?.[0];
  const showSummary = typeof isExpectedTreatmentZone === 'boolean';
  const [originalIsExpectedTreatmentZone, originalIsExpectedCentration, originalPattern] = laterality === Laterality.right ? [
    originalAnswers?.r_is_expected_treatment_zone,
    originalAnswers?.r_is_expected_centration,
    originalAnswers?.r_pattern
  ] : [
    originalAnswers?.l_is_expected_treatment_zone,
    originalAnswers?.l_is_expected_centration,
    originalAnswers?.l_pattern
  ];
  const originalAssessment = originalAnswers && showSummary
  && originalIsExpectedTreatmentZone !== null && originalIsExpectedCentration !== null ?
    getDifferenceMapsAssessment(originalIsExpectedTreatmentZone, originalIsExpectedCentration, originalPattern) : null;
  const assessment = showSummary ? getDifferenceMapsAssessment(isExpectedTreatmentZone, isExpectedCentration, pattern) : null;
  return showSummary ? {
    icon: `${laterality}-eye`,
    value: i18n.global.t(
      `custom.menicon.difference-maps.assessment.${assessment}`,
      [],
      { locale }
    ),
    description: originalAssessment && assessment !== originalAssessment ? i18n.global.t('custom.menicon.lens-support.difference-maps.original-answer', [
      i18n.global.t(`custom.menicon.difference-maps.assessment.${originalAssessment}`, [], { locale })
    ], { locale }) : null
  } : {};
};

export const getDifferenceMapsDescription =
  (originalAnswer: boolean | DifferenceMapsPattern | null,
    currentAnswer: boolean | DifferenceMapsPattern | null,
    locale = i18n.global.locale.value) => currentAnswer !== originalAnswer && originalAnswer !== null && typeof originalAnswer !== 'undefined' ?
    i18n.global.t('custom.menicon.lens-support.difference-maps.original-answer', [
      typeof originalAnswer === 'boolean' ? formatBooleanAnswer(originalAnswer, locale) :
        i18n.global.t(`custom.menicon.difference-maps.pattern-${originalAnswer}`, [], { locale })
    ], { locale }) : null;

export const getReorderReasons = (value: string[], locale = i18n.global.locale.value): string => {
  const options = reorderReasonsOptions(locale);
  return value?.length ? value.map((reason) => options.find((option) => option.value === reason)?.label).join(', ') :
    i18n.global.t('platform.common.not-answered', [], { locale });
};

export const getSlitLampData = (conditions: Encounter['slit_lamp_conditions'], isNone?: boolean, details?: string, locale = i18n.global.locale.value): EncounterQuestionSummaryData[] => {
  let formattedConditions = i18n.global.t('platform.common.not-answered', [], { locale });
  let grading: EncounterGridSummaryData[] = [];
  if (isNone) {
    formattedConditions = i18n.global.t('custom.menicon.encounter.slit-lamp-without-lenses.present-conditions.none', [], { locale });
  } else if (conditions) {
    formattedConditions = Object
      .keys(conditions)
      .map((condition) =>
        i18n.global.t(`custom.menicon.encounter.slit-lamp-without-lenses.present-conditions.${condition}`, [], { locale })
      ).join(', ');
    grading = Object.keys(conditions).map((condition) => ({
      label: i18n.global.t(`custom.menicon.encounter.slit-lamp-without-lenses.present-conditions.grade.${condition}`, [], { locale }),
      type: EncounterSummaryDataType.GRID,
      display: EncounterSummaryDataDisplay.ROW,
      value: [
        {
          icon: 'right-eye',
          value: conditions?.[condition]?.r_grading ?
            i18n.global.t(`custom.menicon.encounter.slit-lamp-without-lenses.present-conditions.grade.${conditions[condition]?.r_grading}`, [], { locale }) :
            i18n.global.t('platform.common.not-answered', [], { locale })
        },
        {
          icon: 'left-eye',
          value: conditions?.[condition]?.l_grading ?
            i18n.global.t(`custom.menicon.encounter.slit-lamp-without-lenses.present-conditions.grade.${conditions[condition]?.l_grading}`, [], { locale }) :
            i18n.global.t('platform.common.not-answered', [], { locale })
        }
      ]
    }));
  }
  return [
    {
      display: EncounterSummaryDataDisplay.ROW,
      type: EncounterSummaryDataType.STRING,
      label: i18n.global.t('custom.menicon.encounter.slit-lamp-without-lenses.present-conditions.question', [], { locale }),
      value: formattedConditions
    } as EncounterStringSummaryData,
    ...grading,
    {
      display: EncounterSummaryDataDisplay.ROW,
      type: EncounterSummaryDataType.STRING,
      label: i18n.global.t('custom.menicon.encounter.slit-lamp-without-lenses.further-details.label', [], { locale }),
      value: details || i18n.global.t('platform.common.not-answered', [], { locale })
    } as EncounterStringSummaryData
  ];
};

export const vaRefractionProps = (encounter: Encounter, locale = i18n.global.locale.value) => {
  const encounterType = encounter.type;
  const defaultValue = {
    showAdd: isSet(encounter.r_addition) || isSet(encounter.l_addition),
    showAxialLength: false,
    showBcva: true,
    vaLabel: i18n.global.t('platform.eyes.bcva', [], { locale })
  };
  switch (encounterType) {
  case EncounterTypeName.INITIAL_MEASUREMENT:
  case EncounterTypeName.BLOOM_NIGHT_6_OR_12_MONTHS_ASSESSMENT:
  case EncounterTypeName.BLOOM_DAY_6_OR_12_MONTHS_PROGRESS_ASSESSMENT:
    return {
      ...defaultValue,
      showAxialLength: true
    };
  case EncounterTypeName.QUICK_INITIAL_MEASUREMENT:
    return {
      ...defaultValue,
      showAxialLength: true,
      showBcva: false
    };
  case EncounterTypeName.BLOOM_NIGHT_COLLECTION:
    return {
      ...defaultValue,
      showAdd: false,
      vaLabel: i18n.global.t('platform.eyes.va-with-lenses', [], { locale })
    };
  case EncounterTypeName.BLOOM_NIGHT_3_WEEK_LENS_EFFICACY_ASSESSMENT:
  case EncounterTypeName.BLOOM_NIGHT_3_OR_9_MONTHS_ASSESSMENT:
    return {
      ...defaultValue,
      showAdd: false
    };
  default:
    return defaultValue;
  }
};

export const getVaLabel = (vaUnit: VaUnit, value?: string, locale = i18n.global.locale.value): string | undefined =>
  getVaOptions(vaUnit, locale).find((option) => option.value === value)?.label;

export const getVARefractionData = (
  encounter: Encounter,
  laterality: Laterality | 'both',
  locale = i18n.global.locale.value
): EncounterTableSummaryData => {
  const data = vaRefractionProps(encounter, locale);
  return {
    label: '',
    type: EncounterSummaryDataType.TABLE,
    laterality,
    value: {
      columns: [
        ...(laterality === 'both'
          ? [data.vaLabel, ...Array(3).fill(''), ...(data.showAdd ? [''] : []), ...(data.showAxialLength ? [''] : [])]
          : [
            i18n.global.t('platform.eyes.sphere', [], { locale }),
            i18n.global.t('platform.eyes.cylinder', [], { locale }),
            i18n.global.t('platform.eyes.axis', [], { locale }),
            ...(data.showBcva ? [data.vaLabel] : []),
            ...(data.showAdd ? [i18n.global.t('platform.eyes.add', [], { locale })] : []),
            ...(data.showAxialLength ? [i18n.global.t('platform.eyes.axial-length', [], { locale })] : [])
          ])
      ],
      rows: [
        [
          ...(laterality === 'both'
            ? [
              getVaLabel(encounter.va_unit, encounter.combined_visual_acuity, locale),
              ...Array(3).fill(''),
              ...(data.showAdd ? [''] : []),
              ...(data.showAxialLength ? [''] : [])
            ]
            : [
              laterality === Laterality.left ? (isSet(encounter.l_sphere) ? `${encounter.l_sphere} D` : null) : (isSet(encounter.r_sphere) ? `${encounter.r_sphere} D` : null),
              laterality === Laterality.left ? (isSet(encounter.l_cylinder) ? `${encounter.l_cylinder} D` : null) : (isSet(encounter.r_cylinder) ? `${encounter.r_cylinder} D` : null),
              laterality === Laterality.left ? (isSet(encounter.l_axis) ? `${encounter.l_axis}º` : null) : (isSet(encounter.r_axis) ? `${encounter.r_axis}º` : null),
              ...(data.showBcva ? [laterality === Laterality.left ?
                getVaLabel(encounter.va_unit, encounter.l_visual_acuity, locale) :
                getVaLabel(encounter.va_unit, encounter.r_visual_acuity, locale)] : []),
              ...(data.showAdd
                ? [laterality === Laterality.left ? (isSet(encounter.l_addition) ? `${encounter.l_addition} D` : null) : (isSet(encounter.r_addition) ? `${encounter.r_addition} D` : null)]
                : []),
              ...(data.showAxialLength
                ? [laterality === Laterality.left ? (isSet(encounter.l_axial_length) ? `${encounter.l_axial_length} mm` : null) : (isSet(encounter.r_axial_length) ? `${encounter.r_axial_length} mm` : null)]
                : [])
            ])
        ]
      ]
    }
  };
};

export const getPatternQuestions = (
  encounter: Encounter,
  locale = i18n.global.locale.value
): Array<EncounterGridSummaryData | EncounterHeadingSummaryData> => {
  const displayRPattern =
    encounter.r_is_expected_treatment_zone === false && encounter.r_is_expected_centration === true;
  const displayLPattern =
    encounter.l_is_expected_treatment_zone === false && encounter.l_is_expected_centration === true;
  if (displayRPattern || displayLPattern) {
    const result: Array<EncounterGridSummaryData | EncounterHeadingSummaryData> = [
      {
        label: i18n.global.t('custom.menicon.difference-maps.pattern', [], { locale }),
        type: EncounterSummaryDataType.HEADING
      }
    ];
    if (displayRPattern) {
      result.push({
        label: i18n.global.t('custom.menicon.difference-maps.questions.right-eye-pattern', [], { locale }),
        type: EncounterSummaryDataType.GRID,
        display: EncounterSummaryDataDisplay.ROW,
        value: [
          {
            icon: 'right-eye',
            value:
              encounter.r_pattern === DifferenceMapsPattern.A
                ? i18n.global.t('custom.menicon.difference-maps.pattern-a', [], { locale })
                : i18n.global.t('custom.menicon.difference-maps.pattern-b', [], { locale }),
            description: getDifferenceMapsDescription(
              encounter.lens_support_info?.original_answers?.[0]?.r_pattern,
              encounter.r_pattern,
              locale)
          }
        ]
      });
    }
    if (displayLPattern) {
      result.push({
        label: i18n.global.t('custom.menicon.difference-maps.questions.left-eye-pattern', [], { locale }),
        type: EncounterSummaryDataType.GRID,
        display: EncounterSummaryDataDisplay.ROW,
        value: [
          {},
          {
            icon: 'left-eye',
            value:
              encounter.l_pattern === DifferenceMapsPattern.A
                ? i18n.global.t('custom.menicon.difference-maps.pattern-a', [], { locale })
                : i18n.global.t('custom.menicon.difference-maps.pattern-b', [], { locale }),
            description: getDifferenceMapsDescription(
              encounter.lens_support_info?.original_answers?.[0]?.l_pattern,
              encounter.l_pattern,
              locale)
          }
        ]
      });
    }
    return result;
  }
  return [];
};

export const getLens = (
  lens: LensSummarySection,
  locale = i18n.global.locale.value
): EncounterTableSummaryData[] => {
  const prescriptionValue = getLensOrderingPrescriptionValue(lens.parameters, locale);

  const lensColumns = [
    i18n.global.t('custom.menicon.lens-ordering.lens', [], { locale }),
    i18n.global.t('custom.menicon.lens-ordering.pack', [], { locale }),
    i18n.global.t('custom.menicon.lens-ordering.qty', [], { locale })
  ];

  lensColumns.push(
    ...Array(prescriptionValue.length - lensColumns.length)
      .join('.')
      .split('.')
  );

  return [
    {
      label: '',
      type: EncounterSummaryDataType.TABLE,
      laterality: lens.laterality,
      value: {
        columns: lensColumns,
        rows: [
          [
            lens.parameters.lensName,
            lens.unit_of_measurement,
            lens.quantity,
            ...Array(prescriptionValue.length - 3).fill('')
          ]
        ]
      }
    },
    {
      label: '',
      type: EncounterSummaryDataType.TABLE,
      laterality: 'spacer',
      dataDusk: `${lens.laterality}-properties`,
      value: {
        columns: [
          ...Object.keys(lens.parameters.lensParameters).map((param) =>
            i18n.global.t(`custom.menicon.easyfit.Lens/${param}`, [], { locale })
          )
        ],
        rows: [prescriptionValue]
      }
    }
  ];
};

export const getLensOrderingPrescriptionValue = (parameters: PrescriptionParams, locale = i18n.global.locale.value) => {
  const result: string[] = [];
  for (const parameter in parameters.lensParameters) {
    if (parameter === MATERIAL_PARAMETER_NAME) {
      result.push(parameters.materialName);
    } else if (parameter === TRIAL_LENS_PARAMETER_NAME) {
      result.push(
        i18n.global.t(`custom.menicon.easyfit.${Boolean(+parameters.lensParameters[parameter])}`, [], { locale })
      );
    } else if (parameter === COMPRESSION_FACTOR_PARAMETER_NAME) {
      let key = 'custom.menicon.easyfit.';
      if (+parameters.lensParameters[parameter] === -0.5) {
        key += 'Low';
      } else if (+parameters.lensParameters[parameter] === -1) {
        key += 'Medium';
      } else {
        key += 'High';
      }
      result.push(
        i18n.global.t(key, [], { locale })
      );
    } else {
      result.push(`${parameters.lensParameters[parameter]}${lensParametersUnits[parameter] ?? ''}`);
    }
  }

  return result;
};

export const formatBooleanAnswer = (answer: boolean | undefined, locale = i18n.global.locale.value): string => {
  if (typeof answer === 'undefined' || answer === null) {
    return i18n.global.t('platform.common.not-answered', [], { locale });
  }

  return answer
    ? (i18n.global.t('platform.common.yes', [], { locale }))
    : (i18n.global.t('platform.common.no', [], { locale }));
};

export const getEncounterScans = (studies: Study[], encounter: Encounter | null): Record<Laterality, ImageSeries | null> =>
  studies.reduce(
    (acc, study) => {
      if (encounter?.r_mls_id) {
        const rSeries = study.series.find(
          (series) => series.laterality === 'R' && series.topographerParams?.mlsId === encounter.r_mls_id
        );
        if (rSeries) {
          acc.right = {
            ...rSeries,
            study_id: study.id
          };
        }
      }
      if (encounter?.l_mls_id) {
        const lSeries = study.series.find(
          (series) => series.laterality === 'L' && series.topographerParams?.mlsId === encounter.l_mls_id
        );
        if (lSeries) {
          acc.left = {
            ...lSeries,
            study_id: study.id
          };
        }
      }
      return acc;
    },
    { right: null, left: null } as Record<Laterality, ImageSeries | null>
  );
