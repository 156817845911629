import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white border rounded-lg p-4 shadow inline-block" }
const _hoisted_2 = ["src"]

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CardImage',
  props: {
  imageUrl: {
    type: String,
    default: null
  },
  imagePath: {
    type: String,
    default: null
  },
  description: {
    type: String,
    default: null
  },
  width: {
    type: Number,
    default: 200
  },
  height: {
    type: Number,
    default: 200
  }
},
  setup(__props) {

const props = __props;

const getImageUrl = computed<string>(() => {
  if (!props.imageUrl && !props.imagePath) {
    return '';
  }
  return props.imageUrl ? props.imageUrl : require(`@/assets/images/${props.imagePath}`);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "bg-center bg-no-repeat bg-cover",
      style: _normalizeStyle(`width: ${__props.width}px; height: ${__props.height}px;`)
    }, [
      _createElementVNode("img", { src: getImageUrl.value }, null, 8, _hoisted_2)
    ], 4),
    (__props.description)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: "mt-4 text-center leading-tight font-medium text-sm",
          style: _normalizeStyle(`max-width: ${__props.width}px`)
        }, _toDisplayString(__props.description), 5))
      : _createCommentVNode("", true)
  ]))
}
}

})