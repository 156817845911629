import { KUnit, VaUnit } from '@/custom/menicon/models';

export const lensParametersUnits = {
  Addition: ' D',
  BackCylinder: ' D',
  BackCylinderAxis: '˚',
  Diameter: ' mm',
  EdgeLift: ' mm',
  FrontCylinder: ' D',
  FrontCylinderAxis: '˚',
  Height: ' mm',
  HeightFlat: ' mm',
  HeightSteep: ' mm',
  LensTotalHeight: ' mm',
  Power: ' D',
  Prism: ' pD',
  Radius: ' mm',
  RadiusFlat: ' mm',
  RadiusSteep: ' mm',
  SkirtRadius: ' mm',
  Tangent: '˚',
  TangentFlat: '˚',
  TangentSteep: '˚',
  Vault: ' µm'
};

export const kUnits: string[] = Object.values(KUnit);
export const vaUnits: string[] = Object.values(VaUnit);
