import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "flex flex-col justify-center" }
const _hoisted_3 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_4 = { class: "text-base font-medium text-gray-900" }
const _hoisted_5 = {
  key: 0,
  class: "text-xs text-gray-500 font-medium"
}
const _hoisted_6 = { class: "text-base font-normal text-gray-600" }
const _hoisted_7 = { class: "text-base font-normal text-gray-600" }

import { onMounted, ref } from 'vue';
import vueFilePond from 'vue-filepond';
import { FilePondErrorDescription, FilePondFile, FilePondInitialFile, FilePondServerConfigProps } from 'filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { apiClient } from '@/services/api';
import cookieService from '@/services/cookie.service';


export default /*@__PURE__*/_defineComponent({
  __name: 'AvatarUpload',
  props: {
  id: {
    type: String,
    default: ''
  },

  isOptional: {
    type: Boolean,
    default: false
  }
},
  emits: ["refresh-avatar-upload-component", "update:id", "loading"],
  setup(__props, { emit: __emit }) {

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop
);

const props = __props;
const emit = __emit;

const files = ref<FilePondInitialFile[]>([]);
const server: FilePondServerConfigProps['server'] = {
  url: `${apiClient.defaults.baseURL}/v1/images`,
  headers: {
    [apiClient.defaults.xsrfHeaderName]: cookieService.getXsrfToken()
  },
  process: '/',
  load: '/'
};

onMounted(() => {
  if (props.id) {
    files.value = [
      {
        source: props.id,
        options: {
          type: 'local'
        }
      }
    ];
  }
});
const handleError = () => {
  setTimeout(() => {
    emit('refresh-avatar-upload-component', true);
  }, 15000);
};
const handleProcessFile = (error: FilePondErrorDescription, file: FilePondFile) => {
  if (!error) {
    const newId = JSON.parse(file.serverId).data.id;
    emit('update:id', newId);
  }

  emit('loading', false);
};
const handleRemoveFile = (error: FilePondErrorDescription) => {
  if (!error) {
    emit('update:id', '');
  }
};
const handleAddFileStart = () => {
  if (!props.id) {
    emit('loading', true);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(FilePond), {
      name: "file",
      "label-idle": _ctx.$t('platform.profile.image-upload'),
      "label-invalid-field": _ctx.$t('platform.file-upload.invalid-field'),
      "label-file-waiting-for-size": _ctx.$t('platform.file-upload.file-waiting-for-size'),
      "label-file-size-not-available": _ctx.$t('platform.file-upload.file-size-not-available'),
      "label-file-loading": _ctx.$t('platform.file-upload.file-loading'),
      "label-file-load-error": _ctx.$t('platform.file-upload.file-load-error'),
      "label-file-processing": _ctx.$t('platform.file-upload.file-processing'),
      "label-file-processing-complete": _ctx.$t('platform.file-upload.file-processing-complete'),
      "label-file-processing-aborted": _ctx.$t('platform.file-upload.file-processing-aborted'),
      "label-file-processing-error": _ctx.$t('platform.file-upload.file-processing-error'),
      "label-file-processing-revert-error": _ctx.$t('platform.file-upload.file-processing-revert-error'),
      "label-file-remove-error": _ctx.$t('platform.file-upload.file-remove-error'),
      "label-tap-to-cancel": _ctx.$t('platform.file-upload.tap-to-cancel'),
      "label-tap-to-retry": _ctx.$t('platform.file-upload.tap-to-retry'),
      "label-tap-to-undo": _ctx.$t('platform.file-upload.tap-to-undo'),
      "label-button-remove-item": _ctx.$t('platform.file-upload.button-remove-item'),
      "label-button-abort-item-load": _ctx.$t('platform.file-upload.button-abort-item-load'),
      "label-button-retry-item-load": _ctx.$t('platform.file-upload.button-retry-item-load'),
      "label-button-abort-item-processing": _ctx.$t('platform.file-upload.button-abort-item-processing'),
      "label-button-undo-item-processing": _ctx.$t('platform.file-upload.button-undo-item-processing'),
      "label-button-retry-item-processing": _ctx.$t('platform.file-upload.button-retry-item-processing'),
      "label-button-process-item": _ctx.$t('platform.file-upload.button-process-item'),
      "label-max-file-size-exceeded": _ctx.$t('platform.file-upload.max-file-size-exceeded'),
      "label-max-file-size": _ctx.$t('platform.file-upload.max-file-size'),
      "label-max-total-file-size": _ctx.$t('platform.file-upload.max-total-file-size'),
      "label-file-type-not-allowed": _ctx.$t('platform.file-upload.file-type-not-allowed'),
      "file-validate-type-label-expected-types": _ctx.$t('platform.file-upload.file-validate-type-label-expected-types'),
      "image-validate-size-label-format-error": _ctx.$t('platform.file-upload.image-validate-size-label-format-error'),
      "image-validate-size-label-image-size-too-small": _ctx.$t('platform.file-upload.image-validate-size-label-image-size-too-small'),
      "image-validate-size-label-image-size-too-big": _ctx.$t('platform.file-upload.image-validate-size-label-image-size-too-big'),
      "image-validate-size-label-expected-min-size": _ctx.$t('platform.file-upload.image-validate-size-label-expected-min-size'),
      "image-validate-size-label-expected-max-size": _ctx.$t('platform.file-upload.image-validate-size-label-expected-max-size'),
      "image-validate-size-label-image-resolution-too-low": _ctx.$t('platform.file-upload.image-validate-size-label-image-resolution-too-low'),
      "image-validate-size-label-image-resolution-too-high": _ctx.$t('platform.file-upload.image-validate-size-label-image-resolution-too-high'),
      "image-validate-size-label-expected-min-resolution": _ctx.$t('platform.file-upload.image-validate-size-label-expected-min-resolution'),
      "image-validate-size-label-expected-max-resolution": _ctx.$t('platform.file-upload.image-validate-size-label-expected-max-resolution'),
      "accepted-file-types": "image/png, image/jpeg",
      "style-panel-layout": "compact",
      "allow-multiple": false,
      "image-preview-height": 112,
      "image-crop-aspect-ratio": '1:1',
      files: files.value,
      server: server,
      onError: handleError,
      onProcessfile: handleProcessFile,
      onRemovefile: handleRemoveFile,
      onAddfilestart: handleAddFileStart
    }, null, 8, ["label-idle", "label-invalid-field", "label-file-waiting-for-size", "label-file-size-not-available", "label-file-loading", "label-file-load-error", "label-file-processing", "label-file-processing-complete", "label-file-processing-aborted", "label-file-processing-error", "label-file-processing-revert-error", "label-file-remove-error", "label-tap-to-cancel", "label-tap-to-retry", "label-tap-to-undo", "label-button-remove-item", "label-button-abort-item-load", "label-button-retry-item-load", "label-button-abort-item-processing", "label-button-undo-item-processing", "label-button-retry-item-processing", "label-button-process-item", "label-max-file-size-exceeded", "label-max-file-size", "label-max-total-file-size", "label-file-type-not-allowed", "file-validate-type-label-expected-types", "image-validate-size-label-format-error", "image-validate-size-label-image-size-too-small", "image-validate-size-label-image-size-too-big", "image-validate-size-label-expected-min-size", "image-validate-size-label-expected-max-size", "image-validate-size-label-image-resolution-too-low", "image-validate-size-label-image-resolution-too-high", "image-validate-size-label-expected-min-resolution", "image-validate-size-label-expected-max-resolution", "files"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('platform.profile.image')), 1),
        (__props.isOptional)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('platform.common.optional')), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('platform.profile.image-format')), 1),
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('platform.profile.image-message')), 1)
    ])
  ]))
}
}

})