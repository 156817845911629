import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

import { BaseIcon } from '@/lib/components/Icon';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarNavigationLink',
  props: {
  /**
   * A vue-Router object or string specifying the URL to navigate to
   */
  to: {
    type: [String, Object],
    default: null
  },
  icon: {
    type: String,
    default: null
  }
},
  setup(__props) {


const currentRoute = useRoute();
const isRouteActive = (path: string) => currentRoute?.path.includes(path);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.to ? 'router-link' : 'a'), { to: __props.to }, {
    default: _withCtx(({ route }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["flex items-center px-4 h-12 rounded-md focus:outline-none", 
        isRouteActive(route.path)
          ? 'text-gray-900 bg-gray-100'
          : 'text-gray-500 border-transparent hover:text-gray-900 hover:border-gray-400 focus:text-gray-900 focus:border-gray-400'
      ])
      }, [
        (__props.icon)
          ? (_openBlock(), _createBlock(_unref(BaseIcon), {
              key: 0,
              name: __props.icon,
              class: "text-gray-900 mr-4"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ]),
    _: 3
  }, 8, ["to"]))
}
}

})