import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row bg-gray-900 rounded items-center p-2 text-sm font-medium select-none" }
const _hoisted_2 = { class: "text-center text-gray-400" }

import { computed } from 'vue';
import { BaseIcon } from '@/lib/components/Icon';


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewerZoomInput',
  props: {
  modelValue: {
    type: Number,
    default: 100
  },
  min: {
    type: Number,
    default: 50
  },
  disabled: {
    type: Boolean,
    default: false
  }
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const roundedValue = computed(() => Math.round(props.modelValue));
const zoomIn = () => {
  if (!props.disabled) {
    emit('update:modelValue', props.modelValue + 10);
  }
};
const zoomOut = () => {
  if (props.modelValue - 10 >= props.min && !props.disabled) {
    emit('update:modelValue', props.modelValue - 10);
  } else {
    emit('update:modelValue', props.min);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: "focus:outline-none px-4",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (zoomOut()))
    }, [
      _createVNode(_unref(BaseIcon), {
        name: "minus",
        class: "text-white"
      })
    ]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(roundedValue.value) + "%", 1),
    _createElementVNode("button", {
      type: "button",
      class: "focus:outline-none px-4",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (zoomIn()))
    }, [
      _createVNode(_unref(BaseIcon), {
        name: "add",
        class: "text-white"
      })
    ])
  ]))
}
}

})