import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center space-x-4" }
const _hoisted_2 = {
  key: 0,
  class: "text-base font-medium"
}
const _hoisted_3 = { class: "flex flex-row items-center" }

import { PropType, ref } from 'vue';
import { IAction, ILabel } from '@/lib';
import { BasePopover, PopoverButton } from '@/lib/components/Popover';
import { BaseButton, SpecialityButton } from '@/lib/components/Button';
import MedKitBadge from '@/lib/components/Badge/MedKitBadge.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupTreeSegment',
  props: {
  name: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  label: {
    type: Object as PropType<ILabel>,
    default: null
  },
  enableAdd: {
    type: Boolean,
    default: false
  },
  actions: {
    type: Array as PropType<IAction[]>,
    default: () => []
  }
},
  emits: ["createChild"],
  setup(__props) {




const hover = ref(false);

return (_ctx: any,_cache: any) => {
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex flex-row justify-between bg-white border border-solid border-gray-300 rounded-lg shadow p-4",
    onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (hover.value = true)),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (hover.value = false))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (__props.name)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.name), 1))
        : _createCommentVNode("", true),
      (__props.label?.name)
        ? (_openBlock(), _createBlock(MedKitBadge, {
            key: 1,
            class: "capitalize",
            kind: __props.label.kind,
            label: __props.label.name,
            size: "small"
          }, null, 8, ["kind", "label"]))
        : _createCommentVNode("", true),
      (__props.description)
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: _normalizeClass(["text-gray-500 text-sm transition-opacity truncate ml-10", hover.value ? 'opacity-1 ease-in duration-200' : 'opacity-0 ease-out duration-500'])
          }, _toDisplayString(__props.description), 3))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "left-actions")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "right-actions"),
      (__props.enableAdd)
        ? (_openBlock(), _createBlock(_unref(BaseButton), {
            key: 0,
            "data-dusk": "org-new-child-btn",
            color: "ghost",
            "left-icon": "add",
            size: "small",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('createChild')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.org-unit.new-child')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (__props.actions?.length)
        ? (_openBlock(), _createBlock(_unref(BasePopover), {
            key: 1,
            position: "left"
          }, {
            button: _withCtx(() => [
              _createVNode(_unref(SpecialityButton), { class: "ml-4" })
            ]),
            menu: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.actions, (item) => {
                return _withDirectives((_openBlock(), _createBlock(_unref(PopoverButton), {
                  key: item.label,
                  onClick: ($event: any) => (item.onClick($event))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.label), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])), [
                  [_directive_allow, item.permission]
                ])
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ], 32))
}
}

})