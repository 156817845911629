import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }

import { computed, onMounted, PropType, ref, watch } from 'vue';
import { getSuggestions } from '@/helpers/search.helper';
import { IOption, ISuggestion } from '@/lib';
import { SearchInput } from '@/lib/components/Search';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSelectInput',
  props: {
  placeholder: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String as PropType<string | number>,
    default: null
  },
  icon: {
    type: String,
    default: ''
  },
  iconPosition: {
    type: String,
    default: 'left'
  },
  error: {
    type: String,
    default: null
  },
  options: {
    type: Array as PropType<IOption[]>,
    default: () => []
  },
  clear: {
    type: Boolean,
    default: false
  },
  isOptional: {
    type: Boolean,
    default: false
  },
  iconStroke: {
    type: Boolean,
    default: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  paginate: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  }
},
  emits: ["update:modelValue", "change"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const searchInput = ref(null);

const search = ref('');
const perPage = ref(15);

const suggestionsList = computed<ISuggestion[]>(() => {
  if (search.value) {
    const suggestions = getSuggestions(convertOptionsToSuggestions(filteredList.value), search.value);
    return props.paginate ? suggestions.slice(0, perPage.value) : suggestions;
  }

  if (props.paginate) {
    return convertOptionsToSuggestions(props.options.slice(0, perPage.value));
  }

  return convertOptionsToSuggestions(props.options);
});
const filteredList = computed<IOption[]>(() => props.options.filter((item) => !search.value.includes(item.label)));

onMounted(() => {
  search.value = getMatchSuggestion(props.options, props.modelValue)?.label ?? '';
});
watch([() => props.modelValue, () => props.options], () => {
  search.value = getMatchSuggestion(props.options, props.modelValue)?.label ?? '';
});
const convertOptionsToSuggestions = (options: IOption[]): ISuggestion[] => {
  if (!options.length) {
    return [];
  }
  return options.map(({ value, label }) => ({
    id: value,
    label
  } as ISuggestion));
};
const validateOrClear = (): void => {
  const suggestions = convertOptionsToSuggestions(props.options);
  const valid = fuzzyQuery(suggestions, search.value);
  const exactMatch = getMatchSuggestion(props.options, search.value);
  // If user typed the exact match to option, trigger save
  if (exactMatch) {
    search.value = exactMatch.label;
    updateModelValue(exactMatch?.value ?? '');
    // close the popover buttons
    searchInput.value?.close();
  }

  if (!valid || !exactMatch) {
    search.value = '';
    updateModelValue('');
    // close the popover buttons
    searchInput.value?.close();
  }
};
const getMatchSuggestion = (list: IOption[], keyword: string | number): IOption | undefined => {
  if (!keyword || !list.length) {
    return;
  }
  return list.find(({ label, value }) =>
    (label.toLowerCase() === keyword.toString().toLowerCase() ||
      value?.toString()?.toLowerCase() === keyword?.toString().toLowerCase())
  );
};
const validateInputOrClear = (value: ISuggestion): void => {
  const suggestions = convertOptionsToSuggestions(props.options);
  const valid = fuzzyQuery(suggestions, value.label);
  if (valid) {
    search.value = value.label;
    updateModelValue(value?.id ?? '');
  } else {
    search.value = '';
  }
};
const fuzzyQuery = (list: ISuggestion[], keyWord: string): boolean => {
  if (!keyWord || !list.length) {
    return false;
  }
  const arr: ISuggestion[] = [];
  for (let i = 0; i < list.length; i++) {
    if (list[i].label?.toLowerCase().indexOf(keyWord?.toLowerCase()) >= 0) {
      arr.push(list[i]);
    }
  }
  return arr.length > 0;
};
const updateModelValue = (value: string): void => {
  emit('update:modelValue', value);
  emit('change');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(SearchInput), {
      ref_key: "searchInput",
      ref: searchInput,
      modelValue: search.value,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
        _cache[2] || (_cache[2] = ($event: any) => (perPage.value = 15))
      ],
      label: __props.label,
      suggestions: suggestionsList.value,
      placeholder: __props.readonly ? '' : __props.placeholder,
      description: __props.description,
      loading: __props.loading,
      icon: __props.icon,
      "icon-position": __props.iconPosition,
      "icon-stroke": __props.iconStroke,
      clear: __props.clear,
      error: __props.error && __props.error.length ? __props.error : '',
      wrap: "",
      "is-optional": __props.isOptional,
      readonly: __props.readonly,
      onSubmit: validateInputOrClear,
      onValidate: validateOrClear,
      onScrolled: _cache[1] || (_cache[1] = ($event: any) => (perPage.value += 15))
    }, null, 8, ["modelValue", "label", "suggestions", "placeholder", "description", "loading", "icon", "icon-position", "icon-stroke", "clear", "error", "is-optional", "readonly"])
  ]))
}
}

})