import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { computed } from 'vue';
import { useSessionStore } from '@/stores/session.store';
import { useUiStore } from '@/stores/ui.store';
import { CustomerBranding } from '@/models';


export default /*@__PURE__*/_defineComponent({
  __name: 'BrandLogo',
  props: {
  useDefault: {
    type: Boolean,
    default: false
  },
  useHeaderBranding: {
    type: Boolean,
    default: false
  },
  logoSize: {
    type: String,
    default: 'regular',
    validator: (value: string) => ['regular', 'mark'].includes(value)
  }
},
  setup(__props) {

const props = __props;

const sessionStore = useSessionStore();
const uiStore = useUiStore();

const branding = computed(() => props.useHeaderBranding ? uiStore.headerBranding :
  sessionStore.customerBranding);
const brandLogo = computed(() => {
  if (!props.useDefault && branding.value && branding.value !== CustomerBranding.BPM) {
    return require(`@/assets/branding/logos/logo-${branding.value?.toLowerCase()}-${props.logoSize}.svg`);
  }
  return require(`@/assets/branding/logos/logo-big-picture-medical-${props.logoSize}.svg`);
});

return (_ctx: any,_cache: any) => {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (brandLogo.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_InlineSvg, {
          src: brandLogo.value,
          class: "inline-block",
          "data-dusk": `${__props.useDefault ? 'big-picture-medical' : branding.value}-logo`
        }, null, 8, ["src", "data-dusk"])
      ]))
    : _createCommentVNode("", true)
}
}

})