import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "pr-6 text-sm leading-snug font-normal",
  "data-test": "text"
}

import { PropType, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { BaseIcon } from '@/lib/components';


const toastTypes = ['default', 'success', 'error'] as const;
// eslint-disable-next-line @typescript-eslint/no-type-alias
type ToastType = typeof toastTypes[number];
const validateIsToastType = (value: ToastType): boolean => toastTypes.includes(value);

const __default__ = {
  name: 'MedKitToast'
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
  /** The content that should appear in the toast */
  text: {
    type: String,
    required: true
  },
  /** Controls whether the toast is shown */
  modelValue: {
    type: Boolean,
    default: false
  },
  /** Controls how long the toast is shown before closing automatically. An empty or 0 or negative duration will not close. */
  duration: {
    type: Number,
    default: 5000
  },
  /** The text label or/aria label of the button. If nothing is specified “Close” will display as a translated string. */
  buttonLabel: {
    type: String,
    default: ''
  },
  /** The type of the toast. */
  type: {
    type: String as PropType<ToastType>,
    default: (): ToastType => 'default',
    validator: validateIsToastType
  }
},
  emits: ["update:modelValue", "close", "click:button"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const iconName = computed(() => {
  if (props.type === 'error') {
    return 'alert';
  }
  if (props.type === 'success') {
    return 'tick-2';
  }

  return '';
});

const backgroundColors = {
  default: 'bg-gray-900',
  error: 'bg-danger-700'
};

const borderColors = {
  default: 'border-gray-300',
  error: 'border-danger-300'
};

const actionButtonClicked = () => {
  emit('click:button');
  closeToast();
};

const closeToast = () => {
  emit('update:modelValue', false);
  emit('close');
};

watch(
  () => props.modelValue,
  (newValue) => {
    if (props.duration && props.duration > 0 && newValue === true) {
      setTimeout(closeToast, props.duration);
    }
  }
);

const { t } = useI18n();
const buttonText = computed(() => props.buttonLabel || t('platform.common.close'));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    appear: "",
    "enter-active-class": "duration-150 ease-out",
    "leave-active-class": "duration-150 ease-in",
    "enter-from-class": "opacity-0 translate-y-6",
    "leave-to-class": "opacity-0 translate-y-6"
  }, {
    default: _withCtx(() => [
      (__props.modelValue)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([[backgroundColors[__props.type] ?? backgroundColors.default], "h-14 py-3 pl-4 pr-3 flex absolute bottom-4 mx-auto left-0 right-0 text-center z-1000 text-white items-center justify-between rounded-md"]),
            style: {"width":"max-content"},
            role: "status",
            "aria-live": "polite",
            "aria-atomic": "true"
          }, [
            (iconName.value)
              ? (_openBlock(), _createBlock(_unref(BaseIcon), {
                  key: 0,
                  class: "mr-3",
                  name: iconName.value,
                  size: "medium"
                }, null, 8, ["name"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_1, _toDisplayString(__props.text), 1),
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass(["h-8 text-sm font-medium bg-transparent border border-solid rounded px-4 py-1 focus-visible-ring transition-outline-colors ease-in-cube duration-130 outline-0 focus:outline-0", [borderColors[__props.type] ?? borderColors.default]]),
              style: {"width":"max-content"},
              onClick: actionButtonClicked
            }, _toDisplayString(buttonText.value), 3)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})