import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 1,
  "data-cy": "nav-account-initials",
  class: "flex items-center justify-center w-6 h-6 text-xs font-semibold text-white bg-gray-900 rounded"
}

import { PropType, computed } from 'vue';

interface UserName {
  given_name: string;
  family_name: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseAvatar',
  props: {
  userName: {
    type: Object as PropType<UserName>,
    default: () => ({
      given_name: '',
      family_name: ''
    })
  },
  url: {
    type: String,
    default: ''
  }
},
  setup(__props) {

const props = __props;

const initials = computed(() => (
  (props.userName.given_name || '').charAt(0) + (props.userName.family_name || '').charAt(0)).toUpperCase()
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (__props.url)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "flex items-center justify-center w-6 h-6 rounded bg-cover bg-no-repeat bg-center",
          style: _normalizeStyle(`background-image: url(${__props.url})`)
        }, null, 4))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(initials.value), 1))
  ]))
}
}

})