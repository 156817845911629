import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-between leading-tight mb-3"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_5 = ["id", "value", "disabled", "name"]
const _hoisted_6 = {
  key: 0,
  value: "",
  selected: "",
  disabled: ""
}
const _hoisted_7 = ["label"]
const _hoisted_8 = ["value", "disabled"]
const _hoisted_9 = ["value", "disabled"]

import { computed, PropType, ref, useId } from 'vue';
import { IOption, IOptionGroup } from '@/lib';
import { BaseIcon } from '@/lib/components/Icon';
import { InputTextHelper } from '@/lib/components/Helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSelect',
  props: {
  modelValue: {
    type: [String, Number, Boolean],
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  displayError: {
    type: Boolean,
    default: true
  },
  isOptional: {
    type: Boolean,
    default: false
  },
  iconStroke: {
    type: Boolean,
    default: false
  },
  truncate: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  options: {
    type: Array as PropType<Array<IOption | IOptionGroup>>,
    default: () => []
  },
  error: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: null
  },
  iconPosition: {
    type: String,
    default: 'left'
  },
  iconColor: {
    type: String,
    default: null
  },
  iconHeight: {
    type: String,
    default: '20px'
  },
  instructions: {
    type: String,
    default: null
  },
  lineHeight: {
    type: Number,
    default: 1
  },
  readonly: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
},
  emits: ["update:modelValue"],
  setup(__props) {

const props = __props;


const select = ref(null);

const id = useId();
const focused = ref(false);

const item = computed(() => exposed.findRecursive(props.options, props.modelValue));

const findRecursive = (options: Array<IOption | IOptionGroup>, value: typeof props.modelValue): IOption | null | undefined => {
  const searchTree = (item: IOption | IOptionGroup, value: typeof props.modelValue): IOption | null => {
    // eslint-disable-next-line eqeqeq
    if ('value' in item && (item as IOption).value == value) {
      return item;
    }
    let result: IOption | null = null;
    if ('options' in item && item.options?.length) {
      for (let i = 0; !result && i < item.options.length; i++) {
        result = searchTree(item.options[i], value);
      }
    }
    return result;
  };
  return options.map((opt) => searchTree(opt, value)).find((opt) => !!opt);
};
const focus = () => {
  focused.value = true;
};
const blur = () => {
  focused.value = false;
};
const exposed = {
  findRecursive
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ((__props.label?.length) || _ctx.$slots.action)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", {
            for: _unref(id),
            class: _normalizeClass(["text-base font-medium leading-tight", { 'text-transparent bg-gray-200 rounded-lg': __props.loading }])
          }, _toDisplayString(__props.label), 11, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            (__props.isOptional)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["font-medium text-xs", __props.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500'])
                }, _toDisplayString(_ctx.$t('platform.common.optional')), 3))
              : _createCommentVNode("", true),
            (_ctx.$slots.action)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["text-xs font-semibold", __props.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-primary-500'])
                }, [
                  _renderSlot(_ctx.$slots, "action")
                ], 2))
              : _createCommentVNode("", true)
          ]),
          (__props.instructions && __props.instructions.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["instructions", __props.loading ? 'text-transparent bg-gray-200 rounded-lg' : 'text-gray-500'])
              }, _toDisplayString(__props.instructions), 3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center w-full h-12 bg-white border rounded", {
        'border-danger-700 hover:border-danger-700 focus:border-danger-700 focus:outline-none':
          __props.error,
        'bg-gray-50 border-gray-200': __props.readonly,
        'outline-primary-500': focused.value && !__props.loading
      }])
    }, [
      _renderSlot(_ctx.$slots, "left"),
      (!__props.loading && __props.icon?.length)
        ? (_openBlock(), _createBlock(_unref(BaseIcon), {
            key: 0,
            class: _normalizeClass(['text-' + __props.iconColor, __props.iconPosition === 'right' ? 'order-1' : 'mr-2']),
            position: __props.iconPosition === 'left' ? 'right' : 'left',
            name: __props.icon,
            height: __props.iconHeight,
            stroke: __props.iconStroke
          }, null, 8, ["class", "position", "name", "height", "stroke"]))
        : _createCommentVNode("", true),
      (!__props.readonly)
        ? (_openBlock(), _createElementBlock("select", _mergeProps({ key: 1 }, _ctx.$attrs, {
            id: _unref(id),
            ref_key: "select",
            ref: select,
            class: ["w-full h-full pr-10 leading-normal appearance-none bg-white form-select focus:outline-none", {
          'text-gray-400': __props.modelValue === '',
          'leading-none': __props.lineHeight === 1,
          'leading-tight': __props.lineHeight > 1,
          invisible: __props.loading,
          truncate: __props.truncate
        }],
            value: __props.modelValue,
            disabled: __props.disabled,
            name: __props.name,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).value))),
            onFocus: focus,
            onBlur: blur
          }), [
            (__props.placeholder)
              ? (_openBlock(), _createElementBlock("option", _hoisted_6, _toDisplayString(__props.placeholder), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: (option as IOption).value ?? option.label
              }, [
                ((option as IOptionGroup).options)
                  ? (_openBlock(), _createElementBlock("optgroup", {
                      key: 0,
                      label: option.label
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((option as IOptionGroup).options, (nestedOption) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: nestedOption.value,
                          value: nestedOption.value,
                          disabled: nestedOption.disabled
                        }, _toDisplayString(nestedOption.label), 9, _hoisted_8))
                      }), 128))
                    ], 8, _hoisted_7))
                  : (_openBlock(), _createElementBlock("option", {
                      key: typeof option === 'object' ? (option as IOption).value : option,
                      value: typeof option === 'object' ? (option as IOption).value : option,
                      disabled: (option as IOption).disabled
                    }, _toDisplayString(typeof option === 'object' ? option.label : option), 9, _hoisted_9))
              ], 64))
            }), 128))
          ], 16, _hoisted_5))
        : (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: "w-full h-full flex items-center px-4 text-gray-900 border-none rounded focus:outline-none",
            tabindex: "0",
            onFocus: focus,
            onBlur: blur
          }, _toDisplayString(item.value?.label ?? ''), 33)),
      _renderSlot(_ctx.$slots, "right")
    ], 2),
    _createVNode(_unref(InputTextHelper), {
      error: __props.displayError ? __props.error : '',
      loading: __props.loading,
      description: __props.description
    }, null, 8, ["error", "loading", "description"])
  ]))
}
}

})