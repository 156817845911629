import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["tabindex"]
const _hoisted_2 = ["disabled", "value", "checked"]

import { computed, PropType } from 'vue';
import { BaseTooltip } from '@/lib/components/Tooltip';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseRadio',
  props: {
  modelValue: {
    type: [String, Boolean],
    default: ''
  },
  value: {
    type: [String, Boolean],
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  labelPosition: {
    type: String,
    validator: (value: string) => ['left', 'right'].includes(value),
    default: 'right'
  },
  title: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  light: {
    type: Boolean,
    default: false
  },
  error: {
    type: String as PropType<string | null>,
    default: null
  }
},
  emits: ["update", "update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const style = computed(() => ({
  label: props.light
    ? ['inline-flex items-center mr-2 focus:outline-none']
    : [
      'rounded flex items-center border border-solid transition-all duration-150 space-x-3 p-4',
      props.disabled || props.readonly
        ? checked.value
          ? 'border-primary-500 outline-primary-500 bg-gray-50 cursor-default'
          : 'border-gray-200 bg-gray-50 cursor-default'
        : props.error
          ? 'bg-white border-danger-700 cursor-pointer'
          : checked.value && !props.disabled && !props.readonly
            ? 'bg-white border-primary-500 outline-primary-500 shadow cursor-pointer'
            : 'bg-white border-gray-300 border border-solid shadow hover:shadow-md hover:border-gray-400 cursor-pointer'
    ],
  radio: [
    props.light && props.error ? 'form-radio outline-danger-500' : 'form-radio outline-none',
    props.labelPosition === 'right' ? (props.light ? 'mr-3' : '') : 'order-2 ml-3'
  ]
}));
const checked = computed<boolean>(() => !props.loading && props.modelValue === props.value);
const onChange = (value: any) => {
  if (!props.loading && !props.disabled && !props.readonly) {
    emit('update', value);
    emit('update:modelValue', value);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseTooltip), {
    position: "above",
    disabled: !__props.title.length || __props.loading
  }, {
    target: _withCtx(() => [
      _createElementVNode("label", {
        class: _normalizeClass(style.value.label),
        tabindex: __props.readonly ? 0 : -1
      }, [
        _createElementVNode("input", _mergeProps({
          type: "radio",
          class: style.value.radio,
          disabled: __props.disabled || __props.loading || (__props.light ? __props.readonly && !checked.value : __props.readonly)
        }, _ctx.$attrs, {
          value: __props.value,
          checked: checked.value,
          onChange: _cache[0] || (_cache[0] = ($event: any) => (onChange(__props.value)))
        }), null, 16, _hoisted_2),
        _createElementVNode("span", {
          class: _normalizeClass(["text-base", { 'text-transparent bg-gray-200 rounded-lg h-5': __props.loading }])
        }, _toDisplayString(__props.label), 3)
      ], 10, _hoisted_1)
    ]),
    content: _withCtx(() => [
      _createTextVNode(_toDisplayString(__props.title), 1)
    ]),
    _: 1
  }, 8, ["disabled"]))
}
}

})