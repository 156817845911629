import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, defineAsyncComponent, onBeforeUnmount, PropType, watch } from 'vue';
import BaseDatePicker from '@/lib/components/Date/BaseDatePicker.vue';
import { BaseTextInput } from '@/lib/components/Input';
import { RadioGroup } from '@/lib/components/Radio';
import { CheckboxGroup } from '@/lib/components/Checkbox';
import { BaseSelect, SelectInput } from '@/lib/components/Select';
import { BaseTextarea } from '@/lib/components/Textarea';
import { FormItem } from '@/models';
import { useSmartFormStore } from '@/stores/smartForm.store';
import MedKitBadge from '@/lib/components/Badge/MedKitBadge.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SmartFormRepeaterItem',
  props: {
  modelValue: {
    type: [String, Number, Boolean, Array, Object],
    default: null
  },
  item: {
    type: Object as PropType<FormItem>,
    required: true
  },
  error: {
    type: String,
    default: null
  },
  formSubmissionId: {
    type: String,
    required: true
  },
  readonly: {
    type: Boolean,
    default: false
  }
},
  emits: ["update:modelValue", "change"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const componentTypes = {
  text: BaseTextInput,
  checkbox: CheckboxGroup,
  date: BaseDatePicker,
  badge: MedKitBadge,
  select: BaseSelect,
  textarea: BaseTextarea,
  radio: RadioGroup,
  selectInput: SelectInput
};
const smartForm = useSmartFormStore();

const componentType = computed(() => {
  if (props.item.type === 'component') {
    return defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "component-[request]" */
          `@/${props.item.component}.vue`
        )
    );
  }

  return componentTypes[props.item.type];
});
const conditionsMet = computed(() => smartForm.getItemConditionsMet(props.formSubmissionId, props.item.conditions));

watch(conditionsMet, () => exposed.clearAnswerWhenConditionsNotMet());

onBeforeUnmount(() => {
  exposed.clearAnswerWhenConditionsNotMet();
});
const clearAnswerWhenConditionsNotMet = () => {
  if (props.modelValue !== null && typeof props.modelValue !== 'undefined' && !conditionsMet.value) {
    emit('update:modelValue', null);
    emit('change');
  }
};
const exposed = {
  clearAnswerWhenConditionsNotMet
};

return (_ctx: any,_cache: any) => {
  return (conditionsMet.value && __props.item.type !== 'hidden')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({
      'p-6 bg-white border rounded-lg shadow': __props.item.container === 'card'
    })
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(componentType.value), _mergeProps({
          id: __props.item.id,
          "is-optional": __props.item.optional,
          "model-value": __props.modelValue,
          error: __props.error,
          readonly: __props.readonly
        }, { ...__props.item.props, ..._ctx.$attrs }, {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change', $event)))
        }), null, 16, ["id", "is-optional", "model-value", "error", "readonly"]))
      ], 2))
    : _createCommentVNode("", true)
}
}

})