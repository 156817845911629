import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "cursor-pointer z-10",
  type: "button"
}

import { BaseIcon } from '@/lib/components/Icon';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClearButton',
  props: {
  dark: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createVNode(_unref(BaseIcon), {
      class: _normalizeClass([__props.dark ? 'bg-gray-700 hover:bg-gray-500' : 'bg-gray-300 hover:bg-gray-900', "text-white rounded-full p-px stroke-1.5"]),
      name: "close"
    }, null, 8, ["class"])
  ]))
}
}

})