import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white border-b border-gray-300" }
const _hoisted_2 = { class: "flex justify-between w-full p-6 mx-auto max-w-screen-xl" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "ml-6" }
const _hoisted_5 = {
  class: "text-lg font-bold text-gray-900",
  "data-cy": "nav-patient-name"
}
const _hoisted_6 = { class: "uppercase" }
const _hoisted_7 = {
  class: "text-base font-semibold uppercase text-gray-400",
  "data-cy": "nav-patient-id"
}
const _hoisted_8 = { class: "grid gap-x-8 grid-rows-2 grid-flow-col" }
const _hoisted_9 = { class: "flex justify-between" }
const _hoisted_10 = { class: "text-gray-500 mr-3" }
const _hoisted_11 = {
  class: "font-medium",
  "data-cy": "nav-patient-dob"
}
const _hoisted_12 = { class: "flex justify-between" }
const _hoisted_13 = { class: "text-gray-500 mr-3" }
const _hoisted_14 = {
  class: "font-medium",
  "data-cy": "nav-patient-age"
}
const _hoisted_15 = { class: "flex justify-between" }
const _hoisted_16 = { class: "text-gray-500 mr-3" }
const _hoisted_17 = {
  class: "font-medium",
  "data-cy": "nav-patient-sex"
}
const _hoisted_18 = { class: "flex justify-between" }
const _hoisted_19 = { class: "text-gray-500 mr-3" }
const _hoisted_20 = {
  class: "font-medium",
  "data-cy": "nav-patient-ethnicity"
}
const _hoisted_21 = { class: "flex justify-between" }
const _hoisted_22 = { class: "text-gray-500 mr-3" }
const _hoisted_23 = {
  class: "font-medium",
  "data-cy": "nav-patient-phone"
}
const _hoisted_24 = {
  key: 0,
  class: "border-t"
}
const _hoisted_25 = { class: "flex w-full h-16 mx-auto max-w-screen-xl" }
const _hoisted_26 = { class: "flex flex-grow" }

import { computed, PropType, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { getDobFromISOString } from '@/helpers/patient.helper';
import { Organisation, PaginatedResponse, Patient } from '@/models';
import BaseIcon from '@/lib/components/Icon/BaseIcon.vue';
import ButtonLink from '@/lib/components/Button/ButtonLink.vue';
import NavLink from '@/lib/components/Navigation/NavLink.vue';
import { FEATURES, sexOptions } from '@/constants';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import axios from 'axios';
import { useNotificationStore } from '@/stores/notification.store';
import { useRoute } from 'vue-router';
import { Journey, JourneyTypeName } from '@/custom/menicon/models';
import { PatientJourneyService } from '@/custom/menicon/services';


export default /*@__PURE__*/_defineComponent({
  __name: 'SingleNavigation',
  props: {
  patient: {
    type: Object as PropType<Patient>,
    required: true
  },
  organisation: {
    type: Object as PropType<Organisation>,
    required: true
  },
  hideTabs: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {

const { t, d } = useI18n();
const props = __props;

const route = useRoute();
const notificationStore = useNotificationStore();
const isVirtualDoctorFeatureEnabled = isFeatureFlagEnabled(FEATURES.VIRTUAL_DOCTOR);
const showBloomAppTab = ref(false);
const controller = new AbortController();

const sex = computed(() => sexOptions().find((option) => option.value === props.patient.sex));

onBeforeMount(async () => {
  if (isVirtualDoctorFeatureEnabled) {
    await fetchPatientJourneys();
  }
});

onBeforeUnmount(() => {
  controller.abort();
});

const patientJourneyService = new PatientJourneyService(props.patient.id);

const fetchPatientJourneys = async () => {
  try {
    const response = (await patientJourneyService.index({
      signal: controller.signal
    })) as PaginatedResponse<Journey[]>;
    showBloomAppTab.value = response.data.some((journey: Journey) =>
      (journey.type === JourneyTypeName.MENICON_BLOOM_NIGHT) || (journey.type === JourneyTypeName.MENICON_BLOOM_DAY));
  } catch (e) {
    if (!axios.isCancel(e)) {
      notificationStore.addErrorNotification({ title: t('custom.menicon.prescriptions.fetch-error') });
    }
  }
};

const formatDate = (date: string | null, type = 'short'): string => date ? d(getDobFromISOString(date), type) : '';

return (_ctx: any,_cache: any) => {
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(ButtonLink, {
          to: { name: 'patient-list', params: { organisationId: __props.organisation.id } },
          color: "primary"
        }, {
          default: _withCtx(() => [
            _createVNode(BaseIcon, {
              name: "arrow-left",
              class: "text-lg text-white stroke-1.5"
            })
          ]),
          _: 1
        }, 8, ["to"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(__props.patient.last_name), 1),
            _createTextVNode(", " + _toDisplayString(__props.patient.first_name), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('platform.common.id')) + ": " + _toDisplayString(__props.patient.mrn), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('platform.patient.dob')), 1),
          _createElementVNode("span", _hoisted_11, _toDisplayString(formatDate(__props.patient.date_of_birth)), 1)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('platform.patient.age')), 1),
          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('platform.patient.years', {age: __props.patient.age})), 1)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("span", null, [
            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('platform.patient.sex')), 1),
            _createElementVNode("span", _hoisted_17, _toDisplayString(sex.value?.label), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('platform.patient.ethnicity')), 1),
          _createElementVNode("span", _hoisted_20, _toDisplayString(__props.patient.ethnicity ? __props.patient.ethnicity.title : _ctx.$t('platform.patient.not-specified')), 1)
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('platform.patient.phone')), 1),
          _createElementVNode("span", _hoisted_23, _toDisplayString(__props.patient.contact_number), 1)
        ])
      ])
    ]),
    (!__props.hideTabs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, [
              _createVNode(NavLink, {
                "data-cy": "nav-patient-details-nav",
                to: {
              name: 'patient-details',
              params: { patientId: _unref(route).params.patientId, organisationId: __props.organisation.id }
            }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.details')), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _withDirectives((_openBlock(), _createBlock(NavLink, {
                "data-cy": "prescriptions-nav",
                to: {
              name: 'patient-prescriptions',
              params: { patientId: _unref(route).params.patientId, organisationId: __props.organisation.id }
            }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.prescriptions.prescriptions')), 1)
                ]),
                _: 1
              }, 8, ["to"])), [
                [_directive_allow, 'menicon-prescription:manage']
              ]),
              _withDirectives((_openBlock(), _createBlock(NavLink, {
                to: {
              name: 'patient-scans',
              params: { patientId: _unref(route).params.patientId, organisationId: __props.organisation.id }
            }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.imaging')), 1)
                ]),
                _: 1
              }, 8, ["to"])), [
                [_directive_allow, 'patent-scans:read']
              ]),
              (showBloomAppTab.value)
                ? _withDirectives((_openBlock(), _createBlock(NavLink, {
                    key: 0,
                    "data-cy": "virtual-doctor-nav",
                    to: {
              name: 'virtual-doctor',
              params: { patientId: _unref(route).params.patientId, organisationId: __props.organisation.id }
            }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.virtual-doctor.title')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])), [
                    [_directive_allow, 'menicon-virtual-doctor:read']
                  ])
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})