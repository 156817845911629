import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex flex-row summary-item" }
const _hoisted_2 = {
  key: 0,
  class: "space-x-3 text-base font-semibold text-black flex flex-row",
  style: {"width":"150px"}
}
const _hoisted_3 = ["data-dusk"]
const _hoisted_4 = {
  key: 3,
  "data-dusk": "answer"
}

import FormSummaryTable from '@/custom/menicon/components/encounter-summary/FormSummaryTable.vue';
import FormSummaryGrid from '@/custom/menicon/components/encounter-summary/FormSummaryGrid.vue';
import { BaseIcon } from '@/lib/components';
import { i18n } from '@/i18n/i18n';
import { PropType } from 'vue';
import { stringToDuskAttribute } from '@/custom/menicon/helpers/strings.helper';
import {
  EncounterGridSummaryData,
  EncounterSummaryDataDisplay, EncounterSummaryDataType,
  EncounterTableSummaryData
} from '@/custom/menicon/helpers/form-summary.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'FormSummaryQuestion',
  props: {
  question: {
    type: String,
    required: true
  },
  value: {
    type: [Object, String, Array] as PropType<string |EncounterGridSummaryData['value'] | EncounterTableSummaryData['value']>,
    default: null
  },
  type: {
    type: String as PropType<EncounterSummaryDataType>,
    default: EncounterSummaryDataType.STRING,
    validator: (value: EncounterSummaryDataType) => ['table', 'grid', 'string'].includes(value)
  },
  display: {
    type: String as PropType<EncounterSummaryDataDisplay>,
    default: EncounterSummaryDataDisplay.ROW,
    validator: (value: EncounterSummaryDataDisplay) => Object.values(EncounterSummaryDataDisplay).includes(value)
  },
  lineClass: {
    type: String,
    default: ''
  },
  questionClass: {
    type: String,
    default: 'text-sm font-medium text-gray-500'
  },
  laterality: {
    type: String,
    default: null
  },
  locale: {
    type: String,
    default: i18n.global.locale.value
  },
  isPdf: {
    type: Boolean,
    default: false
  },
  description: {
    type: String,
    default: ''
  },
  action: {
    type: Object as PropType<EncounterTableSummaryData['action']>,
    default: null
  }
},
  emits: ["viewScan"],
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.laterality)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (__props.laterality !== 'spacer')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", {
                  class: _normalizeClass({ 'mt-2': __props.isPdf })
                }, [
                  _createVNode(_unref(BaseIcon), {
                    name: `${__props.laterality}-eye${__props.laterality === 'both' ? 's' : ''}`,
                    stroke: false
                  }, null, 8, ["name"])
                ], 2),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t(`platform.eyes.${__props.laterality}-eye${__props.laterality === 'both' ? 's' : ''}`, [], {locale: __props.locale})), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex-grow", [__props.display === 'row' ? 'grid md:grid-cols-2 grid-cols-1 gap-y-2 md:gap-x-2 w-full' : 'flex flex-col space-y-2', __props.lineClass]]),
      "data-dusk": _unref(stringToDuskAttribute)(__props.question)
    }, [
      (__props.question)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(__props.questionClass)
          }, _toDisplayString(__props.question), 3))
        : _createCommentVNode("", true),
      (__props.type === 'table')
        ? (_openBlock(), _createBlock(FormSummaryTable, {
            key: 1,
            description: __props.description,
            columns: (__props.value as EncounterTableSummaryData['value'])?.columns,
            rows: (__props.value as EncounterTableSummaryData['value'])?.rows,
            locale: __props.locale,
            border: (__props.value as EncounterTableSummaryData['value'])?.border,
            action: __props.action,
            "is-pdf": __props.isPdf,
            onViewScan: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('viewScan', $event)))
          }, null, 8, ["description", "columns", "rows", "locale", "border", "action", "is-pdf"]))
        : (__props.type === 'grid')
          ? (_openBlock(), _createBlock(FormSummaryGrid, {
              key: 2,
              value: __props.value as EncounterGridSummaryData['value'],
              "is-pdf": __props.isPdf
            }, null, 8, ["value", "is-pdf"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(__props.value), 1)),
      _renderSlot(_ctx.$slots, "default")
    ], 10, _hoisted_3)
  ]))
}
}

})