import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
  BadgeKind,
  validateIsBadgeKind,
  BadgeSize,
  validateIsBadgeSize,
  BadgeColorDefinitionList,
  BadgeSizeClassList
} from '@/lib/components/Badge/index';


import { computed, PropType } from 'vue';

const __default__ = {
  name: 'MedKitBadge'
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
  /**
   * Describes the hierarchy and intent of the badge.
   */
  kind: {
    type: String as PropType<BadgeKind>,
    default: (): BadgeKind => 'stable',
    validator: validateIsBadgeKind
  },
  /**
   * The label text of the badge.
   */
  label: {
    type: String,
    required: true
  },
  /**
   * The size of the badge.
   */
  size: {
    type: String as PropType<BadgeSize>,
    default: (): BadgeSize => 'medium',
    validator: validateIsBadgeSize
  },
  /**
   * The changes the colour of the badge to a darker tint.
   */
  tint: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {

const props = __props;

const colors = computed(
  (): BadgeColorDefinitionList => ({
    stable: {
      text: props.tint ? 'text-white' : 'text-gray-800',
      bg: props.tint ? 'bg-gray-900' : 'bg-gray-100'
    },
    low: {
      text: props.tint ? 'text-white' : 'text-primary-800',
      bg: props.tint ? 'bg-primary-500' : 'bg-primary-100'
    },

    medium: {
      text: props.tint ? 'text-white' : 'text-success-800',
      bg: props.tint ? 'bg-success-500' : 'bg-success-100'
    },

    high: {
      text: props.tint ? 'text-gray-900' : 'text-alert-800',
      bg: props.tint ? 'bg-alert-300' : 'bg-alert-100'
    },

    critical: {
      text: props.tint ? 'text-white' : 'text-danger-800',
      bg: props.tint ? 'bg-danger-500' : 'bg-danger-100'
    }
  })
);

const sizes: BadgeSizeClassList = {
  tiny: 'h-4 px-1 py-0.5 text-xs leading-snug font-semibold',
  small: 'h-6 px-2 py-1.5 text-xs leading-snug font-semibold',
  medium: 'h-8 px-4 py-2 text-sm leading-snug font-medium',
  large: 'h-10 px-4 py-3 text-sm leading-snug font-medium'
};

const textColorClass = computed((): string => colors.value[props.kind].text);

const bgColorClass = computed((): string => colors.value[props.kind].bg);

const sizeClasses = computed((): string => sizes[props.size]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[textColorClass.value, bgColorClass.value, sizeClasses.value], "inline-flex items-center justify-center text-center rounded"]),
    style: {"width":"max-content"}
  }, _toDisplayString(__props.label), 3))
}
}

})