import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "text-sm font-medium text-white" }

import { computed, onMounted, ref, watch } from 'vue';
import { BaseIcon } from '@/lib/components/Icon';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSnackbar',
  props: {
  label: {
    type: String,
    default: ''
  },
  autoDismiss: {
    type: Boolean,
    default: true
  },
  dismissAfter: {
    type: Number,
    default: 3000
  },
  action: {
    type: Object,
    default: null
  },
  color: {
    type: String,
    default: 'base',
    validator: (value: string) => ['base', 'danger', 'success'].includes(value)
  },
  icon: {
    type: String,
    default: 'tick'
  },
  dark: {
    type: Boolean,
    default: false
  },
  showClose: {
    type: Boolean,
    default: false
  }
},
  emits: ["dismiss"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const timeout = ref(null);

const bgColorClass = computed<string>(() => {
  if (props.color === 'danger') {
    return 'bg-danger-600';
  }

  if (props.color === 'success') {
    return 'bg-success-600';
  }

  return 'bg-gray-900';
});
const separatorColorClass = computed<string>(() => {
  if (props.color === 'danger') {
    return 'border-danger-300';
  }

  if (props.color === 'success') {
    return 'border-success-300';
  }

  return 'border-gray-800';
});
const actionColorClass = computed<string>(() => {
  if (props.color === 'danger') {
    return 'text-danger-200';
  }

  if (props.color === 'success') {
    return 'text-success-200';
  }

  return 'text-gray-500';
});
const dismissColorClass = computed<string>(() => {
  if (props.color === 'danger') {
    return 'hover:text-danger-300';
  }

  if (props.color === 'success') {
    return 'hover:text-success-300';
  }

  return 'hover:text-gray-500';
});

watch([() => props.autoDismiss, () => props.dismissAfter], () => exposed.setDismiss());

onMounted(() => {
  exposed.setDismiss();
});
const setDismiss = () => {
  if (props.autoDismiss) {
    timeout.value = window.setTimeout(() => emit('dismiss'), props.dismissAfter);
  } else if (timeout.value) {
    window.clearTimeout(timeout.value);
  }
};
const exposed = {
  setDismiss
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(
      __props.dark
        ? 'background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%)'
        : 'background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%)'
    ),
    class: "flex w-full justify-center items-end fixed py-8 bottom-0 right-0 left-0 h-48 z-50 pointer-events-none"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-row items-center py-3 px-4 rounded text-white space-x-4 pointer-events-auto", bgColorClass.value])
    }, [
      (__props.icon)
        ? (_openBlock(), _createBlock(_unref(BaseIcon), {
            key: 0,
            name: __props.icon,
            height: "16px"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, _toDisplayString(__props.label), 1),
      (__props.action || !__props.autoDismiss)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["h-6 border", separatorColorClass.value])
          }, null, 2))
        : _createCommentVNode("", true),
      (__props.action)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["text-sm font-medium hover:text-white", actionColorClass.value])
          }, [
            _createVNode(_component_router_link, {
              to: __props.action.to
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(__props.action.label), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ], 2))
        : _createCommentVNode("", true),
      (!__props.action && (!__props.autoDismiss || __props.showClose))
        ? (_openBlock(), _createElementBlock("button", {
            key: 3,
            type: "button",
            class: _normalizeClass(["focus:outline-none", dismissColorClass.value]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dismiss')))
          }, [
            _createVNode(_unref(BaseIcon), {
              name: "close",
              height: "16px"
            })
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 4))
}
}

})