import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCard',
  props: {
  padding: {
    type: String,
    default: '8'
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bg-white border border-gray-200 rounded-lg shadow", `p-${__props.padding}`])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})