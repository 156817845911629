import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["tabindex"]
const _hoisted_2 = ["name", "disabled", "checked"]

import { ICheckboxOption } from '@/lib';
import { InputTextHelper } from '@/lib/components/Helper';
import { computed, PropType, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCheckbox',
  props: {
  modelValue: {
    type: [Boolean, Array] as PropType<boolean | string[] | number[]>,
    required: true
  },

  checkboxClass: {
    type: String,
    default: 'ml-4'
  },

  value: {
    type: [String, Number],
    default: ''
  },

  disabled: {
    type: Boolean,
    default: false
  },

  readonly: {
    type: Boolean,
    default: false
  },

  loading: {
    type: Boolean,
    default: false
  },

  label: {
    type: String,
    default: ''
  },

  shadow: {
    type: Boolean,
    default: true
  },

  border: {
    type: Boolean,
    default: true
  },

  dark: {
    type: Boolean,
    default: false
  },

  error: {
    type: String,
    default: null
  },

  displayError: {
    type: Boolean,
    default: true
  },

  hoverBgClass: {
    type: String,
    default: ''
  },

  textClass: {
    type: String,
    default: 'text-red'
  },

  labelClass: {
    type: String,
    default: ''
  }
},
  emits: ["update", "update:modelValue", "update:listObject"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;
const emit = __emit;

const checkbox = ref(null);

const isHovering = ref(false);

const computedModel = computed<string[] | number[] | boolean>(() => {
  if (props.modelValue === null) {
    return [];
  }
  return props.modelValue;
});
const checked = computed<boolean>(() => {
  if (Array.isArray(computedModel.value)) {
    return (computedModel.value as any[]).includes(props.value);
  }
  return !!computedModel.value;
});

const update = () => {
  const checked = (checkbox.value as HTMLInputElement).checked;

  if (!Array.isArray(computedModel.value)) {
    emit('update', checked);
    emit('update:modelValue', checked);
    return;
  }

  const updatedValue = checked ? [...computedModel.value, props.value] :
    (computedModel.value as any[]).filter((value) => value !== props.value);
  emit('update', updatedValue as typeof props.modelValue);
  emit('update:modelValue', updatedValue as typeof props.modelValue);

  emit('update:listObject', {
    id: props.value as string,
    checked,
    label: props.label
  });
};
const uncheck = () => {
  (checkbox.value as HTMLInputElement).checked = false;
  emit('update:listObject', {
    id: props.value as string,
    checked: false,
    label: props.label
  });
};

__expose({
  uncheck
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      class: _normalizeClass(["rounded inline-flex items-center transition-all duration-150 cursor-pointer", [
        {
          'border border-danger-700': __props.error && !__props.disabled,
          'border border-solid border-gray-300 bg-gray-100 cursor-not-allowed': __props.disabled,
          'bg-white border-gray-300 border border-solid shadow hover:shadow-md hover:border-gray-400':
            !__props.disabled && !__props.dark && (__props.border || __props.shadow),
          'border border-solid border-primary-500 outline-primary-500':
            checked.value && !__props.disabled && !__props.loading && !__props.error && !__props.dark && (__props.border || __props.shadow),
          'bg-white': checked.value && !__props.disabled && !__props.loading && !__props.error && !__props.readonly && !__props.dark && (__props.border || __props.shadow),
          'bg-danger-500 shadow': checked.value && !__props.disabled && __props.dark,
          'bg-white outline-none': !__props.border && !__props.shadow,
          'shadow-none': !__props.shadow,
          'border-none': !__props.border,
          'border-gray-800 border border-solid shadow hover:shadow-md hover:border-gray-400 bg-gray-900':
            !__props.disabled && __props.dark,
          'text-gray-600 bg-gray-50': __props.readonly
        },
        isHovering.value ? __props.hoverBgClass : '',
        __props.labelClass
      ]]),
      tabindex: __props.readonly ? 0 : -1,
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (isHovering.value = true)),
      onMouseout: _cache[1] || (_cache[1] = ($event: any) => (isHovering.value = false))
    }, [
      _createElementVNode("input", _mergeProps({
        ref_key: "checkbox",
        ref: checkbox,
        class: ["form-checkbox outline-none", [
          __props.disabled || __props.loading || __props.readonly ? 'cursor-not-allowed' : 'cursor-pointer',
          __props.dark ? 'text-danger-900 bg-gray-800' : '',
          __props.checkboxClass
        ]],
        type: "checkbox",
        name: __props.value as string
      }, _ctx.$attrs, {
        disabled: __props.disabled || __props.loading || __props.readonly,
        checked: checked.value,
        onChange: update
      }), null, 16, _hoisted_2),
      (!_ctx.$slots.customLabel)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["p-3 text-base", [{ 'text-white': __props.dark, 'text-transparent bg-gray-200 rounded-lg h-5 m-3': __props.loading }, __props.textClass]])
          }, _toDisplayString(__props.label), 3))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "customLabel")
    ], 42, _hoisted_1),
    _createVNode(_unref(InputTextHelper), {
      border: true,
      error: __props.displayError ? __props.error : '',
      loading: __props.loading
    }, null, 8, ["error", "loading"])
  ]))
}
}

})