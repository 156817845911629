import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

import { computed, PropType, ref } from 'vue';
import { DifferenceMapsType, KUnit, MeniconImageDetailsLegendItem } from '@/custom/menicon/models';
import TopographyScansScaleItem from '@/custom/menicon/components/scans/TopographyScansScaleItem.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TopographyScansScale',
  props: {
  legendItems: {
    type: Array as PropType<MeniconImageDetailsLegendItem[]>,
    default: () => []
  },
  unit: {
    type: String as PropType<KUnit>,
    default: KUnit.MM
  },
  type: {
    type: String as PropType<DifferenceMapsType>,
    default: DifferenceMapsType.AXIAL
  },
  horizontal: {
    type: Boolean,
    default: false
  },
  height: {
    type: String,
    default: null
  }
},
  setup(__props) {

const props = __props;

const title = ref(null);
const legendItem = ref(null);

const items = computed<MeniconImageDetailsLegendItem[]>(() => [...props.legendItems]
  .sort((a, b) => a.displayOrder - b.displayOrder)
  .map((legendItem) => ({
    ...legendItem,
    value: props.unit === KUnit.DIOPTRES ? Math.round((337.5 / legendItem.value) * 100) / 100 : legendItem.value
  }))
);

const itemHeight = computed<number>(() => {
  if (props.height && !props.horizontal) {
    const totalHeight = +props.height.split('px')[0];
    const titleHeight = title.value?.offsetHeight ?? 0;
    const height = (totalHeight - titleHeight) / items.value.length;
    return height > 30 ? 30 : height < 21 ? 21 : height;
  }
  return 30;
});
const itemWidth = computed<number>(() => {
  if (props.horizontal) {
    const max = (legendItem.value ?? []).reduce((maxWidth, element) =>
      maxWidth > (element?.text?.offsetWidth + 4) ? maxWidth : (element?.text?.offsetWidth + 4)
    , 0);
    return max <= 4 ? 32 : max;
  }
  return 32;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex", __props.horizontal ? 'flex-row items-center space-x-2' : 'flex-col space-y-2']),
    style: _normalizeStyle(`${__props.horizontal ? 'height' : 'width'}: max-content`)
  }, [
    _createElementVNode("div", {
      ref_key: "title",
      ref: title,
      class: _normalizeClass(["flex opacity-80 text-white text-sm font-bold leading-normal", __props.horizontal ? 'flex-col' : 'flex-row space-x-1'])
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t(`custom.menicon.scans.${__props.type}`)), 1),
      _createElementVNode("p", null, " (" + _toDisplayString(__props.unit) + ") ", 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["flex", __props.horizontal ? 'flex-row' : 'flex-col']),
      style: _normalizeStyle(`${__props.horizontal ? 'height' : 'width'}: max-content`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
        return (_openBlock(), _createBlock(TopographyScansScaleItem, {
          ref_for: true,
          ref_key: "legendItem",
          ref: legendItem,
          key: item.value,
          value: item.value,
          color: item.hexColor,
          "is-first": !index,
          "is-last": index === items.value.length - 1,
          vertical: __props.horizontal,
          height: `${itemHeight.value}px`,
          width: `${itemWidth.value}px`
        }, null, 8, ["value", "color", "is-first", "is-last", "vertical", "height", "width"]))
      }), 128))
    ], 6)
  ], 6))
}
}

})