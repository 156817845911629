import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-4"
}

import { FormItem } from '@/models';
import FormSummaryQuestion from '@/custom/menicon/components/encounter-summary/FormSummaryQuestion.vue';
import {
  EncounterSummaryDataDisplay,
  EncounterSummaryDataType,
  formatFormSchemaAnswer,
  shouldDisplayFormSchemaQuestion
} from '@/custom/menicon/helpers/form-summary.helper';
import { i18n } from '@/i18n/i18n';
import { PropType, computed } from 'vue';
import { useSmartFormStore } from '@/stores/smartForm.store';
import { stringToDuskAttribute } from '@/custom/menicon/helpers/strings.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'FormSummaryItem',
  props: {
  item: {
    type: Object as PropType<FormItem>,
    required: true
  },
  formSubmissionId: {
    type: String,
    required: true
  },
  locale: {
    type: String,
    default: i18n.global.locale.value
  },
  isPdf: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {

const props = __props;

const smartForm = useSmartFormStore();

const displayQuestion = computed<boolean>(() => shouldDisplayFormSchemaQuestion(props.item));
const duskAttribute = computed<string>(() => {
  const label = props.item.label || props.item.props?.label;
  return stringToDuskAttribute(label);
});
const answerType = computed<EncounterSummaryDataType>(() => {
  if (props.item.type === 'group' && props.item.layout === 'grid') {
    return isTable(props.item.items) ? EncounterSummaryDataType.TABLE : EncounterSummaryDataType.GRID;
  }
  if (
    props.item.type === 'repeater' ||
    (props.item.type === 'component' && props.item.component === 'custom/medication/MedicationQuestion')
  ) {
    return EncounterSummaryDataType.TABLE;
  }
  return EncounterSummaryDataType.STRING;
});

const conditionsMet = computed<boolean>(() =>
  smartForm.getItemConditionsMet(props.formSubmissionId, props.item.conditions)
);
const answer = computed(() => getAnswer(props.item.id));
const formattedAnswer = computed(() => {
  if (props.item.type === 'group' && props.item.items) {
    return isTable(props.item.items) ? convertGroupToTableAnswer(props.item.items) :
      convertGroupToGridAnswer(props.item.items);
  }
  return formatFormSchemaAnswer(answer.value, props.item, props.locale);
});

const isTable = (items: FormItem[]) => items?.length && !items.find(({ label, props }) => !label && !props?.label);

const convertGroupToGridAnswer = (items: FormItem[]): { icon: string | null; value: string }[] => items
  .filter((item) => smartForm.getItemConditionsMet(props.formSubmissionId, item.conditions))
  .map((item) => ({
    icon: item.props?.icon || null,
    value: item.type === 'spacer' ? '' : formatFormSchemaAnswer(getAnswer(item.id), item, props.locale)
  }));

const convertGroupToTableAnswer = (items: FormItem[]): { columns: string[], rows: any[][] } => {
  const displayedItems = items
    .filter((item) => smartForm.getItemConditionsMet(props.formSubmissionId, item.conditions));
  const columns = displayedItems.map(({ label, props }) => label || props?.label || '');
  const rows = [displayedItems.map((item) => item.type === 'spacer' ? '' : formatFormSchemaAnswer(getAnswer(item.id), item, props.locale))];
  return { columns, rows };
};

const getAnswer = (itemId: string) => smartForm.answers[props.formSubmissionId]?.[itemId];

return (_ctx: any,_cache: any) => {
  const _component_FormSummaryItem = _resolveComponent("FormSummaryItem", true)!

  return (
      conditionsMet.value &&
        __props.item &&
        __props.item.type !== 'hidden' &&
        (__props.item.label || __props.item.props?.label || __props.item.type === 'group')
    )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (displayQuestion.value)
          ? (_openBlock(), _createBlock(FormSummaryQuestion, {
              key: 0,
              "data-dusk": duskAttribute.value,
              question: __props.item.label || __props.item.props?.label || '',
              value: formattedAnswer.value,
              type: answerType.value,
              locale: __props.locale,
              display: __props.isPdf ? _unref(EncounterSummaryDataDisplay).COLUMN : _unref(EncounterSummaryDataDisplay).ROW,
              "is-pdf": __props.isPdf
            }, null, 8, ["data-dusk", "question", "value", "type", "locale", "display", "is-pdf"]))
          : _createCommentVNode("", true),
        ((!displayQuestion.value || __props.item.type !== 'group') &&
        __props.item.items &&
        __props.item.type !== 'repeater'
      )
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.item.items, (subItem) => {
              return (_openBlock(), _createBlock(_component_FormSummaryItem, {
                key: subItem.id,
                item: subItem,
                "form-submission-id": __props.formSubmissionId,
                locale: __props.locale,
                "is-pdf": __props.isPdf
              }, null, 8, ["item", "form-submission-id", "locale", "is-pdf"]))
            }), 128))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})