import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "jammer p-14 bg-primary-500 rounded-xl text-white text-lg" }
const _hoisted_2 = { class: "max-w-5xl" }
const _hoisted_3 = { class: "text-2xl font-bold" }
const _hoisted_4 = { class: "mt-8 flex space-x-4" }
const _hoisted_5 = { class: "flex-grow space-x-4" }
const _hoisted_6 = { class: "flex-row-reverse space-x-4" }

import { computed, PropType } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { IRadioJammerOption } from '@/lib';
import { BaseButton } from '@/lib/components/Button';
import BaseModal from './BaseModal.vue';
import { useHistoryStore } from '@/stores/history.store';


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioJammerModal',
  props: {
  title: {
    type: String,
    required: true
  },

  body: {
    type: String,
    required: true
  },

  options: {
    type: Array as PropType<IRadioJammerOption[]>,
    required: true
  },

  disabled: {
    type: Boolean,
    default: false
  },

  next: {
    type: Function,
    required: true
  },

  back: {
    type: Function,
    required: true
  },
  skip: {
    type: Function,
    default: () => ({})
  }
},
  emits: ["input", "change"],
  setup(__props, { emit: __emit }) {

const router = useRouter();
const route = useRoute();
const props = __props;
const emit = __emit;

const historyStore = useHistoryStore();

const leftOptions = computed<IRadioJammerOption[]>(() => props.options.filter((option) => option.position === 'left'));
const rightOptions = computed<IRadioJammerOption[]>(() => props.options.filter((option) => option.position === 'right'));

const click = (option: IRadioJammerOption) => {
  emit('input', option.label ? option.value : option);
  if (option.function === 'back') {
    props.back();
  } else if (option.function === 'next') {
    props.next();
  } else if (option.function === 'skip') {
    props.skip();
  } else if (option.function === 'exit') {
    emit('change');
    cancel();
  }
};
const cancel = () => {
  router.push({
    name: historyStore.from ?? 'patient',
    params: route.params
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseModal, {
    "prevent-autofocus": "",
    "vertical-align": "top"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(__props.title), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.body.split('\n'), (line) => {
            return (_openBlock(), _createElementBlock("p", {
              key: line,
              class: "mt-6"
            }, _toDisplayString(line), 1))
          }), 128)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(leftOptions.value, (option) => {
                return (_openBlock(), _createBlock(_unref(BaseButton), {
                  key: option.label,
                  size: "large",
                  disabled: __props.disabled,
                  "left-icon": option.iconPosition === 'left' ? option.icon : '',
                  "right-icon": option.iconPosition === 'right' ? option.icon : '',
                  color: option.color,
                  onClick: ($event: any) => (click(option))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(option.label ? option.label : option), 1)
                  ]),
                  _: 2
                }, 1032, ["disabled", "left-icon", "right-icon", "color", "onClick"]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rightOptions.value, (option) => {
                return (_openBlock(), _createBlock(_unref(BaseButton), {
                  key: option.label,
                  size: "large",
                  disabled: __props.disabled,
                  "left-icon": option.iconPosition === 'left' ? option.icon : '',
                  "right-icon": option.iconPosition === 'right' ? option.icon : '',
                  color: option.color,
                  onClick: ($event: any) => (click(option))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(option.label ? option.label : option), 1)
                  ]),
                  _: 2
                }, 1032, ["disabled", "left-icon", "right-icon", "color", "onClick"]))
              }), 128))
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})