export const badgeKinds = ['stable', 'low', 'medium', 'high', 'critical'] as const;
// eslint-disable-next-line @typescript-eslint/no-type-alias
export type BadgeKind = typeof badgeKinds[number];
export const validateIsBadgeKind = (value: BadgeKind): boolean => badgeKinds.includes(value);
export const badgeSizes = ['tiny', 'small', 'medium', 'large'] as const;
// eslint-disable-next-line @typescript-eslint/no-type-alias
export type BadgeSize = typeof badgeSizes[number];
export const validateIsBadgeSize = (value: BadgeSize): boolean => badgeSizes.includes(value);

export interface BadgeColorDefinition {
  text: string;
  bg: string;
}

export type BadgeColorDefinitionList = {
  [key in BadgeKind]: BadgeColorDefinition;
};

export type BadgeSizeClassList = {
  [key in BadgeSize]: string;
};
