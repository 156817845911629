import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'GridLayout',
  props: {
  cols: {
    type: Number,
    default: 1,
    validator: (value: number) => value > 0
  },
  rows: {
    type: Number,
    default: 1,
    validator: (value: number) => value > 0
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('grid h-full w-full grid-cols-' + __props.cols + ' grid-rows-' + __props.rows)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})