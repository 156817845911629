import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center" }
const _hoisted_2 = ["id", "checked", "disabled", "readonly"]
const _hoisted_3 = ["for"]

import { useId } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PermissionGroupItem',
  props: {
  modelValue: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: ''
  },
  readonly: {
    type: Boolean,
    default: false
  }
},
  emits: ["update:modelValue"],
  setup(__props) {

const props = __props;


const id = useId();
const onClick = (ev: UIEvent) => {
  if (props.readonly || props.disabled) {
    ev.preventDefault();
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      id: _unref(id),
      type: "checkbox",
      checked: __props.modelValue,
      disabled: __props.disabled,
      readonly: __props.readonly,
      class: _normalizeClass(["form-checkbox mr-3", __props.readonly ? 'cursor-default' : 'cursor-pointer']),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (onClick($event))),
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).checked)))
    }, null, 42, _hoisted_2),
    (__props.label?.length)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["font-medium text-base select-none cursor-pointer", __props.modelValue ? 'text-gray-900' : 'text-gray-500']),
          for: _unref(id)
        }, _toDisplayString(__props.label), 11, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}
}

})